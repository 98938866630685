<template>
  <div>
    <div class="txt" style="white-space: pre-wrap;">{{ textContent }}</div>
  </div>
</template>
<script setup>
import {computed, ref} from 'vue';
import axios from 'axios';
import {useRoute} from "vue-router";
const route = useRoute()

const textContent = ref("");

const url = computed(() => route.query.url);


const transformData = (data) => {
  return new Promise((resolve) => {
    let reader = new FileReader();
    reader.readAsText(data, 'UTF-8');
    reader.onload = () => {
      resolve(reader.result)
    }
  })
}

axios.get(url.value, {
  responseType: "blob",
  transformResponse: [
    async function (data) {
      return await transformData(data);
    },
  ],
}).then(res => {
  res.data.then((data) => {
    textContent.value = data;
  })
})

</script>
<style lang="less" scoped>
.txt{
  width: 800px;
  margin: auto;
  margin-top: 20px;
}
</style>
