<template>
  <div class="outbox">
    <audio-player :option="audioOption"/>
  </div>
</template>

<script>
import AudioPlayer from "vue3-audio-player";
import "vue3-audio-player/dist/style.css";
import {computed} from "vue";
import {useRoute} from "vue-router";
import musicImg from "@/assets/music.png"

export default {
  name: "App",
  components: {AudioPlayer},
  setup() {
    const route = useRoute()
    const audioOption = computed(() => {
      return {
        src: route.query.url,
        title: route.query.title,
        coverImage: musicImg,
      }
    });
    return {
      audioOption,
    };
  },
};
</script>
<style lang="less" scoped>
.outbox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;

  & > div {
    min-width: 800px;
  }
}

:deep(.audio__player-play-and-title) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

:deep(.audio__player-play-icon) {
  top: 1.9rem !important;
  left: 1.9rem !important;
}
</style>