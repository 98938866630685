<template>
  <ElDialog
      v-model="isShow"
      :title="$t('common.rename')"
      :footer="null"
      centered
      :width="520"
      destroyOnClose
      @cancel="close"
      :class="[isHeadLine ? 'headline' : 'noheadline']"
  >
    <div style="display: flex;justify-content: center;align-items: center">
      <span class="title_cdir" style="display:inline-block;width: 100px;line-height: 30px"
            v-if="targetItem.isFolder===1">{{$t("common.folder.name")}}:</span>
      <span class="title_cdir" style="display:inline-block;width: 100px;line-height: 30px" v-else>{{$t("common.file.name")}}:</span>
      <ElInput
          type="text"
          v-model="fileDirName"
          style="height: 32px; width: 100%;"
          @keydown.enter.native="changeTargetFile"
      />
    </div>
    <template #footer>
      <div class="dialog-footer">
        <ElButton @click="close">{{
            $t("common.cancel")
          }}
        </ElButton>
        <ElButton type="primary" @click="changeTargetFile" :loading="loading">{{
            $t("common.confirm")
          }}
        </ElButton>
      </div>
    </template>
  </ElDialog>
</template>

<script setup>
import {ElButton, ElDialog, ElInput, ElMessage} from "element-plus";
import {ref, defineProps, watch, defineEmits, computed, onMounted} from 'vue'
import {useStore} from "vuex";
import dayjs from "dayjs";
import {useRoute} from "vue-router";
import router from "@/router";

const store = useStore();
const emit = defineEmits()
const isShow = ref(false);
const fileDirName = ref("")
const loading = ref(false)


const {value, isHeadLine, targetItem} = defineProps({
  value: {type: Boolean, default: false},
  isHeadLine: {type: Boolean, default: false},
  targetItem: {
    type: Object, default: {}
  }
})

onMounted(() => {
  fileDirName.value = targetItem.fileName;
})

watch(() => value, (val) => {
  isShow.value = val;
}, {immediate: true})
const close = () => {
  emit("close");
}
const route = useRoute()
const changeTargetFile = async () => {
  console.log(targetItem, 'targetItem')
  targetItem.fileName = fileDirName;
  await store.dispatch("cloud/editFileApis", targetItem)
  close()
}
</script>
<style scoped lang="less">
.fx-j-c {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.title_cdir {
  color: rgba(0, 0, 0, .85);
  font-weight: 700;
  font-size: 14px;
}

.fx-j-r {
  display: flex;
  justify-content: right;
  margin-top: 40px;
}

</style>
