<template>
 <SettingGuide></SettingGuide>
</template>

<script setup>
import SettingGuide from "@/views/FileDoc/components/SettingGuide.vue";

</script>


<style lang="less" scoped>

</style>