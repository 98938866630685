<template>

  <Cards>
    <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
      <el-tab-pane
          :label="$t('common.customer.level.setting')"
          name="1"
          v-if="isSuperAdmin"
          lazy
      >
        <div>
          <TableEditeInput/>
        </div>
      </el-tab-pane>
      <el-tab-pane
          :label="$t('common.customer.setting.followup')"
          name="2"
          v-if="isSuperAdmin"
          lazy
      >
        <!-- 自定义 -->
        <MyAlert/>
        <div class="table_tops">
          <div style="font-size: 14px">{{ $t("common.customer.info4") }}</div>
          <div class="show_userName" v-for="item in showCheckList">{{ item.userName }}</div>
          <el-button type="primary" link @click="showDialog"
          >{{ $t("common.member.add") }}
          </el-button
          >
        </div>
        <FollowClient/>
        <el-dialog
            v-model="dialogVisible"
            :title="$t('common.customer.info5')"
            width="30%"
            :before-close="handleClose"
        >
          <el-checkbox-group v-model="checkList">
            <el-checkbox
                v-for="item in AllTableData"
                :label="JSON.stringify({id: item.id, userId: item.userId})"
                :key="item.id"
            >{{ item.userName }}
            </el-checkbox
            >
          </el-checkbox-group>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="dialogVisible = false">{{ $t("common.sss16") }}</el-button>
              <el-button type="primary" @click="updateFollowUsers">
                {{ $t("common.confirm") }}

              </el-button>
            </span>
          </template>
        </el-dialog>
      </el-tab-pane>
      <el-tab-pane
          :label="$t('common.customer.branch.setting')"
          name="3"
          lazy
      >
        <TopSearch :adminShow="true" :searchShow="true" @searchKey="getSearchKey"/>
        <div>
          <div class="top_tips">
            <h2 style="font-weight: 500;font-size: 21px !important;">{{ $t('common.customer.branch.setting') }}</h2>
            <div>
              <el-button
                  type="primary"
                  @click="handleClickBranch"
              >{{ $t('common.customer.branch.batch') }}
              </el-button
              >
              <!--              <el-button-->
              <!--                  type="primary"-->
              <!--                  v-show="userIsAdmin"-->
              <!--                  :icon="CirclePlus"-->
              <!--                  @click="handleClickAppends"-->
              <!--              >{{ $t('common.customer.branch.push') }}-->
              <!--              </el-button-->
              <!--              >-->
              <ConfirmDeletePopover
                  :visible="popoverVisibleAll"
                  :width="164"
                  :message="$t('common.cds1')"
                  @update:visible="(value) => (popoverVisibleAll = value)"
                  @confirm:delete="handleDelete"
              >
                <template #settings>
                  <el-button
                      type="primary"
                      @click="handleClickRemove"
                  >{{ $t('common.move.to.seas') }}
                  </el-button
                  >
                </template>
              </ConfirmDeletePopover>
            </div>
          </div>
          <!-- 客户分管设置 -->
          <el-table
              v-loading="isLoading"
              :data="customerList"
              :style="{width: '100%', marginTop: '16px',minHeight:customerList.length===0?'200':customerList.length-1<10?'max-content':'580px'}"
              @selection-change="handleSelectionChange"
              :cell-style="rowClassName"
              :header-cell-style="headerClassName"
              :height="customerList.length-1<10?'max-content':'560px'"
          >
            <el-table-column type="selection" fixed="left" width="60"/>
            <el-table-column
                v-for="(item, index) in table_columns_admin"
                :key="item.key"
                :label="item.key"
                :prop="item.value"
                label="Date"
                :width="item.value==='customerCode'?200:item.value==='customerOrigin'?'240':item.value==='customerName'?auto:(flexWidth(item.value, customerList, item.key))"
                :min-width="item.value==='customerName'?300:auto"
                :show-overflow-tooltip="item.value==='customerName'||item.value==='customerCode'||item.value==='customerOrigin'?true:false"
            >
              <!-- 分管特殊处理 -->
              <template #default="{ row, column, $index }">
                <template v-if="item.key === $t('common.bybranch')">
                  <div class="hover" v-if="row.mangerVOList&&row.mangerVOList.length > 0">
                    <div
                        :ref="ref => (refMap[row.customerCode] = ref)"
                    >
                      <!-- 触发内容1 -->
                      <el-button link type="primary" @mouseover="handleRef(refMap[row.customerCode], row, 2)">
                        {{ row.mangerVOList && row.mangerVOList.length }}
                      </el-button>
                    </div>
                  </div>
                  <template v-else>
                    <el-button link type="info">
                      {{ row.mangerVOList && row.mangerVOList.length }}
                    </el-button>
                  </template>
                </template>
                <template v-else-if="item.key === $t('common.contact')">
                  <div class="hover" v-if="row.maiContactVOList&&row.maiContactVOList.length > 0">
                    <div
                        :ref="ref => (refMap[row.id] = ref)"
                    >
                      <!-- 触发内容1 -->
                      <el-button link type="primary" @mouseover="handleRef(refMap[row.id], row, 1)">
                        {{ row.maiContactVOList.length }}
                      </el-button>
                    </div>
                  </div>
                  <div v-else>
                    <el-button link type="info">
                      {{ row.maiContactVOList && row.maiContactVOList.length }}
                    </el-button>
                  </div>
                </template>
                <template v-else-if="item.key ===$t('common.lastcontact.time')">
                  <!--                  <div>{{ row.lastTime ? row.lastTime.split(' ')[0] : row.lastTime }}</div>-->
                  <!--                  <div>{{ row.lastTime ? row.lastTime.split(' ')[1] : row.lastTime }}</div>-->
                  <div>{{ row.lastTime }}</div>
                </template>
                <template v-else-if="item.key ===$t('common.customer.Region')">
                  <div style="cursor: pointer">
                    <div
                        :ref="ref => (refMap[`${row.id}_img`] = ref)"
                        @mouseover="handleRef(refMap[`${row.id}_img`], row, 3)"
                        v-if="row.zoneAddress"
                    >
                      <!-- 触发内容1 -->
                      <el-image :src="flag[row.zoneCode]"
                                style="width:26px;margin-right:2px;vertical-align: middle;" :key="row.zoneCode" lazy/>
                    </div>
                  </div>
                </template>
              </template>
            </el-table-column>
            <!-- 操作 -->
            <el-table-column :label="$t('common.cz')" fixed="right" :width="180">
              <template #default="{ row, column, $index }">
                <el-button
                    link
                    type="primary"
                    @click="handleClickTargetData(row)"
                >{{ $t('common.archives') }}
                </el-button
                >
                <el-button
                    link
                    type="primary"
                    @click="handleClickBran(row)"
                >{{ $t('common.branch') }}
                </el-button
                >
                <ConfirmDeletePopover
                    :visible="row.popoverVisibleRemove"
                    :width="164"
                    :message="$t('common.cds1')"
                    @update:visible="(value) => (row.popoverVisibleRemove = value)"
                    @confirm:delete="deleteRow(row, column, $index)"
                >
                  <template #settings>
                    <el-button
                        link
                        type="primary"
                        @click="row.popoverVisibleRemove = true"
                    >{{ $t('common.move.to.seas') }}
                    </el-button
                    >
                  </template>
                </ConfirmDeletePopover>
              </template>
            </el-table-column>
            <template #empty>
              <el-empty :description="$t('common.data.empty')" :image="emptyUrl"/>
            </template>
          </el-table>
        </div>
        <!-- 分页 -->
        <div class="pages">
          <el-pagination
              popper-class="page"
              v-model:current-page="current"
              v-model:page-size="size"
              :page-sizes="[10,30,50]"
              background
              layout="sizes,total, prev, pager, next"
              v-if="totals"
              :total="totals"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane lazy name="4" :label="$t('common.customer.receive.examine')" v-if="isSuperAdmin||isAdmin">
        <template #label>
          {{ $t('common.customer.receive.examine') }}
          <el-badge
              v-if="myEmailList.length>0 && myEmailList[4].count !== 0"
              :value="myEmailList[4].count"
              class="item"
              type="danger"
          ></el-badge>
        </template>
        <div>
          <!-- heander -->
          <div class="top_tips">
            <h2 style="font-weight: 500">{{ $t('common.customer.receive.examine') }}</h2>
            <div>
              <ConfirmDeletePopover
                  :visible="popoverVisiblePass"
                  :width="164"
                  :message="$t('common.cds9')"
                  @update:visible="(value) => (popoverVisiblePass = value)"
                  @confirm:delete="handlePassList(row, column, $index)"
              >
                <template #settings="{ row, column, $index }">
                  <el-button
                      type="primary"
                      @click="clickPass"
                  >{{ $t('common.pass') }}
                  </el-button
                  >
                </template>
              </ConfirmDeletePopover>

              <ConfirmDeletePopover
                  :visible="popoverVisibleReject"
                  :width="164"
                  :message="$t('common.cds10')"
                  @update:visible="(value) => (popoverVisibleReject = value)"
                  @confirm:delete="handleRejectList(row, column, $index)"
              >
                <template #settings="{ row, column, $index }">
                  <el-button
                      type="primary"
                      @click="clickReject"
                  >{{ $t('common.reject') }}
                  </el-button
                  >
                </template>
              </ConfirmDeletePopover>
            </div>
          </div>
          <!-- 审批表 -->
          <el-table
              v-loading="isLoading"
              :data="allAdminList"
              style="width: 100%;margin-top: 20px"
              @selection-change="handleSelectionChangeAdmin"
              :cell-style="rowClassName"
              :header-cell-style="headerClassName"
          >
            <!--              @row-click="handleClickTargetData"-->
            <el-table-column type="selection" width="60" fixed="left"/>
            <el-table-column
                v-for="(item, index) in table_columns_required"
                :key="item.key"
                :label="item.key"
                :prop="item.value"
                label="Date"
                :width="item.value==='customerCode'?200:item.value==='customerOrigin'?240:(flexWidth(item.value, allAdminList, item.key))"
                :show-overflow-tooltip="item.value==='customerName'||item.value==='customerCode'||item.value==='customerOrigin'?true:false"
            >
              <template #default="{ row, column, $index }">
                <template v-if="item.key ===$t('common.contact')">
                  <div class="hover" v-if="row.maiContactVOList&&row.maiContactVOList.length > 0">
                    <div
                        :ref="ref => (refMap[row.id] = ref)"
                    >
                      <!-- 触发内容1 -->
                      <el-button link type="primary" @mouseover="handleRef(refMap[row.id], row, 1)">
                        {{ row.maiContactVOList && row.maiContactVOList.length }}
                      </el-button>
                    </div>
                  </div>
                  <div v-else>
                    <el-button link type="info">
                      {{ row.maiContactVOList && row.maiContactVOList.length }}
                    </el-button>
                  </div>
                </template>
                <template v-else-if="item.key ===$t('common.customer.Region')">
                  <div style="cursor: pointer">
                    <!-- 触发内容1 -->
                    <el-image :ref="ref => (refMap[`${row.id}_img`] = ref)"
                              @mouseover="handleRef(refMap[`${row.id}_img`], row, 3)"
                              v-if="row.zoneAddress"
                              :src="flag[row.zoneCode]"
                              lazy
                              style="width:26px;margin-right:2px;vertical-align: middle;"
                              :key="row.zoneCode"
                    />
                  </div>
                </template>
              </template>
            </el-table-column>
            <el-table-column :label="$t('common.cz')" fixed="right" width="120">
              <template #default="{ row, column, $index }">
                <ConfirmDeletePopover
                    :visible="row.popoverVisiblePass"
                    :width="164"
                    :message="$t('common.cds9')"
                    @update:visible="(value) => (row.popoverVisiblePass = value)"
                    @confirm:delete="passCheck(row, column, $index)"
                >
                  <template #settings>
                    <el-button
                        link
                        type="primary"
                        @click.native.stop="row.popoverVisiblePass = true"
                    >{{ $t('common.pass') }}
                    </el-button
                    >
                  </template>
                </ConfirmDeletePopover>
                <ConfirmDeletePopover
                    :visible="row.popoverVisibleReject"
                    :width="164"
                    :message="$t('common.cds10')"
                    :ref="`popover-${$index}`"
                    @update:visible="
                    (value) => (row.popoverVisibleReject = value)
                  "
                    @confirm:delete="handleReject(row, column, $index)"
                >
                  <template #settings>
                    <el-button
                        link
                        type="primary"
                        @click.native.stop="row.popoverVisibleReject = true"
                    >{{ $t('common.reject') }}
                    </el-button
                    >
                  </template>
                </ConfirmDeletePopover>
              </template>
            </el-table-column>
            <template #empty>
              <el-empty :description="$t('common.data.empty')" :image="emptyUrl"/>
            </template>
          </el-table>
        </div>
        <div class="pages">
          <el-pagination
              popper-class="page"
              v-model:current-page="currentList"
              v-model:page-size="sizeList"
              :page-sizes="[10,30,50]"
              background
              layout="sizes,total, prev, pager, next"
              v-if="totalsList"
              :total="totalsList"
              @size-change="handleSizeChangeList"
              @current-change="handleCurrentChangeList"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane lazy :label="$t('common.customer.number.update')" name="5" v-if="isSuperAdmin">
        <el-row :gutter="24">
          <el-col :span="8">
            <el-form
                ref="ruleFormRef"
                style="margin-top: 20px"
                :model="ruleForm"
                :rules="rules"
                label-width="120px"
                class="ruleFormInput"
            >
              <el-form-item style="margin-bottom: 24px" :label="$t('common.customer.number.old')"
                            prop="oldClientNumber">
                <el-input v-model="ruleForm.oldClientNumber"/>
              </el-form-item>
              <el-form-item style="margin-bottom: 24px" :label="$t('common.customer.number.new')"
                            prop="newClientNumber">
                <el-input v-model="ruleForm.newClientNumber"/>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitForm(ruleFormRef)"
                >{{ $t('common.confirm') }}
                </el-button
                >
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane
          lazy
          v-if="isSuperAdmin"
          :label="$t('common.customer.batchhandover')"
          name="6"
      >
        <MyAlert :textInner="alert1"/>
        <div>
          <el-form
              ref="ruleFormRemove"
              :model="ruleFormTabAB"
              :rules="rules"
              class="demo-ruleForm"
              :size="formSize"
          >
            <div class="top">
              <el-form-item :label="`${$t('common.salesman')} A`">
                <el-select
                    :placeholder="$t('common.please.select')"
                    style="width: 200px"
                    filterable
                    v-model="ruleFormTabAB.A"
                >
                  <el-option
                      v-for="item in tableDataSelect"
                      :label="item.userName"
                      :value="item.userId"
                      :key="item.id"
                  />
                </el-select>
              </el-form-item>
              <el-image
                  style="width: 32px; height: 32px; margin: 0px 20px 0 20px"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAAXNSR0IArs4c6QAABFhJREFUWEftll1oW2UYx39Pktraba6dXZOU4aYou5nOj3rRDURR8VYExxA/mYIWaU46S5HqqO1Q5+aaTJ2KTNyHc8yJIl4o24XCNiZOEXcxOnXKbM3pFFuELkWT88hJky5Ncr6kBS88V8k5//d5fu/z8T6v8B97ZL55YkO6gjCNkRw/jXRL1svfvAFFU7pBlGcRlhchsiK8O5mn989u+cMJbF6AYil9CehxcDp8wWKNE9ScA8VTeovCF66pEd42E7KhlmbOgWJp3YPygEetTOnftIz1yGSlbu6BUvo1cKNX8Vqw+rwh3/1roEK3wK0CSzTESCTPkZEaxRlN6zFR1ngB5SKs/P1JORMYqPlNXdyQZafCeiBUZiCLsMUcZ5B+sUrvoykdFHjGA+hXM8EyRDQQ0NLXdGEkx1FVVrs42GMa8lDp++Kd2nzpX/wALHFsbeGJTELeCFzU8ZRuV0h6hR/lXjMph0q6+Cvarnk+BS6vWqs8ZyalP/g5tEPrYxa/AYs8geBz05DbynVtKV2ZhwMC1xffjws8ljHkAzd7jl3WtkNvsCy+8QFjS7KmIY32j/irulxzDKCsR7ikYn0eOCxKXyYpNW07ArUO6dqQcNQnkGVOUBdv4j4FuzYWeKyzEAbMLgYqC9sRKLpVW6UOE/A8qwS+B15XeNmPfgZW2G0meAQudpurs+iQHhbhDs8oCadQVgWCKRkVBs2EbLr418VbNK2rUI4JXOYisyf3QqiqF8992AKFvECHachX9n/PdBRb+BMgWsPDSYSzKOvKvuUVVCDii8iGEo6PJWStLyBbtDSt14SV/UB70Ymq8JYqm0JwFmi0d4pwIBxis5XnM+AKv0CFSIXoGOuSE64Riqf1JlUGgbsqxsa0L2FKlYgI7+XCbC7NpnhaH9fKNCt9uKReIJUxJFkb6KCGYxleRNnokdbT4Tx3j26sHpKV0YmldRSlzTFqwikzIddVAa3o14ZsEx8L3Okn5ArfivCCOc6h0pCNb9d2EerL11vCR0CLC9AFMyELZgP1ayjWxPvAPX5gZmmEMypsGatnbyxbGK6Basi2la9j0SygWFqfQtnqF0ZhPyG+xKJXmEnHueJQ9Tqtq9w0KM0zQNEhvUpChQOuMJNqPMMIR8rfi3IiY8g+O81TTTwK9ALL/G6oUjcLKJ5Wu41tozUfhX1jhrjfle0bgm1DCw1hX2V/DgIXaqSzEKG2bdpiRfgFaHAB+jASoqv8ezjC5LlOGS+8O6jh6CjrBJ5GuFZgV8YQxw06+SkAxVN6v8LeILuZPobYlZmgM9rMg6KFdF1dZmPcnKCVfskFsVsAig3pOwgz19AABk4XL3A160Ysbs50y8kA9qZnWSylx4GOIAt9aYWHzYTs9qUtikpA9jy6MshCX1qlx0zKNl/aCiD7IlZrmgexVaUV6MsY8nwQI6UI/Q/k2vbRtN4uzB6GQcLspLWE4fNd8mMQW543xiDG5kL7D3n/dCF/GHS6AAAAAElFTkSuQmCC"
                  :fit="fit"
              />
              <el-form-item :label="`${$t('common.salesman')} B`">
                <el-select
                    :placeholder="$t('common.please.select')"
                    style="width: 200px"
                    filterable
                    v-model="ruleFormTabAB.B"
                >
                  <el-option
                      v-for="item in tableDataSelect"
                      :label="item.userName"
                      :value="item.userId"
                      :key="item.id"
                  />
                </el-select>
              </el-form-item>
            </div>
            <div class="bottom">
              <el-form-item>
                <el-button @click="changeTransfer" type="primary">{{
                    $t("common.confirm")
                  }}
                </el-button>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </el-tab-pane>
      <el-tab-pane
          lazy
          :label="$t('common.customer.merge')"
          name="7"
          v-if="isSuperAdmin"
      >
        <MyAlert :textInner="alert2"/>
        <div>
          <el-form
              ref="ruleFormRemove"
              :model="ruleFormTabABMerge"
              :rules="rules"
              class="demo-ruleForm"
              :size="formSize"
          >
            <div class="top">
              <el-form-item :label="$t('common.customer')+'A'">
                <el-select
                    :placeholder="$t('common.please.select')"
                    style="width: 200px"
                    filterable
                    v-model="ruleFormTabABMerge.A"
                    :loading="loadingCustomer"
                >
                  <!-- 循环 -->
                  <el-option
                      v-for="item in customerListAll"
                      :label="item.customerName"
                      :value="item.id"
                      :key="item.id"
                  />
                </el-select>
              </el-form-item>
              <el-image
                  style="width: 32px; height: 32px; margin: 0px 20px 0 20px"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAAXNSR0IArs4c6QAABQNJREFUWEftlmuIlGUUx3/nnV1dxbzrvKPdtFI0irKwPihRKShWopUQ5gUqJbTd2VDK/LKU1oewnVlRMQmLykhLQSsJUqI7gl3IMCNNRXdmXNRVvKy7M+8/3nVnfXecdWbUD37wwDC85/mf8/w4z3kuxjVmdo3xcB2o0Ipcr9CVVahGZZG+zJfHZDPSHmxORVjJNMsUSpwdH/ym+nndeUswATgDfJpsZCk11pwvxyWXzI3pA2BmMNBgXSJq04sCqlNXV3yHGJ2j35CM2rSSgAbENCYE3+cL8sSYI9X2YyGoSJ2my+OjfDrHYVR9pf2WO9ZphcJxVZmI5UsmozJVZcsLAYVjWmrwWic5pqeqbF3RQG6tnsLYkLesxtRElW0qBOTWajbG2rw5xH2Javu1aCDq1DUi/pS4IydoT7KRuztryqD21rWqaDrB78DwnBxfJKP2eEk95IsHL9OwTIjPZfyB6G5wi6WZmlhgBwpVJzs+oFb3hBw+BrZJ9DMY0uww4VilnSwe6PzuqEJEgQjGV8ABxItmfOM5zEm9ZP9dCir8tgY65bwumA109bc7ohljBvCXlTEzMb+IJRsU03AP/P4Y0T6hsRBoQLzf5jsqMS1VbdvzQUXqNFYenwEDA+OzJLqYsabNd1biuVS1fZJzrFz47F+rSMjYaX5VAuaEGJFJ02zG3oC7yRHj6nO2f2SFRqiFHUCPgNbDwQ210CcTYk/WL8iYw+RkpX2Z9XXY9pG41kg8H4QR/JuKWmtju3EdRgwKjO9LNjKcGktnfeG4NpqY0qFyYkey2h5ozRFTfWsbXLBERSND99dYk+/qAOTGtA8YkrMMR81jildGCx5bDXrnTPZwstq+zfrcuBIINyfHKTNmetDVhH/6dwmOZ2BsQ9R+uAgoEtcMiVcwRqIA7Plm9KvQvb3cIu03OLT+JgJhYLfBXsGD0HpdtOsBry3WafsXsN8/68oy1Bx62c5eBJSdrM9q9apo4nZBWNDTPCpwMM9oNjjpiPp0GXucFt4wWndiuwlaHHgmEWXjgBWErYWBiBscKPdFCtEUaqaxpRuHGubZqZxKXqjCgBXq0dBAU7AfcsXB70itRsnY2YnmqNONm+vnmn+7F7S+deqZPZdae6j/Mg3rEmJsfdTeKxjdJgjHtdjEks70EuNT1eYvZ0ELx/SEL0pFbbO5yzWRNIuSIcZTaecKRl8AWmJicad6Y1KyyvwDtbA9rZA7hl0Sq8yNyW/WUOGoDgoPYxViXidxZ9PitjL4GuOuUnL7QH63l2p702JsCFaZMfmiYGNh8jjvuL1ZDzxZSvIreuT3Wqk+Fc1s8be6Gf9ITAJ+SkZtQSkQQe1lAQ1are7eORbgUQn0a01oPILYjiHEpkw58xrmWbJUsJKBIst1vzzWow4n+u5k1Ea6tTqIcVMbRMoTU45U28+lQJUE5NZpNB7bci5Of75l/jKFY/rQ4NkAwGk5jEtV2i/FQhUPtFrl7ln+BobmJpd41H+KRGJ6QfBucFxwuLmJO4+/aieKgSoaKBzTYwZb8iT1MuUM9vvFrdNDeLRftAHtgmTUll1VIDeuWYEHWm5u/520SBDPfUud73fWJKI256oCUSPH7cMs85gs417gRiB7c+eb6xTGLsTWc92IH597lZfsohlXq7z/Gfpj9HQyVJSV46Q9MjicrjjNsYOL7HgxFcnVFN1Dl5P8cmKuOaD/AW4e15QUdPS6AAAAAElFTkSuQmCC"
                  :fit="fit"
              />
              <el-form-item :label="$t('common.customer')+'B'">
                <el-select
                    :placeholder="$t('common.please.select')"
                    style="width: 200px"
                    filterable
                    v-model="ruleFormTabABMerge.B"
                    :loading="loadingCustomer"
                >
                  <el-option
                      v-for="item in customerListAll"
                      :label="item.customerName"
                      :value="item.id"
                      :key="item.id"
                  />
                </el-select>
              </el-form-item>
            </div>
            <div class="bottom">
              <el-form-item>
                <el-button @click="changeMerge" type="primary">{{
                    $t("common.confirm")
                  }}
                </el-button>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </el-tab-pane>
    </el-tabs>
  </Cards>

  <Dialog
      v-model="dialogVisibleBranch"
      :title="$t('common.branch.setting')"
      :dialogVisible="dialogVisibleBranch"
      :isHeadLine="true"
      :width="400"
      @update:dialogVisible="(val) => (dialogVisibleBranch = val)"
  >

    <template #content>
      <el-checkbox-group v-model="checkListBranch">
        <el-checkbox
            v-for="item in tableDataSelect"
            :label="JSON.stringify({manageId: item.userId, userName: item.userName})"
            :key="item.id"
        >{{ item.userName }}
        </el-checkbox
        >
        <!--        :disabled="defaultSelected.includes(JSON.stringify({manageId: item.userId, userName: item.userName}))"-->
      </el-checkbox-group>
    </template>
    <template #footer>
      <div class="buttons_bottom">
        <el-button @click="dialogVisibleBranch = false">{{ $t('common.sss16') }}</el-button>
        <el-button type="primary" @click="handleClickSubmit">{{ $t('common.confirm') }}</el-button>
      </div>
    </template>
  </Dialog>

  <!--  el-popover-->
  <el-popover
      v-model:visible="visiblePopover"
      :virtual-ref="tempRef"
      virtual-triggering
      :width="'max-content'"
      trigger="hover"
      :show-after="500"
      effect="dark"
  >
    <template v-if="popoverType === 1">
      <!-- 业务逻辑1 -->
      <div
          v-html="
                          '<div>' +
                          activeItemElpopover.maiContactVOList
                            .map((item) => {
                              return `${item.contactName}<br/>`;
                            })
                            .join('') +
                          '</div>'
                        "
      ></div>
    </template>
    <template v-else-if="popoverType === 2">
      <!-- 业务逻辑2 -->
      <div
          v-html="
                '<div>' +
                activeItemElpopover.mangerVOList
                  .map((item) => {
                    return `${item.managerName}<br/>`;
                  })
                  .join('') +
                '</div>'
              "
      ></div>
    </template>
    <template v-else-if="popoverType===3">
      <!-- 业务逻辑3 -->
      {{ activeItemElpopover.zoneAddress }}
    </template>
  </el-popover>

  <!-- 抽屉 -->
  <el-drawer size="530px" v-model="isShowDrawers" :direction="direction">
    <template #header>
      <div style="display: flex">
        <h4>{{ activeItems.customerName }}</h4>
        <router-link
            :to="
            '/email/customer/detail/' +
            activeItems.id +
            '?tabKey=1&type=' +
            activeItems.type
          "
            style="color: #409eff"
        >
          <el-button v-if="isShowEdit" link type="primary">{{ $t('common.edit') }}</el-button>
        </router-link>
      </div>
    </template>
    <template #default>
      <div class="top_titles">
        <div>
          <div>{{ $t('common.customer.numberList') }}：{{ activeItems.customerCode }}</div>
          <div>{{ $t('common.byinput') }}：{{ activeItems.createName }}</div>
        </div>
      </div>
      <div style="margin-top: 40px">
        <!-- table -->
        <div>
          <div><h3>{{ $t('common.cds25') }}</h3></div>
          <div class="small_box">
            <TableShows :table-data="companyInfo"/>
          </div>
        </div>
        <div style="margin-top: 40px">
          <div><h3>{{ $t('common.marketing.information') }}</h3></div>
          <div class="small_box">
            <TableShows :table-data="marketingInfo"/>
          </div>
        </div>
        <div style="margin-top: 40px">
          <div><h3>{{ $t('common.contact') }}</h3></div>
          <div
              class="small_box"
              v-for="(item, index) in contactInfo"
              :key="index"
          >
            <TableShows :table-data="item"/>
          </div>
        </div>
      </div>
    </template>
  </el-drawer>
</template>
<script setup>
import {ref, computed, onMounted, watch} from "vue";
import Cards from "./Cards.vue";
import TopSearch from "./TopComponents/TopSearch.vue";
import MyAlert from "./TopComponents/MyAlert.vue";
import TableEditeInput from "./Tables/TableEditeInput.vue";
import {i18n} from "../../lang/index";
import FollowClient from "./Tables/FollowClient.vue";
import Dialog from "@/components/Dialog.vue";
import {useStore} from "vuex";
import {table_columns_admin, table_columns_required} from './map'
import {
  updateCustomerData,
  getMailCustomerList,
  getMailCustomerSetting,
  batchTransfer,
  batchTransferCustomer,
  getMailCustomerAudit,
  updateMailCustomerAudit, updateFollowUser,
  getFollowUser, requestAllCustomer
} from "@/api/customer.js";
import {ElMessage, ElTable} from "element-plus";
import TableShows from "./Tables/TableShows.vue";
import ConfirmDeletePopover from "@/components/ConfirmDeletePopover.vue";
import {debouncePlus, throttle} from "@/utils/mylodash"
import {emptyUrl, TIME_OUT} from "@/utils/statusParams";
import flag from "@/assets/flag";
import {useRoute, useRouter} from "vue-router";
import {flexWidth} from "@/utils/tableWidth";
import sessionStorageUtil from "@/utils/sessionStorageUtil";

const router = useRouter()
const route = useRoute()
const store = useStore();
const direction = ref("rtl");
// 跟进人list
const checkList = ref([]);
// 展示跟进人数据
const showCheckList = ref([])

// 权限控制
const isSuperAdmin = computed(() => store.getters["user/isSuperAdmin"])
const isAdmin = computed(() => store.getters["user/isAdmin"])
// 标记
const teamId = computed(() => store.state.email.teamID);


// el-popover 优化
const refMap = ref({})
const tempRef = ref()
const visiblePopover = ref(false)
// -1-字句审核 0-整句审核 1-日志查看
const popoverType = ref(0)
const activeItemElpopover = ref(null)
const handleRef = debouncePlus((ref, item, type) => {
  tempRef.value = ref
  popoverType.value = type
  activeItemElpopover.value = item;
  if (type === 1) {
    // ...业务逻辑1
  } else {
    // ...业务逻辑2、3
  }
  visiblePopover.value = true
}, 500)


// 更新跟进人列表
const updateFollowUsers = async () => {

  const result = JSON.parse(JSON.stringify(checkList.value));
  const toBaseObj = result.map(item => JSON.parse(item))
  const targetObj = {
    mailFollowAndUserDTOList: [...toBaseObj],
    teamId: teamId.value
  }
  //console.log(targetObj, toBaseObj)
  let res = await updateFollowUser({...targetObj})
  if (res.code !== 200) {
    ElMessage.error(res.message)
    return
  }
  ElMessage.success(res.message)
  dialogVisible.value = false;
  await getFollowUserList()
};

//获取用户勾选状态
const getFollowUserList = async () => {
  let res = await getFollowUser({teamId: teamId.value})
  if (res.code !== 200) {
    ElMessage.error(res.message)
    return
  }
  const stringArray = res.result.map(item => (JSON.stringify({id: item.id, userId: item.userId})));
  checkList.value = stringArray;
  showCheckList.value = res.result

}
const showDialog = async () => {
  await getFollowUserList()
  dialogVisible.value = true;
};

// 搜索
const searchValue = ref({});
const getSearchKey = async (value) => {
  const newObj = JSON.parse(JSON.stringify(value));
  searchValue.value = newObj;

  // const tabCheck = localStorage.getItem("tabCheck");
  // activeName.value = isSuperAdmin.value ? tabCheck ? tabCheck : "1" : "3"
  if (activeName.value === "3") {
    // 列表数据
    await getCustomerList();
  }
};

// tab进行切换的时候
const handleClick = throttle(async (val) => {
  // console.log(val.props, "@@");
  const tabs = val.props.name;
  // localStorage.setItem("tabCheck", tabs);
  switch (tabs) {
    case "4":
      await getCustomerListAdmin();
      await store.dispatch('countshow/getTeamAllDataApis')
      break;
    case "2":
      await store.dispatch("groups/getAllUserList");
      await getFollowUserList();
      await getAllCustomer()
      break;
    case "3":
      await store.dispatch("groups/getAllUserListNo");
      await getCustomerList();
      break;
    case "6":
      await store.dispatch("groups/getAllUserListNo");
      break;
    case "1":
      if (!sessionStorageUtil.getItem("customerLevel")) {
        await store.dispatch("cusList/getMailCustomerLevel");
      }
      break;
    case "7":
      await getAllCustomer()
      break;
  }
}, TIME_OUT);

const loadingCustomer = ref(false)
const getAllCustomer = async () => {
  loadingCustomer.value = true
  await requestAllCustomer({
    teamId: teamId.value,
    type: 1,
  }).then((res) => {
    customerListAll.value = res.result;
    loadingCustomer.value = false
  }).catch(e => {
    ElMessage.error(e)
    loadingCustomer.value = false
  }).finally(() => {
    loadingCustomer.value = false
  });
}


watch(() => isSuperAdmin.value, () => {
  if (!isSuperAdmin.value) {
    // localStorage.setItem('activeMenu', '/email/inbox')
    router.push('/email/inbox')
  }
})


// 分管中的人
const tableDataSelect = computed(() => store.state.groups.AllTableDataNo);
//客户跟进 有权限
const AllTableData = computed(() => store.state.groups.AllTableData)
const contactList = computed(() => store.state.customer.contactList);

const dialogVisible = ref(false);

const ruleForm = ref({
  oldClientNumber: "",
  newClientNumber: "",
});

//客户批量移交
const ruleFormTabAB = ref({
  A: "",
  B: "",
});
//客户批量转交
const ruleFormTabABMerge = ref({
  A: "",
  B: "",
});
// alert1
const alert1 = [
  {text: i18n.global.t("other.text.customer.transfer.tip1")},
  {text: i18n.global.t("other.text.customer.transfer.tip2")},
];
const alert2 = [
  {text: i18n.global.t("other.text.customer.merge.tip1")},
  {text: i18n.global.t("other.text.customer.merge.tip2")},
];

// 默认选中的tab
const activeName = ref("1");

const ruleFormRef = ref(null);
const submitForm = () => {
  ruleFormRef.value.validate(async (valid, fields) => {
    if (valid) {
      const newObj = JSON.parse(JSON.stringify(ruleForm.value));
      const targetObj = {
        newCustomerCode: newObj.newClientNumber.toUpperCase(),
        oldCustomerCode: newObj.oldClientNumber.toUpperCase(),
        teamId: teamId.value
      };
      const res = await updateCustomerData({...targetObj});
      if (res.code !== 200) {
        ElMessage.error(res.message);
        return;
      }
      ElMessage.success(res.message);
      ruleFormRef.value.resetFields();
    } else {
      //console.log("error");
    }
  });
};

// 客户资料进行合并
const changeMerge = async () => {
  const targetObj = {
    newCustomerId: "",
    oldCustomerId: "",
    teamId: teamId.value,
  };
  targetObj.oldCustomerId = ruleFormTabABMerge.value.A;
  targetObj.newCustomerId = ruleFormTabABMerge.value.B;
  //console.log(targetObj);
  let res = await batchTransfer({...targetObj});
  if (res.code !== 200) {
    ElMessage.error(res.message);
    return;
  }
  ElMessage.success(res.message);
  ruleFormTabABMerge.value.A = "";
  ruleFormTabABMerge.value.B = "";
};

// 客户批量移交
const changeTransfer = async () => {
  const targetObj = {
    newUserId: "",
    oldUserId: "",
    teamId: teamId.value,
  };
  targetObj.oldUserId = ruleFormTabAB.value.A;
  targetObj.newUserId = ruleFormTabAB.value.B;
  //console.log(targetObj, "fff");

  let res = await batchTransferCustomer({...targetObj});
  if (res.code !== 200) {
    ElMessage.error(res.message);
    return;
  }
  ElMessage.success(res.message);
};

// 用于分页的客户
const customerList = ref([]);
// 所有客户--用于合并
const customerListAll = ref([]);
const isLoading = ref(false);
const getCustomerList = async () => {
  isLoading.value = true;
  const managerIdLocalStorage = JSON.parse(window.localStorage.getItem('userInfo')).id
  if (searchValue.value.managerId === "all") {
    searchValue.value.managerId = ""
  }
  await getMailCustomerList({
    ...searchValue.value,
    pageSize: size.value,
    pageNo: current.value,
    teamId: teamId.value,
    type: 1,
  }).then((res) => {
    const {records, size, total, pages} = res.result;
    customerList.value = records
    // visibleData.value = customerList.value.slice(0, 10)
    totals.value = total - 0;
    isLoading.value = false;
  });
};


// 客户审批-批量选中的值
const selectTargetListApprove = ref([]);
const handleSelectionChangeAdmin = (data) => {
  selectTargetListApprove.value = JSON.parse(JSON.stringify(data));
};

// 通过？驳回？
const popoverVisiblePass = ref(false);
const popoverVisibleReject = ref(false);
const myEmailList = computed(() => store.state.countshow.myEmailList)
// 单行选中
const passCheck = async (row, column, $index) => {
  const {id, recordId, customerName, userId} = row;
  const targetObj = {
    auditStatus: 2,
    mailCustomerAndRecordIdDTOList: [{customerId: id, customerName, recordId, userId,}],
    teamId: teamId.value,

  };

  console.log('########', targetObj)

  let res = await updateMailCustomerAudit({...targetObj});
  if (res.code !== 200) {
    ElMessage.error(res.message);
    return;
  }
  ElMessage.success(res.message);
  // 更新标志
  await store.dispatch('countshow/getTeamAllDataApis')
  await getCustomerListAdmin();
};
// 单行驳回
const handleReject = async (row, column, $index) => {
  const {id, recordId, customerName, userId} = row;
  const targetObj = {
    auditStatus: 3,
    mailCustomerAndRecordIdDTOList: [{customerId: id, customerName, recordId, userId}],
    teamId: teamId.value,
  };
  //console.log(targetObj, "@@@");
  let res = await updateMailCustomerAudit({...targetObj});
  if (res.code !== 200) {
    ElMessage.error(res.message);
    return;
  }
  ElMessage.success(res.message);
  await getCustomerListAdmin();
};

// 点击批量通过
const clickPass = () => {
  if (selectTargetListApprove.value.length === 0) {
    ElMessage.warning("请选择客户");
    return;
  }
  popoverVisiblePass.value = true;
};
// 点击批量驳回
const clickReject = () => {
  if (selectTargetListApprove.value.length === 0) {
    ElMessage.warning("请选择客户");
    return;
  }
  popoverVisibleReject.value = true;
};
// 批量确认
const handlePassList = async () => {
  const newObj = JSON.parse(JSON.stringify(selectTargetListApprove.value));
  const result = newObj.map((item) => {
    return {customerId: item.id, customerName: item.customerName, recordId: item.recordId, userId: item.userId};
  });
  const targetObj = {
    auditStatus: 2,
    mailCustomerAndRecordIdDTOList: [...result],
    teamId: teamId.value,
  };

  let res = await updateMailCustomerAudit({...targetObj});
  if (res.code !== 200) {
    ElMessage.error(res.message);
    return;
  }
  ElMessage.success(res.message);
  await getCustomerListAdmin();
};
// 批量驳回
const handleRejectList = async () => {
  const newObj = JSON.parse(JSON.stringify(selectTargetListApprove.value));
  const result = newObj.map((item) => {
    return {customerId: item.id, customerName: item.customerName, recordId: item.recordId, userId: item.userId};
  });
  const targetObj = {
    auditStatus: 3,
    mailCustomerAndRecordIdDTOList: [...result],
    teamId: teamId.value,
  };
  //console.log(targetObj, "@@@");

  let res = await updateMailCustomerAudit({...targetObj});
  if (res.code !== 200) {
    ElMessage.error(res.message);
    return;
  }
  ElMessage.success(res.message);
  await getCustomerListAdmin();
};

// 客户分管-分页
const current = ref(1);
const size = ref(10);
const totals = ref(0);

function handleSizeChange(pageSize) {
  size.value = pageSize;
  getCustomerList();
}


function handleSizeChangeList(pageSize) {
  sizeList.value = pageSize;
  getCustomerListAdmin();
}

function handleCurrentChange(page) {
  current.value = page;
  getCustomerList();
}

const isShow = ref(false);
const clickShowDraw = () => {
  isShow.value = !isShow.value;
};

// 分管对话框
const dialogVisibleBranch = ref(false);
// 选择的项
const checkListBranch = ref([]);
// 客户分管-批量选中的值
const selectTargetList = ref([]);
const handleSelectionChange = (data) => {
  selectTargetList.value = JSON.parse(JSON.stringify(data));
};

const flags = ref(0);
// 批量设置分管
const handleClickBranch = () => {
  if (selectTargetList.value.length === 0) {
    ElMessage.warning("请选择客户");
    return;
  }
  dialogVisibleBranch.value = true;
  flags.value = 1;
};
// 选中的行
const activeItems = ref({});
const handleClickBran = async (row) => {
  if (row) {
    // 这里要进行数据的显示
    const result = row.mangerVOList.map(item => JSON.stringify({manageId: item.managerId, userName: item.managerName}));
    checkListBranch.value = result;
    dialogVisibleBranch.value = true;
    activeItems.value = row;
    flags.value = 1;
  }
};
// 追加分管设置
const handleClickAppends = async () => {
  if (selectTargetList.value.length === 0) {
    ElMessage.warning("请选择客户");
    return;
  }
  dialogVisibleBranch.value = true;
  flags.value = 3;
};
// 移入公海
const popoverVisibleAll = ref(false);
const handleClickRemove = async (row) => {
  if (selectTargetList.value.length === 0) {
    ElMessage.warning("请选择客户");
    return;
  }
  popoverVisibleAll.value = true;
};
// 单行 + 批量 移入公海
const handleDelete = async (row, column, $index) => {
  const targetObj = {
    customerDTOList: [],
    managerDTOList: [],
    teamId: teamId.value,
    type: 2,
  };
  const checkoutUsers = checkListBranch.value.map(item => JSON.parse(item))
  targetObj.managerDTOList = [
    ...targetObj.managerDTOList,
    ...checkoutUsers,
  ];
  let newObj = null;
  if (selectTargetList.value.length >= 1) {
    // 批量移入公海
    newObj = JSON.parse(JSON.stringify(selectTargetList.value));
    const result = newObj.map((item) => ({customerId: item.id, customerName: item.customerName}));
    targetObj.customerDTOList = [...targetObj.customerDTOList, ...result];
  }
  let res = await getMailCustomerSetting({...targetObj});
  if (res.code !== 200) {
    ElMessage.error(res.message);
    return;
  }
  ElMessage.success(res.message);
  await getCustomerList();
  dialogVisibleBranch.value = false;
  checkListBranch.value = [];
};

const deleteRow = async (row, column, $index) => {
  const targetObj = {
    customerDTOList: [],
    managerDTOList: [],
    teamId: teamId.value,
    type: 2,
  };
  const checkoutUsers = checkListBranch.value.map(item => JSON.parse(item))
  targetObj.managerDTOList = [
    ...targetObj.managerDTOList,
    ...checkoutUsers,
  ];
  let newObj = null;
  if (selectTargetList.value.length === 0) {
    // 单个移入公海
    newObj = JSON.parse(JSON.stringify(row));
    targetObj.customerDTOList = [...targetObj.customerDTOList, {
      customerId: newObj.id,
      customerName: newObj.customerName
    }];
    let res = await getMailCustomerSetting({...targetObj});
    if (res.code !== 200) {
      ElMessage.error(res.message);
      return;
    }
    ElMessage.success(res.message);
    await getCustomerList();
    dialogVisibleBranch.value = false;
    checkListBranch.value = [];
  } else {
    ElMessage.warning("请使用批量移入公海");
  }

}

// 分管设置+追加分管
const handleClickSubmit = async () => {
  const checkoutUsers = checkListBranch.value.map(item => JSON.parse(item))
  const targetObj = {
    customerDTOList: [],
    managerDTOList: [],
    teamId: teamId.value,
  };
  // 分管设置
  if (flags.value === 1) {
    // 选中的用户
    targetObj.managerDTOList = [
      ...targetObj.managerDTOList,
      ...checkoutUsers,
    ];
    targetObj.type = 1;
    let newObj = null;
    if (selectTargetList.value.length >= 1) {
      // 批量 分管设置
      newObj = JSON.parse(JSON.stringify(selectTargetList.value));
      const result = newObj.map((item) => ({customerId: item.id, customerName: item.customerName}));
      targetObj.customerDTOList = [...targetObj.customerDTOList, ...result];
    } else {
      // 单个 分管设置
      newObj = JSON.parse(JSON.stringify(activeItems.value));
      targetObj.customerDTOList = [...targetObj.customerDTOList, {
        customerId: newObj.id,
        customerName: newObj.customerName
      }];
    }
    let res = await getMailCustomerSetting({...targetObj});
    if (res.code !== 200) {
      ElMessage.error(res.message);
      return;
    }
    ElMessage.success(res.message);
    // 追加分管
  } else if (flags.value === 3) {
    targetObj.managerDTOList = [
      ...targetObj.managerDTOList,
      ...checkoutUsers,
    ];
    targetObj.type = 3
    const newObj = JSON.parse(JSON.stringify(selectTargetList.value));
    const result = newObj.map((item) => ({customerId: item.id, customerName: item.customerName}));
    targetObj.customerDTOList = [...targetObj.customerDTOList, ...result];
    let res = await getMailCustomerSetting({...targetObj});
    if (res.code !== 200) {
      ElMessage.error(res.message);
      return;
    }
    ElMessage.success(res.message);
  }
  await getCustomerList();
  dialogVisibleBranch.value = false;
  checkListBranch.value = [];
  flags.value = 0;
};
// 抽屉的展示
const isShowDrawers = ref(false);
// 公司信息
const companyInfo = ref([]);
// 营销信息
const marketingInfo = ref([]);
// 联系人信息
const ruleFormCustomer = computed(() => store.state.customer.ruleForm);
// 联系人
const contactInfo = ref([]);
// 档案信息
const handleClickTargetData = async (row) => {
  isShowDrawers.value = true;
  activeItems.value = row;
  // console.log(row)
  localStorage.setItem('customerId', row.id)
  console.log(ruleFormCustomer.value, 'ruleFormCustomer')
  await store.dispatch("customer/getQueryById", {
    customerId: row.id,
  });
  console.log(ruleFormCustomer.value, 'ruleFormCustomer')
  if (ruleFormCustomer.value) {
    const {
      customerName,
      phoneWayList,
      setUpTime,
      customerOrigin,
      economicNature,
      companyWebsite,
      companyAddress,
      companyFax,
      emailWayList,
      companyRemark,
      // ----
      customerType,
      customerLevel,
      product,
      marketingRemark,
    } = ruleFormCustomer.value;
    console.log(ruleFormCustomer.value, customerName, 'ruleFormCustomer.value')
    // console.log('详情信息', ruleFormCustomer.value, phoneWayList.filter((item) => item.isDefault === 1)[0].content, emailWayList)
    companyInfo.value = [
      {name: "客户名称", value: customerName},
      {name: "客户来源", value: customerOrigin},
      {
        name: "联系电话",
        value:
            phoneWayList.length !== 0
                ? phoneWayList.filter((item) => item.isDefault === 1)[0].content
                : [],
      },
      {name: "成立时间", value: setUpTime},
      {name: "经济性质", value: economicNature},
      {name: "公司网址", value: companyWebsite},
      {name: "地址信息", value: companyAddress},
      {name: "传真号码", value: companyFax},
      {
        name: "公司邮箱",
        value:
            emailWayList.length !== 0
                ? emailWayList.filter((item) => item.isDefault === 1)[0].content
                : [],
      },
      {name: "其它备注", value: companyRemark},
    ];
    marketingInfo.value = [
      {name: "客户类型", value: customerType},
      {name: "客户等级", value: customerLevel},
      {name: "意向产品", value: product},
      {name: "其它备注", value: marketingRemark},
    ];
    // 联系人详情
    await store.commit("customer/setContactPage", {
      page: 1,
      pageSize: 999,
    });
    await store.dispatch("customer/getContactList",);
    // 清除数据干扰
    contactInfo.value = [];
    // 联系人详情
    contactInfo.value = contactList.value.map((item) => [
      {name: "姓名", value: item.name},
      {name: "邮件", value: item.email},
    ]);
  }
};

// table 中的 confirm
const requestHandleDelete = (row) => {
  row.popoverVisible = true;
};
const cancelPopover = (row) => {
  row.popoverVisible = false;
};

// 审核列表
// 分页
const currentList = ref(1);
const sizeList = ref(10);
const totalsList = computed(() => store.state.customer.badgeDataApproval);
const handleCurrentChangeList = async (page) => {
  console.log(page, 'page')
  currentList.value = page;
  await getCustomerListAdmin();
};
const allAdminList = ref([]);
const getCustomerListAdmin = async () => {
  const targetObj = {
    pageNo: currentList.value,
    pageSize: sizeList.value,
    teamId: teamId.value,
  };
  let res = await getMailCustomerAudit({...targetObj});
  if (res.code !== 200) {
    return;
  }
  //console.log(res.result, "$$$$");
  const {records, size, current, total} = res.result;
  allAdminList.value = records;
  currentList.value = current;
  sizeList.value = size;
  // 徽章数据
  // totalsList.value = total;
  store.commit("customer/setApprovalBadge", total);
};

onMounted(async () => {
  // 确定 activeName 的值
  // const tabCheck = localStorage.getItem("tabCheck");
  // activeName.value = isSuperAdmin.value ? tabCheck ? tabCheck : "1" : "3"
  // console.log(activeName.value, ' activeName.value', isSuperAdmin.value, isSuperAdmin.value ? tabCheck ? tabCheck : "1" : "3")
  if (!isSuperAdmin.value) {
    activeName.value = "3"
  }
  // 判断 activeItems 的值并执行相应的逻辑
  if (activeName.value === "2") {
    await store.dispatch("groups/getAllUserList");
    await getFollowUserList();
    await getAllCustomer()
  } else if (activeName.value === "3" || activeName.value === "6") {
    await store.dispatch("groups/getAllUserListNo");
  } else if (activeName.value === "4") {
    await getCustomerListAdmin();
  } else if (activeName.value === '7') {
    await getAllCustomer()
  } else if (activeName.value === '1') {
    if (!sessionStorageUtil.getItem("customerLevel")) {
      await store.dispatch("cusList/getMailCustomerLevel");
    }
  }
});


watch(() => teamId.value, async () => {
  await getCustomerList();
  await getFollowUserList();
  await requestAllCustomer({
    teamId: teamId.value,
    type: 1,
  }).then((res) => {
    customerListAll.value = res.result
  });
  // await store.dispatch("groups/getAllUserList");
  await store.dispatch("groups/getAllUserListNo");
})

const rowClassName = ({row, column, rowIndex, columnIndex}) => {
  //console.log(row, columnIndex)
  if (columnIndex === 1 || columnIndex === 2) {
    return {textAlign: 'left'}
  } else {
    return {textAlign: 'center'}
  }
}

const headerClassName = ({row, column, rowIndex, columnIndex}) => {
  if (columnIndex === 1 || columnIndex === 2) {
    return {'text-align': 'left', background: '#f9f9f9', color: 'black', padding: '16px 0px'}
  } else {
    return {'text-align': 'center', background: '#f9f9f9', color: 'black', padding: '16px 0px'}
  }
}


const currentStartIndex = ref(0);
const currentEndIndex = ref(10)
// const handelLoadmore = (currentStartIndex, currentEndIndex) => {
//   console.log(currentStartIndex, currentEndIndex, 'ffff')
//   currentStartIndex.value = currentStartIndex;
//   currentEndIndex.value = currentEndIndex;
// }
//
// const filteredData = computed(() => {
//   let list = customerList.value.filter((item, index) => {
//     if (index < currentStartIndex.value) {
//       return false;
//     } else if (index > currentEndIndex.value) {
//       return false;
//     } else {
//       return true;
//     }
//   })
//   return list;
// })


// const loadCount = ref(1)
// const visibleData = ref([])
// const loadMore = () => {
//   const nextData = customerList.value.slice(
//       loadCount.value * 20,
//       (loadCount.value + 1) * 20
//   )
//   if (nextData.length > 0) {
//     visibleData.value = visibleData.value.concat(nextData)
//     loadCount.value++
//   }
// }
</script>

<style scoped lang="less">
@import "../../less/page.less";
@import "./less/setting.less";

.pages :deep(.el-pagination .el-select) {
  width: 100px !important;
}

.pages {
  margin-top: 10px;
}

.small_box {
  width: auto !important;
}

:deep(.el-table__inner-wrapper::before) {
  content: none !important;
  display: none !important;
}

.demo-image__lazy {
  height: 526px;
  overflow-y: auto;
}

.demo-image__lazy .el-image {
  display: block;
  min-height: 526px;
  margin-bottom: 10px;
}

.demo-image__lazy .el-image:last-child {
  margin-bottom: 0;
}

:deep(.el-table__empty-block) {
  height: 200px !important;
}
</style>
