<template>
  <div class="inners">
    <div class="right">
      <div class="titles">绑定邮箱</div>
      <div class="content">
        <el-form
            ref="ruleFormRefEmail"
            :model="ruleForm"
            :rules="rules"
            label-width="120px"
            class="demo-ruleForm"
            :size="formSize"
            :label-position="'top'"
        >
          <el-form-item label="邮箱" prop="email">
            <el-input
                v-model="ruleForm.email"
                placeholder="请输入邮箱"
            ></el-input>
          </el-form-item>
          <el-form-item
              label="验证码"
              prop="emailCode"
              aria-placeholder="请输入验证码"
          >
            <el-input
                v-model="ruleForm.emailCode"
                placeholder="请输入邮箱验证码"
                id="sp_input"
                :clearable="false"
            >
              <template #suffix>
                <el-button style="border-radius: 0px 4px 4px 0px !important;" type="primary" @click="send"
                           :disabled="!show">
                  <span v-if="show">获取验证码</span>
                  <span v-show="!show">{{ count }}s</span>
                </el-button>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" round @click="bindEmailApis">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="text_md">
        [ 可用来接收 Mailtalk 发送的各类系统、营销、服务通知。 ]
      </div>
    </div>
  </div>
</template>
<script setup>
import {computed, onMounted, ref} from "vue";
import {sendEmailSms, bindEmail} from "@/api/usercenter";
import {ElMessage} from "element-plus";
import {useRouter} from "vue-router";
import {emailReg, TIME_COUNT} from '@/utils/statusParams'
import {useStore} from "vuex";

const router = useRouter();
const ruleForm = ref({});
const store = useStore()
const rules = ref({
  email: [{required: true, message: "请输入邮箱", trigger: "blur"}, {}],
  emailCode: [{required: true, message: "请输入验证码", trigger: "blur"}],
});
const bindEmailValue = ref('')
const ruleFormRefEmail = ref(null)
const timer = ref(null);
const count = ref(0);
const show = ref(true)
const targetEmail = ref('')
const send = () => {
  if (ruleForm.value.email !== '' && emailReg.test(ruleForm.value.email)) {
    if (!timer.value) {
      count.value = TIME_COUNT;
      show.value = false;
      timer.value = setInterval(() => {
        if (count.value > 0 && count.value <= TIME_COUNT) {
          count.value--;
        } else {
          show.value = true;
          clearInterval(timer.value); // 清除定时器
          timer.value = null;
        }
      }, 1000)

      //   发送请求
      sendEmailSmsApis()
    }
  } else {
    ElMessage.error("请输入正确的邮箱");
  }
}

const sendEmailSmsApis = async () => {
  const res = await sendEmailSms({
    email: ruleForm.value.email
  })
  if (res.code !== 200) {
    ElMessage.error(res.message);
    return
  }
  ElMessage.success(res.message)
}

const bindEmailApis = async () => {

  const res = await bindEmail({
    email: ruleForm.value.email,
    code: ruleForm.value.emailCode,
    type: 1
  })
  if (res.code !== 200) {
    ElMessage.error(res.message)
    return
  }
  ElMessage.success(res.message)
//   重新获取用户相关数据
  await store.dispatch('user/getUserInfoForGUID')
  await getBindEmails()
  ruleFormRefEmail.value.resetFields();
  router.push('/personal/basic')
}

const getBindEmails = () => {
  const email = JSON.parse(localStorage.getItem('userInfo')).email
  if (email) {
    bindEmailValue.value = email
  }
}
const userInfo = computed(() => store.state.user.userInfo)
const isChange = ref('')
onMounted(async () => {
  isChange.value = router.currentRoute.value.query.isChange
  if (isChange.value === '1') {
    ruleForm.value.email = userInfo.value.email
  }
})

</script>

<style lang="less" scoped>
@import "./less/Tables.less";
@import "../../less/page.less";

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 200px;
  margin-top: 40px;
}

:deep(.el-form) {
  width: 100% !important;
}

:deep(.el-form-item__content),
:deep(.el-select) {
  width: 100% !important;
}

:deep(.el-form-item__content) {
  display: flex;
  justify-content: center;
}

#buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

#avatar {
  display: flex;
  justify-content: center;
}

#sp_input {
  border-right: none !important;
}

:deep(.el-input__wrapper) {
  padding: 1px 0px 1px 11px !important;
}

.text_md{
  text-align: center;
  margin-top: 40px;
  color: #999;
  font-size: 14px;
}
</style>
