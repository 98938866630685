import _store from "store2";
import {getCurrentTeamData, getTeamAllData} from "@/api/home";
import {ElMessage} from "element-plus";
import {objToArrays} from "@/utils/objToArrays";
import sessionStorageUtil from "@/utils/sessionStorageUtil";

const countshow = {
        namespaced: true,
        state: {
            myEmailList: [],
            allEmailList: JSON.parse(window.localStorage.getItem('sumData')) || [],
            getEmailListFlag: 0,
            sidebarTargetNumber: 0,
            sidbarNumbers: 0,
        },
        mutations: {
            setEmailListFlag(state, payload) {
                state.getEmailListFlag = payload;
            },
            setSidebarTargetNumber(state, payload) {
                state.sidebarTargetNumber = payload
            },
            setSidbarNumbers(state, payload) {
                state.sidbarNumbers = payload;

            }
        },
        actions: {
            async getTeamAllDataApis({state, commit}) {
                const teamId = window.localStorage.getItem('teamId')
                if (teamId) {
                    const res = await getCurrentTeamData({
                        teamId: teamId,
                    })
                    const formatData = objToArrays(res.result)
                    state.myEmailList = formatData
                }
            }
            ,
            async getTeamSumDataApis({state, commit}) {
                const teamId = window.localStorage.getItem('teamId')
                const res = await getTeamAllData({
                    teamId: teamId,
                })
                if (res.code !== 200) {
                    ElMessage.error(res.message)
                    return;
                }
                const formatData = objToArrays(res.result)
                window.localStorage.setItem('sumData', JSON.stringify(formatData))
                state.allEmailList = formatData;
            }
        },
    }
;

export default countshow;
