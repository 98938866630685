<template>
  <div class="footerInfoBar">{{ $t("sys.reg.s7") }} | <a href="https://beian.miit.gov.cn" target="_blank">浙ICP备2024065161号-3</a>
  </div>
</template>

<script setup>

</script>

<style lang="less" scoped>
// 底部版权信息等
.footerInfoBar {
  //position: fixed;
  position: absolute;
  font-size: 14px;
  left: 50%;
  bottom: -3%;
  transform: translateX(-50%);
}
</style>