<template>
  <Dialog
      v-model="dialogVisible"
      :dialogVisible="dialogVisible"
      @update:dialogVisible="close"
      :width="'600'"
  >
    <template #content>
      <div class="join_box">
        <div class="join_top">
          {{ targetItem.innerContent }}
        </div>
        <div class="join_bottom">
          <el-button round @click="addTargetTeams" :loading="isLoading">加入团队</el-button>
          <el-button round type="info" @click="unTargetTeams" :loading="unIsloading">拒绝加入</el-button>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script setup>
import {ElButton, ElDialog, ElInput, ElMessage} from "element-plus";
import {ref, defineProps, watch, defineEmits} from 'vue'
import {useStore} from "vuex";
import Dialog from "@/components/Dialog.vue";

const store = useStore();
const emit = defineEmits()
const dialogVisible = ref(false);
const teamName = ref("")
const loading = ref(false)


const {value, targetItem, flag} = defineProps({
  value: {type: Boolean, default: false},
  targetItem: {type: Object, default: {}},
  flag: {type: Number, default: 0,}
})

watch(() => value, (val) => {
  dialogVisible.value = val;
}, {immediate: true})


// 进入团队
const isLoading = ref(false)
const addTargetTeams = async () => {
  try {
    isLoading.value = true
    await store.dispatch("message/auditExternalPersonApis", {
      auditType: 1,
      createId: targetItem.createBy,
      relationId: targetItem.relationId,
      id: targetItem.id,
    })
    isLoading.value = false
  } catch (e) {
    isLoading.value = false
  }
  close();
  if (flag === 1) {
    emit('getUnReadList')
  }
// 重新获取 teamList
  await store.dispatch('email/getTeamList')
  location.reload();
}

const unIsloading = ref(false)
const unTargetTeams = async () => {
  try {
    unIsloading.value = true;
    await store.dispatch("message/auditExternalPersonApis", {
      auditType: 2,
      createId: targetItem.createBy,
      relationId: targetItem.relationId,
      id: targetItem.id,
    })
    unIsloading.value = false;
  } catch (e) {
    unIsloading.value = false;
  }
  close();
  if (flag === 1) {
    emit('getUnReadList')
  } else {

  }
}


const close = () => {
  emit("close");
}
</script>
<style scoped lang="less">
@import "less/index";
</style>
