<template>
  <div class="layout">
    <Header></Header>
    <el-scrollbar :height="computedHeight" min-size="0">
      <RouterView></RouterView>
    </el-scrollbar>
  </div>
</template>
<script setup>
import Header from "../components/Header/Header.vue";
import { onMounted, ref, watchEffect} from "vue";
import {useRoute} from "vue-router";

const route = useRoute()

const computedHeight = ref(`${window.innerHeight - 63.99}px`)
onMounted(() => {
  watchEffect(() => {
    computedHeight.value = `${window.innerHeight - 63.99}px`;
  });
  // 监听窗口 resize 事件
  window.addEventListener('resize', () => {
    computedHeight.value = `${window.innerHeight - 63.99}px`;
  });
});

</script>
<style lang="less" scoped></style>
