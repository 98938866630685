<template>
  <ElDialog
      v-model="isShow"
      :title="numberFlag==='1'?$t('common.move.to'):numberFlag==='2'?$t('common.transfer.to'):$t('common.save.to')"
      :footer="null"
      centered
      :width="520"
      destroyOnClose
      @cancel="close"
      :class="[isHeadLine ? 'headline' : 'noheadline']"
  >
    <div>
      <div class="file_all">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item v-for="(item, index) in pathArrayMove" :key="item.path"
                              @click="handleClick(item, index)">
            <span
                style="cursor: pointer">{{ item.text }}</span>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="inner_out_box">
        <div :class="type!==6?'box_table':'box_table_sp'"
        >
          <el-table
              ref="multipleTableRef"
              :data="tableData"
              v-loading="tableLoading"
              v-el-table-infinite-scroll="handleTableScroll"
          >
            <!--          @row-dblclick="selectTargetDetail"-->
            <el-table-column prop="fileName" class="file_names">
              <template #default="{row}">
                <div v-if="row.isFolder===1" style="display: flex;align-items: center; pointer-events: none">
                  <FileIcon :fileExtend="row.fileExtend" :fileUrl="row.fileExtendPath" :size="25"
                            :isAbbreviation="true"></FileIcon>
                  <el-button link class="pointer" style="margin-left: 10px">{{
                      row.fileName
                    }}
                  </el-button>
                </div>
                <div v-if="row.isFolder===2" style="display: flex;align-items: center;">
              <span style="display: block;height: 24px" class="icon"><i aria-label="图标: folder"
                                                                        class="anticon anticon-folder"
                                                                        style="color: rgb(41, 182, 246); font-size: 24px;"><svg
                  viewBox="64 64 896 896" data-icon="folder" width="1em" height="1em" fill="currentColor"
                  aria-hidden="true" focusable="false" class=""><path
                  d="M880 298.4H521L403.7 186.2a8.15 8.15 0 0 0-5.5-2.2H144c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V330.4c0-17.7-14.3-32-32-32z"></path></svg></i></span>
                  <el-button link type="primary" class="pointer" style="margin-left: 10px"
                             @click.native.stop="enterDirectory(row)" :disabled="row.isUnClick">
                    {{ row.fileName }}
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <ElButton @click="close">{{
            $t("common.cancel")
          }}
        </ElButton>
        <ElButton type="primary" @click="createLink" :loading="loading">{{
            $t("common.confirm")
          }}
        </ElButton>
      </div>
    </template>
  </ElDialog>
</template>

<script setup>
import {ElButton, ElDialog, ElMessage, ElTable} from "element-plus";
import {ref, defineProps, watch, defineEmits, computed, onMounted, onUnmounted, watchEffect,} from 'vue'
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {debouncePlus} from "@/utils/mylodash"
import {i18n} from "@/lang";
import FileIcon from "@/components/File/FileIcon.vue";

const store = useStore();
const router = useRouter();
const route = useRoute();
const emit = defineEmits()
const isShow = ref(false);
const loading = ref(false)


const {value, isHeadLine, type, activeItem, list, numberFlag} = defineProps({
  value: {type: Boolean, default: false},
  isHeadLine: {type: Boolean, default: false},
  type: {type: Number, default: 0},
  activeItem: {type: Array, default: []},
  list: {type: Function,},
  numberFlag: {type: String, default: '1'}
})


watch(() => value, (val) => {
  isShow.value = val;
}, {immediate: true})

const close = () => {
  emit("close");
}

const createLink = async () => {
  // alert("移动")
  if (numberFlag === '1') {
    const targetTags = JSON.parse(sessionStorage.getItem("pathArrayMove"));
    if (targetTags !== null) {
      const params = {
        id: targetTags[targetTags.length - 1].tag,
        idList: activeItem.map(_ => _.id),
      }
      await store.dispatch("cloud/moveFileApis", params)
    }
  } else {
    await copyFileTarget()
  }
  // 重制标记
  store.commit("cloud/changeCurrent", false)
  await list()
  close()
}

const current = ref(1)
const size = ref(20)
const total = computed(() => store.state.cloud.total);
/**
 * 处理完操作需要重新判断页数和 重置
 */
const resetCurrentAbout = () => {
  store.commit("cloud/changeCurrent", false)
  if (current.value * size.value >= total.value) {
    if (current.value)
      current.value = 1;
  }
}
// 面包屑
const pathArrayMove = ref([
  {path: '0', text: i18n.global.t("common.file.all"), tag: "0"}
])
const handleClick = async (item, index) => {
  resetCurrentAbout()
  // 处理面包屑导航点击事件
  console.log(item, index, route, pathArrayMove.value, item.tag === "0", 'click')

  console.log(item, route.path, 'targetItem')
  const targetIndex = findIndexWithTag(pathArrayMove.value, item.tag);
  // if (item.tag !== "0") {
  //   router.push({path: route.path, query: {id: item.tag, path: item.path}})
  // } else {
  //   router.push({path: route.path})
  // }
  //   说明后面还有数据
  pathArrayMove.value.splice(targetIndex + 1)
  if (item.tag !== "0") {
    await store.dispatch('cloud/getFilePathApisMove', {
      id: item.tag, type: type === 1 ? type : type === 6 ? 2 : 1, pageNo: current.value,
      pageSize: size.value
    });
  } else {
    await store.dispatch('cloud/getFileListApiMove', {
      pageNo: current.value,
      pageSize: size.value,
      type: 1,
      parentId: "0"
    })
  }
  sessionStorage.setItem('pathArrayMove', JSON.stringify(pathArrayMove.value))
}


function findIndexWithTag(jsonData, flag) {
  for (var i = 0; i < jsonData.length; i++) {
    if (jsonData[i].tag === flag) {
      return i;
    }
  }
  return -1;
}


const tableData = computed(() => {
  try {
    const allList = store.state.cloud.moveFileList;
    const activeFolder = activeItem.filter(item => item.isFolder === 2)
    const unclick = difference(allList, activeFolder)
    const res = intersection(allList, activeFolder);
    console.log(res, 'resHere')
    const targetArr = res.length !== 0 ? res.map(item => ({...item, isUnClick: true})) : []
    console.log(activeItem, allList, unclick, res, [...targetArr, ...unclick], '....')
    return [...targetArr, ...unclick]
  } catch (e) {
    console.log(e)
  }
});


// 交集
function intersection(array1, array2 = []) {
  return array1.filter(obj1 => array2.some(obj2 => obj1.id === obj2.id));
}

//差集
function difference(array2, array1 = []) {
  return array2.filter(obj2 => !array1.some(obj1 => obj1.id === obj2.id));
}

const tableLoading = ref(false);
// 目标id
const targetId = ref(0)
const getFileList = async () => {
  tableLoading.value = true;

  if (findPathArray() === 2) {
    targetId.value = pathArrayMove.value[pathArrayMove.value?.length - 1].tag
    console.log(targetId, 'targetId')
    const params = {
      id: targetId.value,
      type: type === 1 ? type : type === 6 ? 2 : 1,
      pageNo: current.value,
      pageSize: size.value
    }
    await store.dispatch("cloud/getFilePathApisMove", params)
  } else {
    const params = {
      pageNo: current.value,
      pageSize: size.value,
      isHide: type === 5 ? 2 : null,
      // 1 我的文件夹， 2 回收站
      type: type === 1 ? type : type === 6 ? 2 : 1,
      parentId: "0"
    }
    if (params.isHide === null) {
      delete params.isHide;
    }
    if (type !== 1) {
      delete params.parentId;
    }
    await store.dispatch('cloud/getFileListApiMove', params)
  }
  tableLoading.value = false;
}

const TIME_DELAY = 300;
const pages = computed(() => store.state.cloud.pages)
let isFirstPageLoaded = computed(() => store.state.cloud.isFirstPageLoaded);


// 用户是否开始滚动 标识
const scrollStartFlag = ref(false)
const multipleTableRef = ref(null);
const scrollBehaviordd = () => {
  scrollStartFlag.value = true;
  // console.log(scrollStartFlag.value, 'start-scroll')
}

watchEffect(() => {
  console.log(multipleTableRef.value, 'multipleTableRef.value')
  multipleTableRef.value && multipleTableRef.value.$refs.bodyWrapper.addEventListener("mousewheel", scrollBehaviordd, {passive: true})
}, []);


onUnmounted(() => {
  multipleTableRef.value && multipleTableRef.value.$refs.bodyWrapper.removeEventListener("mousewheel", scrollBehaviordd,)
})

const handleTableScroll = debouncePlus(async () => {
  // if (!isFirstPageLoaded.value) {
  //   store.commit("cloud/changeIsFirstPageLoaded", true)
  //   return;
  // }
  // console.log('scroll', current.value, pages.value, tableData.value)
  // if (current.value === pages.value) {
  //   if (current.value !== 1) {
  //     ElMessage.warning("已经到底了")
  //   }
  //   return;
  // } else {
  //   store.commit("cloud/changeCurrent", true)
  //   current.value++
  //   await getFileList();
  // }

  if (scrollStartFlag.value) {
    //  用户开始滚动
    // await store.commit("cloud/changeDisabled", false);
    if (current.value === pages.value) {
      if (current.value !== 1) {
        ElMessage.warning("已经到底了")
      }
      return;
    } else {
      store.commit("cloud/changeCurrent", true)
      current.value++
      await getFileList();
    }
  } else {
    //  未开始不允许翻页
    // await store.commit("cloud/changeDisabled", true);
  }
}, TIME_DELAY)


const tempRouter = ref('')
const enterDirectory = debouncePlus(async (row) => {
  console.log(row, 'row')
  resetCurrentAbout()
  //   请求新的数据
  await store.dispatch("cloud/getFilePathApisMove", {
    id: row.id,
    type: type === 1 ? type : type === 6 ? 2 : 1,
    pageNo: current.value,
    pageSize: size.value
  })
  // 是文件夹
  const filterTagsArray = pathArrayMove.value.map(item => item.tag)
  const targetPath = [...filterTagsArray, row.id].join(',')
  console.log(targetPath, filterTagsArray, pathArrayMove.value, '$$$')
  tempRouter.value = targetPath;
  pathArrayMove.value.push({path: targetPath, text: row.fileName, tag: row.id})
  // 数据持久化
  sessionStorage.setItem('pathArrayMove', JSON.stringify(pathArrayMove.value))
  // router.push({path: route.path, query: {id: row.id, path: targetPath}})
  console.log('-----', route.path)
}, 500)


onMounted(async () => {
  const pathArrays = JSON.parse(sessionStorage.getItem("pathArrayMove"));
  if (pathArrays !== null) {
    if (pathArrays.length > 1) {
      pathArrayMove.value = JSON.parse(sessionStorage.getItem("pathArrayMove"));
    }
  }
  console.log(route.query.id, 'route.query.id')
  await getFileList();
})

const findPathArray = () => {
  if (JSON.parse(sessionStorage.getItem("pathArrayMove")) !== null) {
    if (JSON.parse(sessionStorage.getItem("pathArrayMove")).length > 1) {
      // 子列表
      return 2
    } else {
      // 全部列表
      return 1
    }
  }
}


// 转存到
const copyFileTarget = async () => {
  const payload = {
    id: pathArrayMove.value[pathArrayMove.value?.length - 1].tag,
    idList: activeItem.map(item => item.fileId)
  }
  await store.dispatch("cloud/copyFileApis", payload)
}

</script>
<style scoped lang="less">
.fx-j-c {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.title_cdir {
  color: rgba(0, 0, 0, .85);
  font-weight: 700;
  font-size: 14px;
}

.fx-j-r {
  display: flex;
  justify-content: right;
  margin-top: 40px;
}
</style>
