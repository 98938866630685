<template>
  <!--客户建档-->
  <Dialog
      v-model="dialogVisibleCreatedMessage"
      :title="$t('common.khjd')"
      :dialogVisible="dialogVisibleCreatedMessage"
      :isHeadLine="false"
      :width="'80%'"
      @update:dialogVisible="close"
      :top="forms.merge?'0vh':'2vh'"
      :close-on-click-modal="false"
  >
    <template #content>
      <el-scrollbar :height="forms.merge?'600':'75vh'">
        <Forms :isShowButton="false" ref="childRef">
          <template #createMessage>
            <div class="inner_outbox">
              <el-form-item :label="$t('common.region.name')" prop="zoneAddress" style="width: 200px!important;">
                <el-select v-model="ruleForm.zoneAddress" :placeholder="$t('common.please.select')" filterable
                           value-key="country"
                           @change="selectMore($event)"
                >
                  <el-option
                      v-for="item in areaList"
                      :label="item.country"
                      :value="item"
                      :key="item.country"
                  />
                </el-select>
              </el-form-item>
              <el-form-item style="margin-left: 25px;width: 300px;max-width: 300px;"
                            prop="customerCode">
                <template #label>
                  <span><span style="color: #e47470;margin-right: 4px">*</span>客户编号</span>
                </template>
                <el-input v-model="ruleForm.customerCode" :placeholder="ruleForm.customerCodeDefault"/>
              </el-form-item>
              <el-form-item style="margin-left: 25px;width: 300px;max-width: 300px;" :label="$t('common_customer_name')"
                            prop="customerName">
                <el-input v-model="ruleForm.customerName" :placeholder="$t('common.please.input')"/>
              </el-form-item>
              <el-form-item
                  style="margin-left: 25px"
                  :label="$t('common.customer.source')"
                  prop="customerOrigin"
              >
                <el-input
                    v-model="ruleForm.customerOrigin"
                    :placeholder="$t('common.please.input')"
                />
              </el-form-item>
            </div>
          </template>
        </Forms>
      </el-scrollbar>
    </template>
    <template #footer>
      <div class="footer_box">
        <el-button @click.native.stop="close">{{ $t('common.sss16') }}</el-button>
        <el-button type="primary" @click="handleSaveData">{{ $t('common.save') }}</el-button>
      </div>
    </template>
  </Dialog>
</template>

<script setup>
import Dialog from "@/components/Dialog.vue";
import {defineProps, ref, watch, defineEmits, computed, nextTick, onMounted} from "vue";
import {useRoute, useRouter} from "vue-router";
import Forms from "@/views/Customer/CreateCheck/Forms.vue";
import {useStore} from "vuex";
import sessionStorageUtil from "@/utils/sessionStorageUtil";

const store = useStore()
const router = useRouter()
const emit = defineEmits()
const route = useRoute();
const dialogVisibleCreatedMessage = ref(false)
// 建档信息
const ruleForm = computed(() => store.state.customer.ruleForm);
const teamId = computed(() => store.state.email.teamID)
const randomCustomerCode = computed(() => store.state.customer.randomCustomerCode)
// 区域列表
const areaList = computed(() => store.state.customer.areaList);
const customerFlag = computed(() => store.state.email.customerFlag)
const {value, innerDialog} = defineProps({
  value: {type: Boolean, default: false},
  innerDialog: {type: Boolean, default: false}
})

watch(() => value, (val) => {
  dialogVisibleCreatedMessage.value = val;
}, {immediate: true})


const close = () => {
  // 重置
  store.commit("customer/setDialogFlag", "")
  emit("close")
}
const toBuy = () => {
  router.push({path: "/personal/buystorage", query: {index: 1}});
}

const isSelected = computed(() => store.state.customer.isSelected)

async function selectMore(event) {
  ruleForm.value.zoneAddress = event.country;
  ruleForm.value.zoneCode = event.zoneCode;
  ruleForm.value.customerIds = event.zoneCode

  //   获取 随机生成的 id
  const payload = {teamId: teamId.value, zoneCode: event.zoneCode}
  await store.dispatch("customer/getCustomerCodeApi", payload)
  ruleForm.value.customerCodeDefault = randomCustomerCode.value;
  store.commit("customer/setIsSelected", true)
}

// 保存客户信息
const childRef = ref()

async function handleSaveData() {
  await nextTick()
  await childRef.value.saveData()
}

const dialogFlag = computed(() => store.state.customer.dialogFlag)
watch(() => dialogFlag.value, (newValue) => {
  console.log('watching', newValue)
  if (newValue) {
    console.log(newValue, dialogFlag.value, 'ddddddddd')
    if (newValue === 1) {
      // 表单成功提交后关闭，否则继续展示
      emit('changeInnerDialog', !innerDialog);
      close()
    } else if (newValue === 2) {
      close()
    }
  }
})

const forms = ref({})
onMounted(async () => {
  await store.dispatch('customer/getAreaNames')
  const target = localStorage.getItem('formsFlag');
  if (target) {
    const result = localStorage.getItem('formsFlag')
    forms.value = result
  }
})
</script>

<style lang="less" scoped>
@import "../../../views/Customer/less/detail.less";

.inners_warning {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.setting_center {
  display: flex;
  justify-content: center;
}

.footer_box {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;

  .el-button {
    margin: 0px 10px;
  }
}
:deep(.el-dialog__body){
  padding: 0px !important;
}

</style>