<template>
  <!--  <el-button @click="changeTheme">切换主题颜色</el-button>-->
  <router-view v-slot="{ Component, route }" v-if="!isMobileFlag" v-loading>
    <keep-alive :include="[...visitedViewPathsResult]">
      <component
          :is="Component"/>
    </keep-alive>
  </router-view>
  <Device v-else @change="changeStatus"></Device>
</template>
<script setup>
import {onMounted, computed, watch, ref} from "vue";
import {useStore} from "vuex";
import {loadLanguageAsync} from "@/lang";
import {useRoute, useRouter} from "vue-router";
import {useWebSocket} from "@/Hooks";
import {isMobile} from "@/utils/device";
import Device from "@/views/Device/index.vue"

const store = useStore();
const router = useRouter();
const route = useRoute();

const isMobileFlag = ref(false);
const userInfo = computed(() => store.state.user.userInfo);
const langLocal = computed(() => store.state.system.langLocal)
// teamId 初始化为 0 问题
const teamId = computed(() => store.state.email.teamID);
const isEnterpriseMainUser = computed(() => store.getters['user/isEnterpriseMainUser']);
// 用户接受继续访问
const changeStatus = () => {
  isMobileFlag.value = false
}

watch(() => langLocal.value, (lang) => {
  loadLanguageAsync(lang)
  // routerToI18n() //多语言绑定
}, {immediate: true})

// 发送消息
const userId = JSON.parse(localStorage.getItem("userInfo"))?.id || ''

onMounted(async () => {
  isMobileFlag.value = isMobile();
  // 获取默认主题
  // let html = document.querySelector('html')
  // html.setAttribute('data-theme', localStorage.getItem("theme"));

  // 弹窗测试
  // let target = {
  //   "msg_front_from": "<CMCCAccountaasdfasdasdfasfdo@139.com>",
  //   "msgTxt": "回复：RE：hello world@阿里山来的拉迪说ASDf阿斯顿发堷企鹅们阿里山地方asdfasdfasdasd",
  //   "msg_front_type": "msg_front_email",
  //   "userId": "1764907866498379777"
  // }
  //
  // target.msgTxt = truncateByBytes(target.msgTxt,60)
  // console.log(target,'tar')
  // showNotify(1, target)

  // startLoading()
  if (userInfo.value.companyId) {
    localStorage.setItem('companyId', userInfo.value.companyId);
  }
  if (teamId.value) {
    await store.dispatch("user/checkUserInfoIsAdmin", {teamId: teamId.value, isUpdate: true})
  }
  // userTime()
})

// onBeforeUnmount(() => {
//   timerUser.value = null;
// })

// 主题切换
// const changeTheme = () => {
//   console.log('切换主题颜色')
//   let html = document.querySelector('html')
//   let currentTheme = html.getAttribute('data-theme');
//   console.log(currentTheme, 'currentTheme')
//   if (currentTheme === "light" || currentTheme === null) {
//     html.setAttribute('data-theme', 'dark');
//     localStorage.setItem("theme", "dark")
//   } else {
//     html.setAttribute('data-theme', 'light');
//     localStorage.setItem("theme", "light")
//   }
// }

let timers = null;
const emailMessages = ref([])
const emailData = computed(() => store.state.message.emailData)
// websocket 请求
const {startWS, stopWS} = useWebSocket(userId, store);
onMounted(async () => {
  if (userId) {
    await startWS();
  } else {
    await stopWS()
  }

  console.log(router.options.routes, 'router')
})

function getAllNames(data, result = []) {
  if (Array.isArray(data)) {
    data.forEach(item => {
      if (item.name) {
        result.push(item.name);
      }
      if (item.children) {
        getAllNames(item.children, result);
      }
    });
  }
  return result;
}

// 路由缓存
const visitedViewPathsResult = getAllNames(router.options.routes);

// 权限 轮训 实时反馈
// const timerUser = ref(null)
// const userTime = () => {
//   timerUser.value = setInterval(async () => {
//     console.log('timer')
//     await store.dispatch("user/checkUserInfoIsAdmin", {teamId: teamId.value, isUpdate: true})
//   }, 5000)
// }


// 监听storage事件，及时同步用户信息
window.addEventListener('storage', (e) => {
  if (e.key === "token" && (e.newValue === null || e.newValue !== e.oldValue)) {
    location.reload();
  }
});

</script>
<!-- 全局改变popover -->
<style>
.el-popover {
  min-width: auto !important;
}

.el-icon {
  cursor: pointer;
}

.el-transfer {
  display: flex;
  justify-content: space-between;
}

.el-transfer-panel {
  flex: 1 !important;
}

.el-input__validateIcon {
  display: none !important;
}


.el-table tbody tr:hover > td {
  background-color: #e2f6ff !important
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}


.el-dialog__title {
  color: rgba(0, 0, 0, .85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}

.el-empty__description {
  margin-top: -20px;
}

.tips_width {
  max-width: 200px;
}

.el-drawer__header {
  margin-bottom: 15px !important;
}

.w-e-text-container .placeholder {
  left: 22px !important;
}

.el-form-item {
  font-weight: normal !important;
}

.el-dialog__body {
  padding: 16px;
}
</style>

<style lang="less" scoped>
body {
  width: 100% !important;
}

:deep(.el-table) {
  font-weight: normal !important;
}

</style>