<template>
  <div class="upload_list_box" ref="fileListRef">
    <div class="upload_title">
      <div @click="changeBoxStatus">
        <span>{{$t("common.file.transfer.list")}}</span>
        <div style="display: inline-block;margin-left: 4px">
          <el-icon v-if="isOpen">
            <ArrowDown/>
          </el-icon>
          <el-icon v-else>
            <ArrowUp/>
          </el-icon>
        </div>
      </div>
      <el-icon style="font-size: 16px;height: 16px;width: 16px;line-height: 45px" @click="removeList">
        <CircleClose/>
      </el-icon>
    </div>
    <div class="upload_content">
      <div v-for="item in fileList" :key="item.uid">
        <div :class="[item.isMouseEnter?'hover_bg':'no_bg','item_box']" @mouseenter="handleMouseEnter(item,index)"
             @mouseleave="handleMouseLeave(item,index)">
          <div>
            <el-icon v-if="item.percentage===100 && item.response" color="rgba(0,0,0,.45)" size="14">
              <Link/>
            </el-icon>
            <el-icon v-else class="is-loading" color="rgba(0,0,0,.45)" size="14">
              <Loading/>
            </el-icon>
            <span style="margin-left: 8px">{{ item.name }}</span>
          </div>
          <div>
            <el-icon class="icon_del" v-if="item.isMouseEnter" @click="deleteTarget(item)">
              <Delete/>
            </el-icon>
          </div>
        </div>
        <el-progress v-if="item.percentage!==100" :percentage="item.percentage"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import {nextTick, ref, watch} from "vue";
import {ElDialog} from "element-plus";

const {fileList, showFileList} = defineProps({
  fileList: {
    type: Array,
    default: () => []
  },
  showFileList: {
    type: Boolean,
    default: false,
  }
})

const fileListRef = ref(null)
// 标识展开还是关闭
const isOpen = ref(true);
const changeBoxStatus = () => {
  if (isOpen.value) {
    fileListRef.value.style.height = "45px"
    fileListRef.value.style.overflow = "hidden"
    fileListRef.value.style.transition = "all .3s"
  } else {
    fileListRef.value.style.height = "auto"
    fileListRef.value.style.overflow = "auto"
    fileListRef.value.style.transition = "all .3s"
  }
  isOpen.value = !isOpen.value;
  console.log(fileListRef.value)
}


const handleMouseEnter = (item, index) => {
  item.isMouseEnter = true
}
const handleMouseLeave = (item, index) => {
  item.isMouseEnter = false
}

const deleteTarget = (item) => {
  const targetIndex = fileList.findIndex((it) => it.uid === item.uid);
  if (targetIndex !== -1) {
    fileList.splice(targetIndex, 1)
  }
}

const removeList = () => {
  fileListRef.value.classList.remove("enter_list");
  fileListRef.value.classList.add("leave_list")
}

const enterList = () => {
  fileListRef.value.classList.remove("leave_list");
  fileListRef.value.classList.add("enter_list")
}

defineExpose({
  enterList
})

</script>

<style lang="less" scoped>
.upload_list_box {
  box-sizing: border-box;
  box-shadow: 0 0 1px 1px rgba(28, 28, 32, .05), 0 8px 24px rgba(28, 28, 32, .12);
  position: fixed;
  bottom: -640px;
  right: 30px;
  width: 373px;
  min-width: 373px;
  //height: auto;
  //max-height: 400px;
  //min-height: 45px;
  background-color: #fff;
  z-index: 999999;
  transition: all .4s ease-in-out;
  border-radius: 8px;
  padding: 10px;
  //overflow: scroll;

  .upload_title {
    padding: 2px 2px 12px;
    cursor: pointer;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 356px;
    height: 30px;
    line-height: 30px;
    background: white;


  }
  .upload_content{
    margin-top: 40px;
    height: auto;
    max-height: 400px;
    min-height: 45px;
    overflow: scroll;
  }

  .item_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all .3s;
    cursor: pointer;
    padding: 2px 0px;

    & > div > span {
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: rgba(0, 0, 0, .65);
      font-size: 14px;
    }
  }
}

.hover_bg {
  background: #e9f6fe;
}

.no_bg {
  background: white;
}

:deep(.el-progress-bar__outer) {
  margin-top: 4px;
  height: 2px !important;
}

:deep(.el-progress__text) {
  display: none;
}

.icon_del {
  color: rgba(0, 0, 0, .65);
  margin-right: 4px;
}

.leave_list {
  box-sizing: border-box;
  box-shadow: 0 0 1px 1px rgba(28, 28, 32, .05), 0 8px 24px rgba(28, 28, 32, .12);
  position: fixed;
  bottom: -640px;
  right: 30px;
  width: 373px;
  min-width: 373px;
  //height: auto;
  //max-height: 400px;
  //min-height: 45px;
  background-color: #fff;
  z-index: 999999;
  transition: all .4s ease-in-out;
  border-radius: 8px;
  padding: 10px;
  //overflow: scroll;
}

.enter_list {
  box-sizing: border-box;
  box-shadow: 0 0 1px 1px rgba(28, 28, 32, .05), 0 8px 24px rgba(28, 28, 32, .12);
  position: fixed;
  bottom: 38px;
  right: 30px;
  width: 373px;
  min-width: 373px;
  //height: auto;
  //max-height: 400px;
  //min-height: 45px;
  background-color: #fff;
  z-index: 999999;
  transition: all .4s ease-in-out;
  border-radius: 8px;
  padding: 10px;
  //overflow: scroll;
}
</style>