import request from "@/utils/request";

// 获取订单表
export function getQueryMyOrder(params, config) {
    return request.get("/front/mailPayOrder/queryMyOrder", params, config)
}

//通过id 获取订单
export function getQueryById(params, config) {
    return request.get('/front/mailPayOrder/queryByOrderCode', params, config)
}

//获取发票表
export function getQueryInvoiceList(params, config) {
    return request.get('/front/mailInvoice/queryInvoiceList', params, config)
}

// 统计发票金额
export function getStatisticalInvoice(params, config) {
    return request.get('/front/mailInvoice/statisticalInvoice', params, config)
}

//通过id 获取发票
export function getQueryByIdInvoice(params, config) {
    return request.get('/front/mailInvoice/queryById', params, config)
}

//申请开票的列表
export function getQueryApplyInvoiceList(params, config) {
    return request.get('/front/mailInvoice/queryApplyInvoiceList', params, config)
}

//申请开票
export function mailInvoiceAdd(params, config) {
    return request.post('/front/mailInvoice/add', params, config)
}


//意见反馈
export function getMailOpinionList(params, config) {
    return request.get('/front/mailOpinion/list', params, config)
}

// 通过id 查询反馈内容
export function getQueryByOptionId(params, config) {
    return request.get('/front/mailOpinion/queryByOptionId', params, config)
}

// 评论进行回复
export function mailOpinionContentAdd(params, config) {
    return request.post('/front/mailOpinionContent/add', params, config)
}

// 内容已解决
export function mailOpinionContentEdit(params, config) {
    return request.get('/front/mailOpinionContent/edit', params, config)
}

// 意见反馈- 添加
export function mailOpinionAdd(params, config) {
    return request.post('/front/mailOpinion/add', params, config)
}


// 公司子账号
export function getQuerySubAccountList(params, config) {
    return request.get('/front/companyUser/queryPageList', params, config)
}

// 新增子账号
export function addCompanyUser(params, config) {
    return request.post('/front/companyUser/addCompanyUser', params, config)
}

// 修改子账号信息
export function updateCompanyUser(params, config) {
    return request.post('/front/companyUser/updateCompanyUser', params, config)
}

// 修改账号密码
export function updateCompanyUserPassword(params, config) {
    return request.get('/front/companyUser/updateCompanyUserPassword', params, config)
}

// 删除子账号
export function deleteCompanyUser(params, config) {
    return request.get(`/front/mailFrontUser/deleteUser`, params, config)
}

// 子账号分配空间
export function distributionLocation(params, config) {
    return request.get(`/front/companyUser/distributionLocation`, params, config)
}


//发送邮箱验证码
export function sendEmailSms(params, config) {
    return request.get('/front/mailFrontUser/sendEmailSms', params, config)
}

// 绑定邮箱
export function bindEmail(params, config) {
    return request.get('/front/mailFrontUser/bindEmail', params, config)
}

//发送手机验证码
export function sendBindPhoneSms(params, config) {
    return request.get('/front/mailFrontUser/sendBindPhoneSms', params, config)
}

//绑定手机
export function bindPhone(params, config) {
    return request.get('/front/mailFrontUser/bindPhone', params, config)
}

//登陆密码
export function updatePassword(params, config) {
    return request.get('/front/mailFrontUser/updatePassword', params, config)
}

//通过id 查询公司信息
export function getCompanyQueryById(params, config) {
    return request.get('/front/mailCompany/queryById', params, config)
}

//编辑 公司的信息
export function mailCompanyEdit(params, config) {
    return request.put('/front/mailCompany/edit', params, config)
}

// 编辑个人信息
export function mailFrontUserEdit(params, config) {
    return request.put('/front/mailFrontUser/edit', params, config)
}

// 获取工位列表
export function queryWorkPosition(params, config) {
    return request.get('/front/mailWorkPosition/queryWorkPosition', params, config)
}

// 根据工位编号查询工位详情
export function queryWorkPositionById(params, config) {
    return request.get('/front/mailWorkPosition/queryById', params, config)
}


// 获取商品列表
export function getMailProductList(params, config) {
    return request.get('/front/mailProduct/list', params, config)
}

// 获取工位空闲列表
export function getFreeWorkPosition(params, config) {
    return request.get('/front/mailWorkPosition/getFreeWorkPosition', params, config)
}

// 工位迁移
export function moveWorkPosition(params, config) {
    return request.get('/front/mailWorkPosition/moveWorkPosition', params, config)
}


















