<template>
  <div class="box_content">
    <div class="link_user_box">
      <Cards :isShowTitle="false">
        <div class="top_tools">
          <el-button type="danger" :icon="Delete" style="margin-right: 10px" @click="checkDelete">删除</el-button>
          <el-button type="primary" :icon="CirclePlus" @click="addFollowForm">新增</el-button>
        </div>
        <el-table
            ref="multipleTableRef"
            :data="tableDataLogs"
            style="width: 100%"
            @selection-change="handleSelectionChange"
            :v-loading="isLoadingLog"
            :cell-style="rowClassName"
            :header-cell-style="headerClassName"
            :row-key="row => row.id"
        >
          <el-table-column type="selection" :reserve-selection="true" fixed="left">
          </el-table-column>
          <el-table-column
              v-for="item in table_columns_logs"
              :key="item.key"
              :label="item.key"
              :prop="item.value"
              :width="item.value==='followDetail'||item.value==='remark'?auto:(flexWidth(item.value, tableDataLogs, item.key))"
              :show-overflow-tooltip="item.value==='followDetail'||item.value==='remark'"
          >
            <template v-if="item.value==='mailCustomerFollowContactDTOList'" #default="{row}">
              <el-popover
                  placement="bottom-start"
                  :width="'max-content'"
                  :effect="'dark'"
                  :show-after="500"
                  trigger="hover"
              >
                <div
                    v-html="
                          '<div>' +
                          row.mailCustomerFollowContactDTOList
                            .map((item) => {
                              return `${item.contactName}<br/>`;
                            })
                            .join('') +
                          '</div>'
                        "
                ></div>
                <template #reference>
                  <el-button type="primary" link>{{ row.mailCustomerFollowContactDTOList.length }}</el-button>
                </template>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" :width="140">
            <template #default="{row,column,$index}">
              <el-button link type="primary" @click="editRowItem(row)">编辑</el-button>
              <el-button link type="primary" @click="showDetail(row)">详情</el-button>
              <el-popover
                  :visible="row.popoverVisible"
                  placement="top"
                  :width="164"
                  trigger="click"
              >
                <div class="inners">
                  <el-icon>
                    <WarningFilled/>
                  </el-icon>
                  <p>{{ $t('sys.message.ask.type.delete') }}</p>
                </div>
                <div style="text-align: center; margin-top:10px">
                  <el-button
                      size="small"
                      text
                      @click="cancelPopover(row, column, $index)"
                  >{{ $t('common.cancel') }}
                  </el-button
                  >
                  <el-button
                      size="small"
                      type="primary"
                      v-loading="rowDelete"
                      @click="handleDelete(row, column, $index)"
                  >{{ $t('common.confirm') }}
                  </el-button
                  >
                </div>
                <template #reference>
                  <el-button
                      type="danger"
                      link
                      @click="requestHandleDelete(row, column, $index)"
                  >{{ $t('common.delete') }}
                  </el-button
                  >
                </template>
              </el-popover>
            </template>
          </el-table-column>
          <template #empty>
            <el-empty
                :description="$t('common.data.empty')"
                :image="emptyUrl"
            />
          </template>
        </el-table>
        <!-- 分页 -->
        <div class="pages">
          <el-pagination
              v-model:current-page="pageNo"
              v-model:page-size="pageSize"
              background
              layout="total, prev, pager, next"
              v-if="total"
              :total="total"
              @current-change="handleCurrentChange"
          />
        </div>
      </Cards>
    </div>
    <CreateFollowDetail v-if="followVisible" v-model="followVisible" @close="closeFollow"
                        :isHeadLine="true"
                        :dialogFlag="dialogFlag"
                        :currentItemDetail="currentItemDetail"
                        @getList="getQueryPageList"
    >
    </CreateFollowDetail>

    <DeleteFollowItem v-if="followVisibleDelete" v-model="followVisibleDelete" @close="closefollowVisibleDelete"
                      :is-head-line="true" :multipleSelection="multipleSelection"
                      @getList="getQueryPageList"></DeleteFollowItem>
  </div>
</template>
<script setup>
import {emptyUrl} from "@/utils/statusParams";
import {ElMessage, ElTable} from "element-plus";
import Cards from "@/views/Customer/Cards.vue";
import {CirclePlus, Delete} from "@element-plus/icons-vue";
import {onMounted, ref} from "vue";
import CreateFollowDetail from "@/components/DiaPops/CreateFollowDetail.vue";
import {auto} from "@popperjs/core";
import {flexWidth} from "@/utils/tableWidth";
import {deleteBatchFollow, getMailCustomerContactByCustomerId, queryPageList} from "@/api/customer";
import {useRoute} from "vue-router";
import DeleteFollowItem from "@/components/DiaPops/DeleteFollowItem.vue";

const route = useRoute();

const tableDataLogs = ref([])

const table_columns_logs = ref([
  {key: '跟进日期', value: 'followTime'},
  {key: '联系人', value: 'mailCustomerFollowContactDTOList'},
  {key: '跟进方式', value: 'followWay'},
  {key: '跟进目的', value: 'followAim'},
  {key: '操作人', value: 'createName'},
  {key: '跟进详情', value: 'followDetail'},
  {key: '备注', value: 'remark'}
])


const followVisible = ref(false)
// create / edit / detail
const dialogFlag = ref('')
const addFollowForm = async () => {
  followVisible.value = true;
  dialogFlag.value = 'create'
}

const currentItemDetail = ref({})
const showDetail = (row) => {
  followVisible.value = true;
  currentItemDetail.value = row;
  dialogFlag.value = 'detail'
}
const editRowItem = (row) => {
  followVisible.value = true;
  currentItemDetail.value = row;
  dialogFlag.value = 'edit'
}

const deleteTargetItem = (row) => {
  console.log(row, 'row')
}
const closeFollow = () => {
  followVisible.value = false;
}

const multipleSelection = ref([])
const handleSelectionChange = (val) => {
  multipleSelection.value = val;
}


const followVisibleDelete = ref(false)
const closefollowVisibleDelete = () => {
  followVisibleDelete.value = false
}
const checkDelete = async () => {
  if (multipleSelection.value.length === 0) {
    ElMessage.warning("请你选择跟进记录")
    return
  }
  followVisibleDelete.value = true
}


const cancelPopover = async (row) => {
  row.popoverVisible = false;
};

// 删除 单行
const rowDelete = ref(false)
const handleDelete = async (row, column, index) => {
  const {id} = row;
  try {
    rowDelete.value = true
    await deleteBatchFollow({ids: [id]});
    ElMessage.success("删除成功");
    rowDelete.value = false
    row.popoverVisible = false;
    await getQueryPageList()
  } catch (e) {
    ElMessage.error(e)
    rowDelete.value = false
  }
};


const requestHandleDelete = (row) => {
  row.popoverVisible = true;
}


// 获取列表
const pageNo = ref(1);
const pageSize = ref(10);
const total = ref(0);
const isLoadingLog = ref(false)
const getQueryPageList = async () => {
  const payload = {
    customerId: route.params.userId,
    pageNo: pageNo.value,
    pageSize: pageSize.value,
  }
  try {
    isLoadingLog.value = true
    let res = await queryPageList(payload)
    console.log(res, 'res')
    total.value = res.result.total;
    pageNo.value = res.result.current;
    tableDataLogs.value = res.result.records;
    isLoadingLog.value = false
  } catch (e) {
    isLoadingLog.value = false
  }
}

async function handleCurrentChange(page) {
  pageNo.value = page;
  await getQueryPageList()
}

onMounted(async () => {
  await getQueryPageList()
})


const rowClassName = ({row, column, rowIndex, columnIndex}) => {
  if (columnIndex === 2 || columnIndex === 5) {
    return {textAlign: 'center'}
  } else {
    return {textAlign: 'left'}
  }
}

const headerClassName = ({row, column, rowIndex, columnIndex}) => {
  if (columnIndex === 2 || columnIndex === 5) {
    return {'text-align': 'center', background: '#f9f9f9', color: 'black', padding: '16px 0px'}
  } else {
    return {'text-align': 'left', background: '#f9f9f9', color: 'black', padding: '16px 0px'}
  }
}
</script>
<style lang="less" scoped>
@import "../../../less/page.less";

.box_content {
  width: 100%;
}

:deep(.el-empty__image img) {
  width: 54% !important;
}

.top_tools {
  padding-bottom: 16px;
  background: white;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

:deep(.el-card__body) {
  padding: 16px !important;
}

:deep(.el-popper) {
  max-width: 40% !important;
  max-height: 80px !important;
  overflow: scroll;
}

.inners {
  display: flex;
  justify-content: center;
  align-items: center;

  & > .el-icon {
    color: #faad14;
    margin-right: 2px;
  }
}
</style>