<template>
  <div class="inners">
    <div class="right">
      <div class="titles">我要反馈</div>
      <div class="content">
        <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            :rules="rules"
            label-width="120px"
            class="demo-ruleForm"
            :size="formSize"
            :label-position="'top'"
        >
          <el-form-item label="标题" prop="title">
            <el-input
                v-model="ruleForm.title"
                placeholder="请输入标题，最多50字"
                show-word-limit
                maxlength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="内容" prop="text">
            <el-input
                v-model="ruleForm.text"
                :rows="6"
                type="textarea"
                show-word-limit
                maxlength="300"
                placeholder="请输入内容，最多300字"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="图片" prop="img">
            <el-upload :action="action" :limit="3" ref="upload" list-type="picture-card"
                       :on-error="handleUploadError"
                       :on-success="handleUploadSuccess"
                       :headers="{
                          'X-Access-Token': getToken(),
                       }"
            >
              <div class="upload_inner">
                <el-icon>
                  <Plus/>
                </el-icon>
                <span>上传</span>
              </div>
              <template #file="{ file }">
                <div>
                  <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                  />
                  <span class="el-upload-list__item-actions">
                    <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                    >
                      <el-icon><zoom-in/></el-icon>
                    </span>
                    <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                    >
                      <el-icon><Delete/></el-icon>
                    </span>
                  </span>
                </div>
              </template>
            </el-upload>
          </el-form-item>
          <div>
            <el-alert
                title="图片最多三张"
                type="warning"
                show-icon
                :closable="false"
            />
          </div>

          <div class="submit_btn">
            <el-button type="primary" round @click="handleSubmit">提交</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
  <el-dialog width="max-content" v-model="dialogVisible">
    <img w-full :src="dialogImageUrl" alt="Preview Image"/>
  </el-dialog>
</template>
<script setup>
import {ref} from "vue";
import {useRouter} from "vue-router";
// 上传的地址
const action = ref(`${process.env.VUE_APP_BASE_API}/sys/oss/qiniu/upload`);
// 所有的数据
const multipartFiles = ref([]);
const router = useRouter();
const ruleForm = ref({title: "", text: ""});
const dialogVisible = ref(false)
const dialogImageUrl = ref('');

import {getToken} from '../../utils/auth';
import {mailOpinionAdd} from "@/api/usercenter";
import {ElMessage} from "element-plus";

const rules = ref({
  title: [{required: true, message: "您输入的标题不能为空", trigger: "blur"}],
  text: [{required: true, message: "你输入的内容不能为空", trigger: "blur"}],
});

const upload = ref(null)
const handleRemove = (uploadFile, uploadFiles) => {
  console.log(uploadFile, uploadFiles)
  const {name} = uploadFile
  upload.value.handleRemove(uploadFile);
  multipartFiles.value.splice(multipartFiles.value.findIndex((item) => item.name === name), 1);
  console.log(multipartFiles, 'multipartFiles')
}

const handlePictureCardPreview = (uploadFile) => {
  dialogImageUrl.value = uploadFile.url
  dialogVisible.value = true
}

function handleUploadError(error) {
  console.log('上传失败', error);
}

function handleUploadSuccess(e) {
  console.log('上传成功', e);
  multipartFiles.value.push(e.result);
  console.log(multipartFiles, 'multipartFiles')
}

const handleSubmit = async () => {
  const {title, text} = ruleForm.value;
  const companyId = window.localStorage.getItem('companyId')
  const multipartFilesToString = multipartFiles.value.map((item) => item.url).join(',')
  const targetObj = {
    title,
    companyId,
    remark: text,
    url: multipartFilesToString
  }
  const res = await mailOpinionAdd(targetObj)
  if (res.code !== 200) {
    ElMessage.error(res.message);
    return
  }
  ElMessage.success(res.message);
  ruleForm.value = {}
  upload.value.clearFiles();
  multipartFiles.value = [];
  router.push("/personal/repairOrder")
};
</script>

<style lang="less" scoped>
@import "./less/Tables.less";
@import "../../less/page.less";

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 155px;
  margin-top: 40px;
}

:deep(.el-form) {
  width: 100% !important;
}

:deep(.el-form-item__content),
:deep(.el-select) {
  width: 100% !important;
}

:deep(.el-form-item__content) {
  display: flex;
  justify-content: left;
}

#buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

#avatar {
  display: flex;
  justify-content: center;
}

#sp_input {
  border-right: none !important;
}

.submit_btn {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.upload_inner {
  display: flex;
  flex-direction: column;
  align-items: center;

  .el-icon {
    font-size: 20px;
  }
}

:deep(.el-upload--picture-card) {
  --el-upload-picture-card-size: 104px !important;
}

:deep(.el-upload-list--picture-card) {
  --el-upload-list-picture-card-size: 104px !important;
}

</style>
