<template>
  <div class="inners">
    <div class="right">
      <div class="titles">我的订单</div>
      <!-- 订单表格 -->
      <el-table :data="tableData" style="width: 100%" :row-style="{height: '70px'}" v-loading="loading"
                @row-click="handle"
                :header-cell-style="{'text-align': 'left', fontSize:'14px',color:'color: rgba(0,0,0,.85);', fontWeight: '500',background: '#f9f9f9', color: 'black', padding: '16px 0px'}"
      >
        <el-table-column prop="orderCode" label="订单编号" width="180"/>
        <el-table-column prop="productName" label="产品和服务" width="180"/>
        <el-table-column prop="payTime" label="付款时间"/>
        <el-table-column prop="payMoney" label="实付金额">
          <template #default="{ row, column, $index }">
            <span style="font-size: 22px">￥</span>
            <span style="font-size: 14px">
              <span v-if="row.payMoney !== null && Object.keys(row).length > 0">
                <span style="font-size: 25px">{{ row.payMoney.toFixed(2).split('.')[0] }}</span>.
                <span>{{ row.payMoney.toFixed(2).split('.')[1] }}</span>
              </span>
             <span v-else>
               {{ row.payMoney }}
             </span>
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div class="pages">
        <el-pagination
            v-model:current-page="current"
            v-model:page-size="size"
            v-if="total"
            layout="total, prev, pager, next"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import BasicCard from "./Basic/BasicCard.vue";
import {reactive, ref, onMounted} from "vue";
import {useRouter} from "vue-router";
import {getQueryMyOrder} from "@/api/usercenter";

const router = useRouter()
const tableData = ref([]);
const loading = ref(false);
const current = ref(1);
const size = ref(10);
const total = ref(0);
const handleCurrentChange = (page) => {
  current.value = page;
  getQueryMyOrderApis();
};


// 获取所有的订单数据
const getQueryMyOrderApis = async () => {
  loading.value = true;
  let res = await getQueryMyOrder(
      {
        pageNo: current.value,
        pageSize: size.value
      }
  )
  if (res.code !== 200) {
    ElMessage.error(res.message)
    return
  }
  const {records} = res.result;
  loading.value = false;
  tableData.value = records;
  current.value = res.result.current;
  total.value = res.result.total;
  size.value = res.result.size;
}

const handle = (row) => {
  const {orderCode, id} = row
  router.push({path: '/personal/orderDetails', query: {payCode: orderCode}})
}

onMounted(async () => {
  await getQueryMyOrderApis();
})
</script>

<style lang="less" scoped>
@import "./less/Tables.less";
@import "../../less/page.less";

.inners {
  width: 840px !important;
  min-width: 840px !important;

  .right {
    height: max-content !important;
    //overflow: auto !important;
    padding-bottom: 40px !important;
  }
}


</style>
