<template>
  <ElDialog
      v-model="isShow"
      :title="'批量选择联系人邮箱'"
      :footer="null"
      centered
      top="2em"
      :width="'85%'"
      style="min-width:800 !important;"
      destroyOnClose
      @cancel="close"
  >

    <div>
      <TopSearch :adminShow="true" :searchShow="true" @searchKey="getSearchKey"/>
    </div>
    <template v-if="!isLoading">
      <div v-if="customerList.length!==0" style="margin-top: 20px">
        <!--        <el-button @click="selectAll" size="small" type="primary"></el-button>-->
        <div id="checkoutAll" ref="checkoutAll">
          <el-checkbox v-model="isSelectAll" label="全选" size="large" @change="selectAll">
            {{
              $t('common.customer.checkout.all', {
                count: isSelectAll ? totals : selectedRows.filter(item => item.email && item.level == 1).length
              })
            }}&nbsp;;&nbsp;
            {{
              !isLoadingAll ? isSelectAll ? '共计' + allTargetEmailList.length + "个邮箱" : '共计' + selectedRows.filter(item => item.email && item.level == 2 && emailReg.test(item.email)).length + "个邮箱" : '共计 (正在统计中...) 个邮箱'
            }}
          </el-checkbox>
          <el-button type="primary" size="small" @click="counterElection" style="margin-left: 10px">反选</el-button>
        </div>
        <div style="margin-top: 15px">
          <el-table
              v-loading="isLoading"
              :data="customerList"
              style="width: 100%;margin-top: 16px"
              lazy
              :height="'45vh'"
              id="treeTable"
              :row-key="(row)=>row.id"
              ref="treeTable"
              @selection-change="handleSelectionChangeCustomerList"
              :header-cell-class-name="cellClass"
              :cell-style="rowClassName"
              :header-cell-style="headerClassName"
          >
            <el-table-column type="selection" :reserve-selection="true" width="40" fixed="left"/>
            <el-table-column
                v-for="(item) in table_columns_public_list_group"
                :key="item.key"
                :label="item.key"
                :prop="item.value"
                label="Date"
                :width="item.value==='customerCode'?'200':item.value==='customerOrigin'?'240':item.value==='customerName'?auto:flexWidth(item.value, customerList, item.key)"
                :min-width="flexWidth(item.value, customerList, item.key)"
                :show-overflow-tooltip="item.value==='customerName'||item.value==='customerCode'||item.value==='customerOrigin'?true:false"
            >
              <template #default="{ row, column, $index }">
                <template v-if="item.key === $t('common.customer.inChargeTheLast') && !row.isContact">
                  <el-popover
                      effect="dark"
                      placement="bottom"
                      width="max-content"
                      trigger="hover"
                      :show-after="500"
                      v-if="row.mangerVOList&&row.mangerVOList.length > 0"
                  >
                    <!-- 联系人 -->
                    <div
                        v-html="
                      '<div>' +
                      row.mangerVOList
                        .map((item) => {
                          return `${item.managerName}<br/>`;
                        })
                        .join('') +
                      '</div>'
                    "
                    ></div>
                    <template #reference>
                      <el-button link type="primary">
                        {{ row.mangerVOList && row.mangerVOList.length }}
                      </el-button>
                    </template>
                  </el-popover
                  >
                  <template v-else>
                    <el-button link type="primary">
                      {{ row.mangerVOList && row.mangerVOList.length }}
                    </el-button>
                  </template>
                </template>
                <template v-else-if="item.key === $t('common.customer.Region')">
                  <div style="cursor: pointer">
                    <el-popover
                        effect="dark"
                        placement="right"
                        width="max-content"
                        trigger="hover"
                        :content="row.zoneAddress"
                        :show-after="500"
                        v-if="row.zoneAddress"
                    >
                      <template #reference>
                        <el-image :src="flag[row.zoneCode]"
                                  style="width:26px;margin-right:2px;vertical-align: middle;" lazy/>
                      </template>
                    </el-popover>
                  </div>
                </template>
                <template v-else-if="item.key ===$t('common.lastcontact.time')">
                  <div>{{ row.lastTime }}</div>
                </template>
                <template v-else-if="item.value==='customerName'">
                  <div>{{ row.customerName ? truncateByBytes(row.customerName, 45) : '' }}</div>
                </template>
                <template v-else-if="item.value==='customerOrigin'">
                  <div v-if="row.level==2">{{ row.email }}</div>
                  <div v-else>{{ row.customerOrigin }}</div>
                </template>
              </template>
            </el-table-column>
            <template #empty>
              <el-empty :description="$t('common.data.empty')" :image="emptyUrl"/>
            </template>
          </el-table>
          <div class="pages">
            <el-pagination
                popper-class="page"
                v-model:current-page="current"
                v-model:page-size="size"
                :page-sizes="[10,30,50,100]"
                background
                layout="total, prev, pager, next"
                v-if="totals"
                :total="totals"
                @current-change="handleCurrentChange"
            />
          </div>

        </div>
      </div>
      <div v-else class="display:flex;">
        <el-empty :description="$t('common.data.empty')" :image="emptyUrl"/>
      </div>
    </template>
    <div style="height: 260px" v-if="isLoading" v-loading="isLoading"></div>
    <template #footer>
      <div class="settings">
        <ElButton @click="close">取消
        </ElButton>
        <ElButton type="primary" @click="addToEmails" :loading="isLoadingAll">确定
        </ElButton>
      </div>
    </template>
  </ElDialog>
</template>

<script setup>
import {ElButton, ElDialog, ElTable,} from "element-plus";
import {ref, defineProps, watch, defineEmits, computed, onMounted, nextTick} from 'vue'
import {useStore} from "vuex";
import {getMassCustomerList} from "@/api/customer";
import TopSearch from "@/views/Customer/TopComponents/TopSearch.vue";
import {emailReg, emptyUrl} from "@/utils/statusParams";
import sessionStorageUtil from "@/utils/sessionStorageUtil";
import {TableV2FixedDir} from 'element-plus'
import {table_columns_public_list, table_columns_public_list_group} from "@/views/Customer/map";
import {flexWidth} from "@/utils/tableWidth";
import flag from "@/assets/flag";
import {truncateByBytes} from "@/utils/notifies/tool";
import ConfirmDeletePopover from "@/components/ConfirmDeletePopover.vue";
import {auto} from "@popperjs/core";
import {extractEmails, flattenChildren, replaceMailMassEmailVOListWithChildren} from "@/utils/filters";

const store = useStore();
const emit = defineEmits()
const isShow = ref(false);
const teamName = ref("")
const loading = ref(false);


const current = ref(1);
const size = ref(20)
const totals = ref(0)


// 历史勾选值
const historySelection = new Map()
const handleCurrentChange = async (val) => {
  current.value = val
  //  重新获取数据
  await getCustomerList()


  if (isSelectAll.value) {
    await selectAll()
  }
  if (isCounterElection.value) {
    counterElection()
  }
}


const targetEmailList = ref([])
const selectedRows = ref([]); // 保存选中的行
const handleSelectionChangeCustomerList = (val) => {
  console.log(val, 'val')
  targetEmailList.value = val.filter(item => item.email && item.level == 2).map(_ => _.email)
  selectedRows.value = val;
  console.log(targetEmailList.value, 'targetEmailList')
}
const treeTable = ref(null)
const allCustomerList = ref([])
const isSelectAll = ref(false)

const selectAll = async () => {
  // 获取全部数据
  customerList.value.forEach(row => {
    treeTable.value.toggleRowSelection(row, true);
  })
  if (isSelectAll.value) {
    await getCustomerAllList()
  }
}


const isCounterElection = ref(false)
const counterElection = () => {
  customerList.value.forEach(row => {
    treeTable.value.toggleRowSelection(row);
  })
  if (isSelectAll.value) {
    isSelectAll.value = !isSelectAll.value
  }
}


const query = ref('')

const {value, isChecked} = defineProps({
  value: {type: Boolean, default: false},
  isChecked: {type: Array, default: []}
})

watch(() => value, (val) => {
  isShow.value = val;
}, {immediate: true})


const customerList = ref([])
const teamId = computed(() => store.state.email.teamID);
const isLoading = ref(false);
const isSuperAdmin = computed(() => store.getters["user/isSuperAdmin"])
const userInfo = computed(() => store.state.user.userInfo)
// 搜索
const searchValue = ref({});
const getSearchKey = async (value) => {
  const newObj = JSON.parse(JSON.stringify(value));
  searchValue.value = newObj;
  console.log(searchValue.value, 'searchValue.value')
  // 列表数据
  await getCustomerList();
};


// 获取所有的客户以及客户的邮箱地址
const getCustomerList = async () => {
  isLoading.value = true;
  if (searchValue.value.managerId === "all") {
    searchValue.value.managerId = ""
  }
  await getMassCustomerList({
    // managerId: isSuperAdmin.value ? managerId === undefined ? '' : managerId : userInfo.value.id,
    ...searchValue.value,
    teamId: teamId.value,
    pageSize: size.value,
    pageNo: current.value,
  }).then((res) => {
    customerList.value = replaceMailMassEmailVOListWithChildren(res.result.records)
    current.value = res.result.current;
    totals.value = res.result.total
    isLoading.value = false;
  });
};


const cellClass = (row) => {
  if (row.columnIndex === 0) {
    return isSelectAll.value ? "disabledCheck" : "abledCheck"
  }
}

const getCustomerAllList = async () => {
  if (searchValue.value.managerId === "all") {
    searchValue.value.managerId = ""
  }
  isLoadingAll.value = true
  await getMassCustomerList({
    ...searchValue.value,
    teamId: teamId.value,
    pageSize: 99999,
    pageNo: 1,
  }).then((res) => {
    allCustomerList.value = replaceMailMassEmailVOListWithChildren(res.result.records)
    isLoadingAll.value = false
  });
}
const allTargetEmailList = computed(() => flattenChildren(allCustomerList.value).filter(item => item.email && item.level == 2 && emailReg.test(item.email)) // 确保 email 存在
    .map(item => item.email))
//收集邮箱并添加
const isLoadingAll = ref(false)
const addToEmails = async () => {
  if (isSelectAll.value) {
    emit('addToEmail', allTargetEmailList.value)
  } else {
    emit('addToEmail', targetEmailList.value)
  }
}

const close = () => {
  emit("close");
}

onMounted(async () => {
  await store.dispatch("cusList/getMailCustomerLevel");

});


const rowClassName = ({row, column, rowIndex, columnIndex}) => {
  if (columnIndex === 1 || columnIndex === 2 || columnIndex === 3) {
    return {textAlign: 'left'}
  } else {
    return {textAlign: 'center'}
  }
}

const headerClassName = ({row, column, rowIndex, columnIndex}) => {
  if (columnIndex === 1 || columnIndex === 2 || columnIndex === 3) {
    return {'text-align': 'left', background: '#f9f9f9', color: 'black', padding: '16px 0px'}
  } else {
    return {'text-align': 'center', background: '#f9f9f9', color: 'black', padding: '16px 0px'}
  }
}

</script>
<style scoped lang="less">
@import "../../less/page.less";

.fx-j-c {
  display: flex;
  justify-content: center;
}

.settings {
  //margin-top: 20px;
  display: flex;
  justify-content: center;
}

:deep(.el-empty__image img) {
  width: 54% !important;
}

:deep(.el-empty__description) {
  margin-top: 10px;
}

:deep(.el-checkbox__inner::after) {
  top: 1px !important;
  left: 5px !important;
}

#checkoutAll {
  padding-left: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

:deep(.disabledCheck .el-checkbox__input) {
  display: none !important;
  padding: 0px !important;
}

:deep(.abledCheck .el-checkbox__input) {
  display: block !important;
  padding: 0px !important;
}

:deep(.disabledCheck) {
  padding: 0px !important;
}

:deep(.abledCheck) {
  padding: 0px !important;
}

:deep(.el-tooltip>div) {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}


</style>
