<template>
  <div :class="isQs===1?'avatar_out_box_sq':'avatar_out_box'">
    <el-avatar :size="isQs===1?50:100" :shape="isQs===1?'square':'circle'" :src="imgUrl"/>
    <ElUpload
        class="upload-demo"
        :action="action"
        :headers="{
          'X-Access-Token': getToken(),
        }"
        :show-file-list="false"
        :on-error="handleUploadError"
        :on-success="handleUploadSuccess"
    >
      <template #trigger>
        <div class="edits">编辑</div>
      </template>
    </ElUpload>
  </div>
</template>

<script setup>
import {ref, defineEmits, reactive, watch} from "vue";
import {getToken} from "@/utils/auth";

const emits = defineEmits(['update:modelValue'])
const multipartFiles = ref([])
const props = defineProps({
  isQs: {
    type: Number,
    default: 0
  },
  imgUrl: {
    type: String,
    default: ''
  }
});
// 上传的地址
const action = ref(`${process.env.VUE_APP_BASE_API}/sys/oss/qiniu/upload`);
const circleUrl = ref('')

function handleUploadError(error) {
  console.log('上传失败', error);
}

function handleUploadSuccess(e) {
  console.log('上传成功', e);
  multipartFiles.value.push(e.result);
  circleUrl.value = multipartFiles.value[0].url
//   将数据进行回传？
  emits("update:modelValue", circleUrl.value)
}
</script>

<style lang="less" scoped>
.avatar-box-border {
  border: 1px dashed #409eff !important;
}

.avatar_out_box {
  position: relative;
  display: flex;
  width: 100px;
  height: 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;

}

.avatar_out_box_sq {
  position: relative;
  display: flex;
  width: 100px;
  height: 100px;
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

:deep(.el-avatar--square >img) {
  height: 60px !important;
  width: 60px !important;
}

:deep(.el-avatar) {
  position: absolute;
}

.edits {
  width: 100px;
  text-align: center;
  line-height: initial;
  background: #3171f6;
  font-size: 14px;
  color: white;
  position: absolute;
  top: 80px;
}

.avatar_out_box {
  background: #f3f3f3 !important;
}


</style>
