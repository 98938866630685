<template>
  <div class="slid_bar">
    <div class="set_up">
      <el-button type="primary" round @click="handleToWrite" :icon="Edit"
      >{{ $t('common.email.write') }}
      </el-button
      >
      <el-button round :loading="loading" @click="handleNewMail" :icon="Message"
      >{{ $t('common.email.recieve') }}
      </el-button
      >
    </div>
    <el-scrollbar height="calc(100vh - 180px)"
    ><!-- 滚动条 -->
      <ElMenu
          :default-active="targetRouter"
          class="el-menu-vertical-demo"
          :router="true"
          :default-openeds="[`/email/customer`, ]"
          @select="handleMenuSelect"
          @open="openTarget"
          @close="closeTarget"
      >
        <ElMenuItem :index="`/email/inbox`" router>
          <span>
            <img class="in" v-if="activeRoute === '/email/inbox'" src="../../assets/statusbar/sb1y.png"/>
            <img v-else class="in" src="../../assets/statusbar/sb1.png"/>
            <span>{{ $t('common.emailbox.receive') }}</span>
          </span>
          <span class="badge_right">
              <el-badge
                  v-if="myEmailList.length!==0?myEmailList[0].count !== 0:false"
                  :value="myEmailList[0].count"
                  class="item"
                  type="danger"
              ></el-badge>
          </span
          >
          <Transition>
            <span v-if="activeRoute === '/email/inbox'" class="sp_right_col"></span>
          </Transition>
        </ElMenuItem>
        <ElMenuItem :index="`/email/has_send`" router>
          <img class="in" v-if="activeRoute === '/email/has_send'" src="../../assets/statusbar/sb2y.png"/>
          <img v-else class="in" src="../../assets/statusbar/sb2.png"/>
          <span>{{ $t("common.has.sent") }}</span>
          <Transition>
            <span v-if="activeRoute === '/email/has_send'" class="sp_right_col"></span>
          </Transition>
        </ElMenuItem>
        <ElMenuItem :index="`/email/draft`" router>
          <img class="in" v-if="activeRoute === '/email/draft'" src="../../assets/statusbar/sb3y.png"/>
          <img v-else class="in" src="../../assets/statusbar/sb3.png"/>
          <span>{{ $t("common.draftsbox") }}</span>
          <span class="badge_right">
            <el-badge
                v-if="myEmailList.length!==0?myEmailList[2].count !== 0:false"
                :value="myEmailList[2].count"
                class="item"
                type="danger"
            ></el-badge>
            </span
            >
          <Transition>
            <span v-if="activeRoute === '/email/draft'" class="sp_right_col"></span>
          </Transition>
        </ElMenuItem>
        <ElMenuItem :index="`/email/waste`" router>
          <img class="in" v-if="activeRoute === '/email/waste'" src="../../assets/statusbar/sb4y.png"/>
          <img v-else class="in" src="../../assets/statusbar/sb4.png"/>
          <span>{{ $t('common.dustbin') }}</span>
          <Transition>
            <span v-if="activeRoute === '/email/waste'" class="sp_right_col"></span>
          </Transition>
        </ElMenuItem>
        <!--        v-if="userIsAdmin"-->
        <ElMenuItem :index="`/email/examine`" router v-if="isSuperAdmin||isAdmin">
          <img v-if="activeRoute === '/email/examine'" class="in" src="../../assets/statusbar/sb5y.png"/>
          <img v-else class="in" src="../../assets/statusbar/sb5.png"/>
          <span>{{ $t('common.email.examine') }}</span>
          <span class="badge_right">
                                  <el-badge
                                      v-if="myEmailList.length!==0?myEmailList[1].count !== 0:false"
                                      :value="myEmailList[1].count"
                                      class="item"
                                      type="danger"
                                  ></el-badge> </span
          >
          <Transition>
            <span v-if="activeRoute === '/email/examine'" class="sp_right_col"></span>
          </Transition>
        </ElMenuItem>
        <ElMenuItem :index="`/email/follow_up`" router>
          <img v-if="activeRoute === '/email/follow_up'" class="in" src="../../assets/statusbar/sb6y.png"/>
          <img v-else class="in" src="../../assets/statusbar/sb6.png"/>
          <span>{{ $t('common.followup.emailremind') }}</span>
          <span class="badge_right">
                                  <el-badge
                                      v-if="myEmailList.length!==0?myEmailList[3].count !== 0:false"
                                      :value="myEmailList[3].count"
                                      class="item"
                                      type="danger"
                                  ></el-badge></span
          >
          <Transition>
            <span v-if="activeRoute === '/email/follow_up'" class="sp_right_col"></span>
          </Transition>
        </ElMenuItem>
        <!--                新建文件夹-->
        <!--                <ElSubMenu id="dir_target" @contextmenu.prevent="open($event, data)">-->
        <!--                  <template #title>-->
        <!--                    <span>我的文件夹</span>-->
        <!--                  </template>-->
        <!--                  <ElMenuItem v-for="item in dirList">-->
        <!--                    <i aria-label="图标: folder" class="anticon anticon-folder"-->
        <!--                       style="color: rgb(41, 182, 246); font-size: 24px;height: 24px;">-->
        <!--                      <svg viewBox="64 64 896 896" data-icon="folder" width="1em" height="1em" fill="currentColor"-->
        <!--                           aria-hidden="true" focusable="false" class="">-->
        <!--                        <path-->
        <!--                            d="M880 298.4H521L403.7 186.2a8.15 8.15 0 0 0-5.5-2.2H144c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V330.4c0-17.7-14.3-32-32-32z"></path>-->
        <!--                      </svg>-->
        <!--                    </i>-->
        <!--                    <span style="margin-left: 8px"-->
        <!--                    >{{ item.dirName }}</span-->
        <!--                    ></ElMenuItem-->
        <!--                  >-->
        <ElSubMenu id="dir_target" :index="`/email/folder`">
          <template #title>
            <el-icon v-if="openFlag">
              <FolderOpened/>
            </el-icon>
            <el-icon v-else>
              <Folder/>
            </el-icon>
            <span>我的文件夹</span>
          </template>
          <ElMenuItem v-for="item in fileList" :key="item.id" :index="`/email/folder/${item.id}`" router
                      style="padding: 0px 0px 0px 20px !important;">
            <!--            <i aria-label="图标: folder" class="anticon anticon-folder"-->
            <!--               style="color: rgb(41, 182, 246); font-size: 24px;height: 24px;">-->
            <!--              <svg viewBox="64 64 896 896" data-icon="folder" width="1em" height="1em" fill="currentColor"-->
            <!--                   aria-hidden="true" focusable="false" class="">-->
            <!--                <path-->
            <!--                    d="M880 298.4H521L403.7 186.2a8.15 8.15 0 0 0-5.5-2.2H144c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V330.4c0-17.7-14.3-32-32-32z"></path>-->
            <!--              </svg>-->
            <!--            </i>-->
            <div style="width: 100%;height: 100%;display: flex;justify-content: space-between;align-items: center">
              <el-popover
                  placement="top"
                  :width="'max-content'"
                  style="max-width: 300px"
                  trigger="hover"
                  :effect="'dark'"
                  :show-after="600"
                  :content="item.fileName"
                  :popper-class="'sp_dir'"
              >
                <template #reference>
                  <span class="dir_name">{{ item.fileName }}</span>
                </template>
              </el-popover>
              <el-dropdown :trigger="'hover'">
                <span class="el-dropdown-link">
                    <el-icon :size="14">
                      <Operation/>
                     </el-icon>
                 </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <!--                    <el-dropdown-item @click="editeTarget">编辑</el-dropdown-item>-->
                    <el-dropdown-item @click="editeTarget(item)">编辑</el-dropdown-item>
                    <el-dropdown-item @click="deleteTargetDir(item)"><span style="color: #c8453f">删除</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </ElMenuItem
          >
          <ElMenuItem style="padding: 0px !important;">
            <div style="width: 100%;height: 100%;display: flex;justify-content: center;align-items: center">
              <el-button size="small" :icon="FolderAdd" type="primary" plain @click="handleCreateDir">新建</el-button>
            </div>
          </ElMenuItem>
        </ElSubMenu>


        <ElMenuItem :index="`/email/foldergroup`" router style="padding-left: 12px !important;"
                    v-if="isSuperAdmin||isAdmin">

          <el-icon style="color:#64a2f8" v-if="activeRoute === '/email/foldergroup'">
            <Files/>
          </el-icon>
          <el-icon v-else>
            <Files/>
          </el-icon>
          <span>团队文件夹</span>
          <Transition>
            <span v-if="activeRoute === '/email/foldergroup'" class="sp_right_col"></span>
          </Transition>
        </ElMenuItem>

        <ElSubMenu :index="`/email/customer`">
          <template #title>
            <span style="margin-left: 4px">{{ $t('common.customer') }}</span>
          </template>
          <ElMenuItem :index="`/email/customer/list`">
            <img class="in" v-if="activeRoute==='/email/customer/list'" src="../../assets/statusbar/sb7y.png"/>
            <img class="in" v-else src="../../assets/statusbar/sb7.png">
            <span
            >{{ $t('common.customer.list') }}</span
            >
            <Transition>
              <span v-if="activeRoute === '/email/customer/list'" class="sp_right_col"></span>
            </Transition>
          </ElMenuItem
          >
          <ElMenuItem :index="`/email/customer/public`">
            <img class="in" v-if="activeRoute==='/email/customer/public'" src="../../assets/statusbar/sb8y.png"/>
            <img class="in" v-else src="../../assets/statusbar/sb8.png"/>
            <span
            >{{ $t('common.customer.public') }}</span
            >
            <Transition>
              <span v-if="activeRoute === '/email/customer/public'" class="sp_right_col"></span>
            </Transition>
          </ElMenuItem
          >

          <ElMenuItem :index="`/email/customer/setting`"
          >
            <img class="in" v-if="activeRoute==='/email/customer/setting'" src="../../assets/statusbar/sb9y.png"/>
            <img class="in" v-else src="../../assets/statusbar/sb9.png"/>
            <span>{{ $t('common.customer.management') }}</span>
            <span class="badge_right">
                                      <el-badge
                                          v-if="myEmailList.length!==0?myEmailList[4].count !== 0:false"
                                          :value="myEmailList[4].count"
                                          class="item"
                                          type="danger"
                                      ></el-badge> </span
            >
            <Transition>
              <span v-if="activeRoute === '/email/customer/setting'" class="sp_right_col"></span>
            </Transition>
          </ElMenuItem>

          <ElMenuItem :index="`/email/customer/follow`"
          ><img class="in" v-if="activeRoute==='/email/customer/follow'" src="../../assets/statusbar/sb10y.png"/>
            <img class="in" v-else src="../../assets/statusbar/sb10.png"/>
            <span
            >{{ $t("common.customer.followup.remind") }}</span
            >
            <span class="badge_right">
            <el-badge
                v-if="myEmailList.length!==0?myEmailList[5].count !== 0:false"
                :value="myEmailList[5].count"
                class="item"
                type="danger"
            ></el-badge>
            </span
            >
            <Transition>
              <span v-if="activeRoute === '/email/customer/follow'" class="sp_right_col"></span>
            </Transition>
          </ElMenuItem
          >

          <ElMenuItem :index="`/email/customer/sending`"
          ><img class="in" v-if="activeRoute==='/email/customer/sending'" src="../../assets/statusbar/sendAll.png"/>
            <img class="in" v-else src="../../assets/statusbar/sendAlly.png"/>
            <span
            >{{ $t("sending.customer.group.text") }}</span
            >
            <Transition>
              <span v-if="activeRoute === '/email/customer/sending'" class="sp_right_col"></span>
            </Transition>
          </ElMenuItem
          >
        </ElSubMenu>
        <ElSubMenu :index="`/email`">
          <template #title>
            <span style="margin-left: 4px">{{ $t('common.setting') }}</span>
          </template>
          <ElMenuItem :index="`/email/group_setting`">
            <img class="in" v-if="activeRoute==='/email/group_setting'" src="../../assets/statusbar/sb11y.png"/>
            <img class="in" v-else src="../../assets/statusbar/sb11.png"/>
            <span>{{ $t("common.team.management") }}</span>
            <Transition>
              <span v-if="activeRoute === '/email/group_setting'" class="sp_right_col"></span>
            </Transition>
          </ElMenuItem>
          <ElMenuItem :index="`/email/account_setting`">
            <img class="in" v-if="activeRoute==='/email/account_setting'" src="../../assets/statusbar/sb12y.png"/>
            <img class="in" v-else src="../../assets/statusbar/sb12.png"/>
            <span>{{ $t('common.setting.email') }}</span>
            <Transition>
              <span v-if="activeRoute === '/email/account_setting'" class="sp_right_col"></span>
            </Transition>
          </ElMenuItem>
        </ElSubMenu>
      </ElMenu>
    </el-scrollbar>
  </div>

  <ul v-show="visible" :style="{ left: left + 'px', top: top + 'px' }" class="newcontextmenu">
    <li @click="addDir"><i class="el-icon-plus" style="color:#1e9fff"></i>新增文件夹</li>
    <li @click="addDepartment('新增同级')"><i class="el-icon-plus" style="color:#1e9fff"></i>重命名</li>
    <li @click="deleteDepartment"><i class="el-icon-delete" style="color:red"></i>删除</li>
  </ul>

  <!--    邮箱是否绑定？-->
  <Dialog
      v-model="dialogVisibleEmailBind"
      :width="400"
      :dialogVisible="dialogVisibleEmailBind"
      :isHeadLine="false"
      @update:dialogVisible="(val) => (dialogVisibleEmailBind = val)"
  >
    <template #content>
      <div class="cont">
        <span><el-icon style="color:#efb041;font-size: 20px;margin-right: 10px;"><QuestionFilled/></el-icon></span>
        <span class="text">{{ $t('common.email.reminderEmailAddress') }}</span>
      </div>
    </template>
    <template #footer>
      <div class="setting_left">
        <el-button @click="dialogVisibleEmailBind = false">{{ $t('common.sss16') }}</el-button>
        <el-button type="primary" @click="goToAddShowConfig">{{ $t('common.confirm') }}</el-button>
      </div>
    </template>
  </Dialog>
  <!--  新建文件夹-->
  <CreateDir v-if="visitable" v-model="visitable" @close="close" :isHeadLine="true" @addDir="addTargetDir"
             :isEdit="isEdit" @getList="getFileList" :currentItem="currentItem"></CreateDir>
  <DeleteDir v-if="visitableDelete" v-model="visitableDelete" @close="closeDelete" :currentItem="currentItem"
             :isHeadLine="true" @getList="getFileListDelete"></DeleteDir>

</template>
<script setup>
import {ElMenu, ElMenuItem, ElSubMenu} from "element-plus";
import {
  Edit,
  Message,
  FolderAdd,
} from "@element-plus/icons-vue";
import {useRoute, useRouter} from "vue-router";
import {onMounted, ref, reactive, computed, watch, onBeforeMount} from "vue";
import {getMailByDay, getDefaultMailConfig} from "@/api";
import {useStore} from "vuex";
import Dialog from "@/components/Dialog.vue";
import CreateDir from "@/components/DiaPops/CreateDir.vue";
import DeleteDir from "@/components/DiaPops/DeleteDir.vue";

const store = useStore();
const router = useRouter();
const route = useRoute();
const activeRoute = computed(() => {
  return route.path;
});

const activeRouteParams = computed(() => {
  return route.params
})

const fileList = computed(() => store.state.sidebar.fileList)
// const activeRoute = computed(() => {
//   return `/email/inbox`;
// });
const dialogVisibleEmailBind = ref(false)

const myEmailList = computed(() => store.state.countshow.myEmailList)
// 权限控制
const isSuperAdmin = computed(() => store.getters["user/isSuperAdmin"])
const isAdmin = computed(() => store.getters["user/isAdmin"])

const teamId = computed(() => store.state.email.teamID);
// 徽章
const loading = ref(false);

async function handleToWrite() {
  const resultConfig = await getDefaultMailConfig({teamId: teamId.value});
  // console.log(resultConfig)
  if (resultConfig.result == null) {
    // ElMessage.warning('未绑定拉取的默认邮箱,请先绑定默认邮箱!');
    loading.value = false;
    dialogVisibleEmailBind.value = true;
    return
  }
  // router.push({path: `/email/write_mail`}, "_blank");
  const newUrl = router.resolve('/email/write_mail')
  openNewWindow(newUrl.href);
}

const openNewWindow = (url) => {
  window.open(url, "_blank");
};

const dropdown_file = ref(null)
const handleClickDropDownFile = (visible) => {
  // console.log(visible, 'visible')
  if (!dropdown_file.value) return
  dropdown_file.value.handleOpen()

}

const targetUserFiles = ref("我")
const handleDropDown = (event, val) => {
  // console.log(event.target.innerText, val, 'val')
  const selectTargetUser = event.target.innerText;
  targetUserFiles.value = `${selectTargetUser}`
}

async function getMailConfig() {
  // console.log("导航组件获取start");
  // // console.log(result, '导航组件获取')
  // if (result.code !== 0) {
  //   ElMessage.error(result.msg);
  //   return;
  // }
  // id.value = mailId;
}

function goToAddShowConfig() {
  dialogVisibleEmailBind.value = false;
  // localStorage.setItem('activeMenu', '/email/account_setting')
  targetRouter.value = '/email/account_setting'
  router.push({path: `/email/account_setting`, query: {type: 'add'}})
}

const flags = ref(0)

async function handleNewMail() {
  loading.value = true;
  const resultConfig = await getDefaultMailConfig({teamId: teamId.value});
  // console.log(resultConfig)
  if (resultConfig.result == null) {
    // ElMessage.warning('未绑定拉取的默认邮箱,请先绑定默认邮箱!');
    loading.value = false;
    dialogVisibleEmailBind.value = true;
    return
  }
  const {id: mailId} = resultConfig.result;
  const result = await getMailByDay({id: mailId, type: 1});
  if (result.code !== 200) {
    return
  }
  loading.value = false;
  flags.value += 1
// 重新获取邮件列表
  await store.commit("countshow/setEmailListFlag", flags.value)
//   更新标记
  await store.dispatch('countshow/getTeamAllDataApis')
}

onMounted(async () => {
  // targetRouter.value = targetCom.value
  await getMailConfig();
  await store.dispatch('countshow/getTeamAllDataApis')
  await getFileList()
});

const getFileList = async () => {
  await store.dispatch("sidebar/getEmailGroupFileListApis", {teamId: teamId.value, isUpdateFileList: true})
}
const getFileListDelete = async () => {
  await getFileList()
  // 删除选中的那一项
  if (activeRouteParams.value.folderId === currentItem.value?.id) {
    router.push('/email/inbox')
  }
}

watch(() => teamId.value, async () => {
  await getMailConfig();
  await store.dispatch('countshow/getTeamAllDataApis')
})

const targetRouter = ref(route.path)
watch(() => route.path, (newValue) => {
  targetRouter.value = newValue;
})

const handleMenuSelect = async (val) => {
  console.log(route.path, val, 'target')
  if (route.path === val || route.path.indexOf('/email/folder') !== -1 || route.path.indexOf('/email/foldergroup') !== -1) {
    await store.commit('sidebar/reload')
  }
  await store.dispatch('countshow/getTeamAllDataApis')
  await store.dispatch("user/checkUserInfoIsAdmin", {teamId: teamId.value, isUpdate: true})
}

// 文件夹列表
const dirList = ref([{dirName: '文件夹1', id: 1}, {dirName: '文件夹2', id: 2}, {dirName: '文件夹3', id: 3}])

const visible = ref(false);
const top = ref(0);
const left = ref(0);

// const open = (e, data) => {
//   var x = e.pageX; //这个应该是相对于整个浏览器页面的x坐标，左上角为坐标原点（0,0）
//   var y = e.pageY - 50; //这个应该是相对于整个浏览器页面的y坐标，左上角为坐标原点（0,0）
//   top.value = y;
//   left.value = x;
//   visible.value = true; //显示菜单
// }

const openFlag = ref(false)
const openTarget = (val) => {
  if (val === '/email/folder') {
    openFlag.value = true
  }
}
const closeTarget = (val) => {
  if (val === '/email/folder') {
    openFlag.value = false
  }
}


watch(() => visible.value, (newValue) => {
      if (newValue) {
        document.body.addEventListener("click", closeMenu);
      } else {
        document.body.removeEventListener("click", closeMenu);
      }
    }
)

const closeMenu = () => {
  visible.value = false;
}
// 显示对话框 --添加/编辑
const visitable = ref(false);
// 是否是 编辑
const isEdit = ref(false);
const close = () => {
  visitable.value = false
}
const addDir = () => {
  visitable.value = true;
}
const addTargetDir = (val) => {
  dirList.value.push({dirName: val})
  close()
}
const handleCreateDir = () => {
  visitable.value = true;
  isEdit.value = false
}

const currentItem = ref({})
const editeTarget = (item) => {
  visitable.value = true
  isEdit.value = true
  currentItem.value = item
}

const visitableDelete = ref(false);
const deleteTargetDir = (item) => {
  visitableDelete.value = true
  currentItem.value = item
}
const closeDelete = () => {
  visitableDelete.value = false
}
</script>
<style lang="less" scoped>
@import "index";

:deep(.el-button.is-round) {
  padding: 8px 25px !important;
}

.badge_right {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

:deep(.el-menu-item *) {
  vertical-align: middle !important;
}

.sp_right_col {
  width: 3px;
  height: 100%;
  background: #1890ff;
  position: absolute;
  right: 0px;
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

:deep(.el-menu-item) {
  padding-right: 10px;
}

.dir_name {
  margin-left: 26px;
  width: 65px;
  max-width: 65px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.sp_dir {
  padding: 0px !important;

  & > div {
    margin: -12px !important;
    padding: 4px !important;
  }
}

:deep(.el-scrollbar__thumb) {
  width: 3px !important;
  right: -3px !important;
}

:deep(#dir_target .el-sub-menu__icon-arrow) {
  display: none !important;
}

.file_user {
  color: #40a5ff;
  display: inline-block;
  max-width: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
