<template>
  <div>
    <Cards
        :title="targetTeam?targetTeam.name:''"
        :isShowTitle="true"
        :bordered="true"
        :isShowMore="true"
        :height="'auto'"
    >
      <template #default>
        <div class="out_box">
          <div class="left">
            <Left></Left>
          </div>
          <div class="right">
            <Right></Right>
          </div>
        </div>
      </template>
    </Cards>
  </div>
</template>

<script setup>
import Cards from "@/views/Customer/Cards.vue";
import Left from "./Left.vue";
import Right from "./Right.vue";
import {computed} from "vue";
import {useStore} from "vuex";

const store = useStore()

const teamId = computed(() => store.state.email.teamID)
const teamList = computed(() => store.state.email.teamList)

const targetTeam = computed(() => teamList.value.filter(item => item.id === teamId.value)[0])
</script>

<style lang="less" scoped>
.out_box {
  display: flex;

  justify-content: center;
  flex-direction: row;

  & > .left {
    width: 50%;
  }

  & > .right {
    width: 50%;
  }
}
</style>
