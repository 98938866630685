<template>
  <ElDialog
      v-model="isShow"
      :title="'批量导入客户'"
      :footer="null"
      centered
      top="2em"
      :width="'40%'"
      style="min-width:300px"
      destroyOnClose
      @cancel="close"
  >

    <el-upload
        ref="uploadRef"
        class="upload-demo"
        action=""
        :accept="['.xls','.xlsx']"
        :auto-upload="false"
        :show-file-list="false"
        :on-change="handleUploadChange"
    >
      <el-button round type="primary">导入</el-button>
    </el-upload>
    <div style="margin-top: 20px;margin-bottom: 10px">共 {{ customerList.length }} 条 数据</div>
    <el-scrollbar height="300">

    </el-scrollbar>
    <div class="settings">
      <ElButton @click="close" :loading="loading">取消
      </ElButton>
      <ElButton type="primary" @click="addToEmails" :loading="loading">确定
      </ElButton>
    </div>

  </ElDialog>
</template>

<script setup>
import {ElButton, ElDialog, ElInput, ElMessage} from "element-plus";
import {V2CreateTeam} from '../../api'
import {ref, defineProps, watch, defineEmits, reactive, computed, onMounted} from 'vue'
import {useStore} from "vuex";
import {i18n} from "@/lang";
import {getMailCustomerList, requestAllCustomer} from "@/api/customer";
import TopSearch from "@/views/Customer/TopComponents/TopSearch.vue";
import * as XLSX from "xlsx";
import Tables from "@/views/Customer/Tables/Tables.vue";
import {table_columns_list, table_columns_list_sp} from "@/views/Customer/map";

const store = useStore();
const emit = defineEmits()
const isShow = ref(false);
const teamName = ref("")
const loading = ref(false);
// 选中的数组
const checkList = ref([])

const {value, isChecked} = defineProps({
  value: {type: Boolean, default: false},
  isChecked: {type: Array, default: []}
})

watch(() => value, (val) => {
  isShow.value = val;
}, {immediate: true})

const teamId = computed(() => store.state.email.teamID);
const isLoading = ref(false);


const showTargetEmails = ref([])
const customerList = ref([])

async function handleUploadChange(file) {
  let dataBinary = await readFile(file.raw);
  let workBook = XLSX.read(dataBinary, {type: "binary", cellDates: true});
  let workSheet = workBook.Sheets[workBook.SheetNames[0]];
  const data = XLSX.utils.sheet_to_json(workSheet);


  // 这里可能需要做国际化处理
  // const allEmails = extractEmails(data)
  // showTargetEmails.value = allEmails;
  customerList.value = data;
  console.log(data, 'data'); //最终获取JSON数据
// ...操作数据
}

function extractEmails(data) {
  return data.map(function (entry) {
    return entry["公司邮箱"];
  });
}

const readFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (e) => {
      resolve(e.target.result);
    };
    reader.onerror = (e) => {
      reject(e);
    };
  });
}

//收集邮箱并添加
const addToEmails = () => {
  if (showTargetEmails.value.length !== 0) {
    emit('addTo', showTargetEmails.value)
  } else {
    ElMessage.warning('请您检查您的Excel文档是否正确，然后再次尝试')
  }
}

const checkoutChange = () => {
  // console.log(checkList, 'checkoutChange')
}


const close = () => {
  emit("close");
}
</script>
<style scoped lang="less">
@import "../../less/page.less";

.fx-j-c {
  display: flex;
  justify-content: center;
}

.settings {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
}
</style>
