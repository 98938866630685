<template>
  <ElDialog
      v-model="isShow"
      :title="isEdit?'编辑文件夹名称':'新建文件夹'"
      :footer="null"
      centered
      :width="520"
      destroyOnClose
      @cancel="close"
      :class="[isHeadLine ? 'headline' : 'noheadline']"
  >
    <div style="display: flex;justify-content: center;align-items: center">
      <span class="title_cdir" style="display:inline-block;width: 100px;line-height: 30px">文件夹名称:</span>
      <ElInput
          ref="refInput"
          type="text"
          v-model="fileDirName"
          style="height: 32px; width: 100%;"
          :maxLength="15"
          @keydown.enter.native="createDir"
      />
    </div>
    <template #footer>
      <div class="dialog-footer">
        <ElButton @click="close">{{
            $t("common.cancel")
          }}
        </ElButton>
        <ElButton type="primary" @click="createDir" :loading="loading">{{
            $t("common.confirm")
          }}
        </ElButton>
      </div>
    </template>
  </ElDialog>
</template>

<script setup>
import {ElButton, ElDialog, ElInput, ElMessage} from "element-plus";
import {ref, defineProps, watch, defineEmits, computed, onMounted, nextTick} from 'vue'
import {useStore} from "vuex";
import {addEmailGroupFile, updateEmailGroupFile} from "@/api/sidebar";

const store = useStore();
const emit = defineEmits()
const isShow = ref(false);
const fileDirName = ref("")
const loading = ref(false)
const teamId = computed(() => store.state.email.teamID);
const refInput = ref(null)
const {value, isHeadLine, isEdit, currentItem} = defineProps({
  value: {type: Boolean, default: false},
  isHeadLine: {type: Boolean, default: false},
  isEdit: {type: Boolean, default: false},
  currentItem: {type: Object, default: {}}
})

watch(() => value, (val) => {
  isShow.value = val;
}, {immediate: true})
const close = () => {
  emit("close");
}

onMounted(() => {
  if (isEdit) {
    fileDirName.value = currentItem.fileName;
  }
  nextTick(() => {
    refInput.value.focus()
  })
})

const isLoading = ref(false)
const createDir = async () => {
  try {
    isLoading.value = true
    const payload = !isEdit ? {
      fileName: fileDirName.value,
      teamId: teamId.value
    } : {
      fileName: fileDirName.value,
      id: currentItem?.id
    }
    let ApiCheckout = !isEdit ? addEmailGroupFile : updateEmailGroupFile
    let res = await ApiCheckout(payload)
    console.log(res, 'res')
    ElMessage.success(res.result)
    if (!isEdit) {
      fileDirName.value = ""
    }
    isLoading.value = false
    close();
    emit('getList')
  } catch (e) {
    isLoading.value = false
  }
}
</script>
<style scoped lang="less">
.fx-j-c {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.title_cdir {
  color: rgba(0, 0, 0, .85);
  font-weight: 700;
  font-size: 14px;
}

.fx-j-r {
  display: flex;
  justify-content: right;
  margin-top: 40px;
}

</style>
