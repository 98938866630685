<template>
  <div class="outer">
    <div class="box_inner">
      <div class="side_menu">
        <div class="menus">
          <ElMenu
              :default-active="`/notificationList/systemNotification`"
              class="el-menu-vertical-demo"
              :router="true"
              :default-openeds="[`/notificationList/systemNotification`, `/notificationList`]"
          >
            <ElMenuItem class="fon16" :index="`/notificationList/systemNotification`">
              <div class="empty">
                <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAAXNSR0IArs4c6QAAA2FJREFUWEfNmNlLVFEcx7+/e8ZcxiVNw9IER7QMXzIiSTGQUqjooUWMoMglDVrsvYf+gghMW0aNwiymaANDo6dBMxCLKEuiMBOXGjHXcnTuPXFHR0Zn9J5rCve8nt/yOctvOYegd1gHEiWu5BEhiwOpBJ4AIGzWzBgH/SDgM+doVkhqQklslx4XJCRsszE2mn0UXD4LYBcAMT2AA3gNYtfkcPtD5OfLWv40DZtqe/coMioI2KJlbKl5DnRKDOdchXGvlpJbHOh2VxCTA68COA3ONcGFYInUHbslM2c5TiVO+tPx76i2P4bJvAHgO4Qc6RaiNpnRfhRucCxU9QWq7Y+RZG4n8P86Ii1GDupUGGUvhJoPpB6TK9C+ejvjsx9tssmZ7X1884BYdd8NcF6qtboVnSe6KRdvLPPYnANSo4kr9HLFLrAoNREnied6om8GyGZj0kjmRz2hbTYRSlNDcDgxGFvXmhAgEb6Pu9DY40RFxwS6xzVTzhyymhKUiJY0NU+5gVj1QAG4fF90UckRDA1562AJN/lVmZhWUGQfxqMuv5Ht3w2xY3Jx7IMZIGtvM4BMEaAQE+HdoRgkLQLjsTGtcGQ8deD9kEvErCrTIpfEZRGqui0swPRVtBycTA5Gze5IISd3vkygyD4iJAuAy2BJJNX0lZHCr4tqXckIx/m0UCHxdscUdj4bFJJVhTinM8SsfXUAPy6qVZkZgdJUs5D4h6FpbHvsk4yX0KV7JFn72gk8XcgDgNUE4qC3xKy96hKijQAEYFAFUmMz0CBATkMC6Tqy5oPRyFi/RmhDh50KUmw/MeRU2yChMajrUhdYglCXEyVk2SNU2TGOC62jQjqzl1os7NUM/elIDOJD/ZeLxTy6FI7tTxzo+C2SsdWwF0yMl9PDcCnd87gQWvCcUGPPJA40DWkquROjaOl4nhuFfQlBmkb9CYxNKYi8O6ClO1M6RItrgpnhREow1KPTM1wK8KJnEm9+TWupzRbXZbQf3pYvppmxN34mjfX/kfUU0/mA3u3Hcho0j7XNEQz1OVHYZJZQ3jqK+m9/tXbCZ96nQVMljNXCzjIbqsl3MxntGeSGMtRD0XPdDPWU9kAZ6rPBKzCN8x3jnS2M9GHlk8Wqui1SYEAuKRpfehKaFUX/l94/hTqdEv8ZOxEAAAAASUVORK5CYII=">
              </div>
              <span class="text">系统通知</span>
            </ElMenuItem>
            <ElMenuItem class="fon16" :index="`/notificationList/announcement`">
              <div class="empty">
                <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAAXNSR0IArs4c6QAAA/BJREFUWEfNmGtoFFcUx/9nJvuYRKtYdaSCxuaDT6QGxKASQUqEFKopSptGRMFHhCoSEA1SMiBBSiGKFmofYCnxhWKsYEARoUGLIo0iPiK0JAYUF7WYxN3Z2c3MKXfWjdnsziNuhN2Pe87c+5vz+J87lzDKn75fnWWZvIpAy8E8l0EziDBeLMOMAQL3gughg69JMl1Svo10j2YL8uPM6yDr89V1AL5hYCkYvp4DgQn4C8APyv3IGToD02s/z4XjmvrpIOMIAXO8FnOzM9BVRNgR1iJX3PwcgVgrDcehH2JgK/uNiAcxpSL2cxjKLtJ64rnccwINNE6bIgf5IgOL84mK07ME3DIT9Nn4A8+ej/TJArJhQtzBnF+KvF6ECF2mQZUjoTKARJp01jveV2RyROOWQkrl8PRlAOmaetRibPN6u7G0S4SfFC1Sn15zCEh0kwVcHqsC9gstCl0CqtLdZwMJnYnOU++5tTaNU1G0cg+KZq8CgsWwnt5FsqMF1r9/Zuwtla1AoLIB0kcLgUQMg48uYfDqd+DXEUdGIQklDyILhE7ZQDFN/YoZJx27YpyK0JZ2SBOmZ7gwW0i07YR596z9v7xwLYI1h0EkZfhZfU9g/FLtCkWE2mItcioNdI0Zy5yAAp8fRKC8NqeZ433QW8ptm9LQCQpPyOmX7DyB5IUGxygR4XqxFllOrzT14wDwj9s4UHbfB5V86LhYvLUOYpiE6447pyX6Evr3851LS9SSjDKKalPrwfSjWxEq+3pAgbCji3Fuh20LfXHEGSgZh95c6l7rxNsppqmtzKhz8wxtPA+5tMIZqG1nCqjmsKOP2XMDxm9rXIGIcJxiTVP/ZlCqCBx+0owlCG1qyyrWtLvhASSK3zhWA6v3pjsQuJOiTaqYJ5PdYwnIc6oRXHMIFP4gy9U4m9K10NqjWTaO9yNxfhfMrnavLYT9hUhZnBkhP94IlkAuXQYoE9+6mwmYD1ObyXOrATn41qa/gtlzHUhEfS1PBGPUQNLMCpAyCaA3Iu8ExAyO/Qer98bogHynTIhedXNOnXFPWR8S7fuGxNMjVC/8FXXZCoTWn8yvqFtrs8bMSDgSRe2r7Tf9AXnmkvza/vFNGMdWe7e9P2HsBgWUvIA4qUNvnuWeMSGMYzE6jBMbUm3/9e+OG7Lf0SFWiGlqHsO1H3rLIhtEabidU6eEzfdwfQP07seP05uHRM8Wzy9/zf/44f+AthdFs6sgBNJ6cgfJqweyxoEYM4GVjZCmf2Lrzzsd0ESUCuoIm67EgjrkC6iC+wwSUAX1oZhOXUF9SqehCuqyYbjcFsx1zHCogrqwGjmcxOwLg6us93Sl9z9UvhSVACwnEwAAAABJRU5ErkJggg==">
              </div>
              <span class="text">公告资讯</span>
            </ElMenuItem>
          </ElMenu>
        </div>
      </div>
      <div class="side_inner">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script setup>

</script>


<style lang="less" scoped>
.layout {
  background: #f5f5f5;
}

.empty {
  display: flex;
}

.outer {
  background: #f5f5f5;

  .box_inner {
    width: 100vw;
    min-width: 800px;
    max-width: 1329px;
    height: calc(100vh - 65px);
    margin: 0px auto;
    display: flex;
    justify-content: flex-start;

    .side_menu {
      width: 220px;
      height: calc(100vh - 65px);
      background-color: #fff;
      padding: 20px 10px;
      font-size: 20px;
      font-weight: 500;
      color: #333;
    }

    .side_inner {
      flex: 1;
      background: #f7f9fa !important;
    }
  }
}

:deep(.el-menu-item:hover) {
  color: #1890ff;
  background-color: #e6f7ff !important;
}

:deep(.el-menu-item.is-active) {
  background-color: #e6f7ff;
}

:deep(.el-menu) {
  border-right: 0px solid !important;
}

.text {
  margin-left: 10px;
  font-size: 20px;
}

:deep(.el-menu-item) {
  height: 64px !important;
  line-height: 30px !important;

}

</style>