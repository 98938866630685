<template>
  <div class="inners">
    <div class="right">
      <div class="titles">购买</div>
      <div class="outer">
        <div class="tops" style="margin-bottom: 20px">
          <div style="min-width: 240px; color: rgb(153, 153, 153)">
            <div class="sp_box">
              <div>可开票金额</div>
              <div class="bold red">￥{{
                  Object.keys(numberInfo).length > 0 ? numberInfo.unFinishMoney.toFixed(2) : 0
                }}
              </div>
            </div>
            <div class="sp_box">
              <div>历史已开票</div>
              <div class="bold">￥{{ Object.keys(numberInfo).length > 0 ? numberInfo.finishMoney.toFixed(2) : 0 }}</div>
            </div>
          </div>
          <el-button type="primary" @click="requestPayStatus"
          >申请开票
          </el-button
          >
        </div>
        <div>
          <el-table
              :data="tableData"
              style="width: 100%"
              :row-style="{ height: '70px' }"
              v-loading="loading"
              :header-cell-style="{'text-align': 'left', fontSize:'14px',color:'color: rgba(0,0,0,.85);', fontWeight: '500',background: '#f9f9f9', color: 'black', padding: '16px 0px'}"
          >
            <el-table-column prop="createTime" label="申请时间" width="180"/>
            <el-table-column prop="invoiceContent" label="发票抬头"/>
            <el-table-column prop="invoiceMoney" label="发票金额"/>
            <el-table-column prop="invoiceStatus" label="发票状态">
              <template #default="{ row, column, $index }">
                <div v-if="row.invoiceStatus===1">开发票中</div>
                <div v-else-if="row.invoiceStatus===2">已开发票</div>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template #default="{ row, column, $index }">
                <el-button type="primary" size="small" link @click="handleClick(row)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="pages">
            <el-pagination
                v-model:current-page="current"
                v-model:page-size="size"
                v-if="total"
                layout="total, prev, pager, next"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import BasicCard from "./Basic/BasicCard.vue";

import {onMounted, ref} from "vue";
import {useRouter} from "vue-router";
import {getQueryInvoiceList, getStatisticalInvoice} from "@/api/usercenter";
import {ElMessage} from "element-plus";

const router = useRouter();

const tableData = ref([]);

const current = ref(1);
const size = ref(10);
const total = ref(0);
const loading = ref(false);
const handleCurrentChange = (page) => {
  current.value = page;
  getQueryInvoiceListApis();
};

const requestPayStatus = () => {
  router.push("/personal/applyOpen");
};


const getQueryInvoiceListApis = async () => {
  loading.value = true;
  const res = await getQueryInvoiceList(
      {
        pageNo: current.value,
        pageSize: size.value
      }
  )
  if (res.code !== 200) {
    ElMessage.error(res.message);
    return
  }
  loading.value = false;
  const {records} = res.result;
  tableData.value = records;
  current.value = res.result.current
  size.value = res.result.size;
  total.value = res.result.total;
}
const handleClick = (row) => {
  const {id, orderCode} = row
  router.push({path: '/personal/invoiceDetails', query: {id: id}})
}

// 获取开票条数
const numberInfo = ref({})
const getStatisticalInvoiceApis = async () => {
  const res = await getStatisticalInvoice()
  if (res.code !== 200) {
    ElMessage.error(res.message)
    return
  }
  numberInfo.value = res.result
}

onMounted(async () => {
  await getQueryInvoiceListApis();
  await getStatisticalInvoiceApis()
})
</script>

<style lang="less" scoped>
@import "./less/Tables.less";
@import "../../less/page.less";
.inners{
  width: 840px !important;
  min-width: 840px !important;
  .right {
    height: max-content !important;
    padding-bottom: 40px !important;
  }
}

.outer {
  .tops {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div {
      display: flex;
      width: 240px;
      justify-content: space-between;
      align-items: center;
      height: 60px;
    }

    .sp_box {
      display: flex;
      flex-direction: column;
      align-content: center;

      .bold {
        font-weight: bold;
        font-size: 20px;
        margin-top: 4px;
      }

      .red {
        color: rgb(217, 55, 55);
      }
    }
  }
}
</style>
