import request from "@/utils/request";

// 获取日历相关信息
export function getCalendarList(params, config) {
    return request.get("/front/mailCalendar/getList", params, config)
}

// 添加日历
export function addCalendar(params, config) {
    return request.post('/front/mailCalendar/add', params, config)
}

//删除待办项
export function deleteCalendar(params, config) {
    const {id} = params;
    return request.delete(`/front/mailCalendar/delete?id=${id}`, params, config)
}

//更新
export function updateCalendar(params, config) {
    return request.put('/front/mailCalendar/edit', params, config)
}

//获取当天的待办事项
export function queryById(params, config) {
    return request.get('/front/mailCalendar/queryById', params, config)
}