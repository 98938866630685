export function replaceKeys(obj) {
    // 检查对象是否为空
    if (!obj || Object.keys(obj).length === 0) {
        return {};
    }

    // 创建一个新对象来存储替换后的键值对
    let newObj = {};

    // 遍历对象的每个键值对
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            // 根据指定的键进行替换
            switch (key) {
                case 'flag':
                    newObj['messageType'] = obj[key];
                    break;
                case 'title':
                    newObj['title'] = obj[key];
                    break;
                case 'msgTxt':
                    newObj['content'] = obj[key];
                    break;
                case 'time':
                    newObj['createTime'] = obj[key];
                    break;
                default:
                    // 对于其他键，直接复制到新对象中
                    newObj[key] = obj[key];
            }
        }
    }

    return newObj;
}