import {ElNotification} from "element-plus";


export function showNotify(messageNumber, item, fun) {
    if (item) {
        const {msg_front_from, msgTxt} = item
        console.log(item, 'item')
        const replaceEmail = msg_front_from.replace(/^<|>$/g, "")
        ElNotification({
            position: 'bottom-right',
            duration: 3000,
            dangerouslyUseHTMLString: true,
            onClick() {
                fun()
            },
            message: `<div class="notify_box">
            <div class="notify_left">
                <div class="notify_inner" style="background-color:#5272e5;padding: 0px 14px;border-radius: 8px">
                    <svg t="1708593116687" className="icon" viewBox="0 0 1024 1024" version="1.1"
                         xmlns="http://www.w3.org/2000/svg" p-id="5124" width="32" height="32">
                        <path
                            d="M891.904 145.92H128.512c-55.808 0-101.376 45.568-101.376 101.376v573.952c0 55.808 45.568 101.376 101.376 101.376h763.392c55.808 0 101.376-45.568 101.376-101.376V247.296c0-55.808-45.568-101.376-101.376-101.376z m-10.24 68.096l-352.768 285.184c-1.024 0.512-2.048 1.536-2.56 2.56-8.704 8.704-23.04 8.704-31.744 0l-2.56-2.56-352.768-285.184h742.4z m10.24 640.512H128.512c-18.432 0-32.768-14.848-32.768-32.768V266.752l351.744 284.672c17.408 16.896 39.936 25.088 62.464 25.088 22.528 0 45.056-8.192 62.464-25.088l351.744-284.672v554.496c0.512 18.432-13.824 33.28-32.256 33.28z"
                            p-id="5125" fill="#fff"></path>
                    </svg>
                </div>
                <div class="badge">
                    ${messageNumber}
                </div>
            </div>
            <div class="notify_right" style="width: 200px;">
                <div>
                    <span class="userInfo notification" style="display:inline-block;width:100%;padding-right:20px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden">
                        ${replaceEmail}
                    </span>
                </div>
                <div style="width: 185px; /* 设置容器的宽度 */
                    height: 40px; /* 设置容器的高度 */
                    overflow: hidden; /* 隐藏溢出的内容 */
                    box-sizing: border-box; /* 包括内边距和边框在内的宽度计算 */
                    letter-spacing: normal !important;
                    word-spacing: normal !important;
        }">
                    ${msgTxt}
                </div>
            </div>
        </div>`
        })
    }
}