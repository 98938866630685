function convertToHTML(text) {
    // 使用正则表达式替换文本中的制表符、回车符和换行符
    return text
        .replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')  // 将制表符替换为 4 个空格
        .replace(/\r\n|\r|\n/g, '<br>');             // 将回车符和换行符替换为 <br> 标签
}

function containsHtmlTags(str) {
    const htmlMap = ['#text', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'DIV', 'SPAN', 'BR', 'UL', 'OL', 'P', 'A', 'IMG', 'AUDIO', 'VIDEO', 'BUTTON', 'CANVAS', 'CODE', '#comment', 'EM', 'FOOTER', 'IFRAME', 'HR', 'INPUT', 'LABEL', 'NAV', 'I', 'LINK', 'MAIN', 'OPTION', 'CENTER', 'PRE', 'SCRIPT', 'SELECT', 'STRONG', 'STYLE', 'TABLE', 'TEXTAREA'];

    const parser = new DOMParser();
    const doc = parser.parseFromString(str, "text/html");
    // console.log(
    //   Array.from(doc.body.childNodes).map((node) => `${node.nodeName}`)
    // );
    // // 如果解析出的文档中有元素节点，则说明字符串中包含 HTML 标签
    return Array.from(doc.body.childNodes).some((node) => {
        console.log(node, node.nodeType, Node.ELEMENT_NODE, "hello", node.nodeType === Node.ELEMENT_NODE && htmlMap.includes(node.nodeName));
        return (
            node.nodeType === Node.ELEMENT_NODE && htmlMap.includes(node.nodeName)
        );
    });
}

// url 超链接超链接
function convertLinksToHTML(input) {
    // 正则表达式匹配 URL
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    // 将匹配的 URL 替换为超链接
    return input.replace(urlPattern, '<a href="$1" target="_blank">$1</a>');
}

/**
 * 匹配所有img 并添加 error 事件
 * @param htmlString
 * @returns {*}
 */
function addImgOnErrorAttribute(htmlString) {
    if (!htmlString) {
        return
    }
    // 正则表达式匹配所有的 img 标签
    const imgTagRegex = /<img\s+([^>]*\s)?src\s*=\s*['"][^'"]*['"]([^>]*)>/gi;

    // 回调函数用于处理每个匹配的 img 标签
    return htmlString?.replace(imgTagRegex, (match, p1, p2) => {
        // 检查 img 标签是否已经有 onerror 属性
        if (/onerror\s*=\s*['"][^'"]*['"]/i.test(match)) {
            return match; // 已经有 onerror 属性，返回原始标签
        }
        // 插入 onerror 属性
        return match.replace(
            /<img\s+/i,
            "<img onerror=\"errorImage(this)\" "
        );
    });
}

export {convertToHTML, convertLinksToHTML, addImgOnErrorAttribute, containsHtmlTags}