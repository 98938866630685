<template>
  <div class="inners">
    <div class="right">
      <div class="titles">订单详情</div>
      <div class="text_box">
        <div class="top_title">基本信息</div>
        <div><span class="left_title">订单编号</span><span class="inner">{{ selectObj.orderCode }}</span></div>
        <div><span class="left_title">总金额</span><span class="inner">{{ selectObj.totalMoney }}</span></div>
        <!--        <div><span class="left_title">Plus会员优惠</span><span class="inner"></span></div>-->
        <!--        <div><span class="left_title">会员活动优惠</span><span class="inner"></span></div>-->
        <div><span class="left_title">实付金额</span><span class="inner">¥{{ selectObj.totalMoney }}</span></div>
      </div>
      <div>
        <el-table :data="tableData" style="width: 100%" :row-style="{height: '70px'}" v-loading="loading"
        >
          <el-table-column prop="id" label="产品和服务" width="180"/>
          <el-table-column prop="productName" label="产品单位" width="180"/>
          <el-table-column prop="num" label="数量"/>
          <el-table-column prop="price" label="单价">
          </el-table-column>
          <el-table-column prop="totalMoney" label="总金额">
          </el-table-column>
          <!--                    <el-table-column prop="payMoney" label="优惠金额">-->
          <!--                      <template #default="{ row, column, $index }">-->
          <!--                        <span style="font-size: 22px">-->
          <!--                       {{ row.payMoney.toFixed(2) }}-->
          <!--                      </span>-->
          <!--                      </template>-->
          <!--                    </el-table-column>-->
          <el-table-column prop="totalMoney" label="实付金额">
          </el-table-column>
        </el-table>
        <div class="bottom_pay">实付总金额：<span class="reds">¥ {{ selectObj.totalMoney }}</span></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import {useRouter} from "vue-router";
import {getQueryById} from "@/api/usercenter";
import {ElMessage} from "element-plus";

const router = useRouter();
const loading = ref(false)

const orderCode = ref()
const tableData = ref([])
const selectObj = ref({})
// 通过 id 查询数据
const getQueryByIdApis = async () => {
  loading.value = true
  const res = await getQueryById(
      {orderCode: orderCode.value}
  )
  if (res.code !== 200) {
    ElMessage.error(res.message);
    return
  }
  loading.value = false
  selectObj.value = res.result.mailPayOrder;
  tableData.value = res.result.mailPayOrderTmpList
  console.log(selectObj, tableData, 'ff')
}
onMounted(async () => {
  const {payCode} = router.currentRoute.value.query
  orderCode.value = payCode
  await getQueryByIdApis()
})
</script>

<style lang="less" scoped>
@import "./less/Tables.less";
.right {
  height: max-content !important;
  padding-bottom: 40px !important;
}
.text_box {
  & > div {
    margin-bottom: 16px;
  }
}

.left_title {
  color: #999;
  margin-right: 20px;
  font-size: 14px;
}

.bottom_pay {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  margin-top: 20px;
  font-size: 14px;

  .reds {
    color: #c8453f;
    font-size: 14px;
    font-weight: bold;
  }
}

.inner {
  font-size: 14px;
  font-weight: bold;
}


</style>