<template>
  <div class="btn_tools">
    <el-button v-if="type===5" :icon="StarFilled" color="#409eff" plain @click="collectTo('1')">
      {{ $t("common.cds16") }}
    </el-button>
    <!--文件上传-->
    <ElUpload
        v-if="type===1"
        style="margin-right:12px"
        class="upload-demo"
        :action="action"
        :headers="{
          'X-Access-Token': token,
        }"
        :show-file-list="false"
        :on-progress="onProgress"
        :before-upload="beforeUpload"
        :on-error="handleUploadError"
        :on-success="handleUploadSuccess"
        multiple
    >
      <template #trigger="">
        <el-button type="primary" :icon="UploadFilled">{{ $t("common.upload") }}</el-button>
      </template>
    </ElUpload>
    <el-button v-if="type===1" :icon="FolderAdd" plain color="#409eff" @click="addNewDir">{{ $t("common.newfolder") }}
    </el-button>
    <el-button v-if="type===1 || type===2 || type===5" :icon="Download" plain color="#409eff"
               @click="downloadTargetFile">{{ $t("common.download") }}
    </el-button>
    <el-button v-if="type===1 || type===5" :icon="DeleteFilled" plain color="#409eff" @click="deleteTargetFile('2')">
      {{ $t("common.delete") }}
    </el-button>
    <el-button v-if="type===1 || type===5" :icon="Share" plain color="#409eff" @click="sharedTargetFile(_,'1')">
      {{ $t("common.joint.with") }}
    </el-button>
    <el-button v-if="type===1 || type===5" :icon="Promotion" plain color="#409eff" @click="shareLink">
      {{ $t("common.share") }}
    </el-button>
    <el-button v-if="type===1 || type===5" :icon="Rank" plain color="#409eff" @click="moveTo(_,'1')">
      {{ $t("common.move.to") }}
    </el-button>
    <el-button v-if="type===1" :icon="StarFilled" plain color="#409eff" @click="collectTo('2')">
      {{ $t("common.cds15") }}
    </el-button>
    <el-button v-if="type===2" :icon="Tickets" plain color="#409eff" @click="moveTo(_,'2')">
      {{ $t("common.transfer.to") }}
    </el-button>
    <el-button v-if="type===3" :icon="Setting" type="primary" @click="sharedTargetFile(_,'2')"
               :disabled="activeItem.length>1">{{ $t("common.setting.joint") }}
    </el-button>
    <el-button v-if="type===3" :icon="DocumentDelete" type="primary" @click="cancelPublicShare">
      {{ $t("common.cancel.joint") }}
    </el-button>
    <el-button v-if="type===4" :icon="DocumentDelete" type="primary" @click="cancelShare" :loading="cancelLoading">
      {{ $t("common.cancel.share") }}
    </el-button>
    <el-button v-if="type===6" :icon="RefreshLeft" type="primary" @click="deleteTargetFile('1')">
      {{ $t("common.file.restore") }}
    </el-button>
    <el-button v-if="type===6" :icon="CircleClose" type="danger" @click="deleteTargetFile('3')">
      {{ $t("common.file.delete") }}
    </el-button>
    <el-button v-if="type===6" :icon="Delete" type="danger" @click="deleteTargetFile('4')">
      {{ $t("common.recycle.clean") }}
    </el-button>
  </div>
  <div class="file_all">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item v-for="(item, index) in pathArray" :key="item.path"
                          @click.native.stop="handleClick(item, index)">
            <span
                style="cursor: pointer">{{ item.text }}</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
  <div class="inner_out_box">
    <div :class="type!==6?'box_table':'box_table_sp'"
    >
      <el-table
          ref="multipleTableRef"
          :data="tableData"
          @selection-change="handleSelectionChange"
          v-loading="tableLoading"
          @row-click="selectClickEvent"
          @selectionChange="selectCheckbox"
          v-el-table-infinite-scroll="handleTableScroll"
      >
        <!--        :key="refresh"-->
        <!--        :infinite-scroll-disabled="disabled"-->
        <!--          @row-dblclick="selectTargetDetail"-->
        <el-table-column type="selection" width="60" fixed="left"/>
        <el-table-column prop="fileName" :label="$t('common.file.name')" class="file_names">
          <template #default="{row}">
            <!--            如果是文件-->
            <div v-if="row.isFolder===1" style="display: flex;align-items: center;">
              <FileIcon :fileExtend="row.fileExtend.toLocaleLowerCase()"
                        :fileUrl="row.fileExtendPath" :size="25"
                        :isAbbreviation="true"></FileIcon>
              <el-button v-if="type!==6&& imgFormat.includes(row.fileExtend.toLocaleLowerCase())" link class="pointer"
                         style="margin-left: 10px"
                         @click.native.stop="showTargetFile(row)">{{
                  row.fileName
                }}
              </el-button>
              <el-button v-else link class="pointer" style="margin-left: 10px">{{ row.fileName }}</el-button>
            </div>
            <div v-if="row.isFolder===2" style="display: flex;align-items: center;">
              <span style="display: block;height: 24px" class="icon"><i aria-label="图标: folder"
                                                                        class="anticon anticon-folder"
                                                                        style="color: rgb(41, 182, 246); font-size: 24px;"><svg
                  viewBox="64 64 896 896" data-icon="folder" width="1em" height="1em" fill="currentColor"
                  aria-hidden="true" focusable="false" class=""><path
                  d="M880 298.4H521L403.7 186.2a8.15 8.15 0 0 0-5.5-2.2H144c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V330.4c0-17.7-14.3-32-32-32z"></path></svg></i></span>
              <el-button link type="primary" class="pointer" style="margin-left: 10px"
                         @click.native.stop="enterDirectory(row)">
                {{ row.fileName }}
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="type===1||type===3||type===5" width="40" style="position: relative">
          <template #default="scope">
            <template v-if="scope.row.isFolder!==2">
              <img v-if="scope.row.isHide===2" @click.stop="() => handleSettingStress(scope,)"
                   class="pointer flags_f"
                   src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAZ1JREFUOE+dk71LXEEUxc+Z997uGtC1sZD8A4ql1T5UDNq4EE38gKQTAzZJl04LNQnYCHYRCyX1ypLdLNFKUPzKH5BCLZZUESWEpIr6ZubKewmyK7v64pRzz/1x7rkzRJ0j+e5W7doNCFLGMJsa2S3XkrIeQBf9FRFOhHUSH92hveHYACllHmrDMsDE3yaxVktHcvTg8CakpoOg6C9A+LpSTMqqO7T/oiZAcv3pIPWnXRlpI9lhiVfh7DfEmsCSFfuVcI5dyyOO7JwwKPpvIZgCqOrlccv9InXB/yVg+h7NIPCbQcFfAzh6T8AHSi7TYBJOSSB9/wMhkHdOz59HW5BS5wNtUp8B9MaE5N2094yPtsJg/20615k2ieRPuStMQeAmmxqZ3biIHtk1YL27RV/aszgOXMdr4eOtH1WAoOj3QLgdBwCler3BnUh77cB86pq0VpbjAJTiS2dw9321g0LXNCDvKgBHpMwCyojYOYDtFbU33pO9meoMog+kNgkkKZxXze5qmHK0pdyYY7zv4yCmBbjQYgYann75FtauADgOlbK39b/7AAAAAElFTkSuQmCC"/>
              <img v-else class="pointer flags_f" @click.stop="() => handleSettingStress(scope,)"
                   src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAY5JREFUOE+dk7FLW1EUxr9zH7GtIJkcpOS+vClK/oeS4KRjcdBNWuhSN7c6tNZCl0K3ioPSvYiDFCcH/wcHI+SR3BvywJRitxjfu195wYYYEnz2jPd853e/e865ggnRaDTmlFInJJ8CWPZ9PxwnlUkAY8w+yVdpXkSOtNYvMwOstc+TJAlFZOquyDnnykEQXIxCxjowxnwhuTksFpEDrfXrsYB6vZ5XSi14njcPoExyA0D69uGIAewCOFdKXSZJUisWi5EYY3ZIvgOgJvVj0jnJr9JsNq8B5B9bfKf/kwJ+AFj5H4CIfBdr7TPn3DGAxUdCDjudzlp/Cu12ezqO458kKxkhh1rrVRGJB2NMJ5HL5X6TfKiZt1rrGRG56S/ZvxujKJrt9XpXWRx0u93ZUqn06x7AGPOC5FkWgFKqUigU+tqBA2vtG+fcXhaAiLzVWn8bdbBF8tMQoCYiH0gmALYBLAzlPvq+/37UQfqBTkXkCYDPYRgeVKvVdH1B0mu1WuvOuS0AN865pSAIGmnuL4BAqYD60aJ1AAAAAElFTkSuQmCC"/>
            </template>
          </template>
        </el-table-column>
        <el-table-column v-if="type===1|| type===3||type===5" width="50">
          <template #default="{row}">
            <el-dropdown>
              <el-button class="sp_btn"
                         :icon="MoreFilled"></el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="targetFileChange(row)">
                    <el-icon>
                      <Edit/>
                    </el-icon>
                    <span>{{ $t("common.rename") }}</span></el-dropdown-item>
                  <el-dropdown-item v-if="row.isFolder!==2" @click="sharedTargetFile(row,'1')">
                    <el-icon>
                      <UploadFilled/>
                    </el-icon>
                    <span>{{ $t("common.joint.with") }}</span></el-dropdown-item>
                  <el-dropdown-item @click="shareLink(row)">
                    <el-icon>
                      <Promotion/>
                    </el-icon>
                    <span>{{ $t("common.share") }}</span></el-dropdown-item>
                  <el-dropdown-item @click="moveTo(row,'1')">
                    <el-icon>
                      <Rank/>
                    </el-icon>
                    <span>{{ $t("common.move.to") }}</span></el-dropdown-item>
                  <el-dropdown-item v-if="row.isFolder!==2" @click="downloadTargetFile(row)">
                    <el-icon>
                      <Download/>
                    </el-icon>
                    <span>{{ $t("common.download") }}</span></el-dropdown-item>
                  <el-dropdown-item style="color: red" @click="targetFileDelete(row,'2')">
                    <el-icon>
                      <Delete/>
                    </el-icon>
                    <span>{{ $t("common.delete") }}</span></el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
        <el-table-column v-if="type===2" prop="createName" width="160" :label="$t('common.cds13')"></el-table-column>
        <el-table-column v-if="type===4" width="120" prop="shareDay" :label="$t('common.joint.state')">
          <template #default="{row}">
            <el-button link type="info" v-if="row.shareDay!==0">
              {{ $t("displace.cloud.afterinvalidNumberDays", {num: row.shareDay}) }}
            </el-button>
            <el-button v-else type="info" disabled>{{ $t("common.expired") }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="contentSize" sortable :label="$t('common.size')" width="120">
          <template #default="scope">
            <template v-if="scope.row.isFolder===2"></template>
            <template v-else>
              {{ formatSize(scope.row.fileSize) }}
            </template>
          </template>
        </el-table-column>
        <el-table-column v-if="type===1||type===3||type===5" prop="date" sortable :label="$t('common.time.update')"
                         width="180"
                         show-overflow-tooltip>
          <template #default="scope">
            <template v-if="scope.row.updateTime!=null">{{ scope.row.updateTime }}</template>
            <template v-else>
              {{ scope.row.createTime }}
            </template>
          </template>
        </el-table-column>
        <el-table-column v-if="type===2" prop="createTime" sortable :label="$t('common.cds18')" width="180"
                         show-overflow-tooltip/>
        <el-table-column v-if="type===4" prop="createTime" sortable :label="$t('common.time.share')" width="160"
                         show-overflow-tooltip/>
        <el-table-column v-if="type===6" prop="deleteDate" sortable :label="$t('common.xcsj')" width="180"
                         show-overflow-tooltip>
          <template #default="{row}">
            <template v-if="row.updateTime">{{ row.updateTime }}</template>
            <template v-else>{{ row.createTime }}</template>
          </template>
        </el-table-column>

        <el-table-column v-if="type===4" fixed="right" :label="$t('common.cz')" width="160">
          <template #default="{row}">
            <el-button link type="primary" @click="cancelShare(row)">{{ $t("common.cancel.share") }}</el-button>
            <el-button link type="primary" @click="copyLink(row)">{{ $t("common.link.copy") }}</el-button>
          </template>
        </el-table-column>
        <template #empty>
          <el-empty :description="$t('common.data.empty')" :image="emptyUrl"/>
        </template>
      </el-table>
    </div>
    <div class="box_file_text" v-if="type!==6">
      <div style="padding: 20px;height: 100%">
        <div class="titles" style="margin-bottom: 12px" v-if="activeItem.length===1">{{
            $t('common.file.info')
          }}{{
            !suportFile.includes(activeItem[0].fileExtend) ? '' : imgFormat.includes(activeItem[0].fileExtend) ? `（单击列表中的文件名可在线预览）` : `（双击列表中的文件名可在线预览）`
          }}
        </div>
        <div class="titles" style="margin-bottom: 12px" v-if="activeItem.length>1">
          {{ $t("displace.file.select.total", {number: activeItem.length}) }}
        </div>
        <div class="file_boxs" style="height: 100%;" v-if="activeItem.length===0">
          <div style="display: flex;justify-content: center;flex-direction: column;align-items: center">
            <div style="height: 54px;"><span>
              <i
                  aria-label="图标: folder"
                  class="anticon anticon-folder"
                  style="color: rgb(41, 182, 246); font-size: 60px;"><svg
                  viewBox="64 64 896 896" data-icon="folder" width="1em" height="1em" fill="currentColor"
                  aria-hidden="true" focusable="false" class=""><path
                  d="M880 298.4H521L403.7 186.2a8.15 8.15 0 0 0-5.5-2.2H144c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V330.4c0-17.7-14.3-32-32-32z"></path></svg></i></span>
            </div>
            <div class="f12" style="color: rgb(135, 140, 156); margin-top: 10px;">
              {{ $t("common.select.file.details") }}
            </div>
          </div>
        </div>


        <template v-else-if="activeItem.length===1">
          <!--          文件-->
          <!--        展示普通的文件资源-->
          <div v-if="activeItem[0].isFolder===1"
               style="width: 100%;display: flex;flex-direction: column;justify-content: center">
            <div class="file_details" style="display: flex;justify-content: center;align-items: center">
              <!--              <el-image v-if="imgFormat.includes(activeItem[0].isFolder)" style="width: 100%; height: 160px" :src="activeItem[0].fileExtendPath" fit="contain"/>-->
              <FileIcon :fileExtend="activeItem[0].fileExtend" :fileUrl="activeItem[0].fileExtendPath"
                        :size="60"></FileIcon>
            </div>
            <div class="image_titles">{{ activeItem[0].fileName }}</div>
            <div class="detail-msg flex1 oh fx-d-c">
              <div>{{ $t("common.file.format") }}：{{ activeItem[0].fileExtend }}</div>
              <div>{{ $t("common.time.create") }}：{{ activeItem[0].createTime }}</div>
              <div>{{ $t("common.lastupdate") }}：{{
                  activeItem[0].updateTime ? activeItem[0].updateTime : activeItem[0].createTime
                }}
              </div>
            </div>
          </div>
          <!--          文件夹-->
          <div v-else style="width: 100%;display: flex;flex-direction: column;justify-content: center">
            <div style="display: flex;justify-content: flex-start;justify-items: center">
              <span style="display: inline-block;height: 24px" class="icon"><i aria-label="图标: folder"
                                                                               class="anticon anticon-folder"
                                                                               style="color: rgb(41, 182, 246); font-size: 24px;"><svg
                  viewBox="64 64 896 896" data-icon="folder" width="1em" height="1em" fill="currentColor"
                  aria-hidden="true" focusable="false" class=""><path
                  d="M880 298.4H521L403.7 186.2a8.15 8.15 0 0 0-5.5-2.2H144c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V330.4c0-17.7-14.3-32-32-32z"></path></svg></i></span>
              <span link type="primary" class="pointer" style="margin-left: 6px">
                {{ activeItem[0].fileName }}
              </span>
            </div>
            <div class="file_details_long">
              <div style="padding: 20px;">
                <div v-if="showLoading" v-loading="showLoading" style="margin-top: 10%"></div>
                <div v-else v-for="row in dirFileList">
                  <div v-if="row.isFolder===1" style="display: flex;align-items: center;margin-bottom: 10px">
                    <FileIcon :fileExtend="row.fileExtend.toLocaleLowerCase()"
                              :fileUrl="row.fileExtendPath" :size="25"
                              :isAbbreviation="true"></FileIcon>
                    <el-button link class="pointer" style="margin-left: 10px">
                      {{
                        row.fileName
                      }}
                    </el-button>
                  </div>
                  <div v-if="row.isFolder===2" style="display: flex;align-items: center;margin-bottom: 10px">
              <span style="display: block;height: 24px" class="icon"><i aria-label="图标: folder"
                                                                        class="anticon anticon-folder"
                                                                        style="color: rgb(41, 182, 246); font-size: 24px;"><svg
                  viewBox="64 64 896 896" data-icon="folder" width="1em" height="1em" fill="currentColor"
                  aria-hidden="true" focusable="false" class=""><path
                  d="M880 298.4H521L403.7 186.2a8.15 8.15 0 0 0-5.5-2.2H144c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V330.4c0-17.7-14.3-32-32-32z"></path></svg></i></span>
                    <el-button link type="primary" class="pointer" style="margin-left: 10px"
                    >
                      {{ row.fileName }}
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="select_option oh"
               style="background: rgb(245, 245, 245); border-radius: 8px; height: 160px;">
            <span
                style="vertical-align: middle;"><i
                aria-label="图标: folder" class="anticon anticon-folder"
                style="color: rgb(41, 182, 246); font-size: 50px;">
              <svg viewBox="64 64 896 896" data-icon="folder"
                   width="1em" height="1em" fill="currentColor"
                   aria-hidden="true" focusable="false" class=""><path
                  d="M880 298.4H521L403.7 186.2a8.15 8.15 0 0 0-5.5-2.2H144c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V330.4c0-17.7-14.3-32-32-32z"></path></svg></i></span>
          </div>
        </template>
      </div>
    </div>
  </div>
  <!--  弹出框 上传列表-->
  <UploadBox ref="uploadRefs" :fileList="fileList"></UploadBox>
  <!--  新建文件夹-->
  <CreateDir v-if="visitable" v-model="visitable" @close="close" :isHeadLine="true"
             @getFileList="resetGetFileList"></CreateDir>
  <!--  创建分享链接-->
  <CreateShareLink v-if="visitableLink" v-model="visitableLink" @close="closeLink"
                   :isHeadLine="true" :activeItem="activeItem"></CreateShareLink>
  <!--  移动对话框-->
  <MoveTargetFile v-if="moveTargetFileVisitable" v-model="moveTargetFileVisitable"
                  @close="closeMoveTarget" :is-head-line="true" :type="type" :activeItem="activeItem"
                  :list="getFileList" :numberFlag="numberFlag"></MoveTargetFile>
  <!--  删除对话框-->
  <Dialog
      v-model="deleteVisitable"
      width="400"
      :dialogVisible="deleteVisitable"
      :isHeadLine="false"
      @update:dialogVisible="(val) => (deleteVisitable = val)"
  >
    <template #content>
      <div class="cont">
        <span><el-icon style="color:#efb041;font-size: 20px;margin-right: 10px;"><QuestionFilled/></el-icon></span>
        <span class="text" v-if="flags===2">{{ $t("common.delete.select.file") }}</span>
        <span class="text" v-else-if="flags===3">{{ $t("common.true.delete.select.file") }}</span>
        <span class="text" v-else-if="flags===1">{{ $t("common.hf.select.file") }}</span>
        <span class="text" v-else-if="flags===4">{{ $t("common.clean.recycle") }}</span>
      </div>
    </template>
    <template #footer>
      <div class="setting_left">
        <el-button @click="deleteVisitable = false">{{ $t('common.sss16') }}</el-button>
        <el-button type="primary" :loading="deleteLoading" @click="deleteTarget">{{ $t('common.confirm') }}</el-button>
      </div>
    </template>
  </Dialog>

  <!--  文件重命名-->
  <ChangeFile v-if="changeVisitable" v-model="changeVisitable" @close="closeChangeVisitable"
              :targetItem="changTargetItem"></ChangeFile>

  <!--  共享给我-->
  <SharedMe v-if="visitableShared" v-model="visitableShared" @close="closeShared" :isHeadLine="true"
            :activeItem="activeItem" @getList="resetGetFileList" :numberFlags="numberFlags"></SharedMe>

  <!--  云空间不足 警告-->
  <ToBuyFileArea v-if="toBuyDialogVisible" v-model="toBuyDialogVisible"
                 @close="toBuyDialogVisibleClose"></ToBuyFileArea>

  <!--  <show-video v-if="visitableVideo" v-model="visitableVideo" @close="closeVideo" :isHeadLine="false"-->
  <!--  ></show-video>-->
</template>

<script setup>
import {computed, nextTick, onBeforeUnmount, onMounted, onUnmounted, reactive, ref, watch, watchEffect} from 'vue'
import {
  UploadFilled,
  FolderAdd,
  Download,
  DeleteFilled,
  Share,
  Promotion,
  Rank,
  StarFilled, Tickets, Setting, DocumentDelete, RefreshLeft, Delete, CircleClose, MoreFilled, Edit
} from "@element-plus/icons-vue";
import {ElMessage, ElTable} from "element-plus";
import {useRoute, useRouter} from "vue-router";
import {getApi1, getTargetFile} from "@/api/fileupload";
import "viewerjs/dist/viewer.css";
import {api as viewerApi} from 'v-viewer';
import {getToken} from "@/utils/auth";
import UploadBox from "@/views/Cloud/Components/UploadBox.vue";
import CreateDir from "@/views/Cloud/Components/CreateDir.vue";
import CreateShareLink from "@/views/Cloud/Components/CreateShareLink.vue";
import {debouncePlus, throttle} from "@/utils/mylodash";
import Dialog from "@/components/Dialog.vue";
import {useStore} from "vuex";
import MoveTargetFile from "@/views/Cloud/Components/MoveTargetFile.vue";
import {
  audioFormat,
  downloadFile,
  excelFormat,
  formatSize,
  imgFormat,
  pdfFormat, pptFormat, textFormat,
  videoFormat,
  wordFormat, zipFormat
} from "@/utils/file";
import ChangeFile from "@/views/Cloud/Components/ChangeFile.vue";
import SharedMe from "@/views/Cloud/Components/SharedMe.vue";
import useClipboard from "vue-clipboard3";
import {emptyUrl} from "@/utils/statusParams";
import ToBuyFileArea from "@/components/DiaPops/ToBuyFileArea.vue";
import {i18n} from "@/lang";
import FileIcon from "@/components/File/FileIcon.vue";
import ShowVideo from "@/components/ShowTargetFile/showVideo.vue";

const TIME_DELAY = 300;
const route = useRoute();
const router = useRouter();
const store = useStore();
const {toClipboard} = useClipboard();


/**
 * 面包屑核心对比 过程
 */
const checkRouter = async () => {
  if (sessionStorage.getItem("pathTemplateDb")) {
    const pathTemplateDbObj = await JSON.parse(sessionStorage.getItem("pathTemplateDb"));
    const path = route.query.path ? route.query.path.split(',') : "0"
    const targetArray = []
    if (path === "0") {
      targetArray.push({path: "0", text: i18n.global.t("common.file.all"), tag: "0"})
    } else {
      for (let i = 0; i < pathTemplateDbObj.length; i++) {
        for (let j = 0; j < path.length; j++)
          if (pathTemplateDbObj[i].tag === path[j]) {
            targetArray.push(pathTemplateDbObj[i])
          }
      }
    }
    pathArray.value = targetArray;
    sessionStorage.setItem('pathArray', JSON.stringify(pathArray.value))
  }
}


/**
 * 收藏
 * @returns {Promise<void>}
 */
const collectTo = debouncePlus(async (num) => {
  if (activeItem.value.length === 0) {
    ElMessage.warning(i18n.global.t("sys.tip.select.file"));
    return
  } else if (activeItem.value.filter(item => item.isFolder === 2).length >= 1) {
    ElMessage.warning(i18n.global.t("common.file.dir"));
    return
  } else {
    const idList = activeItem.value.map(_ => _.id)
    await store.dispatch("cloud/hideFileApis", {idList, isHide: num * 1})
    if (type === 5) {
      tableData.value = tableData.value.filter(item => !idList.includes(item.id))
    } else {
      tableData.value = findTargetItemIsHide(tableData.value, idList)
    }
    await getFileList()
  }
}, TIME_DELAY)

const handleSettingStress = async (scope, num) => {
  const {row} = scope;
  const idList = [row.id];
  console.log('ddd', row.isHide, tableData.value, idList)
  await store.dispatch("cloud/hideFileApis", {idList, isHide: row.isHide === 2 ? 1 : 2})
  if (type === 5) {
    const startIndex = tableData.value.findIndex(item => item.id === row.id)
    tableData.value.splice(startIndex, 1)
  } else {
    tableData.value = findTargetItem(tableData.value, idList)
  }
}

/**
 * 一键收藏
 * @param arr1
 * @param arr2
 */
const findTargetItemIsHide = (arr1, arr2) => {
  arr1.forEach(obj1 => {
    if (arr2.includes(obj1.id)) {
      obj1.isHide = 2;
    }
  });
}

/**
 * 前端 点击收藏 再次点击取消收藏
 */
const findTargetItem = (arr1, arr2) => {
  return arr1.map(obj1 => {
    if (arr2.includes(obj1.id)) {
      obj1.isHide = obj1.isHide === 2 ? 1 : 2;
    }
  });
}


// 文件移动
const moveTargetFileVisitable = ref(false);
const closeMoveTarget = () => {
  moveTargetFileVisitable.value = false
}
const numberFlag = ref('1')
const moveTo = debouncePlus((row, num) => {
  console.log('fff', row, num, activeItem.value)
  if (row.id) {
    activeItem.value = [];
    activeItem.value = [row]
  }
  if (activeItem.value.length === 0) {
    ElMessage.warning(i18n.global.t("sys.tip.select.file"));
    return
  } else {
    numberFlag.value = num
    resetCurrentAbout()
    moveTargetFileVisitable.value = true;
  }
}, TIME_DELAY)


// 新建文件夹
const visitable = ref(false)
const addNewDir = () => {
  visitable.value = true;
}
const close = async () => {
  visitable.value = false

}

const resetGetFileList = async () => {
  resetCurrentAbout()
  await getFileList();
}


// 下载文件
const downloadTargetFile = debouncePlus((row) => {
  if (row.id) {
    activeItem.value = []
    activeItem.value = [row]
  }

  if (activeItem.value.length === 0) {
    ElMessage.warning(i18n.global.t("sys.tip.select.file"));
    return
  } else if (activeItem.value.filter(_ => _.isFolder === 2).length >= 1) {
    ElMessage.warning(i18n.global.t("common.file.dir.download"))
  } else if (activeItem.value.length === 1) {
    // console.log(activeItem.value[0].fileExtendPath,'activeItem.value[0].fileExtendPath')
    fetch(activeItem.value[0].fileExtendPath.replace(/^http/, "https"))
        // record.doc_url为文件url地址
        .then((res) => res.blob())
        .then((blob) => {
          downloadFile(blob, activeItem.value[0].fileName);
        })
        .catch((error) => {
          ElMessage.error('下载失败');
        });
  } else if (activeItem.value.length > 1) {
    //   多文件打包下载
    alert('多文件打包下载，敬请期待')
  }
}, TIME_DELAY)

// 删除文件
const deleteVisitable = ref(false)
const flags = ref(0);
const deleteTargetFile = debouncePlus((val) => {
  flags.value = val * 1;
  if (flags.value === 4) {
    deleteVisitable.value = true;
  } else {
    if (activeItem.value.length === 0) {
      ElMessage.warning("请选择文件")
      return
    } else {
      deleteVisitable.value = true;
    }
  }
}, TIME_DELAY)
const deleteLoading = ref(false);
const deleteTarget = debouncePlus(async () => {
  if (flags.value === 4) {
    deleteLoading.value = true
    await store.dispatch("cloud/clearFileApis")
    deleteVisitable.value = false;
    deleteLoading.value = false;
  } else {
    // alert("删除选中的文件")
    const idList = activeItem.value.map(_ => _.id)
    const payload = {
      idList,
      type: flags.value,
    }
    deleteLoading.value = true
    if (targetDeleteList.value !== null) {
      await store.dispatch('cloud/deleteFileApis', {type: 2, idList: [targetDeleteList.value.id]})
    } else {
      await store.dispatch('cloud/deleteFileApis', payload)
    }
    deleteLoading.value = false;
    deleteVisitable.value = false;
  }
  resetCurrentAbout()
  await getFileList()
}, TIME_DELAY);


/**
 * 处理完操作需要重新判断页数和 重置
 */
const resetCurrentAbout = () => {
  store.commit("cloud/changeCurrent", false)
  if (current.value * size.value >= total.value) {
    if (current.value)
      current.value = 1;
  }
}


// 共享文件
const visitableShared = ref(false);
const closeShared = () => {
  visitableShared.value = false;
}
const numberFlags = ref('1')
const sharedTargetFile = debouncePlus((row, num) => {
  if (row.id) {
    console.log(row, 'row')
    activeItem.value = [];
    activeItem.value = [row]
  }
  if (activeItem.value.length === 0) {
    ElMessage.warning(i18n.global.t("sys.tip.select.file"));
    return
  } else if (activeItem.value.filter(item => item.isFolder === 2).length >= 1) {
    ElMessage.warning(i18n.global.t("common.cds17"))
    return;
  } else {
    console.log(activeItem.value, 'a')
    numberFlags.value = num;
    if (num === '1') {
      visitableShared.value = true;
    } else {
      visitableShared.value = true;
    }
  }
}, TIME_DELAY)


// 创建分享链接
const visitableLink = ref(false);
const closeLink = () => {
  visitableLink.value = false;
}
// 分享文件
const shareLink = debouncePlus((row) => {
  if (row.id) {
    activeItem.value = [];
    activeItem.value = [row]
  }
  if (activeItem.value.length === 0) {
    ElMessage.warning(i18n.global.t("sys.tip.select.file"))
    return
  } else {
    visitableLink.value = true;
  }
}, TIME_DELAY)


const action = ref(`${process.env.VUE_APP_BASE_API}/sys/oss/qiniu/upload`);
const token = getToken();
const multipartFiles = ref([]);

// 这里是通过文件列表接口进行展示的
const fileList = ref([])
const selectFileList = ref([])
// 文件空间信息
const spaceSize = computed(() => store.state.cloud.spaceSize)

const toBuyDialogVisible = ref(false)

const toBuyDialogVisibleClose = () => {
  toBuyDialogVisible.value = false;
}

const beforeUpload = (file) => {
  selectFileList.value.push(file);
  console.log('文件：', file, selectFileList.value)
  var FileExt = file.name.replace(/.+\./, "")
  // 文件大小限制
  const isLt5M = file.size / 1024 / 1024 < 300;

  var extension = ['exe', 'iso'].indexOf(FileExt.toLowerCase()) === -1
  if (!extension) {
    ElMessage({
      type: 'warning',
      message: '禁止上传 exe, iso 文件！'
    })
    return false
  }
  if (!isLt5M) {
    ElMessage({
      type: 'warning',
      message: '附件大小超限，文件不能超过 300M'
    })
    return false
  }


  // 云文件存储限制
  if (file.size > spaceSize.value.freeLocation) {
    toBuyDialogVisible.value = true;
    return false;
  } else {
    // 允许上传
    return true
  }
};

function handleUploadError(error) {
  ElMessage.error(error)
}

const uploadPromises = ref([])
const numbers = ref(1);
const timer = ref(null)

async function handleUploadSuccess(e) {
  const {fileName, size, name, url} = e.result;
  const params = {
    fileName: name,
    parentId: route.query.id ? route.query.id : '0',
    filePath: route.query.id ? route.query.id : '0',
    fileArea: route.query.path ? route.query.path : '0',
    fileExtendPath: url,
    fileSize: size,
    isFolder: 1,
    fileExtend: `${fileName.substring(fileName.lastIndexOf('.') + 1)}`,
  }
  await store.dispatch('cloud/addFile', params);
  multipartFiles.value.push(e.result);
  numbers.value++

  console.log(uploadPromises.value.length, fileList.value.length, numbers.value - 1,
      'target'
  )

  await Promise.all(uploadPromises.value)
  if (numbers.value - 1 === fileList.value.length) {
    resetCurrentAbout()
    await getFileList()
    await store.dispatch("cloud/queryByUserIdApis")
  } else {
    timer.value = setTimeout(async () => {
      await getFileList()
      await store.dispatch("cloud/queryByUserIdApis")
    }, 3000)
  }
}

onBeforeUnmount(() => {
  clearTimeout(timer.value)
})


// 文件进度
const uploadRefs = ref(null)

function onProgress(progressEvent, file) {
  console.log(uploadRefs.value, '')
  uploadRefs.value.enterList();
  if (fileList.value.filter(item => item.uid === file.uid).length === 0) {
    fileList.value.push(file)
  }
  file.percentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
}


const {type} = defineProps({
  type: {type: Number, default: 1}
})

// 面包屑
const pathArray = ref([
  {path: '0', text: i18n.global.t("common.file.all"), tag: "0"}
])

const pathTemplateDb = ref([
  {path: '0', text: i18n.global.t("common.file.all"), tag: "0"}
])

// 进入文件夹
const tempRouter = ref('')
// 避免用户重复点击
const enterDirectory = throttle(async (row) => {
  tableLoading.value = true
  // refresh.value = !refresh.value
  if (type === 4) {
    await store.dispatch('cloud/queryShareFileByParentIdApi', {
      pageNo: current.value,
      pageSize: size.value,
      parentId: row.id
    })
  } else {
    //   请求新的数据
    await store.dispatch("cloud/getFilePathApis", {
      id: row.id,
      type: type === 1 ? type : type === 6 ? 2 : 1,
      pageNo: current.value,
      pageSize: size.value
    })
  }


  // 是文件夹
  const filterTagsArray = pathArray.value.map(item => item.tag)
  const targetPath = [...filterTagsArray, row.id].join(',')
  console.log(targetPath, filterTagsArray, pathArray.value, '$$$')
  tempRouter.value = targetPath;
  pathArray.value.push({path: targetPath, text: row.fileName, tag: row.id})
  console.log(!pathTemplateDb.value.filter(_ => _.tag === row.id).length >= 1, 'pathTemplateDb.value')
  const target = JSON.parse(sessionStorage.getItem('pathTemplateDb')) || [{
    path: '0',
    text: i18n.global.t("common.file.all"),
    tag: "0"
  }]
  if (!target.filter(_ => _.tag === row.id).length >= 1) {
    pathTemplateDb.value = [...target, {
      path: targetPath,
      text: row.fileName,
      tag: row.id
    }
    ]
    sessionStorage.setItem('pathTemplateDb', JSON.stringify(pathTemplateDb.value))
  }
  // 数据持久化
  sessionStorage.setItem('pathArray', JSON.stringify(pathArray.value))
  console.log(pathTemplateDb.value, 'pathTemplateDb.value')
  await router.push({path: route.path, query: {id: row.id, path: targetPath}})
  console.log('-----', route.path)
  tableLoading.value = false;
}, TIME_DELAY)


// 单击 弹出 查看文件
const showTargetFile = (row) => {
  // alert(row.name)
  let images = [];
  let initialViewIndex = 0;
  // 过滤出非文件夹的文件
  tableData.value.filter(_ => _.isFolder !== 2 && imgFormat.includes(_.fileExtend)).forEach((o, i) => {
    if (o.fileName === row.fileName) initialViewIndex = images.length
    images.push({src: o.fileExtendPath})
  })
  console.log(tableData.value, row.fileName, 'tableData.value')

  viewerApi({
    images,
    options: {
      initialViewIndex
    }
  })
}
// pdf 预览
const showTargetPdf = (row) => {
  console.log(row, 'row')
  let fullPath = `${location.origin}/showfile?url=${encodeURIComponent(row.fileExtendPath.replace(/^http/, "https"))}&type=${encodeURIComponent(row.fileExtend.toLocaleLowerCase().toLocaleLowerCase())}`;
  openNewWindow(fullPath)
}

const showTargetText = (row) => {
  let fullPath = `${location.origin}/showtext?url=${encodeURIComponent(row.fileExtendPath.replace(/^http/, "https"))}&type=${encodeURIComponent(row.fileExtend.toLocaleLowerCase().toLocaleLowerCase())}`;
  openNewWindow(fullPath)
}


const visitableVideo = ref(false)
const showTargetVideo = (row) => {
  // visitableVideo.value = true;
  let fullPath = `${location.origin}/showvideo?url=${encodeURIComponent(row.fileExtendPath)}&type=${encodeURIComponent(row.fileExtend.toLocaleLowerCase().toLocaleLowerCase())}`;
  openNewWindow(fullPath)
}
// const closeVideo = () => {
//   visitableVideo.value = false
// }

const showTargetAudio = (row) => {
  let fullPath = `${location.origin}/showaudio?url=${encodeURIComponent(row.fileExtendPath)}&type=${encodeURIComponent(row.fileExtend.toLocaleLowerCase().toLocaleLowerCase())}&title=${encodeURIComponent(row.fileName)}`;
  openNewWindow(fullPath)
}

const openNewWindow = (url) => {
  window.open(url, "_blank");
};


// 多选框select
const selectCheckbox = (selectArray) => {
  console.log('选中的', selectArray)
  activeItem.value = selectArray
}


// 点击 右侧查看文件
const activeItem = ref([])
const showTarget = (row) => {
  console.log(row, '文件')
  activeItem.value = [row];
}

// 单击 右侧查看文件夹
const dirFileList = computed(() => store.state.cloud.dirFileList)
const showLoading = ref(false)
const showTargetDirectory = async (row) => {
  resetCurrentAbout()
  console.log(row.fileName, row.id, '文件夹')
  activeItem.value = [row];
  //  获取内部文件夹列表
  showLoading.value = true
  if (type === 4) {
    // parentId ===0
    await store.dispatch('cloud/queryShareFileByParentIdApi', {
      pageNo: current.value,
      pageSize: size.value,
      show: 1,
      parentId: row.id
    })

  } else {
    await store.dispatch('cloud/getFilePathApis', {
      id: type === 4 ? row.fileId : row.id,
      type: type === 1 ? type : type === 6 ? 2 : 1,
      show: 1,
      pageNo: current.value,
      pageSize: size.value
    });
  }
  showLoading.value = false;
}


const clickTimeout = ref(false);

// 双击
const selectTargetDetail = (row) => {
  if (row.isFolder === 2) {
    //   文件夹
    //   进入文件夹
    enterDirectory(row);
  } else {
    if (!suportFile.value.includes(row.fileExtend.toLocaleLowerCase())) {
      ElMessage.warning("不支持该文件类型在线预览")
      return
    }
    //  文件
    //  弹出 查看文件
    if (imgFormat.includes(row.fileExtend.toLocaleLowerCase())) {
      // 图片预览
      showTargetFile(row)
    }
    if (pdfFormat.includes(row.fileExtend.toLocaleLowerCase())) {
      // pdf
      //预览
      showTargetPdf(row);
    } else if (wordFormat.includes(row.fileExtend.toLocaleLowerCase()) || excelFormat.includes(row.fileExtend.toLocaleLowerCase()) || pptFormat.includes(row.fileExtend.toLocaleLowerCase())) {
      // /world/excel
      window.open("https://view.officeapps.live.com/op/view.aspx?src=" + encodeURIComponent(row.fileExtendPath))
    } else if (textFormat.includes(row.fileExtend.toLocaleLowerCase())) {
      showTargetText(row)
    } else if (videoFormat.includes(row.fileExtend.toLocaleLowerCase())) {
      showTargetVideo(row)
    } else if (audioFormat.includes(row.fileExtend.toLocaleLowerCase())) {
      showTargetAudio(row)
    } else {
      // alert('下载')
      downloadTargetFile(row)
    }
  }
}

const suportFile = computed(() => ([...imgFormat, ...pdfFormat, ...wordFormat, ...excelFormat, ...excelFormat, ...videoFormat, ...audioFormat, ...pptFormat, ...textFormat]))

// 单击 文件详情展示
const targetFileDetail = (row) => {
  if (row.isFolder === 2) {
    // 右侧 查看文件夹
    showTargetDirectory(row)
  } else {
    // 右侧 查看文件
    showTarget(row)
  }
}

// 记录点击的数量
const clickCount = ref(0);
// 选择点击的事件
const selectClickEvent = (row) => {
  clickCount.value++;
  if (clickCount.value === 1) {
    //   单击事件
    clickTimeout.value = setTimeout(() => {
      multipleTableRef.value.clearSelection()
      multipleTableRef.value.toggleRowSelection(row)
      targetFileDetail(row);
      resetClickCount();
    }, TIME_DELAY)
  } else {
    //   双击事件
    clearTimeout(clickTimeout.value);
    selectTargetDetail(row);
    resetClickCount();
  }

}

// 重制点击事件
const resetClickCount = () => {
  clickCount.value = 0
}


const handleClick = throttle(async (item, index) => {
  tableLoading.value = true
  refresh.value = !refresh.value
  resetCurrentAbout()
  // 处理面包屑导航点击事件
  console.log(item, index, route, pathArray.value, item.tag === "0", route.query.id,
      'click'
  )

  console.log(item, route.path, 'targetItem')
  const targetIndex = findIndexWithTag(pathArray.value, item.tag);
  console.log(targetIndex, 'targetIndex')


  if (item.tag !== "0") {
    // 子路由
    await router.push({path: route.path, query: {id: item.tag, path: item.path}})
  } else {
    // 根路由
    await router.push({path: route.path})
  }

  pathArray.value = pathArray.value.slice(targetIndex, targetIndex + 1)
  console.log(pathArray.value, 'splice')
  console.log(pathTemplateDb.value, 'pathTemplateDb.value')
  sessionStorage.setItem('pathArray', JSON.stringify(pathArray.value))
  await getFileList()
  tableLoading.value = false
}, TIME_DELAY)

function findIndexWithTag(jsonData, flag) {
  for (var i = 0; i < jsonData.length; i++) {
    if (jsonData[i].tag === flag) {
      return i;
    }
  }
  return -1;
}


// 表格
const multipleTableRef = ref(null)
const multipleSelection = ref([])
const handleSelectionChange = (val) => {
  multipleSelection.value = val
}

// 表格中的数据
const tableLoading = ref(false);
// 数据总表格
// const tableData = ref([])
// 右侧展示的表格
const rightTableDataList = ref([])

const userInfo = computed(() => store.state.user.userInfo);


// 用户是否开始滚动 标识
const scrollStartFlag = ref(false)
const scrollBehavior = () => {
  scrollStartFlag.value = true;
  // console.log(scrollStartFlag.value, 'start-scroll')
}


onMounted(async () => {
  console.log(route.query.id, 'route.query.id')
  if (route.path !== '/cloud/info') {
    await getFileList();
    multipleTableRef.value && multipleTableRef.value.$refs.bodyWrapper.addEventListener("mousewheel", scrollBehavior, {passive: true})
  }
})
onUnmounted(() => {
  multipleTableRef.value && multipleTableRef.value.$refs.bodyWrapper.removeEventListener("mousewheel", scrollBehavior)
})

// 获取所有数据
const current = ref(1);
const size = ref(20);
const total = computed(() => store.state.cloud.total);

const tableData = computed(() => store.state.cloud.fileList)
const getFileList = async () => {
  tableLoading.value = true;
  if (type === 1 || type === 5 || type === 6) {
    console.log('route.query.id', pathArray.value, route.query.id)
    if (route.query.id) {
      checkRouter()
      const params = {
        id: route.query.id,
        type: type === 1 ? type : type === 6 ? 2 : 1,
        pageNo: current.value,
        pageSize: size.value
      }
      await store.dispatch("cloud/getFilePathApis", params)
    } else {
      const params = {
        pageNo: current.value,
        pageSize: size.value,
        isHide: type === 5 ? 2 : null,
        // 1 我的文件夹， 2 回收站
        type: type === 1 ? type : type === 6 ? 2 : 1,
        parentId: "0"
      }
      if (params.isHide === null) {
        delete params.isHide;
      }
      if (type !== 1) {
        delete params.parentId;
      }
      await store.dispatch('cloud/getFileListApi', params)
    }
  } else if (type === 2) {
    const params = {
      pageNo: current.value,
      pageSize: size.value,
    }
    await store.dispatch("cloud/publicShareOtherListApis", params)
  } else if (type === 3) {
    const params = {
      pageNo: current.value,
      pageSize: size.value,
    }
    await store.dispatch("cloud/myPublicShareListApis", params)
  } else if (type === 4) {
    console.log(route.query.id, 'route.query.id')
    if (route.query.id) {
      checkRouter()
      await store.dispatch('cloud/queryShareFileByParentIdApi', {
        pageNo: current.value,
        pageSize: size.value,
        parentId: route.query.id,
      })
    } else {
      const params = {
        pageNo: current.value,
        pageSize: size.value,
      }
      await store.dispatch("cloud/mailFileShareListApis", params)
    }
  }
  tableLoading.value = false;
}


// table 懒加载
const pages = computed(() => store.state.cloud.pages)
let isFirstPageLoaded = computed(() => store.state.cloud.isFirstPageLoaded);
const refresh = ref(false)
const disabled = computed(() => store.state.cloud.disabled);


const handleTableScroll = debouncePlus(async () => {

  // console.log('scroll', current.value, pages.value, total.value, tableData.value)
  if (scrollStartFlag.value) {
    //  用户开始滚动
    // await store.commit("cloud/changeDisabled", false);
    if (current.value === pages.value) {
      if (current.value !== 1) {
        ElMessage.warning("已经到底了")
      }
      return;
    } else {
      store.commit("cloud/changeCurrent", true)
      current.value++
      await getFileList();
    }
  } else {
    //  未开始不允许翻页
    // await store.commit("cloud/changeDisabled", true);
  }


}, TIME_DELAY)


/**
 * 文件重命名
 */
const changeVisitable = ref(false);
const changTargetItem = ref({})
const targetFileChange = async (row) => {
  console.log(row, '重命名-row')
  changeVisitable.value = true
  changTargetItem.value = row
}

const closeChangeVisitable = () => {
  changeVisitable.value = false
}


/**
 * 指定文件删除
 */
const targetDeleteList = ref(null)
const targetFileDelete = (row, val) => {
  flags.value = val * 1
  targetDeleteList.value = row;
  deleteVisitable.value = true;
}


/**
 * 取消我的分享
 */
const cancelLoading = ref(false)
const cancelShare = async (row) => {
  if (row.id) {
    activeItem.value = [];
    activeItem.value = [row];
  }
  if (activeItem.value.length === 0) {
    ElMessage.warning("请选择文件")
    return;
  } else {
    const payload = {
      idList: activeItem.value.map(_ => _.id)
    }
    cancelLoading.value = true;
    await store.dispatch("cloud/fileCancelShareApis", payload)
    await getFileList()
    cancelLoading.value = false;
  }
}

/**
 * copy 分享链接
 */
const copyLink = async (row) => {
  const {shareCode, linkAddress} = row;
  const targetHref = `${window.location.origin}/cloud/download/`
  try {
    await toClipboard(`分享链接: ${targetHref}${linkAddress}  提取码: ${shareCode}`)
    ElMessage.success("复制分享链接成功")
  } catch (e) {
    ElMessage.error("复制链接失败")
  }
}

/**
 * 取消共享
 */
const cancelPublicShare = async () => {
  if (activeItem.value.length === 0) {
    ElMessage.warning("请选择文件");
    return;
  } else {
    const payload = {
      fileId: activeItem.value.map(_ => _.fileId),
      userId: activeItem.value.map(_ => _.userId),
    }
    await store.dispatch("cloud/fileCancelPublicShareApis", payload)
    await getFileList()
  }
}

// window.addEventListener("popstate", async function (evt) {
//   // refresh.value = !refresh.value;
//   // if (route.query.id) {
//   //   await checkRouter()
//   //   await getFileList()
//   // }
//   scrollStartFlag.value = false
//   console.log(evt, 'evt')
//   // await checkRouter()
//   // await getFileList()
// }, false);
watch(() => route.query.id, async () => {
  refresh.value = !refresh.value;
  // if (route.query.id) {
  resetCurrentAbout()
  await getFileList()
  await checkRouter()
  // }
})

// watch(() => pathRouter, async (newValue) => {
//   // 如果是分享list
//
// }, {deep: true, immediate: true})

// watch(() => route.path, (newVal, oldVal) => {
//   console.log(newVal, oldVal, newVal.indexOf('/cloud') !== -1, '3333')
//   if (newVal.indexOf('/cloud') !== -1) {
//     window.onload = () => {
//       alert('fff')
//     }
//   }
// })


</script>

<style lang="less" scoped>
@import "./index";

:deep(.el-empty__image img) {
  width: 54% !important;
}

:deep(.el-empty__description) {
  margin-top: -20px;
}
</style>
