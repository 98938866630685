import _store from "store2";
// import { EventEmitterSingle } from '@/utils/EventEmitter.js'
// const EventEmitter = EventEmitterSingle()
import {
    addMailCustomer,
    getAreaName,
    queryById,
    deleteMailCustomerContact, addArchiveUserEmail, getCustomerCodeApis,
} from "@/api/customer";
import dayjs from "dayjs";
import {ElMessage} from "element-plus";
import router from "../../router";
import {
    updateMailCustomer,
    getMailCustomerContact,
    addMailCustomerContact,
    getMailCustomerContactDetail,
} from "@/api/customer";
import {outputArray, inArray, compareAndFilter, compareAndModify, updateIsDefault} from "@/utils/objchange.js";
import {requestMailFlagList} from "@/api";
import sessionStorageUtil from "@/utils/sessionStorageUtil";

const customer = {
    namespaced: true,
    state: {
        removeListArray: {
            emails: [],
            phones: []
        },
        removeContactEmail: [],
        isSelected: false,
        // 随机生成的客户编号
        randomCustomerCode: '',
        saveDataIsLoading: false,
        // 表单数据
        ruleForm: {
            zoneCode: "",
            customerCode: "",
            customerCodeDefault: "请输入",
            zoneAddress: "",
            customerName: "",
            customerOrigin: "",
            setUpTime: null,
            economicNature: "",
            companyWebsite: "",
            companyAddress: "",
            phoneWayList: [],
            emailWayList: [],
            teamId:"",
            companyFax: "",
            companyRemark: "",
            customerLevel: "",
            customerType: "",
            customerTypeId: "",
            product: "",
            marketingRemark: " ",
            createBy: "",
            branchAdmin: "",
            mangerVOList: [{managerName: ''}]
            // customerIds: '',
        },
        dialogFlag: '',
        // 表单
        form: {
            phones: [{phoneNumber: "", isDefault: true}],
            emails: [{email: "", isDefault: true}],
        },
        formTemp: {
            phones: [{phoneNumber: "", isDefault: true}],
            emails: [{email: "", isDefault: true}],
        },
        // 区域名称
        areaList: JSON.parse(localStorage.getItem("countryList")) || [],
        // 选中的等级
        selectTarget: "",
        // 联系人列表
        contactList: [],
        // 联系人分页
        contactPage: {
            pageSize: 10,
            pageNo: 1,
            total: 0,
        },
        // 联系人loading
        contactLoading: false,
        contactMessage: {},
        contactMessageTemp: {},

        // 审批列表中的 徽章数据
        badgeDataApproval: 0,

        // 客户相关的查询
        searchForm: {
            customerName: "",
            managerId: "",
            zoneAddress: "",
            customerTypeId: "",
        },
        customerLevels: []
    },
    getters: {},
    mutations: {
        setRemoveListArray(state, payload) {
            const {phones, emails} = payload
            state.removeListArray.emails = emails;
            state.removeListArray.phones = phones;
        },
        setIsSelected(state, payload) {
            state.isSelected = payload;
        },
        setSaveDataIsLoading(state, payload) {
            state.saveDataIsLoading = payload;
        },
        setCustomerLevels(state, payload) {
            state.customerLevels = payload
        },
        // 改变客户查询条件
        changeSearchForm(state, payload) {
            state.searchForm = payload;
        },
        // 改变数值
        changeSelect(state, payload) {
            state.selectTarget = payload;
        },
        // 添加手机号
        addEmailPhoneItem(state, payload) {
            const {title} = payload;
            if (title.indexOf("电话") != -1) {
                state.form.phones.push({phoneNumber: "", isDefault: false});
            } else {
                state.form.emails.push({email: "", isDefault: false});
            }
        },
        // 删除手机号/邮箱
        removeEmailPhoneItem(state, payload) {
            const {index, title, item} = payload;
            const targetArray = title.indexOf("电话") !== -1 ? "phones" : "emails";
            if (targetArray === 'emails') {
                // 没有id 的值就是 新添加的值 不需要做记录
                state.removeListArray.emails = [item, ...state.removeListArray.emails].filter(_ => _.id)
            } else if (targetArray === "phones") {
                state.removeListArray.phones = [item, ...state.removeListArray.phones].filter(_ => _.id)
            }
            state.form[targetArray].splice(index, 1);
        },
        findSelectIndex(state, payload) {
            const {title, selectedIndex} = payload;
            const targetArray = title.indexOf("电话") !== -1 ? "phones" : "emails";
            const targetKey = title.indexOf("电话") !== -1 ? "phoneNumber" : "email";

            state.form[targetArray].forEach((item, index) => {
                item.isDefault = index === selectedIndex;
            });
            const res = state.form[targetArray].filter((item) => item.isDefault);
            state.ruleForm[targetKey] = res[0].targetKey;
        },
        setAreaList(state, payload) {
            state.areaList = payload;
        },
        // 更改表单
        setRuleForm(state, payload) {
            state.ruleForm = payload;
        },


        // 修改from表单
        setForm(state, payload) {
            // console.log('000000000000000ff')
            const {emails, phones} = payload;
            state.form.phones = [...phones];
            state.form.emails = [...emails];
        },
        // 修改临时表单
        setFormTemps(state, payload) {
            const {emails, phones} = payload;
            state.formTemp.phones = [...phones];
            state.formTemp.emails = [...emails];
        },

        // 修改联系人列表
        setContactList(state, payload) {
            // console.log(payload, '@@@')
            state.contactList = payload;
        },
        // 修改联系人页面
        setContactPage(state, payload) {
            state.contactPage = payload;
        },
        // 修改联系人loading状态
        setContactLoading(state, payload) {
            state.contactLoading = payload;
        },
        setContactMessage(state, payload) {
            state.contactMessage = payload;
        },
        //   更改审批 徽章的数据
        setApprovalBadge(state, payload) {
            state.badgeDataApproval = payload;
        },
        //     改变新建弹窗的状态
        setDialogFlag(state, payload) {
            state.dialogFlag = payload;
        }
    },
    actions: {
        // 添加客户
        async saveActivity({state, commit}, payload) {
            // let teamId = window.localStorage.getItem("teamId");
            let copyObj = JSON.parse(JSON.stringify(state.ruleForm));
            // console.log(copyObj, "copyObj");

            delete copyObj.customerLevel;
            delete copyObj.customerType;
            delete copyObj.setUpTime;
            const {customerLevel, setUpTime} = state.ruleForm;
            if (copyObj.customerCode === "") {
                copyObj.customerCode = copyObj.customerCodeDefault
            }
            // console.log('state.form', state.form);
            // console.log('state.formTemp', state.formTemp);
            const {emails, phones} = compareAndModify(state.formTemp, state.form)

            const formAllData = {
                ...copyObj,
                setUpTime:
                    setUpTime && dayjs(setUpTime).isValid()
                        ? dayjs(setUpTime).format("YYYY-MM-DD")
                        : null,
                customerTypeId: customerLevel,
                emailWayList: emails,
                phoneWayList: phones,
            };
            const contactArray = JSON.parse(localStorage.getItem("tableData"))
            if (contactArray && contactArray?.length >= 1) {
                contactArray.forEach(item => {
                    delete item.id;
                })
                formAllData.mailContactDTOList = contactArray
            }
            //判断收件箱入口还是客户入口
            if (payload&&payload[0]&&payload[0].flag) {
                formAllData.mailContactDTOList = payload
            }else{
                formAllData.teamId=payload.teamId;
            }



            console.log(formAllData, state.form.phones, outputArray(state.form.phones), "data");
            localStorage.setItem('saveCustomerEmailWayList', JSON.stringify(formAllData.emailWayList))
            // localStorage.setItem('saveCustomerPhoneWayList', JSON.stringify(formAllData.phoneWayList))
            console.log(formAllData, 'formAllData')
            // 有邮箱 1 没有邮箱 2
            const targetFlag = formAllData.emailWayList.filter(item => item.content !== "").length > 0 ? 1 : 2
            try {
                commit("setSaveDataIsLoading", true)
                const res = await addMailCustomer(formAllData);
                if (res.code !== 200) {
                    ElMessage.error(res.message);
                    commit("setSaveDataIsLoading", false)
                    commit("setForm", {
                        emails: formAllData.emailWayList.map(item => ({
                            isDefault: item.isDefault === 1,
                            email: item.content
                        })),
                        phones: formAllData.phoneWayList.map(item => ({
                            isDefault: item.isDefault === 1,
                            phoneNumber: item.content
                        }))
                    });
                    return false;
                }
                ElMessage.success(res.message);
                commit("setSaveDataIsLoading", false)
                commit("setForm", {
                    emails: [{email: "", isDefault: true}],
                    phones: [{phoneNumber: "", isDefault: true}],
                });
                localStorage.setItem("tableData", JSON.stringify([]))
                console.log(targetFlag, 'targetFlag')

                //客户归入口，返回页面
                if (payload&&payload.teamId) {
                    router.push({path: "/email/customer/list"});
                }
                return targetFlag;
            } catch (error) {
                commit("setSaveDataIsLoading", false)
                commit("setForm", {
                    emails: formAllData.emailWayList.map(item => ({
                        isDefault: item.isDefault === 1,
                        email: item.content
                    })),
                    phones: formAllData.phoneWayList.map(item => ({
                        isDefault: item.isDefault === 1,
                        phoneNumber: item.content
                    }))
                });
                return false;
            }
        },
        // 修改客户
        async UpdateActivity({state, commit, dispatch}, payload) {

            console.log("进来了")
            // 手动删除也需要清除
            let targetDefaultId = null;
            let theLastTarget = [];
            if (state.form.emails.filter(item => item.id && item.email === "").length > 0) {
                targetDefaultId = state.form.emails.filter(item => item.id && item.email === "").map((_) => _.id)
                theLastTarget = state.formTemp.emails.filter(item => (targetDefaultId.includes(item.id)));
            }
            let targetDefaultId2 = null;
            let theLastTarget2 = [];
            if (state.form.phones.filter(item => item.id && item.phoneNumber === "").length > 0) {
                targetDefaultId2 = state.form.phones.filter(item => item.id && item.phoneNumber === "").map((_) => _.id)
                theLastTarget2 = state.formTemp.phones.filter(item => (targetDefaultId2.includes(item.id)));
            }


            const copyObj = JSON.parse(JSON.stringify(state.ruleForm));
            delete copyObj.customerLevel;
            delete copyObj.customerType;
            delete copyObj.setUpTime;
            const {customerLevel, setUpTime} = state.ruleForm;
            const {customerType, isAlert} = payload
            // console.log(payload, state.customerLevels, outputArray(state.form.emails, "email").filter(item => item.content === '').length, payload !== null ? state.customerLevels.filter((item) => item.customerType === payload)[0].id : "", '@@')


            console.log('state.form', state.form);
            console.log('state.formTemp', state.formTemp);
            const {emails, phones} = compareAndModify(state.formTemp, state.form)
            console.log(state.form.emails, emails, phones, 'target')
            copyObj.teamId=payload.teamId;
            const formAllData = {
                ...copyObj,
                setUpTime:
                    setUpTime && dayjs(setUpTime).isValid()
                        ? dayjs(setUpTime).format("YYYY-MM-DD")
                        : null,
                customerTypeId: customerType !== null && state.customerLevels.filter((item) => item.customerType === customerType).length >= 1 ? state.customerLevels.filter((item) => item.customerType === customerType)[0].id : "",
                emailWayList: state.form.emails.filter(item => item.isDefault === 1).length === 1 ? emails : updateIsDefault(emails),
                phoneWayList: state.form.phones.filter(item => item.isDefault === 1).length === 1 ? phones : updateIsDefault(phones),
                deleteEmailWayList: outputArray([...state.removeListArray.emails, ...theLastTarget], 'email'),
                phoneDeleteWayList: outputArray([...state.removeListArray.phones, ...theLastTarget2], 'phoneNumber'),
            };
            commit("setForm", {
                emails: [{email: "", isDefault: true}],
                phones: [{phoneNumber: "", isDefault: true}],
            });
            console.log(formAllData, 'formAllData')

            try {
                commit("setSaveDataIsLoading", true)
                const res = await updateMailCustomer(formAllData);
                if (res.code !== 200) {
                    ElMessage.error(res.message);
                    commit("setSaveDataIsLoading", false)
                    return
                }
                commit("setSaveDataIsLoading", false)
                commit("setRemoveListArray", {emails: [], phones: []})
                if (isAlert) {
                    ElMessage.success(res.message);
                }
            } catch (error) {
                commit("setSaveDataIsLoading", false)
            }
            // commit("setForm", {
            //     emails:
            //         [{email: "", isDefault: true}],
            //     phones: [{phoneNumber: "", isDefault: true}],
            // })
            //
            // state.phoneWayList = [];
            // state.emailWayList = [];
            // state.form.emails = [{email: "", isDefault: true}];
            // state.form.phones = [{phoneNumber: "", isDefault: true}]
        },
        // 根据id查询用户
        async getQueryById({state, commit}, payload) {
            const res = await queryById(payload);
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return;
            }
            const targetObj = res.result;
            console.log(targetObj, 'targetObj')
            const {emailWayList, phoneWayList, customerType} = targetObj;
            const targetResultObj = {
                emails: inArray(emailWayList, "email"),
                phones: inArray(phoneWayList, "phoneNumber"),
            }
            console.log(targetResultObj, ';f')
            // 前端数据库
            localStorage.setItem("EditeArrayEmailPhone", JSON.stringify(targetResultObj))
            commit("setRuleForm", targetObj);

            // 回显
            commit("setForm", {
                emails: emailWayList.length !== 0 ? inArray(emailWayList, "email")
                    :
                    [{email: "", isDefault: true}],
                phones: phoneWayList.length !== 0 ?
                    inArray(phoneWayList, "phoneNumber") : [{phoneNumber: "", isDefault: true}],
            })
            ;
            // 记录数据
            commit("setFormTemps", {
                emails: emailWayList.length !== 0 ? inArray(emailWayList, "email")
                    :
                    [{email: "", isDefault: true}],
                phones: phoneWayList.length !== 0 ?
                    inArray(phoneWayList, "phoneNumber") : [{phoneNumber: "", isDefault: true}],
            })
            ;
            commit("changeSelect", customerType);
        },
        // 获取区域名称
        async getAreaNames({state, commit}) {
            if (!localStorage.getItem("countryList")) {
                let res = await requestMailFlagList({
                    pageSize: 999,
                });
                if (res.code !== 200) {
                    ElMessage.error(res.message);
                    return;
                }
                const {records} = res.result;
                commit("setAreaList", records);
                localStorage.setItem("countryList", JSON.stringify(records))
            }
        },
        // 获取联系人
        async getContactList({state, commit},) {
            let customerId = window.localStorage.getItem("customerId");
            commit("setContactLoading", true);
            let res = await getMailCustomerContact({
                customerId,
                pageNo: state.contactPage.pageNo,
                pageSize: state.contactPage.pageSize,
            });
            if (res.code != 200) {
                ElMessage.error(res.message);
                return;
            }
            commit("setContactLoading", false);
            const {records, current, size, total, pages} = res.result;
            commit("setContactList", records);
            commit("setContactPage", {pageNo: current, pageSize: size, total: total});
        },
        // 根据id删除联系人
        async deleteContact({state, commit}, payload) {
            let res = await deleteMailCustomerContact(payload);
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return;
            }
            ElMessage.success(res.message);
        },
        // 联系人详情
        async getContactDetail({state, commit}, payload) {
            let res = await getMailCustomerContactDetail(payload);
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return;
            }
            commit("setContactMessage", res.result);
            localStorage.setItem("ContactMessage", JSON.stringify(res.result))
        },

        // 邮件附件添加
        async addAttachment({state, commit}, payload) {
            let res = await addArchiveUserEmail(payload)
            if (res.code !== 200) {
                ElMessage.error(res.message)
                return;
            }
            ElMessage.success(res.message)
        },
        //     随机生成 客户编号
        async getCustomerCodeApi({state, commit}, payload) {
            let res = await getCustomerCodeApis(payload)
            if (res.code !== 200) {
                ElMessage.error(res.message)
                return;
            }
            console.log(res, 'res')
            state.randomCustomerCode = res.message
        }
    },
};

export default customer;
