import {createApp, createVNode} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {i18n} from "./lang";
import Directives from "./directive";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import Icon from "./components/Icon.vue";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import "@/utils/cleanRest.js"
import "@/utils/wangEditor/wangEditor.min"
import vue3videoPlay from "vue3-video-play"; // 引入组件
import "vue3-video-play/dist/style.css"; // 引入css
import "@/utils/string/index"
// 自定义指令
import {directiveM, loadMore} from '@/directive/tablescroll'
// global css
import "./less/index.less";
import "./less/scroll.less";
// notify less
import '@/utils/notifies/emailMessage.less'
// 主题颜色
import "@/less/theme/them.less"

// 去除所有 waring 和 console
console.warn = () => {
};
console.log(process.env.NODE_ENV, 'ENV')
// console.log = () => {
// };
if (process.env.NODE_ENV === "production") {
    console.log = () => {
    };
    console.warn = () => {
    };
}

// permission control
import "./permission";
// utils
import {DateFormat} from "@/utils/util";

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
// const { mockXHR } = require('../mock')
// mockXHR()

const app = createApp(App);

console.log(i18n, "i18n");
zhCn.el.pagination.goto = "跳至";
zhCn.el.pagination.pageClassifier = "页";

directiveM(app);
loadMore(app);
//全局挂载
app.use(i18n)
    .use(vue3videoPlay)
    .use(ElementPlus, {locale: zhCn})
    .use(Directives)
    .use(router)
    .use(store)
    .provide("$DateFormat", DateFormat)
    .mount("#app");

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}

app.component("Icon", Icon);
// app.component('RecycleScroller', VueVirtualScroller.RecycleScroller)
export default app;
