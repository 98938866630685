<template>
  <ElDialog
      v-model="isShow"
      :title="'客户批量导入'"
      :footer="null"
      centered
      top="10em"
      :width="'40%'"
      style="min-width:500px"
      destroyOnClose
      @cancel="close"
      :class="'headline'"
  >
    <el-upload
        ref="uploadRef"
        style="width: 100%;display: flex;justify-content: center;flex-direction: column;align-items: center;margin-top: 20px"
        class="upload-demo"
        :accept="['.xls','.xlsx']"
        :auto-upload="false"
        :show-file-list="true"
        :file-list="fileList1"
        :on-change="handleUploadChange"
    >
      <el-button v-if="!fileList1.length>=1" type="primary" plain size="large"
                 style="border: 1px dashed #409eff;width: 250px;margin: auto ">
        选择文件
      </el-button>
    </el-upload>
    <div style="height: 15px;width: 100%">
      <el-progress :percentage="percent" v-if="percent!==0" striped :stroke-width="10"/>
    </div>

    <div style="display: flex;margin-top: 10px">
      <el-button plain size="large" style="border: 1px dashed #dddfe5;width: 250px;margin: auto " :icon="Download"
                 @click="downloadExcel" :loading="downloadFile">
        {{ $t('list.customer.download.template') }}
      </el-button>
    </div>
    <div style="width: 100%;display: flex;justify-content: center;margin-top: 20px;margin-bottom: 20px">
      <div style="width: 85%" class="text_content">
        <p><span><el-icon><Warning/></el-icon></span>注意事项1：请严格按照模版指定区域填写内容，避免导入错误。</p>
        <p><span><el-icon><Warning/></el-icon></span>注意事项2：填写表格前，建议先去熟悉后台新增建档客户的流程与操作逻辑，方便填写对应信息，避免混淆。
        </p>
        <p><span><el-icon><Warning/></el-icon></span>
          注意事项3：公司电话和公司邮箱，可添加多条，保证一行一条，没有则可留空。联系人一行一位，包含其信息，具体可参考表格例子。
        </p>
        <p><span><el-icon><Warning/></el-icon></span>
          注意事项4：客户等级一栏，请前往后台“客户管理-客户等级设置”，设置好等级规则，方可生效
        </p>
      </div>
    </div>

    <template #footer>
      <ElButton @click="close">取消
      </ElButton>
      <ElButton type="primary" @click="addToEmails" :loading="loading">确定
      </ElButton>
    </template>

  </ElDialog>
</template>

<script setup>
import {ElButton, ElDialog, ElInput, ElMessage} from "element-plus";
import {V2CreateTeam} from '../../api'
import {ref, defineProps, watch, defineEmits, reactive, computed, onMounted} from 'vue'
import {useStore} from "vuex";
import {i18n} from "@/lang";
import {exportCustomerTemplate, getMailCustomerList, importCustomxerExcel, requestAllCustomer} from "@/api/customer";
import TopSearch from "@/views/Customer/TopComponents/TopSearch.vue";
import * as XLSX from "xlsx";
import Tables from "@/views/Customer/Tables/Tables.vue";
import {table_columns_list, table_columns_list_sp} from "@/views/Customer/map";
import {Download, Warning} from "@element-plus/icons-vue";

const store = useStore();
const emit = defineEmits()
const isShow = ref(false);
const teamName = ref("")
const loading = ref(false);
// 选中的数组
const checkList = ref([])

const {value, isChecked} = defineProps({
  value: {type: Boolean, default: false},
  isChecked: {type: Array, default: []}
})

watch(() => value, (val) => {
  isShow.value = val;
}, {immediate: true})

const teamId = computed(() => store.state.email.teamID);
const isLoading = ref(false);


const showTargetEmails = ref([])
const customerList = ref([])
const percent = ref(0)
const fileList1 = ref([])
const fileTarget = ref(null)

async function handleUploadChange(file, fileList) {
  fileTarget.value = file
  console.log(file, fileTarget.value, 'ffff')
  if (fileList.length > 1) {
    fileList.splice(0, 1);
  }
  fileList1.value = fileList;
}

// 检测上传进度

function extractEmails(data) {
  return data.map(function (entry) {
    return entry["公司邮箱"];
  });
}

const readFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (e) => {
      resolve(e.target.result);
    };
    reader.onerror = (e) => {
      reject(e);
    };
  });
}

//收集邮箱并添加
const addToEmails = async () => {
  console.log(fileTarget.value, 'file.value')
  const blob = new Blob([fileTarget.value.raw], {type: fileTarget.value.raw.type}); // 使用 file.row 而不是 file
  const formData = new FormData();
  formData.append('file', blob, fileTarget.value.name);
  formData.append('teamId', teamId.value);
  console.log(formData.get('file'), 'fff')
  loading.value = true;
  try {
    await importCustomxerExcel(formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: progressEvent => {
        const percentCompleted = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        percent.value = percentCompleted;
      }
    }).then(res => {
      loading.value = false;
      if (percent.value === 100) {
        setTimeout(() => {
          close();
          emit('getCustomerList')
        }, 2000)
      }
    })
  } catch (error) {
    console.error('Error uploading file:', error);
  }

}

/**
 * excel 模版下载
 * @type {Ref<UnwrapRef<boolean>>}
 */
const downloadFile = ref(false);
const downloadExcel = async () => {
  await exportCustomerTemplate(_, {
    responseType: "blob",
    headers: {'Content-Type': 'application/vnd.ms-excel'}
  }).then(res => {
    const link = document.createElement('a');
    const blob = new Blob([res], {type: 'application/vnd.ms-excel;charset=utf-8'}); // 设置正确的字符编码
    link.style.display = 'none';
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', '客户信息模版');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};


const close = () => {
  emit("close");
}
</script>

<style scoped lang="less">
@import "../../less/page.less";

.fx-j-c {
  display: flex;
  justify-content: center;
}

.settings {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
}

:deep(.el-progress__text) {
  display: none;
}

:deep(.el-upload-list) {
  line-height: 50px;
  width: 250px;
  transform: translateX(-50%);
}

:deep(.el-upload-list__item) {
  left: 50%;
}

:deep(.el-upload-list__item-info) {
  align-items: center !important;
}

:deep(.el-upload-list__item-file-name) {
  font-size: 14px;
  font-weight: 600;
}

:deep(.text_content) {
  & > p {
    line-height: 20px;
    font-weight: 500;
    color: #dca550;
    display: flex;
    align-items: flex-start;
    margin-bottom: 6px;

    & > span {
      padding-top: 2px;
    }

  }
}

.headline > .el-dialog__header {
  border-bottom: 1px solid #eee !important;
}


.headline > .el-dialog__footer {
  border-top: 1px solid #eee !important;
}
</style>
