<template>
  <div style="width: 100%">
    <div class="my_labels">
      <label>{{ label }}</label>
      <div>
        <el-button type="primary" link @click="dialogVisible = true"
        >{{ $t('common.setting') }}
        </el-button
        >
      </div>
    </div>
    <slot></slot>
  </div>
  <el-dialog
      v-model="dialogVisible"
      :title="title"
      width="400"
      draggable
      append-to-body
  >
    <div>
      <el-form :model="form" label-position="left" ref="phoneForm">
        <el-form-item
            v-for="(item, index) in title.indexOf('电话') != -1
            ? form.phones
            : form.emails"
            :key="index"
            :label="label"
        >
          <template v-if="title.indexOf('电话') != -1">
            <el-input
                v-model="form.phones[index].phoneNumber"
                class="add_inputs"
            ></el-input>
          </template>
          <template v-else>
            <el-input
                v-model="form.emails[index].email"
                class="add_inputs"
            ></el-input>
          </template>
          <div
              class="setting_box"
              v-if="
              title.indexOf('电话') != -1
                ? form.phones.length > 1
                  ? true
                  : false
                : form.emails.length > 1
                ? true
                : false
            "
          >
            <el-button @click="removePhone(index,title.indexOf('电话') != -1?form.phones[index]:form.emails[index])"
                       type="danger" link
            >{{ $t('common.delete') }}
            </el-button
            >
            <el-button
                v-if="!item.isDefault"
                @click="checkItem(index)"
                type="primary"
                link
            >{{ $t('common.setdefault') }}
            </el-button
            >
          </div>
          <div v-else class="setting_box"></div>
        </el-form-item>
        <div class="add_button">
          <el-button @click="addPhone" type="primary">{{ title }}</el-button>
        </div>
      </el-form>
      <!-- <div><strong>Form Data:</strong> {{ form }}</div> -->
    </div>
  </el-dialog>
</template>

<script setup>
import {ElMessage} from "element-plus";
import {defineProps, ref, computed} from "vue";
import {useStore} from "vuex";

const store = useStore();
const {label, title} = defineProps({
  label: {
    type: String,
    default: "",
  },
  title: {
    type: String,
    default: "",
  },
});

// 对话框
let dialogVisible = ref(false);
const form = computed(() => store.state.customer.form);

const addPhone = () => {
  const targetArray = title.indexOf("电话") !== -1 ? "phones" : "emails";
  const targetKey = title.indexOf("电话") !== -1 ? "phoneNumber" : "email";
  console.log(form.value[targetArray]);
  if (
      form.value[targetArray].filter((item) => item[targetKey] === "").length > 0
  ) {
    ElMessage.error("请填写后再添加");
  } else {
    store.commit("customer/addEmailPhoneItem", {title});
  }
};
const removePhone = (index, item) => {
  console.log(item, 'item')
  store.commit("customer/removeEmailPhoneItem", {index, title, item});
};

const checkItem = (selectedIndex) => {
  store.commit("customer/findSelectIndex", {selectedIndex, title});
};
</script>

<style lang="less" scoped>
.add_inputs {
  min-width: 120px;
}

.my_labels {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 30px;
}

:deep(.el-dialog) {
  min-width: 30% !important;
}

:deep(.el-form-item) {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 16px !important;
}

:deep(.el-form-item__content) {
  align-items: center;
  flex-wrap: nowrap !important;
}

.setting_box {
  width: 124px;
  min-width: 124px;
  max-width: 124px;
  display: block;
}

.add_button {
  margin-top: 20px !important;
  display: flex;
  justify-content: flex-start;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
