<template>
  <div
      class="temp rlt"
      :style="{ background: `url('${backgroundImages}') no-repeat` }"
  >
    <div class="sub">{{ dataMessage.titles }}</div>
    <div class="sum">
      <span class="blue">{{ isByte ? formatSize(dataMessage.sum) : dataMessage.sum }}</span><sub
        class="blue">{{ isByte ? '' : '个' }}</sub><span>/</span
    ><span>{{
        isByte ? formatSize(dataMessage.allCount) : dataMessage.allCount
      }}</span><sub>{{ isByte ? '' : '个' }}</sub>
    </div>
    <div class="progressBar">
      <el-progress class="prog" :striped-flow="true" :percentage="computedPercentage" :color="customColorMethod"/>
    </div>
    <div class="fx">
      <el-button class="btn bug" @click="handleClickBuy" :disabled="true">立即购买
        <!--      <el-button class="btn bug" @click="handleClickBuy" :disabled="dataMessage.titles!=='工位'?true:false">立即购买-->
      </el-button>
      <el-button class="btn dt" style="margin-left: 10px" @click="handleClickTarget">查看详情
      </el-button>
    </div>
  </div>
</template>

<script setup>
import {computed, defineProps, ref, defineEmits} from 'vue'
import {string} from "mockjs/src/mock/random/basic";
import {formatSize} from "../../../utils/file";

const emits = defineEmits(["clickBuy", "clickTarget"])
const {backgroundImages, dataMessage, isByte} = defineProps({
  backgroundImages: {
    type: string,
    default: ''
  },
  dataMessage: {
    type: Object,
    default: () => {
    }
  },
  isByte: {
    type: Boolean,
    default: false
  }
})
// 计算百分比
const computedPercentage = computed(() => {
  const {sum, allCount} = dataMessage
  console.log(sum, allCount, dataMessage, 'ff')
  return sum / allCount * 100
})

const handleClickBuy = () => {
  emits("clickBuy")
}
const handleClickTarget = () => {
  emits("clickTarget")
}
</script>

<style lang="less" scoped>
.temp {
  display: inline-block;
  width: 360px;
  height: 151px;
  margin-top: 20px;
  margin-left: 30px;
  padding: 20px;
  overflow: hidden;
}

.rlt {
  position: relative;
}

.sub {
  font-size: 16px;
  font-weight: 700;
  color: #333;
  line-height: 1;
  margin-bottom: 10px;
}

.sum {
  color: #333;
  vertical-align: sub;
  font-weight: 500;
  line-height: 1;

  span {
    font-size: 24px;
    vertical-align: sub;
    line-height: 1;
  }

  .sub {
    font-size: 12px;
  }
}

.blue {
  color: #1d39c4;
}

.progressBar {
  width: 220px;
  height: 9px;
  background: #fff;
  border-radius: 9px;
  position: relative;
  margin: 12px 0;
}

.fx {
  display: flex;
}

.btn {
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 19px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.bug {
  color: #fff;
  background: #d93737;
}

.dt {
  color: #1d39c4;
  border: 1px solid #1d39c4;
}

:deep(.el-progress__text) {
  display: none !important;
}

:deep(.el-progress-bar__outer) {
  height: 10px !important;
}

:deep(.el-progress-bar__inner) {
  background: #dfe0e2 !important;
}

</style>
