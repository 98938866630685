<template>
  <ElDialog
      v-model="isShow"
      :title="'导入群发列表'"
      :footer="null"
      centered
      top="6em"
      :width="'40%'"
      style="min-width:350px"
      destroyOnClose
      @cancel="close"
  >

    <el-upload
        ref="uploadRef"
        class="upload-demo"
        action=""
        :accept="['.xls','.xlsx']"
        :auto-upload="false"
        :show-file-list="false"
        :on-change="handleUploadChange"
    >
      <el-button round type="primary">选择文件</el-button>
    </el-upload>
    <div style="margin-top: 20px;margin-bottom: 10px">共 {{ showTargetEmails.length }} 条 数据</div>
    <el-scrollbar height="300">
      <div style="margin-top: 20px">
        <div v-for="item in showTargetEmails">
          <p>
            <span>联系人姓名：</span><span>{{ item.name }}</span>&nbsp;&nbsp;&nbsp;&nbsp;
            <span>联系人邮箱：</span><span>{{ item.email }}</span>
          </p>
        </div>
      </div>
    </el-scrollbar>
    <div class="settings">
      <ElButton @click="close" :loading="loading">取消
      </ElButton>
      <ElButton type="primary" @click="addToEmails" :loading="loading">确定
      </ElButton>
    </div>

  </ElDialog>
</template>

<script setup>
import {ElButton, ElDialog, ElMessage} from "element-plus";
import {ref, defineProps, watch, defineEmits, computed} from 'vue'
import {useStore} from "vuex";
import * as XLSX from 'xlsx/xlsx.mjs';

const store = useStore();
const emit = defineEmits()
const isShow = ref(false);
const teamName = ref("")
const loading = ref(false);
// 选中的数组
const checkList = ref([])

const {value, isChecked} = defineProps({
  value: {type: Boolean, default: false},
  isChecked: {type: Array, default: []}
})

watch(() => value, (val) => {
  isShow.value = val;
}, {immediate: true})

const teamId = computed(() => store.state.email.teamID);
const isLoading = ref(false);

const showTargetEmails = ref([])

async function handleUploadChange(file) {
  let dataBinary = await readFile(file.raw);
  let workBook = XLSX.read(dataBinary, {type: "binary", cellDates: true});
  let workSheet = workBook.Sheets[workBook.SheetNames[0]];
  const data = XLSX.utils.sheet_to_json(workSheet);

  console.log('data', data)
  // 这里可能需要做国际化处理
  const allEmails = extractEmails(data)
  console.log(allEmails, 'allEmails')
  // 这里可能需要做验证--校验
  showTargetEmails.value = [...showTargetEmails.value, ...allEmails.filter(item => item.email !== undefined)];

  // console.log(allEmails); //最终获取JSON数据
  // ...操作数据
}

function extractEmails(data) {
  return data.map(function (entry) {
    return {
      name: `(${entry[`联系人姓名（没有姓名则留白即可）`]})`, email: entry[`联系人邮箱（一行填一个邮箱）`]
    };
  });
}

const readFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (e) => {
      resolve(e.target.result);
    };
    reader.onerror = (e) => {
      reject(e);
    };
  });
}

//收集邮箱并添加
const addToEmails = () => {
  if (showTargetEmails.value.length !== 0) {
    emit('addTo', showTargetEmails.value)
  } else {
    ElMessage.warning('请您检查您的Excel文档是否正确，然后再次尝试')
  }
}

const checkoutChange = () => {
  // console.log(checkList, 'checkoutChange')
}


const close = () => {
  emit("close");
}
</script>
<style scoped lang="less">
@import "../../less/page.less";

.fx-j-c {
  display: flex;
  justify-content: center;
}

.settings {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
}
</style>
