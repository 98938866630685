<template>
  <div class="left_calendar">
    <!--    {{ expectDayTime }}-->
    <!--    {{ queryData }}-->
    <div class="left_calendar_body">
      <el-calendar ref="calendar" v-model="targetDate">
        <!--日历的header-->
        <template #header="{ date }">
          <el-button size="small" @click="selectDate('prev-month',date)">
          </el-button>
          <span id="top_date">
<!--          <span class="top_date_before">{{ date }}</span>-->
          <el-date-picker
              popper-class="date_picker_down"
              v-model="pickerDate"
              type="month"
              :clearable="false"
              @change="checkChangeDate"
          />
          </span>
          <el-button size="small" @click="selectDate('next-month',date)">
          </el-button>
        </template>
        <template #date-cell="{ data }">
          <div style="width: 28px;height: 28px;box-sizing: border-box" :class=" data.isSelected ?
          'is-selected sp' : 'normal'">
            <p :class=" data.isSelected ?
          'is-selected' : ''"
               style="position: relative;color: #1b202b;font-size: 14px;font-weight: 800;width: 28px;height: 28px;box-sizing: border-box;">
              <span
                  style="display: flex;flex-direction: column;align-items: center;justify-content: center;height: 28px;width: 28px;">{{
                  data.day.slice(8)
                }}</span>
              <div class="out_box">
                <template v-for="(item, index) in expectDayTime" :key="item.alertTime">
                  <template v-if="data.day.slice(0,10).replace(/\s/g, '') === item.alertTime.replace(/\s/g, '')"
                  >
                    <template v-if="item.flag===1">
                      <div
                          class="budge_urgent"></div>
                    </template>
                    <template v-if="item.flag===0">
                      <div
                          class="budge_ordinary"></div>
                    </template>
                    <template v-if=" item.flag===2">
                      <div
                          class="budge_urgent"></div>
                      <div
                          class="budge_ordinary"></div>
                    </template>
                  </template>
                </template>
              </div>
            </p>
          </div>
        </template>
      </el-calendar>
      <div class="list_cal">
        <div v-show="handleSelect.length!==0" style="margin-top: 20px">
          <ul v-for="(item,index) in handleSelect" :key="index">
            <li @mouseenter="handleMouseEnter(item,index)" @mouseleave="handleMouseLeave(item,index)">
              <div class="outer_box">
                <div :class="[item.isUrgent===0? 'times':'times_sp']">{{
                    item.secondTime === "" || item.secondTime === null ? $t('sys.todo.none') : item.secondTime
                  }}
                </div>
                <div
                    :class="[item.isUrgent===0? !item.isMouseEnter?'text_cal':'text_background':!item.isMouseEnter?'text_sp':'text_sp_background']">
                  <span>{{ item.content }}</span>
                  <transition name="fade">

                  <span class="settings"
                        v-show="item.isMouseEnter"><span><el-icon @click="handleEdit(item)" style="color: white"><Edit/></el-icon></span><span><el-icon
                      @click="handleDelete(item,index)" style="color: white"><Delete/></el-icon></span>
                  </span>
                  </transition>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!--      内部元素-->
      <!--      <div>{{ handleSelect }}</div>-->
      <div v-show="handleSelect.length===0" style="line-height: 40px;font-size: 14px">{{
          $t('common.todo.none')
        }}
      </div>
      <div style="margin-top: 20px">
        <el-button type="primary" @click="handleAdd">{{ $t('common.need.create') }}</el-button>
      </div>
    </div>
    <Dialog
        v-model="dialogVisible"
        :title="!isEdit?$t('common.todo.add'):$t('common.todo.modify')"
        :dialogVisible="dialogVisible"
        :isHeadLine="true"
        :width="600"
        @update:dialogVisible="(val)=>dialogVisible = val"
    >
      <template #content>
        <div class="inner_items">
          <div class="items">
            <div class="sp_titles">{{ $t('common.todo.time') }}:</div>
            <div class="flex_start">
              <el-radio-group v-model="forms.isSetting">
                <el-radio :label="0">{{ $t('common.todo.noset') }}</el-radio>
                <el-radio :label="1">{{ $t('common.todo.settime') }}</el-radio>
                <el-time-picker
                    format='HH:mm'
                    v-model="forms.pickTimes"
                    :picker-options="{
                      selectableRange: '24:00 - 00:00'
                    }"
                    focus
                >
                </el-time-picker>
              </el-radio-group>
            </div>
          </div>
          <div class="items">
            <div class="sp_titles">{{ $t('common.todo.details') }}:</div>
            <div class="flex_start">
              <el-input type="textarea" :placeholder="$t('sys.tip.todo.input.detail')"
                        v-model="forms.content"></el-input>
            </div>
          </div>
          <div class="items">
            <div class="sp_titles">{{ $t('common.todo.priority') }}:</div>
            <div class="flex_start">
              <el-radio-group v-model="forms.isUrgent">
                <el-radio :label="0">{{ $t('common.normal') }}</el-radio>
                <el-radio :label="1">{{ $t('common.urgent') }}</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="footers">
          <el-button @click="dialogVisible=false">{{ $t('common.sss16') }}</el-button>
          <el-button type="primary" @click="saveDataList">{{ $t('common.confirm') }}</el-button>
        </div>
      </template>
    </Dialog>

    <!--删除-->
    <Dialog
        v-model="deleteDialog"
        :width="'400'"
        :dialogVisible="deleteDialog"
        :isHeadLine="false"
        @update:dialogVisible="(val) => (deleteDialog = val)"
    >
      <template #content>
        <div class="cont">
          <span><el-icon style="color:#efb041;font-size: 20px;margin-right: 10px;"><QuestionFilled/></el-icon></span>
          <span class="text">{{ $t('sys.todo.del') }}</span>
        </div>
      </template>
      <template #footer>
        <div class="setting_left">
          <el-button @click="deleteDialog = false">{{ $t('common.sss16') }}</el-button>
          <el-button type="primary" @click="deleteTarget">{{ $t('common.confirm') }}</el-button>
        </div>
      </template>
    </Dialog>
  </div>
</template>
<script setup>
import './css/elcalender.css'
import {ref, computed, onMounted, watch, nextTick, toRefs,} from 'vue'
import Dialog from "@/components/Dialog.vue";
import dayjs from 'dayjs'
import {useStore} from "vuex";

const {pickerDate, targetDate} = toRefs({
  pickerDate: ref(new Date()),
  targetDate: ref(new Date())
});
// 表单数据
const forms = ref({isSetting: 0, pickTimes: '', content: '', isUrgent: 0,})

const dayTime = computed(() => store.state.calendar.dateList)
const expectDayTime = computed(() => store.getters['calendar/expectDayTime'])
const teamId = computed(() => store.state.email.teamID)

onMounted(() => {
  store.dispatch('calendar/getCalendarListApis', {
    alertTime: dayjs(targetDate.value).format("YYYY-MM").replace(/\s/g, '')
  })
  store.dispatch('calendar/queryByIdApis', {alertTime: dayjs(targetDate.value).format('YYYY-MM-DD').replace(/\s/g, '')})
})

const store = useStore()

// 代办事项是否是 编辑
const isEdit = ref(false)
const queryData = computed(() => store.state.calendar.queryData)
const tags = ref(false)
// 选中的日期
const handleSelect = computed(() => {
  return queryData.value;
});
watch(() => targetDate.value, async (newTargetDate) => {
  try {
    await store.dispatch('calendar/queryByIdApis', {alertTime: dayjs(newTargetDate).format('YYYY-MM-DD').replace(/\s/g, '')});
  } catch (error) {
    // console.error('Error while fetching data:', error);
  }
});

// 添加
const dialogVisible = ref(false)
const handleAdd = () => {
  dialogVisible.value = true;
  isEdit.value = false

}


// 删除 代办事项
const deleteDialog = ref(false);
const delListTargetId = ref('');
const handleDelete = async (item, index) => {
  const {id} = item;
  deleteDialog.value = true;
  delListTargetId.value = id;
};

const deleteTarget = async () => {
  try {
    // Delete calendar item
    await store.dispatch('calendar/deleteCalendarApis', {id: delListTargetId.value});

    // Query by id
    await store.dispatch('calendar/queryByIdApis', {
      alertTime: dayjs(targetDate.value).format('YYYY-MM-DD').replace(/\s/g, '')
    });

    // Get calendar list only if queryByIdApis was successful
    await store.dispatch('calendar/getCalendarListApis', {
      alertTime: dayjs(targetDate.value).format("YYYY-MM").replace(/\s/g, '')
    });
    deleteDialog.value = false;
  } catch (error) {
    // Handle error here
    // console.error("Error occurred while handling delete:", error);
    // Optionally, you can notify the user about the error
  }
}


// 待添加的数组
const willAddList = ref([])
const saveDataList = async () => {
  if (isEdit.value) {
    // 编辑
    const {id, alertTime} = activeItem.value
    // const res = dayTime.value.filter(item => item.id === id)[0]
    const {isUrgent, pickTimes, content, isSetting} = forms.value

    const targetObj = {
      id,
      content,
      secondTime: isSetting === 1 ? dayjs(pickTimes).format('HH:mm') : '',
      isUrgent,
      isSetting,
      alertTime: alertTime.replace(/\s/g, ''),
    }
    await store.dispatch('calendar/updateCalendarApis', targetObj)

    dialogVisible.value = false;
    isEdit.value = false
    await nextTick(); // 等待状态更新完成
  } else {
    // 添加
    const {isUrgent, pickTimes, isSetting, content} = forms.value
    const targetObj = {
      isUrgent,
      isSetting,
      secondTime: isSetting === 1 ? dayjs(pickTimes).format('HH:mm') : '',
      alertTime: dayjs(targetDate.value).format("YYYY-MM-DD").replace(/\s/g, ''),
      content,
    }

    // console.log(targetObj, 'value')

    await store.dispatch('calendar/addCalendarApis', targetObj)

    dialogVisible.value = false;
    isEdit.value = false;
  }
  await store.dispatch('calendar/queryByIdApis', {alertTime: dayjs(targetDate.value).format('YYYY-MM-DD').replace(/\s/g, '')})
  await store.dispatch('calendar/getCalendarListApis', {
    alertTime: dayjs(targetDate.value).format("YYYY-MM").replace(/\s/g, '')
  })
  forms.value = {isSetting: 0, pickTimes: '', content: '', isUrgent: 0}
}

// 鼠标移入移出
const handleMouseEnter = (item, index) => {
  item.isMouseEnter = true
}
const handleMouseLeave = (item, index) => {
  item.isMouseEnter = false
}


// 编辑选中代办的项
const activeItem = ref({})
const handleEdit = (item) => {
  isEdit.value = true
  dialogVisible.value = true
  const {id, isUrgent, secondTime, alertTime, content, isSetting} = item;
  // console.log(item, 'item')
  const targetObj = {
    id,
    isUrgent,
    pickTimes: secondTime,
    isSetting,
    content,
    alertTime,
  }

  // console.log(targetObj, 'targetObj')
  forms.value = item;
  activeItem.value = item;
}


// 日历对象
const calendar = ref(null)
const selectDate = async (val, date) => {
  if (!calendar.value) return
  calendar.value.selectDate(val)
  let year = date.split(' 年')[0]
  let mouth = date.split('年 ')[1].split(' 月')[0]
  const targetDate = `${year}-${mouth}`
  // 日期修正
  // console.log(val, targetDate, 'ff', date)
  let res = new Date(pickerDate.value);
  if (val === "prev-month") {
    pickerDate.value = res.setMonth(res.getMonth() - 1)
  } else if (val === "next-month") {
    pickerDate.value = res.setMonth(res.getMonth() + 1)
  }
  await store.dispatch('calendar/getCalendarListApis', {
    alertTime: dayjs(pickerDate.value).format("YYYY-MM").replace(/\s/g, '')
  });
}

// 选择日期和日历时间保持一致
const selectFlag = ref(false)
const checkChangeDate = async (val) => {
  // console.log(val);
  const checkDate = new Date(val)
  pickerDate.value = checkDate;
  targetDate.value = checkDate
  await store.dispatch('calendar/getCalendarListApis', {
    alertTime: dayjs(pickerDate.value).format("YYYY-MM").replace(/\s/g, '')
  });
}

watch(() => pickerDate.value, (value, pre) => {
  // console.log(new Date(value), new Date(pre))
  pickerDate.value = value;
})

</script>

<style lang="less" scoped>
@import "less/index_canlender.less";

#sendRequire {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.cont {
  display: flex;

  .text {
    display: block;
    overflow: hidden;
    color: rgba(0, 0, 0, .85);
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;
  }
}

.setting_left {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

//.outer_box .text_cal {
//  line-height: 24px !important;
//}
//
//.outer_box .text_sp {
//  line-height: 24px !important;
//}
</style>
