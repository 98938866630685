import request from "@/utils/request";

//分页查询企业用户
export function getCompanyUserList(params, config) {
    return request.get("/front/mailFrontUser/queryUserByCompanyId", params, config);
}

// 获取 团队成员列表--分页
export function getTeamUserList(params, config) {
    return request.get("/front/mailTeamMember/getTeamMemberList", params, config);
}

// 获取 团队成员列表 -- 全部--有权限 ---select
export function getTeamUserListAll(params, config) {
    return request.get("/front/mailTeamMember/list", params, config);
}

// 获取 团队成员列表 -- 全部--没有权限
export  function getAllTeamMember(params,config){
    return request.get("/front/mailTeamMember/getAllTeamMember",params,config)
}

// 获取 当前人所在团队下的所有成员列表（去重） -- 全部--没有权限
export function getCloudTeamMemberList() {
    return request.get("/front/mailTeamMember/getCloudTeamMemberList");
}


// 添加 团队成员列表
export function addTeamUser(params, config) {
    return request.post("/front/mailTeamMember/add", params, config);
}

// 修改 团队成员
export function updateTeamUser(params, config) {
    return request.put("/front/mailTeamMember/edit", params, config);
}

// 删除团队成员
export function delTeamUser(params, config) {
    const {id} = params;
    return request.delete(
        `/front/mailTeamMember/delete?id=${id}`,
        params,
        config
    );
}

// 获取团队部门
export function getTeamDepartment(params, config) {
    return request.get("/front/mailTeamDepartment/getTeamDeptList", params, config);
}

// 添加团队部门
export function addTeamDepartment(params, config) {
    return request.post("/front/mailTeamDepartment/add", params, config);
}

// 修改部门
export function updateTeamDepartment(params, config) {
    return request.put(`/front/mailTeamDepartment/edit`, params, config);
}

// 根据id查找部门
export function findTeamDepartmentById(params, config) {
    return request.get("/front/mailTeamDepartment/queryById", params, config);
}

// 删除团队部门
export function delTeamDepartment(params, config) {
    const {id} = params;
    return request.delete(
        `/front/mailTeamDepartment/delete?id=${id}`,
        params,
        config
    );
}


// 添加外部成员
export function addOutsideUser(params, config) {
    return request.get('/front/mailTeamMember/addExternalPerson', params, config)
}

// 查询外部成员
export function getOutsideUser(params, config) {
    return request.get('/front/mailTeamMember/queryExtendPerson', params, config)
}


// 删除团队
export function delTeam(params, config) {
    const {id} = params;
    return request.delete(`/front/mailTeam/delete?id=${id}`)
}

