<template>
  <div id="Card" ref="card">
    <el-card :title="title" :bordered="bordered" :style="{height:height}">
      <template #header v-if="isShowTitle">
        <div class="card-header">
          <span>{{ title }}</span>

          <div v-if="isShowMore">
            <el-dropdown trigger="click" v-if="isSuperAdmin">
        <span class="el-dropdown-link">
          <el-icon>
              <MoreFilled/>
            </el-icon>
        </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="changeTeamNameDia">{{ $t('common.team.modifyName') }}</el-dropdown-item>
                  <el-dropdown-item @click="deleteTeams">
                    <el-button link type="danger">{{ $t('common.team.deleteTeam') }}</el-button>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
      </template>
      <slot></slot>
    </el-card>
  </div>


  <Dialog
      v-model="dialogVisibleChangeTeam"
      :title="$t('common.team.modifyName')"
      :width="'max-content'"
      :dialogVisible="dialogVisibleChangeTeam"
      :isHeadLine="true"
      @update:dialogVisible="(val) => (dialogVisibleChangeTeam = val)"
  >
    <template #content>
      <div class="my_forms">
        <div>{{ $t('common.team.name') }}：</div>
        <div>
          <el-input v-model="inputTeamName"/>
        </div>
      </div>
    </template>
    <template #footer>
      <div id="sendRequire">
        <el-button type="primary" @click="changeTeamName">{{ $t('common.confirm') }}</el-button>
      </div>
    </template>
  </Dialog>


  <!--  确定删除当前团队-->
  <Dialog
      v-model="dialogVisibleEmailBind"
      :width="'400'"
      :dialogVisible="dialogVisibleEmailBind"
      :isHeadLine="false"
      @update:dialogVisible="(val) => (dialogVisibleEmailBind = val)"
  >
    <template #content>
      <div class="cont">
        <span><el-icon style="color:#efb041;font-size: 20px;margin-right: 10px;"><QuestionFilled/></el-icon></span>
        <span class="text">{{ $t('common.team.sureToDeleteCurrentTeam') }}</span>
      </div>
    </template>
    <template #footer>
      <div class="setting_left">
        <el-button @click="dialogVisibleEmailBind = false">{{ $t('common.sss16') }}</el-button>
        <el-button type="primary" @click="deleteTeamsClick">{{ $t('common.confirm') }}</el-button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import {ElCard, ElRow, ElCol, ElMessage} from "element-plus";
import Dialog from "@/components/Dialog.vue";
import {computed, onMounted, ref} from "vue";
import {V2TeamEdit} from "@/api";
import {useStore} from "vuex";
import {delTeam} from "@/api/groups";
import {useRouter} from "vue-router";
import sessionStorageUtil from "@/utils/sessionStorageUtil";

export default {
  name: "Card",
  components: {
    ElCard,
    ElRow,
    ElCol,
    Dialog
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    isShowTitle: {
      type: Boolean,
      default: false,
    },
    isShowMore: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: 'max-content'
    }
  },
  setup(props) {
    const router = useRouter();
    const dialogVisibleChangeTeam = ref(false);
    const inputTeamName = ref("");
    const store = useStore()
    const teamId = computed(() => store.state.email.teamID)
    const dialogVisibleEmailBind = ref(false);
    const teamList = computed(() => store.state.email.teamList)
// 判断是否是超级管理员如果是的话 就展示
    const isSuperAdmin = computed(() => store.getters["user/isSuperAdmin"])

    function changeTeamNameDia() {
      inputTeamName.value = props.title;
      dialogVisibleChangeTeam.value = true
    }

    async function changeTeamName() {
      console.log("修改团队名称");
      const res = await V2TeamEdit({
        id: teamId.value,
        name: inputTeamName.value
      })
      if (res.code !== 200) {
        ElMessage.error(res.message);
        return
      }
      ElMessage.success(res.message)
      dialogVisibleChangeTeam.value = false;
      inputTeamName.value = ''
      await store.dispatch('email/getTeamList')

    }

    async function deleteTeams() {
      dialogVisibleEmailBind.value = true
    }

    async function deleteTeamsClick() {
      const res = await delTeam({
        id: teamId.value
      })
      if (res.code !== 200) {
        ElMessage.error(res.message);
        return
      }
      ElMessage.success(res.message)
      dialogVisibleEmailBind.value = false;
      await executeCodeInOrder();
    }

    async function executeCodeInOrder() {
      // 获取工位列表
      if (teamList.value.length !== 0) {
        try {
          // 1. 执行 store.commit
          await store.commit('email/setCurTeamID', '');

          // 2. 执行 store.dispatch
          await store.dispatch('email/getTeamList', {isUpdate: true})

          // 3. 设置 localStorage
          localStorage.setItem('tabIndex', 0);

          // 清除 本地 session 中 缓存的数据
          sessionStorageUtil.clear()
          //   获取权限
          await store.dispatch("user/checkUserInfoIsAdmin", {teamId: teamId.value})

          // 4. 使用 Promise 来延时，以确保前面的操作完成
          await new Promise(resolve => setTimeout(resolve, 50));

          // 5. 执行 location.reload()
          location.reload();
        } catch (error) {
          console.error('An error occurred:', error);
        }
      } else {
        try {
          // 清除teamId
          await store.commit('email/setCurTeamID', '')
          router.push('/dashboard')
          // 4. 使用 Promise 来延时，以确保前面的操作完成
          await new Promise(resolve => setTimeout(resolve, 50));
          // 5. 执行 location.reload()
          location.reload();
        } catch (error) {
          console.error('An error occurred:', error);
        }
      }
    }

    onMounted(() => {
      inputTeamName.value = props.title;
    })
    return {
      changeTeamName,
      inputTeamName,
      dialogVisibleChangeTeam,
      changeTeamNameDia,
      deleteTeams,
      dialogVisibleEmailBind,
      deleteTeamsClick,
      isSuperAdmin,
    }
  }
};

</script>

<style scoped>
#Card .el-card__body {
  height: 100%;
  padding: 24px !important;
  display: flex;
  flex-direction: column;
}

:deep(.el-card__body) {
  padding: 24px !important;
}

#Card {
  padding: 16px 16px 0 16px !important;
}

:deep(.el-card) {
  border: none;
  box-shadow: none;
}

.card-header {
  color: rgba(0, 0, 0, .85);
  font-weight: 500;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}


#sendRequire {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.inners_warning {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    margin-bottom: 10px;
  }
}

.setting_center {
  display: flex;
  justify-content: center;
}

.my_forms {
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > div {
    line-height: 30px;
  }
}

#sendRequire {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.cont {
  display: flex;

  .text {
    display: block;
    overflow: hidden;
    color: rgba(0, 0, 0, .85);
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;
  }
}

.setting_left {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

</style>
