import {configure, start, done} from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

import router from "./router";
import {getToken} from "./utils/auth"; // get token from cookie
import getPageTitle from "./utils/get-page-title";
import store from './store/index'
import {checkLoginTime} from "@/utils/cleanRest";
import {ElMessage, ElMessageBox} from "element-plus";
import {loadingConfig, routerLoading} from "@/utils/loading";
import glo_loading from "@/utils"

configure({showSpinner: false}); // NProgress Configuration

const whiteList = ["/login", "/register", "/companyRegister", "/forgetPassword", "/guide", "/settingguide"]; // no redirect whitelist

router.beforeEach(async (to, from, next) => {
    if ((from.path === "/email/customer/create" || from.path === "/email/write_mail" || from.path === "/email/send_all") && to.path !== "/success" && to.path !== "/email/customer/list" && to.path !== "/email/examine") {
        if (to.path !== "/login" && to.path !== "/email/draft") {

            const confirmed = await confirmLeave();
            if (confirmed) {
                next();
            } else {
                next(false);
            }
        } else {
            next()
        }
    } else {
        // checkLoginTime();
        cancelAxiosRequests();
        startProgressBar();
        start();
        const hasToken = getToken();
        if (hasToken) {
            console.log("已登录")
            // 设置 page 页面 title
            setPageTitle(to.meta.title);
            if (to.path === "/login") {
                next({path: "/"});
            } else {
                // if (to.path !== "/success") {
                //     // routerLoading ? glo_loading.loadingShow(loadingConfig) : ""
                // }
                next();
            }
        } else {
            if (whiteList.includes(to.path)) {
                console.log("未登录", whiteList.includes(to.path))
                next();
            } else {
                console.log('未登录2')
                const index = to.path.indexOf("/cloud/download/");
                console.log(to.path, index,
                    'to.path2'
                )
                if (index !== -1 && !localStorage.getItem("key")) {
                    const targetValue = window.location.hash.substring(index + "/cloud/download/".length + 1)
                    localStorage.setItem('key', targetValue)
                    next("/login");
                    // next()
                } else {
                    next("/login");
                    // next()
                }

            }
        }
    }

    // routerLoading ? glo_loading.loadingHide() : ''//关闭loading层

});

async function confirmLeave() {
    return ElMessageBox.confirm(
        '当前页面修改数据未保存，确定离开吗?',
        '温馨提示',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }
    ).then(() => true).catch(() => false);
}

function cancelAxiosRequests() {
    if (Array.isArray(window.axiosCancelTokenList)) {
        window.axiosCancelTokenList.forEach((e) => e("cancel request"));
        window.axiosCancelTokenList = [];
    }
}

function startProgressBar() {
    // Your logic for starting progress bar
}

function setPageTitle(title) {
    document.title = getPageTitle(title);
}

router.afterEach((to, from, next) => {
    done();
});
