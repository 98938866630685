<template>
  <Guide :titleList="titleList">
    <template #innerContent>
      <div style="width: 100%;height: 50px;margin-top: 30px">
      </div>
      <h1>关于Mailtalk的使用指南</h1>
      <div
          id="part1"
          style="
              height: max-content;
              margin-top: 30px;
            "
      >
        <h2><span @click="isShow1=!isShow1"><el-icon v-if="!isShow1"><CaretRight/></el-icon><el-icon v-else><CaretBottom/></el-icon></span>
          1、注册登录
        </h2>
        <el-collapse-transition>
          <div v-show="isShow1">
            <p class="bold">目前Mailtalk提供两种账户类型，分别是个人账户和企业账户，对应主界面的两个登录/注册入口</p>
            <ImageShow :url="docImg1"></ImageShow>
            <p class="bold">
              两者的核心功能，比如邮件收发、客户管理等等，均没有大的区别，企业账号主要比个人账号多一个子账号创建和管理功能，不同人群提供合适的方案，个人账号适用于SOHO或者个人小团队，企业账号适用于常规企业，负责人可以为员工开设子账号，方便统一管理。
            </p>
            <div style="width: 100%;height: 40px"></div>
            <p class="bold">
              个人账号，填写手机和密码，完成短信验证码验证，即可完成注册，并且用手机登录
            </p>
            <ImageShow :url="docImg2"></ImageShow>
            <div style="width: 100%;height: 40px"></div>
            <p class="bold">
              企业账号，除了填写手机和密码以外，还需填写企业信息，包括公司账户名称、创建者账户名称，以及公司中文全称和所在地，其中，公司全称仅作为备注登记的信息，暂不直接用于账户登录和验证。
            </p>
            <p class="bold">
              <span class="red">注意：</span>公司账户和创建者账户，将作为企业账号的登录依据，可参考下图，公司账户用品牌缩写，创建者用人名或称呼，方便后续使用。
            </p>
            <ImageShow :url="docImg3"></ImageShow>
            <ImageShow :url="docImg4"></ImageShow>
          </div>
        </el-collapse-transition>
      </div>
      <div
          id="part2"
          style="
              height:max-content; margin-top: 30px;
            "
      >
        <h2><span @click="isShow2=!isShow2"><el-icon v-if="!isShow2"><CaretRight/></el-icon><el-icon v-else><CaretBottom/></el-icon></span>
          2、团队创建
        </h2>
        <el-collapse-transition>
          <div v-show="isShow2">
            <p class="bold">
              进入后台第一步，根据引导创建团队，团队可理解为一家公司或者一个项目组。账号下的团队均可在后台管理，团队管理员可以拉成员进团队，方便协同工作，且管理员享有较高的操作权限。
            </p>
            <ImageShow :url="docImg5"></ImageShow>
            <ImageShow :url="docImg6"></ImageShow>
            <p class="bold">
              后台-设置-团队管理，可以修改/删除团队，可以添加创建不同部门，可以邀请成员加入，等等。比如按需创建团队内的不同部门，业务部、后勤部、财务部，邀请不同成员加入写作，邀请成员需消耗工位。
            </p>
            <p class="bold">
              页面最左侧可以点击红色加号继续添加新团队，可以通过标签实现团队切换，并且不同团队之间数据，包括邮件数据、客户、成员等均不互通，可以理解为两家独立的企业。
            </p>
            <ImageShow :url="docImg7"></ImageShow>
            <p class="bold">
              企业账户可以通过UID进行搜索添加，或者也可以直接邀请内部成员进团队，个人账号则仅可通过UID添加
            </p>
            <ImageShow :url="docImg8"></ImageShow>
            <ImageShow :url="docImg9"></ImageShow>
            <ImageShow :url="docImg10"></ImageShow>
            <ImageShow :url="docImg11"></ImageShow>
          </div>
        </el-collapse-transition>
      </div>
      <div
          id="part3"
          style="
              height: max-content;margin-top: 30px;
            "
      >
        <h2><span @click="isShow3=!isShow3"><el-icon v-if="!isShow3"><CaretRight/></el-icon><el-icon v-else><CaretBottom/></el-icon></span>
          3、账户管理
        </h2>
        <el-collapse-transition>
          <div v-show="isShow3">
            <p class="bold">
              进入后台，可点击右上角头像进入账户管理，其中头像、昵称等均可修改编辑。UID即为账户身份ID，可用于邀请进入团队。账户内的工位数、流量等均可在管理界面显示并续费。
            </p>
            <ImageShow :url="docImg12"></ImageShow>
            <ImageShow :url="docImg13"></ImageShow>
            <p class="bold">
              企业账户，还可在账户中心-子账户管理，添加/管理子账号，且子账号前缀为登录凭证，如下图所示
            </p>
            <ImageShow :url="docImg14"></ImageShow>
            <ImageShow :url="docImg15"></ImageShow>
            <ImageShow :url="docImg16"></ImageShow>
          </div>
        </el-collapse-transition>
      </div>
      <div
          id="part4"
          style="
              height: max-content;margin-top: 30px;
            "
      >
        <h2><span @click="isShow4=!isShow4"><el-icon v-if="!isShow4"><CaretRight/></el-icon><el-icon v-else><CaretBottom/></el-icon></span>
          4、邮箱绑定
        </h2>
        <el-collapse-transition>
          <div v-show="isShow4">
            <p class="bold">
              设置-邮箱设置-邮箱管理-添加邮箱，即可绑定新邮箱。
            </p>
            <p class="bold">
              <span class="red">注意：</span>
              授权码≠邮箱密码，授权码需要到邮箱后台去手动生成，通常是一串无序的英文字母组合，并且不同品牌的邮箱，授权码生成方式和位置均有差别，以实际为准。此处如有疑问，可点击绑定界面上方的“点这里”查看引导，或者咨询对应的邮箱客户经理。
            </p>
            <p class="bold">
              <span class="red">注意：</span>
              POP/IMAP/SMTP的服务器和端口，通常需要去对应品牌的邮箱官网和后台查找，或者百度搜索“XXX企业邮箱的POP和SMTP服务器”即可，不同品牌，服务器地址和内容都不一样，注意辨识。
            </p>
            <ImageShow :url="docImg17"></ImageShow>
            <ImageShow :url="docImg18"></ImageShow>
            <p class="bold">
              绑定成功后即可导入历史邮件，点击“邮件导入”，即可按需导入历史邮件。邮件数量比较多的话，第一次导入可能需要一些时间，不过历史邮件都会陆续添加进来的，并且不建议多次去点击导入按钮，这会使得导入任务重复再重复，反而耽误时间。部分邮箱，比如263需要前往邮箱后台修改同步时间限制，并且将自动归档的邮件移出。
            </p>
            <ImageShow :url="docImg19"></ImageShow>
            <p class="bold">
              后台可绑定多可邮箱，可以设定一个默认邮箱，并且可在下图所示位置，切换人员和邮箱，进行筛选显示。
            </p>
            <ImageShow :url="docImg20"></ImageShow>
            <ImageShow :url="docImg21"></ImageShow>
          </div>
        </el-collapse-transition>
      </div>
      <div
          id="part5"
          style="
              height: max-content;margin-top: 30px;
            "
      >
        <h2><span @click="isShow5=!isShow5"><el-icon v-if="!isShow5"><CaretRight/></el-icon><el-icon v-else><CaretBottom/></el-icon></span>
          5、邮件编辑
        </h2>
        <el-collapse-transition>
          <div v-show="isShow5">
            <p class="bold">
              主界面左上角可以写邮件并手动收邮件
            </p>
            <ImageShow :url="docImg22"></ImageShow>
            <p class="bold">
              模板功能：选择邮件模板，允许用户创建多个邮件模板，其中可设定其中一个为默认模板，每次写邮件会自动填充进来，签名档同样可以在模板中编辑，如下图示意。
            </p>
            <ImageShow :url="docImg23"></ImageShow>
            <p class="bold">
              邮件跟踪：提供两种邮件跟踪方式，已读回执和阅读跟踪。邮件可以勾选已读回执，通过收件人发送已读回执进行跟踪，也可以通过默认勾选阅读跟踪，会自动识别该邮件是否被收件人打开，如果被打开，在发件箱列表中会新增一个被阅读的绿色图标，并且显示详细的阅读信息。
            </p>
            <ImageShow :url="docImg24"></ImageShow>
          </div>
        </el-collapse-transition>
      </div>
      <div
          id="part6"
          style="
              height: max-content;margin-top: 30px;
            "
      >
        <h2><span @click="isShow6=!isShow6"><el-icon v-if="!isShow6"><CaretRight/></el-icon><el-icon v-else><CaretBottom/></el-icon></span>
          6、邮件群发
        </h2>
        <el-collapse-transition>
          <div v-show="isShow6">
            <p class="bold">
              左侧侧边栏-客户-客户群发，可以新建群发任务。提供两种群发渠道，第一种是系统群发，即直接通过用户本人的邮箱和服务器系统群发邮件，适合数量较少的任务。第二种是智能群发，通过Mailtalk服务器群发，更稳定。
            </p>
            <ImageShow :url="docImg25"></ImageShow>
            <ImageShow :url="docImg26"></ImageShow>
            <p class="bold">
              群发邮箱添加输入，01）可以逐一手动输入；02）针对已建档客户，允许通过列表勾选添加；03）可以通过下载预设的Excel表格模板，批量添加导入，注意：表格内一个单元格确保一个邮箱地址，且前后没有空格，否则可能导致无法顺利导入完整信息。
            </p>
            <ImageShow :url="docImg27"></ImageShow>
            <ImageShow :url="docImg28"></ImageShow>
            <p class="bold">
              群发邮件是默认一对一发送的，客户只会收到发给自己的邮件，并且可以在编辑框中插入关键词，以实现客户称呼单独显示，比如插入联系人姓名，邮件中即可自动填入成对应的联系人姓名（与建档客户的联系人，或者本地导入表格中填的联系人相挂钩）。
            </p>
            <ImageShow :url="docImg29"></ImageShow>
            <p class="bold">
              群发任务提交后，进度及统计均可在群发任务列表内查看。
            </p>
            <ImageShow :url="docImg30"></ImageShow>
          </div>
        </el-collapse-transition>
      </div>
      <div
          id="part7"
          style="
              height: max-content;margin-top: 30px;
            "
      >
        <h2><span @click="isShow7=!isShow7"><el-icon v-if="!isShow7"><CaretRight/></el-icon><el-icon v-else><CaretBottom/></el-icon></span>
          7、客户管理
        </h2>
        <el-collapse-transition>
          <div v-show="isShow7">
            <p class="bold">
              客户-客户管理，该版块内可以对建档客户进行管理，包括客户等级设置（推荐优先设置）、客户跟进设置（针对不同等级的客户，制定跟进周期，推荐设置）、客户分管设置（可划分客户分管人或者移入公海）等等。
            </p>
            <ImageShow :url="docImg31"></ImageShow>
            <ImageShow :url="docImg32"></ImageShow>
          </div>
        </el-collapse-transition>
      </div>
      <div
          id="part8"
          style="
              height: max-content;margin-top: 30px;
            "
      >
        <h2><span @click="isShow8=!isShow8"><el-icon v-if="!isShow8"><CaretRight/></el-icon><el-icon v-else><CaretBottom/></el-icon></span>
          8、客户建档
        </h2>
        <el-collapse-transition>
          <div v-show="isShow8">
            <p class="bold">
              客户建档管理，可以从收件箱、发件箱等版块的邮件上直接进行归档操作，或者通过客户-客户列表-手动新增。
            </p>
            <ImageShow :url="docImg33"></ImageShow>
            <p class="bold">
              客户列表手动新增，方法1：通过填写客户信息和联系人信息，逐一新增。
            </p>
            <ImageShow :url="docImg34"></ImageShow>
            <p class="bold">
              方法2：通过下载预设好的Excel表格实现批量建档。
            </p>
            <ImageShow :url="docImg35"></ImageShow>
            <ImageShow :url="docImg36"></ImageShow>
            <p class="bold">
              <span class="red">
                注意1：通过表格批量导入，Excel的文件名、表格标题、格式等等，都不能改动，否则会导致导入不成功。
              </span>
            </p>
            <p class="bold">
              <span class="red">
                注意2：国家，可以直接填，或者下拉菜单选择，但需要确保是正式用名，比如阿联酋，应为阿拉伯联合酋长国。
              </span>
            </p>
            <p class="bold">
              <span class="red">注意3：</span>
              注意3：公司电话、公司邮箱、联系人，<span class="red">一行一条，如有多个，可以添加多行填写，</span>其他信息，比如公司名称、客户类型等等，都需要合并单元格。<span
                class="red">如下图。</span>
            </p>
            <p class="bold">
              <span class="red">注意4：除了必填项，没有内容可以留白。并且建议尽量避免单元格内的内容错误和前后空格。</span>
            </p>
            <p class="bold">
              <span class="red">
                注意5：允许在编辑过程中修改格式内容或者删减行列，比如在编辑的时候，为了方便于添加其他信息，删掉公司网址一整列，这个不影响，但是最终上传前，请务必还原，跟标准样式一致，把公司网址一列加回来。
              </span>
            </p>
            <ImageShow :url="docImg37"></ImageShow>
          </div>
        </el-collapse-transition>
      </div>
      <div
          id="part9"
          style="
              height: max-content;margin-top: 30px;
            "
      >
        <h2><span @click="isShow9=!isShow9"><el-icon v-if="!isShow9"><CaretRight/></el-icon><el-icon v-else><CaretBottom/></el-icon></span>
          9、文件存储
        </h2>
        <el-collapse-transition>
          <div v-show="isShow9">
            <p class="bold">
              顶部第三个按钮进入云文件版块，可在此处上传、存储、分享、下载文件，方便管理和使用，功能类似线上网盘。
            </p>
            <ImageShow :url="docImg38"></ImageShow>
          </div>
        </el-collapse-transition>
      </div>
      <div
          id="part10"
          style="
              height: max-content;margin-top: 30px;
            "
      >
        <h2><span @click="isShow10=!isShow10"><el-icon v-if="!isShow10"><CaretRight/></el-icon><el-icon v-else><CaretBottom/></el-icon></span>
          10、补充说明
        </h2>
        <el-collapse-transition>
          <div v-show="isShow10">
            <p class="bold">
              如有其他疑问或者问题，可随时咨询客户经理，谢谢！！
            </p>
          </div>
        </el-collapse-transition>
      </div>
      <div style="width: 100%;height: 300px;margin-top: 30px">
      </div>
    </template>

  </Guide>
</template>

<script setup>
import docImg1 from "@/assets/docimg/1.png";
import docImg2 from "@/assets/docimg/2.png"
import docImg3 from "@/assets/docimg/3.png"
import docImg4 from "@/assets/docimg/4.png"
import docImg5 from "@/assets/docimg/5.png"
import docImg6 from "@/assets/docimg/6.png"
import docImg7 from "@/assets/docimg/7.png"
import docImg8 from "@/assets/docimg/8.png"
import docImg9 from "@/assets/docimg/9.png"
import docImg10 from "@/assets/docimg/10.png"
import docImg11 from "@/assets/docimg/11.png"
import docImg12 from "@/assets/docimg/12.png"
import docImg13 from "@/assets/docimg/13.png"
import docImg14 from "@/assets/docimg/14.png"
import docImg15 from "@/assets/docimg/15.png"
import docImg16 from "@/assets/docimg/16.png"
import docImg17 from "@/assets/docimg/17.png"
import docImg18 from "@/assets/docimg/18.png"
import docImg19 from "@/assets/docimg/19.png"
import docImg20 from "@/assets/docimg/20.png"
import docImg21 from "@/assets/docimg/21.png"
import docImg22 from "@/assets/docimg/22.png"
import docImg23 from "@/assets/docimg/23.png"
import docImg24 from "@/assets/docimg/24.png"
//
import docImg25 from "@/assets/docimg/25.png"
import docImg26 from "@/assets/docimg/26.png"
import docImg27 from "@/assets/docimg/27.png"
import docImg28 from "@/assets/docimg/28.png"
import docImg29 from "@/assets/docimg/29.png"
import docImg30 from "@/assets/docimg/30.png"
//
import docImg31 from "@/assets/docimg/31.png"
import docImg32 from "@/assets/docimg/32.png"
//
import docImg33 from "@/assets/docimg/33.png"
import docImg34 from "@/assets/docimg/34.png"
import docImg35 from "@/assets/docimg/35.png"
import docImg36 from "@/assets/docimg/36.png"
import docImg37 from "@/assets/docimg/37.png"
//
import docImg38 from "@/assets/docimg/38.png"

import Guide from "@/views/FileDoc/components/Guide.vue";
import ImageShow from "@/views/FileDoc/components/ImageShow.vue";
import {onMounted, ref} from "vue";
import {useRouter} from "vue-router";

const router = useRouter()


const flags = true
const isShow1 = ref(flags);
const isShow2 = ref(flags)
const isShow3 = ref(flags)
const isShow4 = ref(flags);
const isShow5 = ref(flags)
const isShow6 = ref(flags)
const isShow7 = ref(flags)
const isShow8 = ref(flags)
const isShow9 = ref(flags)
const isShow10 = ref(flags)
//
const titleNumber = 10;
const titleList = [];
const listMap = {
  1: "注册登录",
  2: "团队创建",
  3: "账户管理",
  4: "邮箱绑定",
  5: "邮件编辑",
  6: "邮件群发",
  7: "客户管理",
  8: "客户建档",
  9: "文件存储",
  10: "补充说明"
}

const createList = () => {
  for (let i = 1; i <= titleNumber; i++) {
    titleList.push({href: `#part${i}`, title: `${i}.${listMap[i]}`});
  }
}

const containerRef = ref(null)

const handleClick = (e) => {
  e.preventDefault()
}

const rebackHome = () => {
  router.push("/dashboard")
}

onMounted(() => {
  createList()
})
</script>
<style lang="less" scoped>
@import "../index";

:deep(.el-icon) {
  font-size: 16px !important;
}
</style>