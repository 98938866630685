<template>
  <HomeGuide ></HomeGuide>
</template>

<script setup>

import HomeGuide from "@/views/FileDoc/components/HomeGuide.vue";

</script>


<style lang="less" scoped>

</style>