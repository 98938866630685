// 手机验证间隔时间
const TIME_COUNTPhone = 60;
// 邮箱验证间隔时间
const TIME_COUNT = 60;
// 截流防抖 time
const TIME_OUT = 1500;
const emailReg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
const phoneReg = /^1[3456789]\d{9}$/;

const idCardReg = /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
// 中文正则
const isChina = /[\u4e00-\u9fa5]/;
// 特殊字符
const isSpecial = /[!@#$%^&*(),.?":{}|<>]/;
// 密码限制
const passwordReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,18}$/;
// 企业账号名称限制
const accountNameReg = /^[a-zA-Z][a-zA-Z0-9_]{0,9}$/;
// 编辑子账号
const childAccountNameReg = /^[a-zA-Z][a-zA-Z0-9_]{0,9}$/;
const emptyUrl = require('@/assets/default/empty.svg')
const customerCodeReg = /^.{0,30}$/
const htmlReg = /<\/?[a-zA-Z][\s\S]*>/;

export {
    htmlReg,
    customerCodeReg,
    TIME_COUNTPhone,
    childAccountNameReg,
    TIME_COUNT,
    emailReg,
    phoneReg,
    idCardReg,
    emptyUrl,
    isChina,
    isSpecial,
    passwordReg,
    accountNameReg,
    TIME_OUT
}