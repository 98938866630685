// 在 vuex 中创建一个名为 websocket.js 的模块

import {useSocket} from "@/utils/websocket";
import dayjs from "dayjs";

const state = {
    socketInstance: null,
    isConnected: false,
};

const mutations = {
    SET_SOCKET_INSTANCE(state, socket) {
        state.socketInstance = socket;
    },
    SET_IS_CONNECTED(state, isConnected) {
        state.isConnected = isConnected;
    },
};

const actions = {
    startWebSocket({commit, dispatch}, userId) {
        const socketInstance = useSocket(`${process.env.VUE_APP_BASE_API.replace("https://", "wss://").replace("http://", "ws://")}/v3/websocket/${userId}`);

        socketInstance.on('open', event => {
            console.log(`%c新的 websocket 已建立连接 ${dayjs(new Date()).format("HH:mm:ss")}`, 'color: green');
            commit('SET_IS_CONNECTED', true);
        });

        socketInstance.on('message', data => {
            // console.log("Received data:", data);
            // Assuming store is available to commit mutations
            if (data) {
                store.commit("message/setNewData", data);
            }
        });

        socketInstance.on('error', error => {
            console.error("WebSocket Error:", error);
            // Optionally handle error here
        });

        // Commit socket instance to state
        commit('SET_SOCKET_INSTANCE', socketInstance);
    },

    stopWebSocket({state, commit}) {
        if (state.socketInstance) {
            state.socketInstance.close();
            commit('SET_IS_CONNECTED', false);
            commit('SET_SOCKET_INSTANCE', null);
        }
    },
};

const getters = {
    isWebSocketConnected: state => state.isConnected,
};

export default {
    state,
    mutations,
    actions,
    getters,
};
