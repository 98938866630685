<template>
  <div class="inners">
    <div class="right">
      <div class="titles">绑定手机号</div>
      <div class="content">
        <el-form
            ref="ruleFormRef"
            :model="ruleFormPhone"
            :rules="rules"
            label-width="120px"
            class="demo-ruleForm"
            :label-position="'top'"
        >
          <el-form-item label="手机号" prop="phone">
            <CountrySelect @changeSelect="getSelectValue"></CountrySelect>
            <el-input
                v-model="ruleFormPhone.phone"
                placeholder="请输入手机号"
                style="width: 68%"
            ></el-input>
          </el-form-item>
          <el-form-item
              label="验证码"
              prop="code"
              aria-placeholder="请输入验证码"
          >
            <el-input
                v-model="ruleFormPhone.code"
                placeholder="请输入手机验证码"
                id="sp_input"
                :clearable="false"
            >
              <template #suffix>
                <el-button style="border-radius: 0px 4px 4px 0px !important;" type="primary" @click="sendPhone"
                           :disabled="!showPhone">
                  <span v-if="showPhone">获取验证码</span>
                  <span v-show="!showPhone">{{ countPhone }}s</span>
                </el-button>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" round @click="bindPhoneApis">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="text_md">
        [ 可用来接收 Mailtalk 发送的各类系统、营销、服务通知以及修改密码等。]
      </div>
    </div>
  </div>
</template>
<script setup>
import {computed, onMounted, ref} from "vue";
import {ElMessage,} from "element-plus";
import {sendBindPhoneSms, bindPhone} from "@/api/usercenter";
import {TIME_COUNTPhone, phoneReg} from '@/utils/statusParams'
import CountrySelect from "@/components/CountrySelect/index.vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

const store = useStore()
const router = useRouter()
const ruleFormPhone = ref({});
// 绑定的手机
const ruleFormRef = ref(null)
// 绑定手机号
const timerPhone = ref(null);
const countPhone = ref(0);
const showPhone = ref(true)
const rules = ref({
  phone: [{required: true, message: "请输入手机号", trigger: "blur"}],
  code: [{required: true, message: "请输入验证码", trigger: "blur"}],
});
// 选中的区号 默认为中国
const selectTargetPrefix = ref('CN')
const getSelectValue = (val) => {
  selectTargetPrefix.value = val.zoneCode
}

const sendPhone = () => {
  if (ruleFormPhone.value.phone !== '' && phoneReg.test(ruleFormPhone.value.phone)) {
    if (!timerPhone.value) {
      countPhone.value = TIME_COUNTPhone;
      showPhone.value = false;
      timerPhone.value = setInterval(() => {
        if (countPhone.value > 0 && countPhone.value <= TIME_COUNTPhone) {
          countPhone.value--;
        } else {
          showPhone.value = true;
          clearInterval(timerPhone.value); // 清除定时器
          timerPhone.value = null;
        }
      }, 1000)
      //   发送请求
      sendBindPhoneSmsApis()
    }
  } else {
    ElMessage.error("请输入正确的手机号");
  }
}
// 发送手机验证码
const sendBindPhoneSmsApis = async () => {
  const res = await sendBindPhoneSms({
    phone: ruleFormPhone.value.phone,
    zoneCode: selectTargetPrefix.value
  })
  if (res.code !== 200) {
    ElMessage.error(res.message)
    return
  }
  ElMessage.success(res.message)

}

// 绑定手机
const bindPhoneApis = async () => {
  const res = await bindPhone({
    phone: ruleFormPhone.value.phone,
    code: ruleFormPhone.value.code,
    type: 1,
    zoneCode: selectTargetPrefix.value
  })
  if (res.code !== 200) {
    ElMessage.error(res.message)
    return
  }
  ElMessage.success(res.message)
  await store.dispatch('user/getUserInfoForGUID')
  ruleFormRef.value.resetFields();
  router.push('/personal/basic')
}
const userInfo = computed(() => store.state.user.userInfo)
const isChange = ref('')
onMounted(async () => {
  isChange.value = router.currentRoute.value.query.isChange
  if (isChange.value === '1') {
    ruleFormPhone.value.phone = userInfo.value.phone
  }
})
</script>

<style lang="less" scoped>
@import "./less/Tables.less";
@import "../../less/page.less";

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 200px;
  margin-top: 40px;
}

:deep(.el-form) {
  width: 100% !important;
}

:deep(.el-form-item__content),
:deep(.el-select) {
  width: 100% !important;
}

:deep(.el-form-item__content) {
  display: flex;
  justify-content: center;
}

#buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

#avatar {
  display: flex;
  justify-content: center;
}

#sp_input {
  border-right: none !important;
}

:deep(.el-input__wrapper) {
  padding: 1px 0px 1px 11px !important;
}

.text_md {
  text-align: center;
  margin-top: 40px;
  color: #999;
  font-size: 14px;
}
</style>
