<template>
  <IndexTable :type="targetType"></IndexTable>
</template>

<script setup>
import {useRoute} from "vue-router";

const route = useRoute()
import IndexTable from "@/views/SendGroup/compontents/IndexTable.vue";
import {computed} from "vue";

const targetType = computed(() => {
  return route.params.type * 1
})

</script>
<style lang="less" scoped>
</style>