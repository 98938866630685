import request from "@/utils/request";


// 微信下单
export function wxPayTransactions(params, config) {
    return request.get('/wx/pay/transactions', params, config);
}


// 根据订单号查询订单
export function wxPayQuery(params, config) {
    const {orderNo} = params;
    return request.get(`/wx/otherPay/transactions/${orderNo}`, params, config);
}

