import {ElLoading} from 'element-plus'
import {loadingConfig} from '@/utils/loading' //全局的一个基本参数配置

var loading = null;
const loadingShow = (loadingConfig) => {
    loading = ElLoading.service(loadingConfig);
}

const loadingHide = () => {
    setTimeout(() => {
        loading?.close();
    }, 500)
}

const loadingObj = {
    loadingShow,
    loadingHide
}

export default loadingObj