import _store from "store2";
import {
    getMailCustomerLevelList,
    addMailCustomerFollowLevel,
    editMailCustomerLevel,
} from "@/api/customer.js";
import {ElMessage} from "element-plus";
import sessionStorageUtil from "@/utils/sessionStorageUtil";

const customer = {
    namespaced: true,
    state: {
        tableData: sessionStorageUtil.getItem("customerLevel") || [],
    },
    mutations: {
        // 改变编辑状态
        changeEditing(state, payload) {
            const {index, flag} = payload;
            state.tableData[index].editing = flag;
        },

    },
    actions: {
        // 获取等级列表
        async getMailCustomerLevel({state, dispatch, rootState}, payload) {
            let isUpdate = false
            if (payload) {
                isUpdate = payload.isUpdate
            }
            if (!sessionStorageUtil.getItem("customerLevel") || isUpdate) {
                let teamId = window.localStorage.getItem("teamId");
                let res = await getMailCustomerLevelList({teamId, pageSize: 20});
                if (res.code !== 200) {
                    ElMessage.error(res.message);
                    return;
                }
                const {records} = res.result;
                let addEditing = records.map((item) => {
                    return {...item, editing: false};
                });
                state.tableData = addEditing;
                sessionStorageUtil.setItem("customerLevel", addEditing)
            }
        },
        async addCustomerData(state, targetParams) {
            let res = await addMailCustomerFollowLevel(targetParams);
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return;
            }
            // ElMessage.success(res.result);
        },
        async editCustomerData(state, targetParams) {
            let res = await editMailCustomerLevel(targetParams);
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return;
            }
            // ElMessage.success(res.result);
        },
    },
};

export default customer;
