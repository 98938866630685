<template>
  <div class="card_box">
    <el-tabs v-model="activeName" class="demo-tabs">
      <el-tab-pane lazy :label="$t('common.setting.general')" name="first">
        <div class="box">
          <div class="title">{{ $t('common.emailbox') }}</div>
          <div class="desc" style="margin-top: 32px">{{ $t('common.emailbox.default') }}</div>
          <el-select
              v-model="defaultEmail"
              @change="handleChangeDefault"
              class="m-2"
              style="min-width: 323px;width:auto;"
              :placeholder="$t('common.emailbox.default')"
          >
            <el-option
                v-for="item in emailList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
          <div class="tips">{{ $t('other.text.bind.default.mailbox') }}</div>
        </div>
      </el-tab-pane>
      <el-tab-pane lazy :label="$t('common.emailbox.management')" name="second">
        <div class="box">
          <div class="inner_boxs">
            <div class="title">{{ $t('common.emailbox.bind') }}</div>
            <ElButton type="primary" @click="handleAdd">{{ $t('common.emailbox.add') }}</ElButton>
          </div>
          <ElTable :data="tableData"
                   :header-cell-style="{'text-align': 'left', background: '#f9f9f9', color: 'black', padding: '16px 0px'}">
            <!-- <el-table-column prop="account" label="账户名称" /> -->
            <el-table-column prop="username" :label="$t('common.emailbox')"/>
            <el-table-column prop="acceptProtocol" :label="$t('common.mailbox.kinds')">
              <template #default="{row}">
                {{ toUpperCase(row.acceptProtocol) }}
              </template>
            </el-table-column>
            <el-table-column prop="createTime" :label="$t('common.date.add')"/>
            <el-table-column fixed="right" :label="$t('common.operate')" width="240">
              <template #default="scope">
                <el-button
                    link
                    type="primary"
                    @click="handleTest(scope.row)"
                >{{ $t('common.mailbox.test') }}
                </el-button
                >
                <el-button
                    link
                    type="primary"
                    @click="handleEdit(scope.row)"
                >{{ $t('common.update') }}
                </el-button
                >
                <span class="col">|</span>
                <el-button
                    link
                    type="danger"
                    @click="handleDel(scope.row)"
                >{{ $t('common.unbind') }}
                </el-button
                >
                <span class="col">|</span>
                <el-button
                    type="primary"
                    link
                    @click="handleClickImport(scope.row)"
                >
                  {{ $t('common.setting.mailToImport') }}
                </el-button>
                <!--                <el-button-->
                <!--                    type="primary"-->
                <!--                    link-->
                <!--                    @click="handleClickImport(scope.row,2)"-->
                <!--                >-->
                <!--                  导入已发送-->
                <!--                </el-button>-->
              </template>
            </el-table-column>
            <template #empty>
              <el-empty :description="$t('common.data.empty')" :image="emptyUrl"/>
            </template>
          </ElTable>
        </div>
      </el-tab-pane>
      <el-tab-pane lazy :label="$t('common.blacklist')" name="third">
        <Card :useBorder="false">
          <div class="box sp">
            <div class="top">
              <div class="titles">{{ $t('common.emailbox.blacklist') }}</div>
              <div class="bottom_input">
                <el-input
                    v-model="targetEmail"
                    :placeholder="$t('sys.tip.input.blacklist.add.email')"
                    id="sp_input"
                    :clearable="false"
                >
                  <template #suffix>
                    <el-button type="primary" @click="addBlackEmail" class="sp_btn">{{
                        $t('common.blacklist.add')
                      }}
                    </el-button>
                  </template>
                </el-input>
              </div>
            </div>
            <TablesBlack :table-data="tableDataBackEmail" :table_columns="table_columns_email"
                         @handleDelete="deleteBlackEmail"></TablesBlack>
            <div class="pages">
              <el-pagination
                  v-model:current-page="blackEmail.current"
                  v-model:page-size="blackEmail.size"
                  background
                  layout="total, prev, pager, next"
                  v-if="blackEmail.total"
                  :total="blackEmail.total"
                  @current-change="handleCurrentChange"
              />
            </div>
          </div>
        </Card>


        <Card :useBorder="false">
          <div class="box sp">
            <div class="top">
              <div class="titles">{{ $t('common.customer.blacklist') }}</div>
              <div class="bottom_input">
                <el-input
                    v-model="targetCustomer"
                    :placeholder="$t('30004')"
                    id="sp_input"
                    :clearable="false"
                >
                  <template #suffix>
                    <el-button type="primary" @click="addBlackCustomer" class="sp_btn">{{
                        $t('common.blacklist.add')
                      }}
                    </el-button>
                  </template>
                </el-input>
              </div>
            </div>
            <TablesBlack :table-data="tableDataBackCustomer" :table_columns="table_columns_customer"
                         @handleDelete="deleteBlackCustomer"></TablesBlack>
            <div class="pages">
              <el-pagination
                  v-model:current-page="blackCustomer.current"
                  v-model:page-size="blackCustomer.size"
                  background
                  layout="total, prev, pager, next"
                  v-if="blackCustomer.total"
                  :total="blackCustomer.total"
                  @current-change="handleCurrentChangeCus"
              />
            </div>
          </div>
        </Card>


      </el-tab-pane>
    </el-tabs>
    <Dialog
        v-model="dialogVisibleInner"
        :width="480"
        style="min-width: 400px"
        :dialogVisible="dialogVisibleInner"
        :isHeadLine="false"
        @update:dialogVisible="(val) => (dialogVisibleInner = val)"
    >
      <template #content>
        <div style="display: flex;justify-content: flex-start;align-items: center">
          <div style="line-height: 23px;margin-left: 30px">导入邮件类型选择：</div>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="收件箱" :value="1"/>
            <el-checkbox label="发件箱" :value="2"/>
            <!--            <el-checkbox label="同步" :value="3"/>-->
          </el-checkbox-group>
        </div>
        <div class="cont" style="margin-top: 20px">
          <span style="line-height: 24px"><el-icon style="color:#efb041;font-size: 20px;margin-right: 10px;"><QuestionFilled/></el-icon></span>
          <span style="line-height: 24px">即将导入</span>
          <span
              style="font-weight: 600;line-height: 25px;color: #5a9cf8">{{
              `${checkList.includes(1) && checkList.includes(2) ? ' 收件箱 和 发件箱 ' : checkList.includes(1) ? ' 收件箱 ' : checkList.includes(2) ? ' 发件箱 ' : ' [邮件来源] '}`
            }}</span>
          <el-select v-model="valueType" class="m-2" :placeholder="$t('common.select.days')" size="small">
            <el-option
                v-for="item in optionsType"
                :key="item.value"
                :label="item.label"
                :value="item"
            />
          </el-select>
          <span style="line-height: 23px;">{{ $t('common.target.email') }}？</span>
        </div>
      </template>
      <template #footer>
        <div class="settingss">
          <el-button @click="dialogVisibleInner = false">{{ $t('common.cancel') }}</el-button>
          <el-button type="primary" @click="handleClickSure" :loading="btnIsLoading">{{ $t('common.confirm') }}
          </el-button>
        </div>
      </template>
    </Dialog>
    <!-- 选择类型  -->
    <el-dialog
        append-to-body
        width="440px"
        :show-close="false"
        v-model="selectTypeDialog"
    >
      <div class="mail-create-container flex-center">
        <h3 class="mail-header-text">{{ $t('common.system.email.add') }}</h3>
        <div class="createForm">
          <div
              class="mail-item flex-center cursor"
              @click="handleSelectItem(item)"
              v-for="(item, index) in mailList"
              :key="'mailList' + index"
          >
            <div class="mailList-item-row">
              <img
                  :src="item.img"
                  :style="`width: ${item.w};height: ${item.h};`"
                  alt=""
              />
              <span class="mail-item-text" v-if="item.text">{{
                  item.text
                }}</span>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!--  配置  -->
    <el-dialog
        append-to-body
        width="630px"
        v-model="mailConfigDialog"
        :before-close="handleMailConfigDialogCancel"
        :show-close="true"
        :close-on-click-modal="false"
    >
      <template #header>
        <span class="el-dialog__title">{{ $t('common.emailbox.add') }}</span>
        <div style="font-size: 12px;margin-top: 5px;font-weight: 500;"><span>{{ $t('common.cds4') }}</span>
          <view class="search_btn"  @click="toHref">{{
              "查看操作指引"
            }}
          </view>
        </div>
        <div style="border-bottom: 1px solid #f3f3f3; margin-top: 10px;width: 100%"></div>
      </template>
      <mailLoginForm
          :actionMode="actionMode"
          :initData="rowData"
          showUseWay
          :initMailAccountType="mailAccountType"
          @success="successCb"
          v-if="mailConfigDialog"
      />
    </el-dialog>
    <!--    <el-dialog-->
    <!--        title="设置签名"-->
    <!--        :before-close="handleClose"-->
    <!--        :visible.sync="showInfo"-->
    <!--        v-if="showInfo"-->
    <!--    >-->
    <!--      &lt;!&ndash; 个性签名组件 &ndash;&gt;-->
    <!--      &lt;!&ndash; <signature></signature> &ndash;&gt;-->
    <!--    </el-dialog>-->
    <!--    异步提示正在收录中-->
    <Dialog
        v-model="getEmailDia"
        :width="'400'"
        :dialogVisible="getEmailDia"
        :isHeadLine="false"
        @update:dialogVisible="(val) => (getEmailDia = val)"
    >
      <template #content>
        <div class="cont">
          <span><el-icon style="color:#448ef7;font-size: 20px;margin-right: 10px;"><WarningFilled/></el-icon></span>
          <span>{{ $t('common.setting.emailToRemind') }}</span>
        </div>
      </template>
      <template #footer>
        <div class="settingss">
          <el-button type="primary" @click="()=>getEmailDia = false">{{ $t('common.data.know') }}</el-button>
        </div>
      </template>
    </Dialog>
    <!--    测试连通性-->
    <Dialog
        v-model="diaTest"
        :title="$t('common.test.connect.title')"
        :width="'450'"
        :dialogVisible="diaTest"
        :isHeadLine="true"
        @update:dialogVisible="(val) => (diaTest = val)"
    >
      <template #content>
        <div class="cont_test">

          <div>{{ isContent ? $t('common.testing') : $t('common.test.finish') }}</div>
          <div>
            <template v-if="isContent">
              <el-icon class="is-loading">
                <Loading/>
              </el-icon>
              <span style="margin-left: 10px">{{ $t("common.connect.send.server") }}({{
                  activeItem.sendProtocol.toLocaleUpperCase()
                }})</span>
            </template>
            <template v-else>
              <template v-if="sendConnect">
                <el-icon style="color: #7ec050;margin-right: 10px">
                  <Select/>
                </el-icon>
                <span style="color: #7ec050">{{
                    activeItem.sendProtocol.toLocaleUpperCase()
                  }} {{ $t('common.connect.server.success') }}</span>
              </template>
              <template v-else>
                <el-icon style="color: #e47470;margin-right: 10px">
                  <CloseBold/>
                </el-icon>
                <span style="color: #e47470">{{
                    activeItem.sendProtocol.toLocaleUpperCase()
                  }} {{ $t("common.connect.server.fail") }}</span>
              </template>
            </template>
          </div>
          <div>
            <template v-if="isContent">
              <el-icon class="is-loading">
                <Loading/>
              </el-icon>

              <span style="margin-left: 10px">{{ $t("common.connect.get.server") }}({{
                  activeItem.acceptProtocol.toLocaleUpperCase()
                }})</span>
            </template>
            <template v-else>
              <template v-if="acceptConnect">
                <el-icon style="color: #7ec050;margin-right: 10px">
                  <Select/>
                </el-icon>
                <span style="color: #7ec050">{{
                    activeItem.acceptProtocol.toLocaleUpperCase()
                  }} {{ $t('common.connect.server.success') }}</span>
              </template>
              <template v-else>
                <el-icon style="color: #e47470;margin-right: 10px">
                  <CloseBold/>
                </el-icon>
                <span style="color: #e47470">{{
                    activeItem.acceptProtocol.toLocaleUpperCase()
                  }} {{ $t('common.connect.server.fail') }}</span>
              </template>
            </template>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="settingss">
          <el-button @click="()=>diaTest = false">{{ $t('common.cancel') }}</el-button>
          <el-button type="primary" @click="()=>diaTest = false">{{ $t('common.confirm') }}</el-button>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import {ElMessage, ElNotification} from "element-plus";
import {settingAccountOption} from "../utils/config";
import mailLoginForm from "./components/maillLoginForm/index.vue";
import {
  getMailConfigList,
  delConfigById,
  updateConfigState,
  getDefaultMailConfig, checkConnect,
} from "../api/index";
import _store from "store2";
import TablesBlack from "@/views/AccountCom/TablesBlack.vue";
import Card from '@/components/Card.vue';
import {
  copySentMail,
  requestCustomerBlackList, requestCustomerBlackListAdd, requestCustomerBlackListDelete,
  requestEmailBlackList,
  requestEmailBlackListAdd,
  requestEmailBlackListDelete, requestEmailgetSentMail, requestEmailManage
} from "@/api/mail";
import {emailReg} from '@/utils/statusParams'
import Dialog from "@/components/Dialog.vue";
import {emptyUrl} from "@/utils/statusParams";
import * as target from '/public/settings.json'
import {toUpperCase} from "uri-js/dist/esnext/util";
import {ref} from "vue";
import {i18n} from "@/lang";
import router from "@/router";
import {QuestionFilled} from "@element-plus/icons-vue";

export default {
  name: "settingAccount",
  components: {
    QuestionFilled,
    Dialog,
    mailLoginForm,
    TablesBlack,
    Card
  },
  data() {
    return {
      sendConnect: false,
      acceptConnect: false,
      isContent: false,
      diaTest: false,
      emptyUrl: emptyUrl,
      btnIsLoading: false,
      dialogVisibleInner: false,
      targetEmail: '',
      targetCustomer: '',
      tableDataBackEmail: [],
      table_columns_email: [{
        key: i18n.global.t("common.emailbox"),
        value: "mailBox"
      }, {key: i18n.global.t("common.date.add"), value: "createTime"}],
      tableDataBackCustomer: [],
      table_columns_customer: [{
        key: i18n.global.t("common_customer_name"),
        value: "remark"
      }, {key: i18n.global.t("common.date.add"), value: "createTime"}],
      tableData: [],
      emailList: [],
      defaultEmail: "",
      activeName: "first",
      tableOption: settingAccountOption,
      tableLoading: true,
      dialog: false,
      rowData: {}, // 行数据
      knowTaskId: "", // 处理id
      mailConfigDialog: false,
      selectTypeDialog: false,
      actionMode: "add", // [add, edit]
      mailAccountType: "other",
      mailList: [
        // {img: require('@/assets/img/mail/jck.png'),  w: '26px', h: '30px', type: 'jck', text: '进出口邮箱'},
        {
          img: require("@/assets/img/mail/tx.png"),
          w: "29px",
          h: "20px",
          type: "tengXunQiYe",
          text: i18n.global.t("common.email.type.txyx"),
        },
        {
          img: require("@/assets/img/mail/qq.png"),
          w: "29px",
          h: "29px",
          type: "tengXunQQ",
          text: i18n.global.t("common.email.type.qqyx"),
        },
        {
          img: require("@/assets/img/mail/163.png"),
          w: "29px",
          h: "15px",
          type: "wangYi",
          text: "163邮箱",
        },
        {
          img: require("../assets/img/mail/126.png"),
          w: "29px",
          h: "16px",
          type: "126",
          text: "126邮箱",
        },
        {
          img: require('../assets/img/mail/263.jpg'),
          w: "29px",
          h: "16px",
          type: "263",
          text: "263邮箱",
        },
        // {img: require('@/assets/img/mail/gmail.png'),  w: '29px', h: '22px', type: 'gmail', text: 'Gmail'},
        // {img: require('@/assets/img/mail/outlook.png'),  w: '29px', h: '25px', type: 'outlook', text: 'Outlook'},
        // {img: require('@/assets/img/mail/exchange.png'),  w: '29px', h: '27px', type: 'exchange', text: 'Exchange'},
        {
          img: require("@/assets/img/mail/mail.png"),
          w: "29px",
          h: "26px",
          type: "other",
          text: "其他邮箱",
        },
      ],
      showInfo: false,
      remindStatus: false,
      blackEmail: {
        current: 1,
        size: 5,
        total: 0,
      },
      blackCustomer: {
        current: 1,
        size: 5,
        total: 0,
      },
      activeItem: {},
      valueType: {
        label: i18n.global.t('common.select.targetDays', {count: 30}),
        value: '30'
      },
      optionsType: [{
        label: i18n.global.t('common.select.targetDays', {count: 30}),
        value: '30'
      }, {
        label: i18n.global.t('common.select.targetDays', {count: 90}),
        value: '90'
      }, {
        label: i18n.global.t('common.select.targetDays', {count: 180}),
        value: '180'
      }, {
        label: i18n.global.t('common.select.targetYear', {count: 1}),
        value: '365'
      }, {
        label: i18n.global.t('common.select.targetYears', {count: 3}),
        value: '1095'
      }, {
        label: i18n.global.t('common.select.all'),
        value: null
      }],
      valueTypeShow: '',
      getEmailDia: false,
      typeMail: 0,
      checkList: [],
    };
  },
  computed: {
    teamId() {
      return this.$store.state.email.teamID;
    }
  },
  watch: {
    async teamId() {
      await this.getList();
      await this.getDefaultMailConfig();
      await this.requestEmailBlackListApis()
      await this.requestCustomerBlackListApis()
      await this.getDefaultMailConfig();
    }
  },
  async mounted() {
    const {type} = this.$route.query
    this.valueTypeShow = type;
    if (this.valueTypeShow === 'add') {
      this.mailConfigDialog = true
      this.activeName = "second"
    }

    await this.getList();
    await this.getDefaultMailConfig();
    await this.requestEmailBlackListApis()
    await this.requestCustomerBlackListApis()
  },
  methods: {
    toUpperCase,
    toHref() {
      // window.open(target.docUrl, "_blank")
      let newUrl = router.resolve(
          `/settingguide`
      );
      this.openNewWindow(newUrl.href);
    },
    openNewWindow(url) {
      window.open(url, "_blank");
    },
    handleClickImport(row, type) {
      this.dialogVisibleInner = true
      this.activeItem = row
      this.typeMail = type
    },
// 导入邮件
    async handleClickSure() {
      if (this.checkList.length === 0) {
        ElMessage.warning("请选择导入邮件类型")
        return
      }
      console.log(this.valueType, 'this.valueType')
      if (this.valueType !== '') {
        const targetObj = {
          id: this.activeItem.id,
          type: this.valueType.value,
        }
        //console.log('ff', this.activeItem, targetObj)
        this.btnIsLoading = true
        this.dialogVisibleInner = false
        // 异步操作
        this.getEmailDia = true;
        // const res = this.typeMail === 1 ? await requestEmailManage(targetObj) : await requestEmailgetSentMail(targetObj)
        if (this.checkList.includes(1) && this.checkList.includes(2)) {
          await Promise.all([
            requestEmailManage(targetObj),
            requestEmailgetSentMail(targetObj)
          ]).then((res) => {
            // 更新标志
            this.$store.dispatch('countshow/getTeamAllDataApis')
            // 重新获取邮件列表
            this.valueType = {
              label: i18n.global.t('common.select.targetDays', {count: 30}),
              value: '30'
            }
            this.btnIsLoading = false
          })
        } else if (this.checkList.includes(1)) {
          await requestEmailManage(targetObj).then((res) => {
            // 更新标志
            this.$store.dispatch('countshow/getTeamAllDataApis')
            // 重新获取邮件列表
            this.valueType = {
              label: i18n.global.t('common.select.targetDays', {count: 30}),
              value: '30'
            }
            this.btnIsLoading = false
          })
        } else if (this.checkList.includes(2)) {
          await requestEmailgetSentMail(targetObj).then((res) => {
            // 更新标志
            this.$store.dispatch('countshow/getTeamAllDataApis')
            // 重新获取邮件列表
            this.valueType = {
              label: i18n.global.t('common.select.targetDays', {count: 30}),
              value: '30'
            }
            this.btnIsLoading = false
          })
        } else if (this.checkList.includes(3)) {
          await copySentMail(targetObj).then(res => {
            console.log(res, 'res')
          })
        }
      } else {
        ElMessage.error('请选择导入的时间')
      }
    }
    ,

    async handleChangeDefault(id) {
      const result = await updateConfigState({id, isBind: 1, teamId: this.teamId});
      if (result.code !== 200) {
        this.defaultEmail = "";
        ElMessage.error("设置失败");
        return;
      }
      // ElMessage.success("设置成功");
    }
    ,
    async getDefaultMailConfig() {
      const res = await getDefaultMailConfig({teamId: this.teamId});
      if (res.code !== 200) {
        ElMessage.error(res.message);
        return;
      }
      if (res.result == null) {
        this.defaultEmail = '';
        return;
      }
      const {id} = res.result;
      console.log(res.result, 'fffffffffffff')
      this.defaultEmail = id;
    },
//个性签名弹框关闭
    handleClose() {
      this.showInfo = false;
    }
    ,
// 邮箱邮箱
// TODO:
    getList() {
      this.tableLoading = true;
      // const res = {
      //   data: {"code":200,"msg":"成功","data":[{"id":71,"sysUserId":419,"mailType":"wangYi","account":"Beats0","sendHost":"smtp.163.com","acceptHost":"imap.163.com","sendPort":465,"acceptPort":993,"acceptSsl":1,"sendSsl":1,"username":"jyl2047155291@163.com","password":"xxx","sendProtocol":"smtp","acceptProtocol":"imap","defaultEncodingS":"UTF-8","useWay":1,"createTime":"2021-04-03T22:21:00","updateTime":"2021-04-03T22:21:00","delFlag":0,"tenantId":39},{"id":110,"sysUserId":419,"mailType":"tengXunQQ","account":"","sendHost":"smtp.qq.com","acceptHost":"imap.qq.com","sendPort":465,"acceptPort":993,"acceptSsl":1,"sendSsl":1,"username":"1357097581@qq.com","password":"xxppeerzfmrebagg","sendProtocol":"smtp","acceptProtocol":"imap","defaultEncodingS":"UTF-8","useWay":1,"createTime":"2021-06-09T09:26:32","updateTime":"2021-06-09T09:26:32","delFlag":0,"tenantId":39}],"timestamp":"2021-06-09 14:28:06 870"}
      // }
      //console.log("用户信息", _store.get("userInfo"));
      getMailConfigList({teamId: this.teamId})
          .then((res) => {
            //console.log(res.result, "邮箱列表");
            const list = res.result || [];
            this.tableData = list;
            this.emailList = list.map((item) => ({
              label: item.username,
              value: item.id,
            }));
            // if (this.emailList.length >= 1 && this.defaultEmail === "") {
            //   this.defaultEmail = this.emailList[0].value
            //   this.handleChangeDefault(this.emailList[0].value)
            // }
          })
          .finally(() => {
            this.tableLoading = false;
          });
    },
    async successCb() {
      //console.log('fff')
      this.mailConfigDialog = false;
      this.getList();
      //console.log("添加成功");
      // EventBus.$emit('handleCheckIsFirstLogin')
    }
    ,
// 选中item
    handleSelectItem(item) {
      this.mailAccountType = item.type;
      this.actionMode = "add";
      this.rowData = {};
      this.selectTypeDialog = false;
      this.mailConfigDialog = true;
    }
    ,
// 帮助
    handleGoToHelp() {
      // const url = process.env.NODE_ENV === 'development'?location.protocol+'//'+location.host+':8081':''
      // //console.log(location,"99999999999")
      // openLink(location.origin+'/html/mailhelp.html')
    }
    ,
    handleMailConfigDialogCancel() {
      this.mailConfigDialog = false;
    }
    ,
// 开启、关闭
    handleChange(status, row) {
      const query = {
        id: row.id,
        useWay: row.useWay,
      };
      updateConfigState(query).then((res) => {
        if (res.code === 200) {
          this.getList();
        }
      });
    }
    ,
// 删除
    handleDel({id}) {
      //console.log("删除", id);
      this.$msgbox({
        title: "提示",
        message: `
          <div class="msgBoxInner">
            <div class="innerHeader">
              <i class="innerIcon el-icon-info warning-icon"></i>
              <span class="innerTitle">确定将邮箱账号删除吗？</span>
            </div>
          </div>
          `,
        dangerouslyUseHTMLString: true,
        customClass: "customMsgBox",
        showCancelButton: true,
        confirmButtonText: "确定",
        confirmButtonClass: "confirmBtn confirmButton",
        cancelButtonText: "取消",
        cancelButtonClass: "confirmBtn cancelButton",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            delConfigById({id})
                .then((res) => {
                  //console.log("删除成功", res);
                  if (res.code === 200) {
                    this.$message.success("删除成功");
                    // EventBus.$emit('handleCheckIsFirstLogin')
                    this.defaultEmail = "";
                    this.getList();
                  }
                })
                .finally(() => {
                  done();
                  instance.confirmButtonLoading = false;
                });
          } else {
            done();
          }
        },
      })
          .then((action) => {
          })
          .catch((e) => {
          });
    }
    ,
// 编辑
    handleEdit(row) {
      console.log(row, 'row')
      this.actionMode = "edit";
      this.rowData = row;
      this.mailConfigDialog = true;
    }
    ,
// 新增
    handleAdd() {
      this.mailAccountType = "other";
      // this.selectTypeDialog = true;
      this.mailConfigDialog = true
      this.actionMode = "add";
      this.rowData = {};
      // this.mailConfigDialog = true;
    }
    ,
//个性签名弹框打开
    handleSignature() {
      this.showInfo = true;
    }
    ,
//开启关闭消息提醒
    handleremindStatus(row) {
      //console.log(row);
      businessRemind(row.id, row.isRemind).then((res) => {
        if (res.code === 200) {
          this.getList();
        }
      });
    }
    ,
    async requestEmailBlackListApis() {
      const res = await requestEmailBlackList({
        pageNo: this.blackEmail.current,
        pageSize: this.blackEmail.size,
        teamId: this.teamId,
      });
      if (res.code !== 200) {
        ElMessage.error(res.message);
        return
      }
      const {records, total, size, current} = res.result;
      this.tableDataBackEmail = records;
      this.blackEmail.current = current;
      this.blackEmail.size = size;
      this.blackEmail.total = total
    }
    ,
    async addBlackEmail() {
      if (this.targetEmail !== '') {
        // if (emailReg.test(this.targetEmail)) {
        const res = await requestEmailBlackListAdd({
          mailBox: this.targetEmail,
          teamId: this.teamId
        });
        if (res.code !== 200) {
          ElMessage.error(res.message)
          return
        }
        ElMessage.success(res.message);
        this.targetEmail = "";
        this.requestEmailBlackListApis();
        // } else {
        //   ElMessage.warning('请输入正确的邮箱地址')
        // }
      } else {
        ElMessage.warning('请输入邮箱地址')
      }
    }
    ,
    async deleteBlackEmail(val) {
      const res = await requestEmailBlackListDelete({
        id: val.id,
      })
      if (res.code !== 200) {
        ElMessage.error(res.message)
        return
      }
      ElMessage.success(res.message)
      this.requestEmailBlackListApis();
    }
    ,
    handleCurrentChange(page) {
      this.blackEmail.current = page
      this.requestEmailBlackListApis()
    }
    ,


    async requestCustomerBlackListApis() {
      const res = await requestCustomerBlackList({
        pageNo: this.blackCustomer.current,
        pageSize: this.blackCustomer.size,
        teamId: this.teamId,
      })
      if (res.code !== 200) {
        ElMessage.error(res.message)
        return
      }
      const {records, total, size, current} = res.result;
      console.log(res.result, 'ffffffffffasdfasdfasfd')
      this.tableDataBackCustomer = records;
      this.blackCustomer.current = current;
      this.blackCustomer.size = size;
      this.blackCustomer.total = total
    },
    async addBlackCustomer() {
      if (this.targetCustomer !== "") {
        const res = await requestCustomerBlackListAdd({
          teamId: this.teamId,
          customerCode: this.targetCustomer,
        })
        if (res.code !== 200) {
          ElMessage.error(res.message)
          return
        }
        ElMessage.success(res.message)
        this.targetCustomer = "";
        await this.requestCustomerBlackListApis();
      } else {
        ElMessage.warning("请输入客户编码")
      }
    }
    ,
    async deleteBlackCustomer(val) {
      const res = await requestCustomerBlackListDelete({
        id: val.id
      })
      if (res.code !== 200) {
        ElMessage.error(res.message)
        return
      }
      ElMessage.success(res.message);
      this.requestCustomerBlackListApis();
    }
    ,
    handleCurrentChangeCus(page) {
      this.blackCustomer.current = page;
      this.requestCustomerBlackListApis();
    }
    ,

    // 测试连通性测试
    async handleTest(row) {
      console.log(row, 'row')
      this.activeItem = row;
      this.diaTest = true;
      this.isContent = true
      const res = await checkConnect({id: row.id})
      if (res.code !== 200) {
        ElMessage.error(res.message)
        return
      }
      const {acceptConnect, sendConnect} = res.result;
      this.sendConnect = sendConnect;
      this.acceptConnect = acceptConnect;
      this.isContent = false

    }
  },

}
;

</script>

<style lang="less" scoped>
@import "../less/page.less";

.sp {
  margin-bottom: 20px;
}

.box {
  padding: 40px 20px 20px 20px;
  background: white;

  .title {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
  }

  .desc {
    margin-bottom: 8px;
  }

  .tips {
    margin-top: 10px;
    height: 20px;
    line-height: 20px;
    color: #999;
    font-weight: 400;
    font-size: 14px;
  }
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cursor {
  cursor: pointer;
}

.mail-create-container {
  display: flex;
  align-items: center;
  flex-direction: column;

  .mail-header-text {
    color: rgb(0, 140, 255);
    text-align: center;
    letter-spacing: 3px;
    font-size: 18px;
    margin-bottom: 35px;
  }

  .createForm {
    width: 240px;

    .mail-item {
      width: 240px;
      height: 44px;
      background: #f0f3f9;
      border-radius: 8px;
      margin-bottom: 10px;
    }

    .mailList-item-row {
      display: flex;
      align-items: center;
      min-width: 115px;

      .mail-item-text {
        font-size: 14px;
        font-weight: 500;
        color: #222222;
        margin-left: 15px;
      }
    }
  }
}

.card_box {
  padding: 20px;
}

:deep(.el-tabs__nav-scroll) {
  padding-left: 16px;
  background: white;
}

:deep(.el-tabs__header) {
  margin-bottom: 0px !important;
}

.top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .titles {
    margin-top: 0;
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, .85);
    font-weight: 500;
    font-size: 21px;
  }

  .bottom_input {
    width: 480px;
    min-width: 380px;
  }
}

:deep(.el-input__wrapper) {
  padding: 1px 0px 1px 11px;
}

:deep(.el-input__suffix-inner) {
  border-radius: 0px 4px 4px 0px !important;
}


:deep(.el-select__caret) {
  margin-right: 6px !important;
}

.settingss {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

:deep(.el-upload--picture-card) {
  --el-upload-picture-card-size: 58px !important;
}

:deep(.el-upload-list--picture-card) {
  --el-upload-list-picture-card-size: 58px !important;
}

.cont {
  display: flex;
  justify-content: flex-start;
  align-content: center;
}

.cont_test {
  display: flex;
  flex-direction: column;

  & > hr {
    margin: 10px 0;
    color: #d5d5d5;
  }

  & > div {
    padding: 8px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

:deep(.el-select--small) {
  margin: 0px 10px;
  width: 90px !important;
}

.inner_boxs {
  width: 100%;
  display: flex;
  justify-content: space-between;
}


.sp_btn {
  border-radius: 0px 4px 4px 0px !important;
}

.col {
  color: #e8e8e8;
  margin: 0px 4px;
}

:deep(.el-empty__image img) {
  width: 54% !important;
}

.search_btn {
  text-decoration: underline;
  cursor: pointer;
  color: #5a9cf8;
  font-weight: 500 !important;
}
</style>
