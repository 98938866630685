<template>
  <ElDialog title="拉黑确认" :model-value="showFlag">
    <div class="content">
      <div class="t">拉黑后您将不再收到来自以下地址的邮件，确认拉黑吗？</div>
      <div class="mail">
        {{ list.join('；') }}
      </div>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <ElButton @click="() => $emit('cancel')" style="margin-right: 12px;">取消</ElButton>
        <ElButton type="primary" @click="handleEntry" :loading="loading">
          确认
        </ElButton>
      </div>
    </template>
  </ElDialog>
</template>
<script>
import {ElMessage, useSpace} from 'element-plus';
import {useStore} from 'vuex';
import {computed, onMounted, ref} from 'vue';
import { requestBatchBlackEmail } from '../../api/mail';
export default {
  emits: ['succ', 'cancel'],
  props: {
    showFlag: {
      type: Boolean,
      default: true
    },
    list: {
      type: Array,
      default: [],
    },
  },
  setup(props, {emit}) {
    const store = useStore();
    const loading = ref(false);
    const teamId = computed(() => store.state.email.teamID);

    async function handleEntry() {
      loading.value = true;
      const result = await requestBatchBlackEmail({
        emailList: props.list,
        teamId: teamId.value,
      });
      loading.value = false;
      if (result.code !== 200) {
        ElMessage.error(result.message);
        return;
      }
      ElMessage.success('邮箱拉黑成功！');
      emit('succ');
    }

    return {
      loading,
      handleEntry,
    }
  },
}
</script>
<style lang="less" scoped>
.content {
  .t {
    font-size: 14px;
    margin-bottom: 16px;
  }

  .mail {
    word-break: break-all;
    font-weight: 700;
    font-size: 14px;
  }
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>