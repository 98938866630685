import _store from 'store2'
import {addCalendar, deleteCalendar, getCalendarList, queryById, updateCalendar} from "@/api/calendar";
import {ElMessage} from "element-plus";
import {processData} from "@/utils/calendar";

const calendar = {
    namespaced: true,
    state: {
        dateList: [],
        tag: '',
        queryData: []
    },
    getters: {
        expectDayTime(state) {
            return processData(state.dateList)
        },
        queryData(state) {
            return processData(state.queryData)
        }
    },
    actions: {
        // 列表查询
        async getCalendarListApis({state, dispatch, rootState}, payload) {
            await getCalendarList(payload).then(res => {
                // console.log(res, 'res')
                state.dateList = res.result;
                // state.tag = 'list'
            })

        },
        //     添加记录
        async addCalendarApis({state, dispatch, rootState}, payload) {
            // console.log(payload, 'payload')
            await addCalendar(payload).then((res) => {
                // state.tag = 'add' + state.tag;
            })


        },
        //     删除记录
        async deleteCalendarApis({state, dispatch, rootState}, payload) {
            // console.log(payload, 'payload')
            await deleteCalendar(payload).then((res) => {
                // state.tag = 'delete' + state.tag;
            })
        },
        //     更新数据
        async updateCalendarApis({state, dispatch, rootState}, payload) {
            await updateCalendar(payload).then(res => {
                // state.tag = 'update' + state.tag;
            })


        },
        //     获取指定日期
        async queryByIdApis({state, dispatch, rootState}, payload) {
            await queryById(payload).then(res => {
                state.queryData = res.result;
                // state.tag = 'query' + state.tag;
            })


        }
    },
}

export default calendar
