import AD from '@/assets/flag/AD.png'
import AE from '@/assets/flag/AE.png'
import AF from '@/assets/flag/AF.png'
import AG from '@/assets/flag/AG.png'
import AI from '@/assets/flag/AI.png'
import AL from '@/assets/flag/AL.png'
import AM from '@/assets/flag/AM.png'
import AO from '@/assets/flag/AO.png'
import AR from '@/assets/flag/AR.png'
import AS from '@/assets/flag/AS.png'
import AT from '@/assets/flag/AT.png'
import AU from '@/assets/flag/AU.png'
import AW from '@/assets/flag/AW.png'
import AZ from '@/assets/flag/AZ.png'
import BA from '@/assets/flag/BA.png'
import BB from '@/assets/flag/BB.png'
import BD from '@/assets/flag/BD.png'
import BE from '@/assets/flag/BE.png'
import BF from '@/assets/flag/BF.png'
import BG from '@/assets/flag/BG.png'
import BH from '@/assets/flag/BH.png'
import BI from '@/assets/flag/BI.png'
import BJ from '@/assets/flag/BJ.png'
import BM from '@/assets/flag/BM.png'
import BN from '@/assets/flag/BN.png'
import BO from '@/assets/flag/BO.png'
import BR from '@/assets/flag/BR.png'
import BS from '@/assets/flag/BS.png'
import BT from '@/assets/flag/BT.png'
import BV from '@/assets/flag/BV.png'
import BW from '@/assets/flag/BW.png'
import BY from '@/assets/flag/BY.png'
import BZ from '@/assets/flag/BZ.png'
import CA from '@/assets/flag/CA.png'
import CC from '@/assets/flag/CC.png'
import CF from '@/assets/flag/CF.png'
import CG from '@/assets/flag/CG.png'
import CH from '@/assets/flag/CH.png'
import CI from '@/assets/flag/CI.png'
import CK from '@/assets/flag/CK.png'
import CL from '@/assets/flag/CL.png'
import CM from '@/assets/flag/CM.png'
import CN from '@/assets/flag/CN.png'
import CO from '@/assets/flag/CO.png'
import COD from '@/assets/flag/COD.png'
import CR from '@/assets/flag/CR.png'
import CU from '@/assets/flag/CU.png'
import CV from '@/assets/flag/CV.png'
import CX from '@/assets/flag/CX.png'
import CY from '@/assets/flag/CY.png'
import CZ from '@/assets/flag/CZ.png'
import DE from '@/assets/flag/DE.png'
import DJ from '@/assets/flag/DJ.png'
import DK from '@/assets/flag/DK.png'
import DM from '@/assets/flag/DM.png'
import DOM from '@/assets/flag/DOM.png'
import DZ from '@/assets/flag/DZ.png'
import EC from '@/assets/flag/EC.png'
import EE from '@/assets/flag/EE.png'
import EG from '@/assets/flag/EG.png'
import EH from '@/assets/flag/EH.png'
import ER from '@/assets/flag/ER.png'
import ES from '@/assets/flag/ES.png'
import ET from '@/assets/flag/ET.png'
import FI from '@/assets/flag/FI.png'
import FJ from '@/assets/flag/FJ.png'
import FK from '@/assets/flag/FK.png'
import FM from '@/assets/flag/FM.png'
import FO from '@/assets/flag/FO.png'
import FR from '@/assets/flag/FR.png'
import GA from '@/assets/flag/GA.png'
import GD from '@/assets/flag/GD.png'
import GE from '@/assets/flag/GE.png'
import GF from '@/assets/flag/GF.png'
import GH from '@/assets/flag/GH.png'
import GI from '@/assets/flag/GI.png'
import GL from '@/assets/flag/GL.png'
import GM from '@/assets/flag/GM.png'
import GN from '@/assets/flag/GN.png'
import GP from '@/assets/flag/GP.png'
import GQ from '@/assets/flag/GQ.png'
import GR from '@/assets/flag/GR.png'
import GT from '@/assets/flag/GT.png'
import GU from '@/assets/flag/GU.png'
import GW from '@/assets/flag/GW.png'
import GY from '@/assets/flag/GY.png'
import HK from '@/assets/flag/HK.png'
import HM from '@/assets/flag/HM.png'
import HN from '@/assets/flag/HN.png'
import HR from '@/assets/flag/HR.png'
import HT from '@/assets/flag/HT.png'
import HU from '@/assets/flag/HU.png'
import ID from '@/assets/flag/ID.png'
import IE from '@/assets/flag/IE.png'
import IL from '@/assets/flag/IL.png'
import IM from '@/assets/flag/IM.png'
import IN from '@/assets/flag/IN.png'
import IO from '@/assets/flag/IO.png'
import IQ from '@/assets/flag/IQ.png'
import IR from '@/assets/flag/IR.png'
import IS from '@/assets/flag/IS.png'
import IT from '@/assets/flag/IT.png'
import JM from '@/assets/flag/JM.png'
import JO from '@/assets/flag/JO.png'
import JP from '@/assets/flag/JP.png'
import KE from '@/assets/flag/KE.png'
import KG from '@/assets/flag/KG.png'
import KH from '@/assets/flag/KH.png'
import KI from '@/assets/flag/KI.png'
import KM from '@/assets/flag/KM.png'
import KN from '@/assets/flag/KN.png'
import KP from '@/assets/flag/KP.png'
import KR from '@/assets/flag/KR.png'
import KW from '@/assets/flag/KW.png'
import KY from '@/assets/flag/KY.png'
import LA from '@/assets/flag/LA.png'
import LB from '@/assets/flag/LB.png'
import LC from '@/assets/flag/LC.png'
import LI from '@/assets/flag/LI.png'
import LK from '@/assets/flag/LK.png'
import LR from '@/assets/flag/LR.png'
import LS from '@/assets/flag/LS.png'
import LT from '@/assets/flag/LT.png'
import LU from '@/assets/flag/LU.png'
import LV from '@/assets/flag/LV.png'
import LY from '@/assets/flag/LY.png'
import MA from '@/assets/flag/MA.png'
import MC from '@/assets/flag/MC.png'
import MD from '@/assets/flag/MD.png'
import MG from '@/assets/flag/MG.png'
import MH from '@/assets/flag/MH.png'
import MK from '@/assets/flag/MK.png'
import ML from '@/assets/flag/ML.png'
import MM from '@/assets/flag/MM.png'
import MN from '@/assets/flag/MN.png'
import MO from '@/assets/flag/MO.png'
import MP from '@/assets/flag/MP.png'
import MQ from '@/assets/flag/MQ.png'
import MR from '@/assets/flag/MR.png'
import MS from '@/assets/flag/MS.png'
import MT from '@/assets/flag/MT.png'
import MU from '@/assets/flag/MU.png'
import MV from '@/assets/flag/MV.png'
import MW from '@/assets/flag/MW.png'
import MX from '@/assets/flag/MX.png'
import MY from '@/assets/flag/MY.png'
import MZ from '@/assets/flag/MZ.png'
import NA from '@/assets/flag/NA.png'
import NC from '@/assets/flag/NC.png'
import NE from '@/assets/flag/NE.png'
import NF from '@/assets/flag/NF.png'
import NG from '@/assets/flag/NG.png'
import NI from '@/assets/flag/NI.png'
import NL from '@/assets/flag/NL.png'
import NO from '@/assets/flag/NO.png'
import NP from '@/assets/flag/NP.png'
import NR from '@/assets/flag/NR.png'
import NU from '@/assets/flag/NU.png'
import NZ from '@/assets/flag/NZ.png'
import OM from '@/assets/flag/OM.png'
import PA from '@/assets/flag/PA.png'
import PE from '@/assets/flag/PE.png'
import PF from '@/assets/flag/PF.png'
import PG from '@/assets/flag/PG.png'
import PH from '@/assets/flag/PH.png'
import PK from '@/assets/flag/PK.png'
import PL from '@/assets/flag/PL.png'
import PM from '@/assets/flag/PM.png'
import PN from '@/assets/flag/PN.png'
import PR from '@/assets/flag/PR.png'
import PS from '@/assets/flag/PS.png'
import PT from '@/assets/flag/PT.png'
import PW from '@/assets/flag/PW.png'
import RE from '@/assets/flag/RE.png'
import RO from '@/assets/flag/RO.png'
import RU from '@/assets/flag/RU.png'
import RW from '@/assets/flag/RW.png'
import SA from '@/assets/flag/SA.png'
import SB from '@/assets/flag/SB.png'
import SC from '@/assets/flag/SC.png'
import SD from '@/assets/flag/SD.png'
import SE from '@/assets/flag/SE.png'
import SG from '@/assets/flag/SG.png'
import SH from '@/assets/flag/SH.png'
import SI from '@/assets/flag/SI.png'
import SJ from '@/assets/flag/SJ.png'
import SK from '@/assets/flag/SK.png'
import SL from '@/assets/flag/SL.png'
import SM from '@/assets/flag/SM.png'
import SN from '@/assets/flag/SN.png'
import SO from '@/assets/flag/SO.png'
import SR from '@/assets/flag/SR.png'
import ST from '@/assets/flag/ST.png'
import SV from '@/assets/flag/SV.png'
import SY from '@/assets/flag/SY.png'
import SZ from '@/assets/flag/SZ.png'
import TC from '@/assets/flag/TC.png'
import TD from '@/assets/flag/TD.png'
import TF from '@/assets/flag/TF.png'
import TG from '@/assets/flag/TG.png'
import TH from '@/assets/flag/TH.png'
import TJ from '@/assets/flag/TJ.png'
import TK from '@/assets/flag/TK.png'
import TM from '@/assets/flag/TM.png'
import TN from '@/assets/flag/TN.png'
import TO from '@/assets/flag/TO.png'
import TR from '@/assets/flag/TR.png'
import TT from '@/assets/flag/TT.png'
import TV from '@/assets/flag/TV.png'
import TW from '@/assets/flag/TW.png'
import TZ from '@/assets/flag/TZ.png'
import UA from '@/assets/flag/UA.png'
import UG from '@/assets/flag/UG.png'
import UK from '@/assets/flag/UK.png'
import UM from '@/assets/flag/UM.png'
import UN from '@/assets/flag/UN.png'
import US from '@/assets/flag/US.png'
import UY from '@/assets/flag/UY.png'
import UZ from '@/assets/flag/UZ.png'
import VA from '@/assets/flag/VA.png'
import VC from '@/assets/flag/VC.png'
import VE from '@/assets/flag/VE.png'
import VG from '@/assets/flag/VG.png'
import VI from '@/assets/flag/VI.png'
import VN from '@/assets/flag/VN.png'
import VU from '@/assets/flag/VU.png'
import WF from '@/assets/flag/WF.png'
import WS from '@/assets/flag/WS.png'
import XK from '@/assets/flag/XK.png'
import YE from '@/assets/flag/YE.png'
import YT from '@/assets/flag/YT.png'
import ZA from '@/assets/flag/ZA.png'
import ZM from '@/assets/flag/ZM.png'
import ZW from '@/assets/flag/ZW.png'
import SRB from '@/assets/flag/SRB.png'
import QA from "@/assets/flag/QA.png"
import KZ from "@/assets/flag/KZ.png"
import PY from "@/assets/flag/PY.png"
import MNE from "@/assets/flag/MNE.png"
import TLS from "@/assets/flag/TLS.webp"
export default {
    TLS,
    MNE,
    PY,
    AD,
    AE,
    AF,
    AG,
    AI,
    AL,
    AM,
    AO,
    AR,
    AS,
    AT,
    AU,
    AW,
    AZ,
    BA,
    BB,
    BD,
    BE,
    BF,
    BG,
    BH,
    BI,
    BJ,
    BM,
    BN,
    BO,
    BR,
    BS,
    BT,
    BV,
    BW,
    BY,
    BZ,
    CA,
    CC,
    CF,
    CG,
    CH,
    CI,
    CK,
    CL,
    CM,
    CN,
    CO,
    COD,
    CR,
    CU,
    CV,
    CX,
    CY,
    CZ,
    DE,
    DJ,
    DK,
    DM,
    DOM,
    DZ,
    EC,
    EE,
    EG,
    EH,
    ER,
    ES,
    ET,
    FI,
    FJ,
    FK,
    FM,
    FO,
    FR,
    GA,
    GD,
    GE,
    GF,
    GH,
    GI,
    GL,
    GM,
    GN,
    GP,
    GQ,
    GR,
    GT,
    GU,
    GW,
    GY,
    HK,
    HM,
    HN,
    HR,
    HT,
    HU,
    ID,
    IE,
    IL,
    IM,
    IN,
    IO,
    IQ,
    IR,
    IS,
    IT,
    JM,
    JO,
    JP,
    KE,
    KG,
    KH,
    KI,
    KM,
    KN,
    KP,
    KR,
    KW,
    KY,
    LA,
    LB,
    LC,
    LI,
    LK,
    LR,
    LS,
    LT,
    LU,
    LV,
    LY,
    MA,
    MC,
    MD,
    MG,
    MH,
    MK,
    ML,
    MM,
    MN,
    MO,
    MP,
    MQ,
    MR,
    MS,
    MT,
    MU,
    MV,
    MW,
    MX,
    MY,
    MZ,
    NA,
    NC,
    NE,
    NF,
    NG,
    NI,
    NL,
    NO,
    NP,
    NR,
    NU,
    NZ,
    OM,
    PA,
    PE,
    PF,
    PG,
    PH,
    PK,
    PL,
    PM,
    PN,
    PR,
    PS,
    PT,
    PW,
    RE,
    RO,
    RU,
    RW,
    SA,
    SB,
    SC,
    SD,
    SE,
    SG,
    SH,
    SI,
    SJ,
    SK,
    SL,
    SM,
    SN,
    SO,
    SR,
    ST,
    SV,
    SY,
    SZ,
    TC,
    TD,
    TF,
    TG,
    TH,
    TJ,
    TK,
    TM,
    TN,
    TO,
    TR,
    TT,
    TV,
    TW,
    TZ,
    UA,
    UG,
    UK,
    UM,
    UN,
    US,
    UY,
    UZ,
    VA,
    VC,
    VE,
    VG,
    VI,
    VN,
    VU,
    WF,
    WS,
    XK,
    YE,
    YT,
    ZA,
    ZM,
    ZW,
    SRB,
    QA,
    KZ
}
