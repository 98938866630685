<template>
  <ElDialog
      v-model="isShow"
      :title="$t('sending.create.grouptask')"
      :footer="null"
      centered
      :width="870"
      destroyOnClose
      @cancel="close"
      :class="[isHeadLine ? 'headline' : 'noheadline']"
  >
    <div style="display: flex;justify-content: center;align-items: center">
      <div @click="handleClick" :class="isSelect===1 ? 'small_box border' : 'small_box border_normal'"
           style="position: relative">
        <div>
          <div>
            <img v-if="isSelect===1" src="@/assets/sendgroup/send1_select.png" width="160" height="160" @click.stop>
            <img v-else src="@/assets/sendgroup/send1.png" width="160" height="160" @click.stop>
          </div>
          <div>
            <p :class="isSelect===1?'select':'unselect'" @click.stop>
              {{ $t('sending.customer.option.massMailType.system') }}</p>
            <p @click.stop>{{ $t('common.system.des1') }}</p>
            <p @click.stop>{{ $t('common.system.des2') }}</p>
          </div>
        </div>
        <div style="width: 310px;height: 310px;background: rgba(0,0,0,0);position: absolute;border-radius: 10px;"
             id="box1"></div>
      </div>
      <div @click="handleClick" :class="isSelect===2 ? 'small_box border' : 'small_box border_normal'"
           style="position: relative">
        <div>
          <div>
            <img v-if="isSelect===2" src="@/assets/sendgroup/send2_select.png" width="160" height="160">
            <img v-else src="@/assets/sendgroup/send2.png" width="160" height="160">
          </div>
          <div>
            <p :class="isSelect===2?'select':'unselect'">{{ $t('sending.customer.option.massMailType.smart') }}</p>
            <p>{{ $t('common.smart.des1') }}</p>
            <p>{{ $t('common.smart.des2') }}</p>
          </div>
        </div>
        <div style="width: 310px;height: 310px;background: rgba(0,0,0,0);position: absolute;border-radius: 10px;"
             id="box2"></div>
      </div>
    </div>
    <div class="dialog-footer">
      <ElButton @click="close">{{
          $t("common.cancel")
        }}
      </ElButton>
      <ElButton type="primary" @click="checkTargetTask" :loading="loading">{{
          $t("common.confirm")
        }}
      </ElButton>
    </div>
  </ElDialog>
</template>

<script setup>
import {ElButton, ElDialog, ElMessage} from "element-plus";
import {ref, defineProps, watch, defineEmits} from 'vue'
import {useStore} from "vuex";
import {throttle} from "@/utils/mylodash"
import {useRouter} from "vue-router";
import {TIME_OUT} from "@/utils/statusParams";

const store = useStore();
const emit = defineEmits()
const isShow = ref(false);
const router = useRouter()
const loading = ref(false)
const isSelect = ref(0)

const handleClick = (e) => {
  const targetId = e.target.getAttribute("id");
  console.log(targetId, 'targetId')
  if (targetId) {
    if (targetId === 'box1') {
      isSelect.value = 1;
    } else if (targetId === 'box2') {
      isSelect.value = 2;
    }
  }
}


const {value, isHeadLine, activeItem} = defineProps({
  value: {type: Boolean, default: false},
  isHeadLine: {type: Boolean, default: false},
  activeItem: {type: Boolean, default: null}
})

watch(() => value, (val) => {
  isShow.value = val;
}, {immediate: true})
const close = () => {
  emit("close");
}


const checkTargetTask = throttle(() => {
  if (isSelect.value === 0) {
    ElMessage.warning("请选择一种群发的任务模式")
    return
  } else {
    if (activeItem) {
      router.push({path: '/email/send_all', query: {type: isSelect.value, taskCode: activeItem}})
    } else {
      // 1 表示 系统群发
      // 2 表示 智能群发
      router.push({path: '/email/send_all', query: {type: isSelect.value}})
    }
  }

}, TIME_OUT)
</script>
<style scoped lang="less">
.fx-j-c {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.title_cdir {
  color: rgba(0, 0, 0, .85);
  font-weight: 700;
  font-size: 14px;
}

.fx-j-r {
  display: flex;
  justify-content: right;
  margin-top: 40px;
}

.dialog-footer {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.small_box:nth-of-type(1) {
  margin-left: 100px;
  margin-right: 25px;
}

.small_box:nth-of-type(2) {
  margin-left: 25px;
  margin-right: 100px;
}

.small_box {
  width: 310px;
  height: 310px;
  box-sizing: border-box;
  border-radius: 10px;
  cursor: pointer;
  transition: all .3s;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > div:nth-of-type(2) {
      margin-top: 30px;
    }


    & > div > p {
      text-align: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.65);
    }

    & > div > p:nth-of-type(1) {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #8C8C8C;
      margin-bottom: 6px;
    }
  }
}

.select {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px !important;
  color: #1890FF !important;
  margin-bottom: 6px;
  box-sizing: border-box;
  transition: all .3s;
}

.border {
  transition: all .3s;
  border: 2px solid #1890FF;
}

.unselect {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px !important;
  color: #8C8C8C !important;
  margin-bottom: 6px;
  transition: all .3s;
  box-sizing: border-box;
}

.border_normal {
  border: 1px solid #D9D9D9;
  transition: all .3s;
}


</style>
