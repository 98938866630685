import {i18n} from "@/lang";

export const table_columns_list = [
    {key: i18n.global.t('common_customer_number'), value: "customerCode"},
    {key: i18n.global.t('common_customer_name'), value: "customerName"},
    {key: i18n.global.t("common.customer.source"), value: "customerOrigin"},
    {key: i18n.global.t('common.customer.Region'), value: "zoneAddress"},
    {key: i18n.global.t('common_customer_type'), value: "customerType"},
    {key: i18n.global.t('common_customer_level'), value: "customerLevel"},
    {key: i18n.global.t('common.contact'), value: "maiContactVOList"},
    {key: i18n.global.t('common.byinput'), value: "createName"},
    {key: i18n.global.t('common.bybranch'), value: "manageId"},
    {key: i18n.global.t('common.lastcontact.time'), value: "lastTime"},
    {key: i18n.global.t('common.cz'), value: "setting"},
];

export const table_columns_list_sp = [
    {key: i18n.global.t('common_customer_number'), value: i18n.global.t('common_customer_number')},
    {key: i18n.global.t('common_customer_name'), value: i18n.global.t('common_customer_name')},
    {key: i18n.global.t('common.customer.Region'), value: i18n.global.t('common.customer.Region')},
    {key: i18n.global.t('common_customer_type'), value: i18n.global.t('common_customer_type')},
    {key: i18n.global.t('common_customer_level'), value: i18n.global.t('common_customer_level')},
    {key: i18n.global.t('common.contact'), value: i18n.global.t('common.contact')},
    {key: i18n.global.t('common.byinput'), value: i18n.global.t('common.byinput')},
    {key: i18n.global.t('common.bybranch'), value: i18n.global.t('common.bybranch')},
    {key: i18n.global.t('common.lastcontact.time'), value: i18n.global.t('common.lastcontact.time')},
    {key: i18n.global.t('common.cz'), value: i18n.global.t('common.cz')},
];

export const table_columns_public_list = [
    {key: i18n.global.t('common_customer_number'), value: "customerCode"},
    {key: i18n.global.t('common_customer_name'), value: "customerName"},
    {key: i18n.global.t("common.customer.source"), value: "customerOrigin"},
    {key: i18n.global.t('common.customer.Region'), value: "zoneAddress"},
    {key: i18n.global.t('common_customer_type'), value: "customerType"},
    {key: i18n.global.t('common_customer_level'), value: "customerLevel"},
    {key: i18n.global.t('common.contact'), value: "maiContactVOList"},
    {key: i18n.global.t('common.byinput'), value: "createName"},
    {key: i18n.global.t('common_lastcontact_time'), value: "lastTime"},
    {key: i18n.global.t('common.customer.inChargeTheLast'), value: "mangerVOList"},
];

export const table_columns_public_list_group = [
    {key: i18n.global.t('common_customer_number'), value: "customerCode"},
    {key: i18n.global.t('common_customer_name'), value: "customerName"},
    {key: i18n.global.t("common.customer.source"), value: "customerOrigin"},
    {key: i18n.global.t('common.customer.Region'), value: "zoneAddress"},
    {key: i18n.global.t('common_customer_type'), value: "customerType"},
    {key: i18n.global.t('common_customer_level'), value: "customerLevel"},
    {key: i18n.global.t('common.byinput'), value: "createName"},
    // {key: i18n.global.t('common.customer.inChargeTheLast'), value: "mangerVOList"},
];
export const table_columns_public_2 = [
    {key: i18n.global.t('common_customer_number'), value: "customerCode"},
    {key: i18n.global.t('common_customer_name'), value: "customerName"},
    {key: i18n.global.t('common.shr'), value: "auditName"},
    {key: i18n.global.t('common.cds78'), value: "applyTime"},
    {key: i18n.global.t('common.claim.user'), value: "userName"},
    {key: i18n.global.t('common.examine.state'), value: "auditStatus"},
];

export const table_columns_admin = [
    {key: i18n.global.t('common_customer_number'), value: "customerCode"},
    {key: i18n.global.t('common_customer_name'), value: "customerName"},
    {key: i18n.global.t("common.customer.source"), value: "customerOrigin"},
    {key: i18n.global.t('common.customer.Region'), value: "zoneAddress"},
    {key: i18n.global.t('common_customer_type'), value: "customerType"},
    {key: i18n.global.t('common_customer_level'), value: "customerLevel"},
    {key: i18n.global.t('common.byinput'), value: "createName"},
    {key: i18n.global.t('common.contact'), value: "maiContactVOList"},
    {key: i18n.global.t('common.bybranch'), value: "manageId"},
    {key: i18n.global.t('common_lastcontact_time'), value: "lastTime"},
];


export const table_columns_required = [
    {key: i18n.global.t('common_customer_number'), value: "customerCode"},
    {key: i18n.global.t('common_customer_name'), value: "customerName"},
    {key: i18n.global.t("common.customer.source"), value: "customerOrigin"},
    {key: i18n.global.t('common.customer.Region'), value: "zoneAddress"},
    {key: i18n.global.t('common_customer_type'), value: "customerType"},
    {key: i18n.global.t('common_customer_level'), value: "customerLevel"},
    {key: i18n.global.t('common.contact'), value: "maiContactVOList"},
    {key: i18n.global.t('common.byinput'), value: "createName"},
    {key: i18n.global.t('common_lastcontact_time'), value: "lastTime"},
    {key: i18n.global.t('common.applicant'), value: "createName"},
    {key: i18n.global.t('common.time.apply'), value: "applyTime"}
];


export const table_columns_follow =
    [
        {key: i18n.global.t('common_customer_number'), value: "customerCode"},
        {key: i18n.global.t('common_customer_name'), value: "customerName"},
        {key: i18n.global.t("common.customer.source"), value: "customerOrigin"},
        {key: i18n.global.t('common.customer.Region'), value: "zoneAddress"},
        {key: i18n.global.t('common_customer_type'), value: "customerType"},
        {key: i18n.global.t('common_customer_level'), value: "customerLevel"},
        {key: i18n.global.t('common.followup.rate'), value: "alertDay"},
        {key: i18n.global.t('common.followup.time'), value: "followTime"},
    ]






















