<template>
  <div class="box">
    <div class="out_box">
      <div class="btn_tools">
        <el-button type="primary" plain :icon="Download" @click="downloadTargetFile">下载</el-button>
        <el-button type="primary" plain :icon="Document" @click="moveTo(_,'3')"
                   :disabled="activeItem.filter(item=>item.isFolder===2).length>=1">保存到
        </el-button>
      </div>
      选中的文件夹{{ activeItem.length }}
      <div class="content">
        <div class="content_info">
          <div>
            <span style="display: block;height: 24px" class="icon"><i aria-label="图标: folder"
                                                                      class="anticon anticon-folder"
                                                                      style="color: rgb(41, 182, 246); font-size: 24px;"><svg
                viewBox="64 64 896 896" data-icon="folder" width="1em" height="1em" fill="currentColor"
                aria-hidden="true" focusable="false" class=""><path
                d="M880 298.4H521L403.7 186.2a8.15 8.15 0 0 0-5.5-2.2H144c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V330.4c0-17.7-14.3-32-32-32z"></path></svg></i></span>
            <span class="font" style="margin-left: 6px">{{ shareTargetUser.userName }}的文件夹</span>
          </div>
          <div class="fu_title">分享时间：{{ tableData.length!==0?  tableData[0].createTime : 0 }} 过期时间：{{
              tableData.length !== 0 ? tableData[0].shareDay : 0
            }} 天后失效</div>
          <div class="file_all">
            <el-breadcrumb separator="/">
              <el-breadcrumb-item v-for="(item, index) in pathArrayInfo" :key="item.path"
                                  @click="handleClick(item, index)">
            <span
                style="cursor: pointer">{{ item.text }}</span>
              </el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="table_content">
            <el-table
                ref="multipleTableRef"
                :data="tableData"
                v-loading="tableLoading"
                @selectionChange="selectCheckbox"
                v-el-table-infinite-scroll="handleTableScroll"
                @row-dblclick="selectTargetDetail"
            >
              <el-table-column type="selection" width="60" fixed="left"/>
              <el-table-column prop="fileName" label="文件名" class="file_names">
                <template #default="{row}">
                  <div v-if="row.isFolder===1" style="display: flex;align-items: center;">
                    <FileIcon :fileExtend="row.fileExtend" :fileUrl="row.fileExtendPath" :size="25"
                              :isAbbreviation="true"></FileIcon>
                    <el-button link class="pointer" style="margin-left: 10px" @click.native.stop="showTargetFile(row)">
                      {{
                        row.fileName
                      }}
                    </el-button>
                  </div>
                  <div v-if="row.isFolder===2" style="display: flex;align-items: center;">
              <span style="display: block;height: 24px" class="icon"><i aria-label="图标: folder"
                                                                        class="anticon anticon-folder"
                                                                        style="color: rgb(41, 182, 246); font-size: 24px;"><svg
                  viewBox="64 64 896 896" data-icon="folder" width="1em" height="1em" fill="currentColor"
                  aria-hidden="true" focusable="false" class=""><path
                  d="M880 298.4H521L403.7 186.2a8.15 8.15 0 0 0-5.5-2.2H144c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V330.4c0-17.7-14.3-32-32-32z"></path></svg></i></span>
                    <el-button link type="primary" class="pointer" style="margin-left: 10px"
                               @click.native.stop="enterDirectory(row)"
                    >
                      {{ row.fileName }}
                    </el-button>
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="type===2" prop="createName" width="100" label="创建者"></el-table-column>
              <el-table-column v-if="type===4" width="100" prop="shareStatus" label="分享状态">
                <template #default="{row}">
                  <el-button link type="info" v-if="row.shareStatus===1">已失效</el-button>
                </template>
              </el-table-column>
              <el-table-column prop="contentSize" sortable label="大小" width="120">
                <template #default="scope">
                  <template v-if="scope.row.isFolder===2"></template>
                  <template v-else>
                    {{ formatSize(scope.row.fileSize) }}
                  </template>
                </template>
              </el-table-column>
              <el-table-column v-if="type===1||type===3||type===5" prop="date" sortable label="修改时间" width="180"
                               show-overflow-tooltip>
                <template #default="scope">
                  <template v-if="scope.row.updateTime!=null">{{ scope.row.updateTime }}</template>
                  <template v-else>
                    {{ scope.row.createTime }}
                  </template>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <MoveTargetFile v-if="moveTargetFileVisitable" v-model="moveTargetFileVisitable"
                  @close="closeMoveTarget" :is-head-line="true" :type="7" :activeItem="activeItem"
                  :list="getLinkFileList" :numberFlag="numberFlag"></MoveTargetFile>
</template>

<script setup>
import {Download, Document,} from "@element-plus/icons-vue";
import {ElMessage, ElTable} from "element-plus";
import {computed, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {downloadFile, formatSize} from "@/utils/file";
import "viewerjs/dist/viewer.css";
import {api as viewerApi} from 'v-viewer';
import {debouncePlus} from "@/utils/mylodash"
import MoveTargetFile from "@/views/Cloud/Components/MoveTargetFile.vue";
import FileIcon from "@/components/File/FileIcon.vue";

const TIME_DELAY = 300;
const store = useStore();
const route = useRoute();
const router = useRouter();
const tableData = computed(() => store.state.cloud.fileList)

const shareTargetUser = computed(() => store.state.cloud.shareTargetUser)

onMounted(async () => {
  await getLinkFileList();
  await getTargetUserData()
})

const getTargetUserData = async () => {
  await store.dispatch("cloud/getShareNameByLinkCodeApis", {linkAddress: route.query.linkAddress})
}

// 多选框select
const activeItem = ref([])
const selectCheckbox = (selectArray) => {
  console.log('选中的', selectArray)
  activeItem.value = selectArray
}

const pageNo = ref(1);
const pageSize = ref(20);
const getLinkFileList = async () => {
  tableLoading.value = true
  if (route.query.id) {
    pathArrayInfo.value = JSON.parse(sessionStorage.getItem('pathArrayInfo'));
    await store.dispatch('cloud/queryShareFileByParentIdApi', {
      pageNo: current.value,
      pageSize: size.value,
      parentId: route.query.id,
    })
  } else {
    // 跟路径永远只有一个文件夹
    const payload = {
      linkAddress: route.query.linkAddress,
      shareCode: route.query.shareCode,
      pageNo: pageNo.value,
      pageSize: pageSize.value,
    }
    await store.dispatch("cloud/getFileByLinkAddressApis", payload)
  }
  tableLoading.value = false
}

const tableLoading = ref(false)
const type = 1

// 面包屑
const pathArrayInfo = ref([{path: "0", text: '全部文件', tag: "0"}])

const current = ref(1);
const size = ref(20);
const total = computed(() => store.state.cloud.total);

const showTargetFile = (row) => {
  // alert(row.name)
  let images = [];
  let initialViewIndex = 0;
  // 过滤出非文件夹的文件
  tableData.value.filter(_ => _.isFolder !== 2).forEach((o, i) => {
    if (o.fileName === row.fileName) initialViewIndex = images.length
    images.push({src: o.fileExtendPath})
  })
  console.log(tableData.value, row.fileName, 'tableData.value')

  viewerApi({
    images,
    options: {
      initialViewIndex
    }
  })
}

// 进入文件夹
const tempRouter = ref('')
const enterDirectory = debouncePlus(async (row) => {
  tableLoading.value = true
  resetCurrentAbout()
  console.log(type, route.path, row.id, row.fileId, 'targetfs'
  )
  if (route.path === '/cloud/info') {
    await store.dispatch('cloud/queryShareFileByParentIdApi', {
      pageNo: current.value,
      pageSize: size.value,
      parentId: row.id
    })
  }
  // 是文件夹
  const filterTagsArray = pathArrayInfo.value.map(item => item.tag)
  const targetPath = [...filterTagsArray, row.id].join(',')
  console.log(targetPath, filterTagsArray, pathArrayInfo.value, '$$$')
  tempRouter.value = targetPath;
  pathArrayInfo.value.push({path: targetPath, text: row.fileName, tag: row.id})
  // 数据持久化
  sessionStorage.setItem('pathArrayInfo', JSON.stringify(pathArrayInfo.value))
  await router.push({
    path: route.path,
    query: {id: row.id, path: targetPath, linkAddress: route.query.linkAddress, shareCode: route.query.shareCode}
  })
  tableLoading.value = false
  console.log('-----', route.path)
}, 500)

/**
 * 处理完操作需要重新判断页数和 重置
 */
const resetCurrentAbout = () => {
  store.commit("cloud/changeCurrent", false)
  if (current.value * size.value >= total.value) {
    if (current.value)
      current.value = 1;
  }
}


const handleClick = async (item, index) => {
  tableLoading.value = true
  resetCurrentAbout()
  // 处理面包屑导航点击事件
  console.log(item, index, route, pathArrayInfo.value, item.tag === "0", 'click')

  console.log(item, route.path, 'targetItem')
  const targetIndex = findIndexWithTag(pathArrayInfo.value, item.tag);
  if (item.tag !== "0") {
    await router.push({
      path: route.path,
      query: {id: item.tag, path: item.path, linkAddress: route.query.linkAddress, shareCode: route.query.shareCode}
    })
  } else {
    await router.push({
      path: route.path,
      query: {linkAddress: route.query.linkAddress, shareCode: route.query.shareCode}
    })
  }
  //   说明后面还有数据
  pathArrayInfo.value.splice(targetIndex + 1)
  sessionStorage.setItem('pathArrayInfo', JSON.stringify(pathArrayInfo.value))
  if (item.tag !== "0") {
    await store.dispatch('cloud/queryShareFileByParentIdApi', {
      pageNo: current.value,
      pageSize: size.value,
      parentId: item.tag,
    })
  } else {
    await getLinkFileList();
  }
  tableLoading.value = false
}

function findIndexWithTag(jsonData, flag) {
  for (var i = 0; i < jsonData.length; i++) {
    if (jsonData[i].tag === flag) {
      return i;
    }
  }
  return -1;
}

const pages = computed(() => store.state.cloud.pages)
let isFirstPageLoaded = computed(() => store.state.cloud.isFirstPageLoaded);
const handleTableScroll = debouncePlus(async () => {
  // 第一次scroll 数据加载
  if (!isFirstPageLoaded.value) {
    store.commit("cloud/changeIsFirstPageLoaded", true)
    return;
  }
  console.log('scroll', current.value, pages.value, tableData.value)
  if (current.value === pages.value) {
    if (current.value !== 1) {
      ElMessage.warning("已经到底了")
    }
    return;
  } else {
    store.commit("cloud/changeCurrent", true)
    current.value++
    await getLinkFileList();
  }
}, TIME_DELAY)


const downloadTargetFile = debouncePlus((row) => {
  if (row.id) {
    activeItem.value = []
    activeItem.value = [row]
  }

  if (activeItem.value.length === 0) {
    ElMessage.warning("请选择文件");
    return
  } else if (activeItem.value.filter(_ => _.isFolder === 2).length >= 1) {
    ElMessage.warning("文件夹不支持下载")
  } else if (activeItem.value.length === 1) {
    // console.log(activeItem.value[0].fileExtendPath,'activeItem.value[0].fileExtendPath')
    fetch(activeItem.value[0].fileExtendPath.replace(/^http/, "https"))
        // record.doc_url为文件url地址
        .then((res) => res.blob())
        .then((blob) => {
          downloadFile(blob, activeItem.value[0].fileName);
        })
        .catch((error) => {
          ElMessage.error('下载失败');
        });

  } else if (activeItem.value.length > 1) {
    //   多文件打包下载
    alert('多文件打包下载')
  }
}, TIME_DELAY)


// 双击
const selectTargetDetail = (row) => {
  if (row.isFolder === 2) {
    //   文件夹
    //   进入文件夹
    enterDirectory(row);
  } else {
    //  文件
    //  弹出 查看文件
    showTargetFile(row)
  }
}


// watch(() => route.query.id, async (newValue, oldValue) => {
//   pathArrayInfo.value = JSON.parse(sessionStorage.getItem('pathArrayInfo'));
//   console.log(newValue, oldValue, pathArrayInfo.value,
//       'ddddd'
//   )
// //   newValue新的路由地址
//   if (newValue === undefined) {
//     await getLinkFileList()
//   } else {
//     await store.dispatch('cloud/queryShareFileByParentIdApi', {
//       pageNo: current.value,
//       pageSize: size.value,
//       parentId: newValue,
//     })
//   }
// })

watch(() => route.path, (newVal, oldVal) => {
  console.log(newVal, oldVal, newVal.indexOf('/cloud') !== -1, '3333')
  if (newVal.indexOf('/cloud') !== -1) {
    // 禁止浏览器前进和后退
    history.pushState(null, null, document.URL);
    window.addEventListener(
        'popstate',
        function (e) {
          history.pushState(null, null, document.URL);
        }, false);
  }
})


const moveTargetFileVisitable = ref(false)

const closeMoveTarget = async () => {
  moveTargetFileVisitable.value = false
  await getLinkFileList()
}
// 保存到
const numberFlag = ref('1')
const moveTo = debouncePlus((row, num) => {
  console.log('fff', row, num, activeItem.value)
  if (row.id) {
    activeItem.value = [];
    activeItem.value = [row]
  }
  if (activeItem.value.length === 0) {
    ElMessage.warning("请选择文件");
    return
  } else {
    numberFlag.value = num
    resetCurrentAbout()
    moveTargetFileVisitable.value = true;
  }
}, TIME_DELAY)

</script>

<style lang="less" scoped>
.box {
  width: 100%;
  display: flex;
  justify-content: center;

  .out_box {
    .px2vw(width, 1400);

    .btn_tools {
      width: 100%;
      background: #f5f5f5;
      padding-bottom: 20px;
    }

    .content {
      width: 100%;
      background: white;
      .px2vh(height, 720);

      .content_info {
        & > div {
          padding: 0 20px;
        }

        & > div:nth-of-type(1) {
          display: flex;
          .px2vh(padding-top, 20)
        }

        & > div:nth-of-type(2) {
          .px2vh(margin-top, 14)
        }
      }
    }

  }
}

.font {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
}

.fu_title {
  color: #acacac;
  font-size: 14px;
}

.file_all {
  .px2vh(margin-top, 20);
  .px2vh(margin-bottom, 10)
}

</style>