import _store from "store2";
import {
    batchDeleteMassTask,
    queryByTaskCode,
    queryPageList,
    repeatSendMassMail,
    sendMassMail,
    statisticsMassTaskByTaskCode,
    updateMailMassStatus,
} from "@/api/groupSend";
import {ElMessage} from "element-plus";

const groupSend = {
    namespaced: true,
    state: {
        groupMailList: [],
        detailMailList: [],
        staticTaskMessage: [],
        total: 0,
    },
    mutations: {},
    actions: {
        //     获取邮件群发邮件列表
        async queryPageListApis({state,}, payload) {
            const res = await queryPageList(payload);
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return
            }
            return res.result
        },
        //     根据任务编号查询指定的任务
        async queryByTaskCodeApis({state}, payload) {
            const res = await queryByTaskCode(payload)
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return
            }
            return res.result;
        },
        //     改变群发任务状态
        async updateMailMassStatusApis({state}, payload) {
            const res = await updateMailMassStatus(payload);
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return
            }
        },

        //     重新重发群发邮件
        async repeatSendMassMailApis({state}, payload) {
            const res = await repeatSendMassMail(payload)
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return
            }
        },

        //     群发任务统计
        async statisticsMassTaskByTaskCodeApis({state}, payload) {
            const res = await statisticsMassTaskByTaskCode(payload)
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return
            }
            state.staticTaskMessage = res.result;
        },
    },
};

export default groupSend;
