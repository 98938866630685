import {getToken} from './auth';
//常见图片格式
let imgFormat = ['apng', 'avif', 'bmp', 'gif', 'ico', 'cur', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'svg', 'webp']
//常见音频格式
let audioFormat = ['ogg', 'mp3', 'wav', 'flac']
//常见视频格式
let videoFormat = ['mp4', 'webm']
//ppt所有格式
let pptFormat = ['pptx', 'ppt', 'potx', 'pot', 'pps', 'pptm', 'potm', 'ppsx', 'ppsm']
//word所有格式
let wordFormat = ['doc', 'dot', 'docx', 'dotx', 'docm', 'dotm']
//excel所有格式
let excelFormat = ['xls', 'xlt', 'xlsx', 'xlsm', 'xltx', 'xltm', 'xlam', 'xla']
//pdf格式
let pdfFormat = ['pdf']
//txt格式
let textFormat = ['txt', 'text']
//压缩文件格式
let zipFormat = ['rar', 'zip', '7z']

/**
 * @description: 获取文件后缀
 * @param {*} path 文件路径
 * @return {*} 类型
 */
const fileSuffix = function (path) {
    let exp = path.match(/\.(\w+)$/)
    return exp ? exp[1] : ''
}

/**
 * @description: 根据后缀名判断格式
 * @param {*} suffix 后缀名
 * @return {*} 类型
 */
const getFileFormatBySuffix = function (suffix) {
    suffix = suffix.toLowerCase()
    let format = {
        imgFormat,
        videoFormat,
        pptFormat,
        zipFormat,
        wordFormat,
        excelFormat,
        pdfFormat,
        textFormat,
        audioFormat
    }
    for (const key of Object.keys(format)) {
        if (format[key].includes(suffix)) return key.replace('Format', '')
    }
    return ''
}
const action = `${process.env.VUE_APP_BASE_API}/sys/oss/qiniu/upload`;

function uploadFile(file) {
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.set('file', file);
        const xhr = new XMLHttpRequest();
        xhr.open('POST', action);
        xhr.setRequestHeader('X-Access-Token', getToken());
        xhr.send(formData);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                const res = JSON.parse(xhr.response);
                resolve(res.result);
            }
        }
    });
}

function requestUploadFile(files) {
    return new Promise((resolve, reject) => {
        const p = files.map(file => uploadFile(file));
        Promise.all(p).then(res => {
            resolve(res);
        }).catch((err) => reject(err));
    });
}

// 处理浏览器直接预览pdf文件、以及文件名问题
function downloadFile(blob, filename) {
    let url = window.URL.createObjectURL(blob);
    // 创建隐藏的可下载链接
    let link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
};

// 文件大小
// 计算大小
function formatSize(bytes) {
    if (isNaN(bytes)) {
        return "";
    }
    var symbols = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    var exp = Math.floor(Math.log(bytes) / Math.log(2));
    if (exp < 1) {
        exp = 0;
    }
    var i = Math.floor(exp / 10);
    bytes = bytes / Math.pow(2, 10 * i);

    if (bytes.toString().length > bytes.toFixed(2).toString().length) {
        bytes = bytes.toFixed(2);
    }
    return bytes + " " + symbols[i];
}

/**
 * 空间存储计算大小
 */
function spaceSizeFormat(bytes) {
    if (isNaN(bytes)) {
        return "";
    }
    var symbols = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    var exp = Math.floor(Math.log(bytes) / Math.log(2));
    if (exp < 1) {
        exp = 0;
    }
    var i = Math.floor(exp / 10);
    bytes = bytes / Math.pow(2, 10 * i);

    if (bytes.toString().length > bytes.toFixed(2).toString().length) {
        bytes = bytes.toFixed(2);
    }
    return bytes + " " + symbols[i];
}

export {
    uploadFile,
    spaceSizeFormat,
    fileSuffix,
    getFileFormatBySuffix,
    requestUploadFile,
    imgFormat,
    audioFormat,
    videoFormat,
    pptFormat,
    wordFormat,
    excelFormat,
    pdfFormat,
    textFormat,
    zipFormat,
    downloadFile,
    formatSize
}
