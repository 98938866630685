<template>
  <ElDialog
      v-model="isShow"
      :title="dialogFlag==='create'?'新增记录':dialogFlag==='edit'?'编辑记录':'跟进详情'"
      :footer="null"
      centered
      :width="800"
      :top="'8vh'"
      destroyOnClose
      @cancel="close"
      :class="[isHeadLine ? 'headline' : 'noheadline']"
  >
    <div class="inners_warning" v-loading="isDetailLoading">
      <el-form
          ref="ruleFormRef"
          :model="ruleForm"
          :rules="rules"
          style="width: 100%"
          class="demo-ruleForm"
          label-width="auto"
      >
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item :label="'客户编号:'" prop="customerCode">
              <el-input
                  :disabled="!formIsEditable || dialogFlag === 'create' || dialogFlag === 'edit'"
                  v-model="ruleForm.customerCode"
                  :placeholder="$t('common.please.input')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="'客户名称:'" prop="customerName">
              <el-input
                  :disabled="!formIsEditable || dialogFlag === 'create' || dialogFlag === 'edit'"
                  v-model="ruleForm.customerName"
                  :placeholder="$t('common.please.input')"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item :label="'联系人:'" prop="mailCustomerFollowContactDTOList">
              <el-select
                  v-model="ruleForm.mailCustomerFollowContactDTOList"
                  :disabled="!formIsEditable"
                  multiple
                  collapse-tags
                  collapse-tags-tooltip
                  placeholder="请选择联系人"
                  style="width: 240px"
              >
                <el-option v-for="item in targetNewObj"
                           :key="item.contactId"
                           :label="item.contactName"
                           :value="item.contactId"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="'跟进日期:'" prop="followTime">
              <el-date-picker
                  :disabled="!formIsEditable"
                  v-model="ruleForm.followTime"
                  type="datetime"
                  :placeholder="$t('sys.tip.select.date')"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="跟进方式:" prop="followWay">
              <el-input
                  :disabled="!formIsEditable"
                  v-model="ruleForm.followWay"
                  :placeholder="$t('common.please.input')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="'跟进目的:'" prop="followAim">
              <el-input v-model="ruleForm.followAim" :disabled="!formIsEditable"
                        :placeholder="$t('common.please.input')"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item :label="'跟进详情:'" prop="followDetail">
              <el-input
                  :disabled="!formIsEditable"
                  v-model="ruleForm.followDetail"
                  style="width: 240px"
                  :rows="5"
                  type="textarea"
                  placeholder="请输入"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item :label="'备注:'" prop="remark">
              <el-input
                  :disabled="!formIsEditable"
                  v-model="ruleForm.remark"
                  style="width: 240px"
                  :rows="5"
                  type="textarea"
                  placeholder="请输入"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <template #footer v-if="formIsEditable">
      <div class="setting_center">
        <el-button @click="close" style="margin-right: 20px">{{ $t('common.sss16') }}</el-button>
        <el-button type="primary" @click="handleClick" :loading="isloading">确定</el-button>
      </div>
    </template>
  </ElDialog>
</template>

<script setup>
import {ElButton, ElDialog, ElInput, ElMessage} from "element-plus";
import {ref, defineProps, watch, defineEmits, reactive, onMounted, computed} from 'vue'
import {useStore} from "vuex";
import Forms from "@/views/Customer/CreateCheck/Forms.vue";
import {emailReg, emptyUrl} from "@/utils/statusParams";
import {Plus} from "@element-plus/icons-vue";
import ConfirmDeletePopover from "@/components/ConfirmDeletePopover.vue";
import Cards from "@/views/Customer/Cards.vue";
import MyTitles from "@/views/Customer/Detail/MyTitles.vue";
import MoreCheck from "@/views/Customer/CreateCheck/MoreCheck.vue";
import {
  addCustomerFollowLog,
  getMailCustomerContactByCustomerId,
  queryByIdFollowLog,
  updateCustomerFollowLog
} from "@/api/customer";
import {useRoute} from "vue-router";
import dayjs from "dayjs";

const store = useStore();
const route = useRoute();
const emit = defineEmits()
const isShow = ref(false);
const fileDirName = ref("")
const loading = ref(false)
const ruleFormAllData = computed(() => store.state.customer.ruleForm)

const {value, isHeadLine, dialogFlag, currentItemDetail} = defineProps({
  value: {type: Boolean, default: false},
  isHeadLine: {type: Boolean, default: false},
  dialogFlag: {type: String, default: ''},
  currentItemDetail: {type: Object, default: {}}
})


const ruleForm = ref({
  customerCode: '',
  customerName: '',
  mailCustomerFollowContactDTOList: [],
  followTime: '',
  followWay: '',
  followAim: '',
  followDetail: '',
  remark: '',
})

const ruleFormEmpty = ref({
  customerCode: '',
  customerName: '',
  mailCustomerFollowContactDTOList: [],
  followTime: '',
  followWay: '',
  followAim: '',
  followDetail: '',
  remark: '',
})

const rules = reactive({
  customerCode: [{required: true, message: '客户编号不能为空', trigger: ['change', 'blur']}],
  customerName: [{required: true, message: '客户名称不能为空', trigger: ['change', 'blur']}],
  followTime: [{required: true, message: '跟进日期不能为空', trigger: ['change', 'blur']}],
  followAim: [{required: true, message: '跟进目的不能为空', trigger: ['change', 'blur']}],
})

// 表单是否可编辑
const formIsEditable = computed(() => {
  return dialogFlag === 'detail' ? false : true
})


onMounted(async () => {
  // ruleForm.value = Object.assign(ruleForm.value, currentItemDetail)
  if (dialogFlag !== 'create') {
    await queryByIdFollowLogDetail()
  } else {
    Object.assign(ruleForm.value, {
      customerCode: ruleFormAllData.value.customerCode,
      customerName: ruleFormAllData.value.customerName,
    })
  }
  await getAllContact()
})


// 获取客户详情
const isDetailLoading = ref(false)
const queryByIdFollowLogDetail = async () => {
  const payload = {
    id: currentItemDetail?.id
  }
  try {
    isDetailLoading.value = true
    console.log(payload, 'payload')
    let res = await queryByIdFollowLog(payload)
    console.log(res, 'res')
    Object.assign(ruleForm.value, {
      ...res.result,
      customerCode: ruleFormAllData.value.customerCode,
      customerName: ruleFormAllData.value.customerName,
      mailCustomerFollowContactDTOList: res.result.mailCustomerFollowContactDTOList.map(item => item.contactId)
    })
    isDetailLoading.value = false;
    console.log(ruleForm.value, 'ruleForm.value')
  } catch (e) {
    isDetailLoading.value = false;
  }
}


// 获取全部联系人
const targetNewObj = ref([])
const getAllContact = async () => {
  let res = await getMailCustomerContactByCustomerId({customerId: route.params.userId})
  if (res.code !== 200) {
    ElMessage.error(res.message)
    return
  }
  targetNewObj.value = res.result.map(item => ({contactId: item.id, contactName: item.name}))
}

const ruleFormRef = ref(null)
const teamId = computed(() => store.state.email.teamID)
const isloading = ref(false)
const handleClick = () => {
  ruleFormRef.value.validate(async (valid, fields) => {
    if (valid) {
      const {mailCustomerFollowContactDTOList, followAim, followDetail, followTime, followWay, remark} = ruleForm.value
      let payload = {
        contactIdList: mailCustomerFollowContactDTOList,
        customerId: route.params.userId,
        followAim,
        followDetail,
        followTime: dayjs(followTime).format("YYYY-MM-DD HH:mm:ss"),
        followWay,
        remark,
        teamId: teamId.value,
      }
      console.log(ruleForm.value, payload, 'payload')

      try {
        isloading.value = true
        // 判断是 更新 还是 添加
        const apiRequest = dialogFlag === 'create' ? addCustomerFollowLog : updateCustomerFollowLog
        if (dialogFlag === 'edit') {
          payload = {...payload, id: ruleForm.value?.id}
        }
        let res = await apiRequest(payload)
        ElMessage.success(res.result)
        isloading.value = false
        ruleForm.value = [];
        close()
        //   重新获取列表
        emit('getList')
      } catch (e) {
        isloading.value = false
      }
    }
  })

}


watch(() => value, (val) => {
  isShow.value = val;
}, {immediate: true})
const close = () => {
  emit("close");
}

const createDir = () => {
  emit("addDir", fileDirName.value)
}
</script>
<style scoped lang="less">
.fx-j-c {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.title_cdir {
  color: rgba(0, 0, 0, .85);
  font-weight: 700;
  font-size: 14px;
}

.fx-j-r {
  display: flex;
  justify-content: right;
  margin-top: 40px;
}

:deep(.el-form-item) {
  padding: 0px !important;
  margin-bottom: 18px !important;
}

.inners_warning {
  min-height: 400px !important;
}

</style>
