// 使用示例：设置cookie并定时清除localStorage
export const cookieName = 'your_cookie_name';
export const cookieValue = 'your_cookie_value';
export const expirationTimeInSeconds = 8 * 60 * 60 * 1000; // 8小时
// export const expirationTimeInSeconds = 60 * 1000; // 测试5s

// 设置cookie的过期时间为一定时长（以秒为单位）
export function setCookieWithExpirationTime(cookieName, cookieValue, expirationTimeInSeconds) {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + expirationTimeInSeconds);

    const cookieString = `${cookieName}=${encodeURIComponent(cookieValue)}; expires=${expirationDate.toUTCString()}`;
    document.cookie = cookieString;
}


// 清除所有的localStorage
export function clearLocalStorage() {
    const isHaveToken = localStorage.getItem("token");
    // 检测是否是登陆状态
    if (isHaveToken !== null) {
        localStorage.clear();
        sessionStorage.clear()
        location.reload();
    }
}


// 定时检查cookie是否过期，如果过期则重新设置新的过期时间
export function checkAndResetCookie() {
    const existingCookieValue = getCookie(cookieName);
    if (existingCookieValue) {
    } else {
        setCookieWithExpirationTime(cookieName, cookieValue, expirationTimeInSeconds);
    }
}


// 获取cookie的值
function getCookie(cookieName) {
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');

    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i].trim();
        if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }
    return "";
}

export function loginSuccess() {
    // 存储登录时间戳
    localStorage.setItem('loginTime', Date.now());
}

export function checkLoginTime() {
    var loginTime = localStorage.getItem('loginTime');
    if (loginTime) {
        var currentTime = Date.now();
        if (currentTime - loginTime >= expirationTimeInSeconds) {
            // 超过8小时，清空 localStorage
            localStorage.clear();
            sessionStorage.clear()
            return true
        } else {
            return false
        }
    }

}