<template>
  <EmailList :type="1"/>
</template>
<script setup>
import {useRoute} from "vue-router";
import EmailList from "@/views/components/EmailList/EmailList.vue";
const route = useRoute()

</script>
<style lang="less" scoped>

</style>