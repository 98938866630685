<template>
  <ElDialog
      v-model="isShow"
      :title="$t('common.newfolder')"
      :footer="null"
      centered
      :width="520"
      destroyOnClose
      @cancel="close"
      :class="[isHeadLine ? 'headline' : 'noheadline']"
  >
    <div style="display: flex;justify-content: center;align-items: center">
      <span class="title_cdir"
            style="display:inline-block;width: 100px;line-height: 30px">{{ $t("common.folder.name") }}:</span>
      <ElInput
          type="text"
          v-model="fileDirName"
          style="height: 32px; width: 100%;"
          @keydown.enter.native="createDir"
      />
    </div>
    <template #footer>
      <div class="dialog-footer">
        <ElButton @click="close">{{
            $t("common.cancel")
          }}
        </ElButton>
        <ElButton type="primary" @click="createDir" :loading="loading">{{
            $t("common.confirm")
          }}
        </ElButton>
      </div>
    </template>
  </ElDialog>
</template>

<script setup>
import {ElButton, ElDialog, ElInput, ElMessage} from "element-plus";
import {ref, defineProps, watch, defineEmits, computed} from 'vue'
import {useStore} from "vuex";
import dayjs from "dayjs";
import {useRoute} from "vue-router";
import router from "@/router";

const store = useStore();
const emit = defineEmits()
const isShow = ref(false);
const fileDirName = ref("")
const loading = ref(false)


const {value, isHeadLine} = defineProps({
  value: {type: Boolean, default: false},
  isHeadLine: {type: Boolean, default: false}
})

watch(() => value, (val) => {
  isShow.value = val;
}, {immediate: true})
const close = () => {
  emit("close");
}


// 获取所有数据
const current = computed(() => store.state.cloud.filePages.current);
const size = computed(() => store.state.cloud.filePages.size);
const total = computed(() => store.state.cloud.filePages.total);
const route = useRoute()
const createDir = async () => {
  const params = {
    isFolder: 2,
    filePath: route.query.id ? route.query.id : '0',
    parentId: route.query.id ? route.query.id : '0',
    createTime: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    fileName: fileDirName.value,
    fileArea: route.query.path ? route.query.path : '0',
  }
  await store.dispatch('cloud/addFile', params)
  close()
  getFileList()
}

const getFileList = () => {
  emit('getFileList')
}
</script>
<style scoped lang="less">
.fx-j-c {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.title_cdir {
  color: rgba(0, 0, 0, .85);
  font-weight: 700;
  font-size: 14px;
}

.fx-j-r {
  display: flex;
  justify-content: right;
  margin-top: 40px;
}

</style>
