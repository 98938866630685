<template>
  <div class="box_select_con" ref="boxSelect">
    <img :src="flag[defaultItem.zoneCode]"
         style="height:20px;margin-right:2px;vertical-align: center;display: inline-block" class="them_font_color"/>
    <span class="them_font_color">+</span>
    <el-select style="width: 100%" v-model="prefix" placeholder="Select" popper-class="dropdown_selectCountry" @change="changeSelect"
               size="small"
               :no-data-text="$t('sys.noRusult')" class="them_font_color" filterable>
      <template slot="prefix">
        {{ (currentCountrysComputed?.find(item => item.prefix === prefix) || {}).zoneCode }}
      </template>
      <template #header>
        <div class="search_top">
          <el-input style="background: #f5f5f5" size="large" v-model="inputSearch" @input="inputSearchValue"
                    :placeholder="$t('sys.country')"
                    :suffix-icon="Search"/>
        </div>
      </template>
      <el-option
          v-if="currentCountrysComputed.length !== 0"
          v-for="item in currentCountrysComputed"
          :key="item.value"
          :label="`${item.prefix}`"
          :value="item.prefix"
          :style="{ width: '180px' }">
        <template #default>
          <span class="options_item">
            <img :src="flag[item.zoneCode]"
                 style="height:20px;margin-right:2px;vertical-align: 0px;"/>
            <div class="is_line">{{ `+ ${item.prefix}` }}</div>
          </span>
        </template>
      </el-option>
    </el-select>
  </div>
</template>


<script setup>
import {ref, defineEmits, computed, onMounted, nextTick, watch, watchEffect} from "vue";
import {ElMessage} from "element-plus";
import {Search} from "@element-plus/icons-vue";

import flag from '@/assets/flag'
import {requestMailFlagList} from "@/api";
import './index.css'
import sessionStorageUtil from "@/utils/sessionStorageUtil";
import {useStore} from "vuex";

const emits = defineEmits(['changeSelect'])
const store = useStore();

const currentCountrys = computed(() => store.state.customer.areaList)
const prefix = ref("86");
const defaultItem = ref({
  countryEn: "China", country: "中国", prefix: "86", icon: "CN.png", id: "43", zoneCode: "CN"
})
const isChangeSelect = ref(false)
const changeSelect = (val) => {
  isChangeSelect.value = !isChangeSelect.value;
  prefix.value = val;
  defaultItem.value = JSON.parse(JSON.stringify(currentCountrys.value)).filter(item => item.prefix === val)[0]
  console.log(val, defaultItem.value, 'val')
  emits('changeSelect', defaultItem.value)
}


const inputSearch = ref('')
const currentCountrysComputed = computed(() => {
  if (inputSearch.value === '') {
    return currentCountrys.value;
  } else if (!isNaN(inputSearch.value * 1)) {
    // 输入为数字时
    return currentCountrys.value.filter(item => item.prefix.includes(inputSearch.value));
  } else {
    // 输入为非数字时
    return currentCountrys.value.filter(item => item.country.includes(inputSearch.value));
  }
});

const inputSearchValue = (val) => {
  inputSearch.value = val;
}

const boxSelect = ref(null)
onMounted(async () => {
  boxSelect.value.style.width = `${prefix.value.length + 75}px`
  await store.dispatch('customer/getAreaNames')
  console.log(currentCountrys.value, 'currentCountrys')
})
watch(() => isChangeSelect.value, () => {
  if (prefix.value.length === 1) {
    boxSelect.value.style.width = `${prefix.value.length + 73}px`
  }
  if (prefix.value.length === 2) {
    boxSelect.value.style.width = `${prefix.value.length + 75}px`
  }
  if (prefix.value.length === 3) {
    boxSelect.value.style.width = `${prefix.value.length + 81}px`
  }
  if (prefix.value.length === 4) {
    boxSelect.value.style.width = `${prefix.value.length + 86}px`
  }

})
</script>


<style lang="less" scoped>
@import "./less/index";


.box_select_con {
  display: flex;
  align-items: center !important;
  justify-content: center !important;

  & > img {
    display: inline-block;

  }

  :deep(.el-select .el-input__wrapper) {
    box-shadow: none !important;
  }

  .el-input__inner {
    border: none !important;
    box-shadow: none !important;
  }

  :deep(.el-select__wrapper) {
    box-shadow: none !important;
    display: flex;
    align-content: center;
    justify-content: center;
  }

  //:deep(.el-select__input-wrapper){
  //  width: auto !important;
  //}
  :deep(.el-select--small .el-select__wrapper) {
    padding: 2px 6px 2px 2px !important;
  }
}


</style>
