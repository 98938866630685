<template>
  <div>
        <el-table
            :data="tableData"
            border
        >
          <el-table-column prop="name" label="Name" width="240" style="background-color: #e8e8e8;"/>
          <el-table-column prop="value" label="Value" width="240"/>
        </el-table>
  </div>
</template>

<script setup>
import {defineProps} from "vue";
import {emptyUrl} from "@/utils/statusParams";

const props = defineProps({
  tableData: {
    type: Array,
    default: () => [],
  },

  tableKeys: {
    type: Array,
    default: () => ["value", "name"],
  },
});
</script>

<style lang="less" scoped>
:deep(.el-table__header-wrapper) {
  display: none;
}

:deep(.el-empty__image img) {
  width: 54% !important;
}

:deep(.el-empty__description) {
  margin-top: -20px;
}

//.rows {
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  width: 100%;
//  border: 1px solid #e8ebf3;
//
//  & > div {
//    display: flex;
//    justify-content: center;
//    align-items: center;
//    height: 40px;
//    flex: 1;
//    text-align: center;
//    line-height: 20px;
//    font-size: 14px;
//    color: #c1c2c5;
//  }
//
//  & > div:nth-of-type(2) {
//    border-left: 1px solid #e8ebf3;
//  }
//}

</style>
