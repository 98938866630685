<template>
  <div class="out_box">
    <div class="share_box">
      <div class="top">
        <div style="margin-left: 20px">
          <el-avatar
              :src="shareTargetUser.imageUrl?shareTargetUser.imageUrl:require('@/assets/default/avatar.png')"
          />
        </div>

        <span class="name">{{ shareTargetUser.userName }}</span>
        <span class="share">分享</span>
      </div>
      <div class="content">
        <div>
          <div class="font">请输入提取码</div>
          <div>
            <el-input v-model="code" @keydown.native.enter="toInfoFile"></el-input>
          </div>
        </div>
      </div>
      <div class="btn">
        <button @click="toInfoFile">提取文件</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import {ElMessage} from "element-plus";
import {debouncePlus} from "@/utils/mylodash"

const router = useRouter();
const route = useRoute();
const code = ref('');

const store = useStore();

const isCodeShareLink = computed(() => store.state.cloud.isCodeShareLink)

const toInfoFile = debouncePlus(async () => {
  if (code.value.trim() === '') {
    ElMessage.warning("提取码不能为空")
    return
  }
  await getLinkFileList()
  if (isCodeShareLink.value) {
    router.push({path: '/cloud/info', query: {linkAddress: route.params.shareId, shareCode: code.value}})
  }
  console.log(isCodeShareLink.value, 'getFileByLinkAddressApis')
}, 300)

// 判断 分享链接和验证码是否正确？
const pageNo = ref(1);
const pageSize = ref(20);
const getLinkFileList = async () => {
  const payload = {
    linkAddress: route.params.shareId,
    shareCode: code.value,
    pageNo: pageNo.value,
    pageSize: pageSize.value,
  }
  await store.dispatch("cloud/getFileByLinkAddressApis", payload)
}

// 用户分享信息
const shareTargetUser = computed(() => store.state.cloud.shareTargetUser)

onMounted(async () => {
  await getTargetUserData()
})

// 获取分享人相关信息
const getTargetUserData = async () => {
  console.log(route.params.shareId, 'dd')
  await store.dispatch("cloud/getShareNameByLinkCodeApis", {linkAddress: route.params.shareId})
}


</script>
<style lang="less" scoped>

.out_box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .share_box {
    // 使用 mixin
    .px2vw(width, 640);
    .px2vh(height, 284);
    min-width: 480px;
    min-height: 240px;

    background: #FFFFFF;

    .top {
      width: 100%;
      .px2vh(height, 80);
      background: #E6F7FF;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.name {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  margin-left: 10px;
}

.share {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  text-align: left;
  font-style: normal;
  margin-left: 20px;
}

.font {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}

.content {
  width: 100%;
  .px2vh(margin-top, 40);
  display: flex;
  justify-content: center;

  & > div {
    & > div:nth-of-type(1) {
      margin-bottom: 8px;
    }

    & > div:nth-of-type(2) {
      .px2vw(width, 360);
      min-width: 300px;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

.btn {
  width: 100%;
  display: flex;
  justify-content: center;
  .px2vh(margin-top, 30);

  button {
    .px2vw(width, 137);
    min-width: 100px;
    .px2vh(height, 32);
    min-height: 25px;
    background: #1890FF;
    border: none;
    color: white;
    cursor: pointer;
    transition: all .3s;
  }

  button:hover {
    .px2vw(width, 137);
    min-width: 100px;
    .px2vh(height, 32);
    min-height: 25px;
    background: #68b5ff;
    border: none;
    color: white;
    cursor: pointer;
  }
}


</style>