<template>
  <Text :title="$t('common.permission.desc')">
    <template #content>
      <div class="txt">
        <span class="text_title">{{ $t("common.admin") }}：</span>
        <span>{{ $t("other.text.permissions.admin.desc") }}</span>
      </div>
      <div class="txt">
        <span class="text_title">{{ $t("common.generaluser") }}：</span>
        <span>{{ $t("other.text.permissions.generaluser.desc") }}</span>
      </div>
    </template>
  </Text>
  <Text :title="$t('common.department.setting')">
    <template #settings>
      <el-button type="primary" v-if="isSuperAdmin" @click="dialogVisibleAddDepartment = true"
      >{{ $t('common.department.add') }}
      </el-button
      >
    </template>
    <template #content>
      <el-table
          :data="tableData"
          height="auto"
          style="width: 100%"
          :row-class-name="tableRowClassName"
          :cell-style="{ textAlign: 'left' }"
          :header-cell-style="{ 'text-align': 'left',background: '#f9f9f9', color: 'black',padding:'16px 0px'}">
        v-loading="isLoadingDepart"
        >
        <el-table-column prop="name" :label="$t('common.department.name')"
                         :width="flexWidth('name', tableData, $t('common.department.name'))"></el-table-column>
        <el-table-column prop="createTime" :label="$t('common.time.add')" width="180"/>
        <el-table-column prop="count" :label="$t('common.department.numberofpeople')"
                         :width="flexWidth('count', tableData, $t('common.department.numberofpeople'))"/>
        <el-table-column
            prop="userStatus"
            :label="$t('common.cz')"
            width="140"
            fixed="right"
        >
          <template #default="{ row, column, $index }">
            <el-button
                type="primary"
                link
                v-if="isSuperAdmin"
                @click="updatesData(row, column, $index)"
            >{{ $t('common.update') }}
            </el-button
            >
            <span style="color: grey; margin: 0px 6px" v-if="isSuperAdmin">|</span>
            <ConfirmDeletePopover
                :visible="row.popoverVisible"
                :width="164"
                :message="$t('sys.message.ask.type.delete')"
                @update:visible="(value) => (row.popoverVisible = value)"
                @confirm:delete="handleDelete(row, column, $index)"
            >
              <template #settings>
                <el-button type="danger" link @click="row.popoverVisible = true" v-if="isSuperAdmin"
                >{{ $t('common.delete') }}
                </el-button
                >
              </template>
            </ConfirmDeletePopover>
          </template>
        </el-table-column>
      </el-table>
      <div class="pages">
        <el-pagination
            v-model:current-page="current"
            v-model:page-size="size"
            v-if="total"
            layout="total, prev, pager, next"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
      </div>
    </template>
  </Text>
  <Dialog
      v-model="dialogVisible"
      :title="$t('common.department.update')"
      :dialogVisible="dialogVisible"
      :isHeadLine="true"
      :width="'400'"
      @update:dialogVisible="updateDialogVisible"
  >
    <template #content>
      <div class="my_forms">
        <div style="font-weight: 600">{{ $t('common.department.name') }}：</div>
        <div style="margin-top: 10px">
          <el-input v-model="cosNames"/>
        </div>
      </div>
      <div id="sendRequire">
        <el-button type="primary" @click="updatesDataDep(rows)">{{ $t('common.confirm') }}</el-button>
      </div>
    </template>
  </Dialog>
  <Dialog
      v-model="dialogVisibleAddDepartment"
      :title="$t('common.department.add')"
      :isHeadLine="true"
      :width="'400px'"
      :dialogVisible="dialogVisibleAddDepartment"
      @update:dialogVisible="updateDialogVisibleDepartment"
  >
    <template #content>
      <div class="my_forms">
        <div style="font-weight: 600">{{ $t('common.department.name') }}：</div>
        <div style="margin-top: 14px">
          <el-input v-model="departmentNameAdd"/>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="settings_btn">
        <el-button type="primary" @click="addDepartment">{{ $t('common.confirm') }}</el-button>
      </div>
    </template>
  </Dialog>
</template>

<script setup>
import {ref, onMounted, computed, watch} from "vue";
import dayjs from "dayjs";
import {useStore} from "vuex";

import Text from "./Text.vue";
import Dialog from "@/components/Dialog.vue";
import ConfirmDeletePopover from "@/components/ConfirmDeletePopover.vue";
import {ElTable} from "element-plus";
import {flexWidth} from "@/utils/tableWidth";
// 数据加载
const isLoadingDepart = computed(() => store.state.groups.isLoadingDepart);
const store = useStore();

const isSuperAdmin = computed(() => store.getters["user/isSuperAdmin"])
const isAdmin = computed(() => store.getters["user/isAdmin"])

// 对话框的展示 修改
const dialogVisible = ref(false);
const departmentName = ref("");
// 对话框展示 添加部门
const dialogVisibleAddDepartment = ref(false);
const departmentNameAdd = ref("");

const tableData = computed(() => store.state.groups.departList);
const teamId = computed(() => store.state.email.teamID);
// 更新对话框状态
const updateDialogVisible = (val) => {
  dialogVisible.value = val;
};
const updateDialogVisibleDepartment = (val) => {
  dialogVisibleAddDepartment.value = val;
};

const cosNames = ref("");
// 选择的行
const rows = ref();
// 分页
const current = computed(() => store.state.groups.pageDepart.current);
const size = computed(() => store.state.groups.pageDepart.size);
const total = computed(() => store.state.groups.pageDepart.total);

const handleCurrentChange = (page) => {
  store.commit("groups/setDepartPage", {current: page, size, total});
  store.dispatch("groups/getDepartList", {teamId: teamId.value});
};

// 修改部门
const updatesData = (row, column, $index) => {
  dialogVisible.value = true;
  cosNames.value = row.name;
  rows.value = {...row};
};

const updatesDataDep = async (row, column, $index) => {
  dialogVisible.value = false;
  await store.dispatch("groups/updateDepart", {
    id: row.id,
    name: cosNames.value,
  });
  await store.dispatch("groups/getDepartList", {teamId: teamId.value});
};

// 添加部门
const addDepartment = async () => {
  dialogVisibleAddDepartment.value = true;
  let targetObj = {
    createBy: "",
    createTime: `${dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss")}`,
    delFlag: 0,
    id: "",
    name: departmentNameAdd.value,
    remark: "",
    sort: 0,
    teamId: teamId.value,
    updateBy: "",
    updateTime: "",
  };
  await store.dispatch("groups/addDepart", targetObj);
  await store.dispatch("groups/getDepartList", {teamId: teamId.value});
  dialogVisibleAddDepartment.value = false;
  departmentNameAdd.value = "";
};

// 删除
const handleDelete = async (row, column, $index) => {
  row.dialogVisible = true;
  await store.dispatch("groups/deleteDepart", {id: row.id});
  await store.dispatch("groups/getDepartList", {teamId: teamId.value});
};

// table 中的 confirm
const requestHandleDelete = (row) => {
  row.popoverVisible = true;
};
const cancelPopover = (row) => {
  row.popoverVisible = false;
};

const tableRowClassName = ({row, rowIndex}) => {
  return "change_grey";
};

onMounted(async () => {
  await store.dispatch("groups/getDepartList", {teamId: teamId.value});
});
watch(() => teamId.value, async () => {
  await store.dispatch("groups/getDepartList", {teamId: teamId.value});
})
</script>

<style lang="less" scoped>
@import "./less/Right.less";
@import "../../less/page.less";

.settings_btn {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

#sendRequire {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

:deep(.el-table__header) {
  width: 100% !important;
}

:deep(.el-table__body) {
  width: 100% !important;
}
</style>
