import request from "@/utils/request";

// 获取用户操作日志
export function getUserLogList(params, config) {
    return request.get("/front/mailCustomerLog/queryCustomerLog", params, config)
}

// 获取客户列表-分页
export function getMailCustomerList(params, config) {
    return request.post("/front/mailCustomer/getCustomerList", params, config);
}

// 获取客户列表-分页-tree 模式
export function getMassCustomerList(params, config) {
    return request.post("/front/mailCustomer/getMassCustomerList", params, config);
}

// 查询客户列表-all
export function requestAllCustomer(params, config) {
    return request.get('/front/mailCustomer/getAllCustomer', params, config);
}

// 添加客户数据
export function addMailCustomer(params, config) {
    return request.post("/front/mailCustomer/addCustomerData", params, config);
}

// 根据id 查询客户数据
export function queryById(params, config) {
    return request.get("/front/mailCustomer/queryById", params, config);
}

// 修改客户详情
export function updateMailCustomer(params, config) {
    return request.put("/front/mailCustomer/updateCustomerData", params, config);
}

// 客户等级相关
// 添加客户跟进等级
export function addMailCustomerFollowLevel(params, config) {
    return request.post("/front/mailCustomerLevelFollow/add", params, config);
}

// 获取客户等级列表
export function getMailCustomerLevelList(params, config) {
    return request.get("/front/mailCustomerLevelFollow/list", params, config);
}


// 删除客户等级列表
export function deleteMailCustomerLevel(params, config) {
    const {id} = params;
    return request.delete(`/front/mailCustomerLevelFollow/delete?id=${id}`, params, config);
}

// 编辑 客户等级列表
export function editMailCustomerLevel(params, config) {
    return request.put("/front/mailCustomerLevelFollow/edit", params, config);
}

// 获取区域名称
// export function getAreaName(params, config) {
//     return request.get("/front/mailFlag/list", params, config);
// }

// // 添加联系人
// 添加联系人addMailCustomerContact
export function addMailCustomerContact(params, config) {
    return request.post("/front/mailContact/addContactData", params, config);
}

// 获取联系人--分页
export function getMailCustomerContact(params, config) {
    return request.get("/front/mailContact/getCustomerContactList", params, config);
}

// 通过客户id 查询联系人 --all
export function getMailCustomerContactByCustomerId(params, config) {
    return request.get("/front/mailContact/getContactListByCustomer", params, config)
}

// 通过id删除联系人
export function deleteMailCustomerContact(params, config) {
    const {id} = params;
    return request.delete(`/front/mailContact/delete?id=${id}`, params, config);
}

// 获取联系人详情
export function getMailCustomerContactDetail(params, config) {
    return request.get("/front/mailContact/getCustomerContactDetail", params, config);
}

// 修改联系人详情
export function editMailCustomerContact(params, config) {
    return request.put("/front/mailContact/updateContactData", params, config);
}

//领用记录
export function getMailCustomerRecord(params, config) {
    return request.get("/front/mailReceiveRecord/getReceiveRecordList", params, config);
}

// 领用通过id 进行查询
export function getMailCustomerRecordById(params, config) {
    return request.get("/front/mailReceiveRecord/queryById", params, config);
}

// 添加领用
export function addMailCustomerRecord(data, config) {
    return request.post("/front/mailCustomer/batchReceive", data, config);
}

// 客户编号修改
export function updateCustomerData(params, config) {
    return request.get("/front/mailCustomer/updateCustomerCode", params, config);
}

// 客户分管相关设置
export function getMailCustomerSetting(params, config) {
    return request.post("/front/mailCustomer/updateCustomerType", params, config);
}

// 客户合并
export function batchTransfer(params, config) {
    return request.post("/front/mailCustomer/customerMerge", params, config);
}

// 客户批量移交
export function batchTransferCustomer(params, config) {
    return request.post("/front/mailCustomer/batchTransfer", params, config);
}

// 客户跟进列表
export function getMailCustomerFollow(params, config) {
    return request.post("/front/mailCustomerFollow/getFollowList", params, config);
}

// 审核列表
export function getMailCustomerAudit(params, config) {
    return request.get("/front/mailCustomer/getCustomerReceiveList", params, config);
}

// 审核列表操作
export function updateMailCustomerAudit(params, config) {
    return request.post("/front/mailCustomer/batchAudit", params, config);
}

// 批量延迟、取消
export function batchDelayCancel(params, config) {
    return request.put("/front/mailCustomerFollow/batchCancelAndDelay", params, config);
}

//添加、更新跟进人
export function updateFollowUser(params, config) {
    return request.put("/front/mailCustomerFollowSet/edit", params, config);
}

//获取跟进人
export function getFollowUser(params, config) {
    return request.get('/front/mailCustomerFollowSet/queryFollowList', params, config)
}


export function getEmailCustomer(params, config) {
    return request.get('/front/mailCustomer/getEmailCustomer', params, config);
}

// 归档联系人
export function addArchiveUserEmail(params, config) {
    return request.get('/front/mailContact/addContactEmail', params, config)
}

// 查询客户下的所有邮箱列表
export function requestCustomerEmailByCustomerId(params, config) {
    return request.get('/front/mailCustomer/getCustomerEmailByCustomerId', params, config);
}


// 获取模版链接
export function exportCustomerTemplate(params, config) {
    return request.post('/front/mailCustomer/exportCustomerTemplate', params, config)
}

//批量导入
export function importCustomxerExcel(params, config) {
    return request.post(`/front/mailCustomer/importCustomerExcel`, params, config)
}

// 批量导出
export function exportCustomerData(params, config) {
    return request.post(`/front/mailCustomer/exportCustomerData`, params, config)
}


// 批量删除客户
export function batchDeleteCustomerById(params, config) {
    return request.post(`/front/mailCustomer/batchDeleteCustomerById`, params, config)
}

// 获取客户的编号
export function getCustomerCodeApis(params, config) {
    return request.get(`/front/mailCustomer/getCustomerCode`, params, config)
}


// 客户跟进日志 list
export function queryPageList(params, config) {
    return request.get(`/mailCustomerFollowLog/queryPageList`, params, config)
}


// 客户跟进日志 添加
export function addCustomerFollowLog(params, config) {
    return request.post(`/mailCustomerFollowLog/addCustomerFollowLog`, params, config)
}

// 客户跟进详情
export function queryByIdFollowLog(params, config) {
    return request.get(`/mailCustomerFollowLog/queryById`, params, config)
}

// 客户跟进 更新
export function updateCustomerFollowLog(params, config) {
    return request.put(`/mailCustomerFollowLog/updateCustomerFollowLog`, params, config)
}

// 批量删除
export function deleteBatchFollow(params, config) {
    const {ids} = params;
    return request.delete(`/mailCustomerFollowLog/deleteBatch?ids=${ids}`, params, config)
}













