<template>
  <el-empty :description="'火速开发中...敬请期待...'"/>
</template>
<script setup>

</script>
<style lang="less" scoped>
:deep(.el-empty__description){
  margin-top: 40px !important;
}
</style>