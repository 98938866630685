<template>
  <section class="out-body">
    <div id="Dashboard">
      <el-row type="flex" justify="center" :gutter="24">
        <el-col :span="24">
          <AdCard></AdCard>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center" :gutter="24">
        <el-col :xs="15" :sm="15" :md="15" :lg="15" :xl="15" :xxl="15" style="padding-right: 0px !important;">
          <!--          我的消息-->
          <!--          <MyMessage-->
          <!--              :title="$t('common.mymessage')"-->
          <!--              :list="myMessageList"-->
          <!--              extra="addfriend"-->
          <!--          />-->
          <MyMessage :title="$t('common.mymail')" :list="allEmailList"/>
          <MyTeam/>
        </el-col>
        <el-col :xs="9" :sm="9" :md="9" :lg="9" :xl="9" :xxl="9">
          <Information
              :title="$t('common.noticeandnews')"
              :height="'240'"
              @addFriend="goToInfo"
          >
          </Information>
          <Calendar :title="$t('common.mytodo')" :height="'max-content'"/>
        </el-col>
      </el-row>
    </div>
  </section>
</template>

<script setup>
import {ref, onMounted, computed, onUpdated, watch} from "vue";
import {useStore} from "vuex";
import {ElRow, ElCol, ElMessage} from "element-plus";
import AdCard from "@/components/Dashboard/AdCard/index.vue";
import MyMessage from "@/components/Dashboard/MyMessage.vue";
import MyTeam from "@/components/Dashboard/MyTeam.vue";
import Information from "@/components/Dashboard/Information.vue";
import Calendar from "@/components/Dashboard/ElCalender/Calendar.vue";
import {i18n} from "../../lang/index";
import {useRouter} from "vue-router";
import sessionStorageUtil from "@/utils/sessionStorageUtil";

const router = useRouter();
const store = useStore();
console.log("thinks", i18n.global.t("common.mymessage"));

const nametipsArr = ref([
  i18n.global.t("common.friend"),
  i18n.global.t("common.groupchat"),
  i18n.global.t("common.friend.apply"),
  i18n.global.t("common.message.unread"),
  i18n.global.t("common.notice"),
]);

const myEmailList = computed(() => store.state.countshow.myEmailList)
const allEmailList = computed(() => store.state.countshow.allEmailList)

onMounted(async () => {
  await store.dispatch("email/getTeamList")
  await store.dispatch("countshow/getTeamAllDataApis")
  await store.dispatch("countshow/getTeamSumDataApis")
})

const goToInfo = () => {
  router.push("/notificationList/systemNotification")
}
</script>

<style lang="less" scoped>
#Dashboard {
  max-width: 1000px;
  margin: 0 auto;
  min-width: 650px;
}

div[id^="chart"] {
  height: 100%;
}

#Card:deep(.ant-card-body) {
  height: 100%;
  padding: 18px;
  display: flex;
  flex-direction: column;
}

.bfa {
  background: #bfa;
}

// 滑块样式
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

//定义滑块 内阴影+圆角
::-webkit-scrollbar-thumb {
  border-radius: 1em;
}

// 轨道
::-webkit-scrollbar-track {
  border-radius: 1em;
}
</style>
