<template>
  <ElDialog
      v-model="isShow"
      :title="$t('common.file.joint')"
      :footer="null"
      centered
      :width="800"
      destroyOnClose
      @cancel="close"
      :class="[isHeadLine ? 'headline' : 'noheadline']"
  >
    <div class="out_box">
      <div class="left">

        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
          <el-tab-pane label="用户查找" name="first">
            <!--        用户查找-->
            <div class="my_forms">
              <!--              <div style="font-size: 14px;font-weight: bolder;margin-bottom: 4px;padding:10px 10px 0px">-->
              <!--                {{ $t("common.find.user") }}-->
              <!--              </div>-->
              <div style="margin-top: 14px;padding:0px 10px;">
                <el-input v-model="inputUserId"
                          style="box-shadow:none !important;border: 1px solid #dddfe5;border-radius: 4px"
                          :placeholder="$t('common.ph361')" @keydown.enter.native="searchTargetUsers">
                  <template #append>
                    <el-button style="cursor: pointer;background: white !important;border-radius: 0px 4px 4px 0px"
                               @click="searchTargetUsers" :icon="Search"/>
                  </template>
                </el-input>
              </div>

              <div style="margin-top: 20px;" class="users_search">
                <template v-if="!findIsLoading">
                  <template v-if="cantFindUser">
                    <div style="width:100%;font-size: 12px;text-align: center;color: rgba(0, 0, 0, 0.25);">
                      {{ $t("common.cant.find.target.user") }}
                    </div>
                  </template>
                  <template v-else-if="!isEmpty(findTargetUser)">
                    <div class="user_info">
                      <div>
                        <el-avatar :size="40" v-if="findTargetUser.avatar">
                          <img :src="findTargetUser.avatar"/>
                        </el-avatar>
                        <el-avatar :size="40" v-else>
                          <img style="height: 20px; width: 20px" :src="require('@/assets/default/avatar.png')"/>
                        </el-avatar>
                      </div>
                      <div class="users_username">
                        <span>{{ findTargetUser.userName }}</span>
                      </div>
                    </div>
                    <div class="settings">
                      <el-button link type="primary" @click="addFriends('searchUser')" v-if="!findTargetUser.isCheck">
                        {{ $t("common.add") }}
                      </el-button>
                      <el-button link type="info" v-else>{{ $t("common.added") }}</el-button>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <div style="width: 100%;display: flex;justify-content: center;align-items: center">
                    <el-icon class="is-loading">
                      <Loading/>
                    </el-icon>
                  </div>
                </template>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="团队成员" name="second">
            <!--        团队成员列表-->
            <div v-if="teamUserListLoading" v-loading="teamUserListLoading"
                 style="height: 50px;background: rgba(0,0,0,0) !important;"></div>
            <div v-else>
              <div style="display: flex;flex-direction: column;">
                <!--                <div style="font-size: 14px;font-weight: bolder;margin-bottom: 4px;padding:10px 10px 0px">-->
                <!--                  团队成员列表-->
                <!--                </div>-->
                <div style="flex-direction: column;height: calc(60vh - 60px);overflow: scroll"
                     v-if="teamUserList.length>=1">
                  <div v-for="item in teamUserList" :key="item.userId" class="users_search" style="margin-bottom: 20px">
                    <div class="user_info">
                      <div>
                        <el-avatar :size="40" v-if="item.avatar">
                          <img :src="item.avatar"/>
                        </el-avatar>
                        <el-avatar :size="40" v-else>
                          <img style="height: 20px; width: 20px" :src="require('@/assets/default/avatar.png')"/>
                        </el-avatar>
                      </div>
                      <div class="users_username">
                        <span>{{ item.userName }}</span>
                      </div>
                    </div>
                    <div class="settings">
                      <el-button link type="primary" @click="addFriends('teamUserList',item)" v-if="!item.isCheck">
                        {{ $t("common.add") }}
                      </el-button>
                      <el-button link type="info" v-else>{{ $t("common.added") }}</el-button>
                    </div>
                  </div>
                </div>
                <div v-else
                     style="width: 100%;text-align: center;margin-top: 20px;background: rgba(0,0,0,0) !important;">
                  团队下没有除自己以外的人员
                </div>
              </div>
            </div>
          </el-tab-pane>
          <!--          <el-tab-pane label="好友列表" name="third">-->
          <!--            &lt;!&ndash;        好友列表&ndash;&gt;-->
          <!--            <div class="my_forms" style="margin-top: 40px">-->
          <!--&lt;!&ndash;              <div style="font-size: 14px;font-weight: bolder;margin-bottom: 4px;padding:10px 10px 0px">好友列表&ndash;&gt;-->
          <!--&lt;!&ndash;              </div>&ndash;&gt;-->
          <!--              <div style="margin-top: 14px;padding:0px 10px;">-->
          <!--                <el-input v-model="inputUserId"-->
          <!--                          style="box-shadow:none !important;border: 1px solid #dddfe5;border-radius: 4px"-->
          <!--                          :placeholder="$t('common.ph361')" @keydown.enter.native="searchTargetUsers">-->
          <!--                  <template #append>-->
          <!--                    <el-button style="cursor: pointer;background: white !important;border-radius: 0px 4px 4px 0px"-->
          <!--                               @click="searchTargetUsers" :icon="Search"/>-->
          <!--                  </template>-->
          <!--                </el-input>-->
          <!--              </div>-->

          <!--              <div style="margin-top: 20px;" class="users_search">-->
          <!--                <template v-if="!findIsLoading">-->
          <!--                  <template v-if="cantFindUser">-->
          <!--                    <div style="width:100%;font-size: 12px;text-align: center;color: rgba(0, 0, 0, 0.25);">未查到用户信息-->
          <!--                    </div>-->
          <!--                  </template>-->
          <!--                  <template v-else-if="!isEmpty(findTargetUser)">-->
          <!--                    <div class="user_info">-->
          <!--                      <div>-->
          <!--                        <el-avatar :size="40" v-if="findTargetUser.avatar">-->
          <!--                          <img :src="findTargetUser.avatar"/>-->
          <!--                        </el-avatar>-->
          <!--                        <el-avatar :size="40" v-else>-->
          <!--                          <img style="height: 20px; width: 20px" :src="require('@/assets/default/avatar.png')"/>-->
          <!--                        </el-avatar>-->
          <!--                      </div>-->
          <!--                      <div class="users_username">-->
          <!--                        <span>{{ findTargetUser.userName }}</span>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                    <div class="settings">-->
          <!--                      <el-button link type="primary" @click="addFriends" v-if="!findTargetUser.isCheck">添加</el-button>-->
          <!--                      <el-button link type="info" v-else>已添加</el-button>-->
          <!--                    </div>-->
          <!--                  </template>-->
          <!--                </template>-->
          <!--                <template v-else>-->
          <!--                  <div style="width: 100%;display: flex;justify-content: center;align-items: center">-->
          <!--                    <el-icon class="is-loading">-->
          <!--                      <Loading/>-->
          <!--                    </el-icon>-->
          <!--                  </div>-->
          <!--                </template>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </el-tab-pane>-->
        </el-tabs>
      </div>
      <div class="right">
        <div class="top_title">
          <div style="color: rgba(0, 0, 0, .65);font-size: 14px;
    font-weight: bolder;">
            已选择
            <el-button link type="primary">{{ userList.length }}</el-button>
            个用户
            <!--            {{ // $t("displace.friend.haschosen", {number: userList.length})  }}-->
          </div>
          <div>
            <el-button link type="primary" @click="resetUserList">{{ $t("common.empty") }}</el-button>
          </div>
        </div>
        <div class="userList" style="margin-top: 20px">
          <div class="users_search" style="margin-top: 10px" v-for="item in userList" :key="item">
            <div class="user_info">
              <div>
                <el-avatar :size="40" v-if="item.avatar">
                  <img :src="item.avatar"/>
                </el-avatar>
                <el-avatar :size="40" v-else>
                  <img style="height: 20px; width: 20px" :src="require('@/assets/default/avatar.png')"/>
                </el-avatar>
              </div>
              <div class="users_username">
                <span>{{ item.userName }}</span>
              </div>
            </div>
            <div class="settings">
              <el-icon style="color: red;font-size: 16px" @click="deleteUser(item)">
                <CircleCloseFilled/>
              </el-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <ElButton @click="close">{{
            $t("common.cancel")
          }}
        </ElButton>
        <ElButton type="primary" @click="createShared" :loading="loading">{{
            $t("common.confirm")
          }}
        </ElButton>
      </div>
    </template>
  </ElDialog>
</template>

<script setup>
import {ElButton, ElDialog, ElMessage} from "element-plus";
import {ref, defineProps, watch, defineEmits, onMounted, computed} from 'vue'
import {useStore} from "vuex";
import {Search} from "@element-plus/icons-vue";
import {getOutsideUser, getTeamUserListAll,getCloudTeamMemberList} from "@/api/groups";
import {i18n} from "@/lang";
import {debouncePlus, throttle, isEmpty} from "@/utils/mylodash"

const store = useStore();
const emit = defineEmits()
const isShow = ref(false);
const loading = ref(false)


const {value, isHeadLine, activeItem, numberFlags} = defineProps({
  value: {type: Boolean, default: false},
  isHeadLine: {type: Boolean, default: false},
  activeItem: {type: Array, default: []},
  numberFlags: {type: String, default: '1'}
})

const inputUserId = ref('')
const findIsLoading = ref(false)
const cantFindUser = ref(false)
const findTargetUser = ref({})
const searchTargetUsers = throttle(async () => {
  findTargetUser.value.isCheck = false;
  findIsLoading.value = true;
  const res = await getOutsideUser({uuid: inputUserId.value})
  console.log(res, res.message, res.message, typeof res.message, 'res...')
  findIsLoading.value = false;
  if (res.result === null) {
    cantFindUser.value = true
    findTargetUser.value = {}
  } else {
    cantFindUser.value = false;
    findTargetUser.value = res.result;
    const targetIndex = userList.value.findIndex(item => item.id === findTargetUser.value.id)
    const insertKey = targetIndex !== -1 ? {isCheck: true} : {isCheck: false}
    findTargetUser.value = {...res.result, ...insertKey}
    console.log(findTargetUser.value, targetIndex, 'findTargetUser')
  }
}, 3000)

watch(() => value, (val) => {
  isShow.value = val;
}, {immediate: true})


const userList = ref([]);

const TIME_OUT = 300;
const addFriends = debouncePlus((type, item) => {
  // 判断搜索的人是否是当前用户自己
  if (findTargetUser.value.id === userInfo.value.id) {
    ElMessage.warning("你不能将文件共享给自己！")
    return;
  }
  if (type === "searchUser") {
    findTargetUser.value.isCheck = true;
    // 是否已经存在userList
    const targetIndex = userList.value.findIndex(item => item.id === findTargetUser.value.id)
    if (targetIndex !== -1) {
      ElMessage.warning(i18n.global.t("common.user.isInContent"))
      return
    } else {
      userList.value.push(findTargetUser.value);
      console.log(userList.value, findTargetUser.value, 'userList.value')
    }
  } else if (type === "teamUserList") {
    if (item) {
      const {userId} = item;
      const targetIndex = userList.value.findIndex(item => item.id === userId)
      if (targetIndex !== -1) {
        ElMessage.warning(i18n.global.t("common.user.isInContent"))
        return
      } else {
        item.isCheck = true
        item.id = item.userId
        userList.value.push(item);
      }
    }
  }
}, TIME_OUT)

const deleteUser = (val) => {
  console.log(val, 'val')
  const targetIndex = userList.value.findIndex(item => item.id === val.id)
  userList.value.splice(targetIndex, 1)
  val.isCheck = false
  // 如果是当前用户改变状态
  if (val.id === findTargetUser.value.id) {
    findTargetUser.value.isCheck = false;
  }
}

// 清空数组
const resetUserList = (val) => {
  const idList = userList.value.map(item => item.id)
  // 搜索的数据是否在 已有列表中
  const searchTargetId = idList.filter(_ => _ === findTargetUser.value.id)
  if (searchTargetId.length >= 1) {
    //   存在
    findTargetUser.value.isCheck = false;
  }

  //团队成员数据中是否在 已有队列中
  teamUserList.value.forEach(item => {
    if (idList.includes(item.userId)) {
      item.isCheck = false;
    }
  })
  userList.value = []
  console.log('clean', idList)
}

const createShared = async () => {
  const payload = {
    userId: userList.value.map(item => item.id),
    fileId: activeItem[0].id ? activeItem.map(item => item.id) : [activeItemID.value.id]
  }
  await store.dispatch("cloud/filePublicShareApis", payload)
  close();
  getFileListSon()
}

const close = () => {
  emit("close");
}

const getFileListSon = () => {
  emit("getList")
}
const sharedUserList = computed(() => store.state.cloud.sharedUserList)
onMounted(async () => {
  await getTargetTeamList()
  if (activeItem.length === 1 && isShow) {
    await getSharedUserList()
    const res = sharedUserList.value.map(item => ({id: item.userId, ...item, isCheck: true}))
    userList.value = res;
  }
})
const activeItemID = ref({})
const getSharedUserList = async () => {
  console.log(activeItem, 'activeItem')
  activeItemID.value = {...activeItem[0], id: activeItem[0].fileId}
  await store.dispatch("cloud/getPublicShareUserListApis", activeItemID.value.id)
}


// 获取指定团队下面的团队成员
const teamId = computed(() => store.state.email.teamID);
const teamUserList = ref([])
const userInfo = computed(() => store.state.user.userInfo)
const teamUserListLoading = ref(false)
const getTargetTeamList = async () => {
  teamUserListLoading.value = true
  const result = await getCloudTeamMemberList();
  if (result.code !== 200) {
    ElMessage.error(result.result)
    return
  }
  teamUserListLoading.value = false
  teamUserList.value = result.result.filter(item => item.userId !== userInfo.value.id).map(item => ({
    ...item,
    isCheck: false
  }))
  console.log(teamUserList.value, 'targetUserList')
};


// tab相关
const activeName = ref("second")
const handleClick = (tab, event) => {
  console.log(tab, event)
}
</script>
<style scoped lang="less">
.out_box {
  display: flex;
  justify-content: space-between;

  & > div {
    width: 350px;
    background: #fafafa;
    height: 60vh;
  }

  & > div:nth-of-type(1) {
    margin-right: 30px;
  }
}

.fx-j-c {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.title_cdir {
  color: rgba(0, 0, 0, .85);
  font-weight: 700;
  font-size: 14px;
}

.fx-j-r {
  display: flex;
  justify-content: right;
  margin-top: 40px;
}

.users_search {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;

  & > .user_info {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    & > div:nth-of-type(2) {
      margin-left: 6px;
    }
  }

  .settings {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

:deep(.el-input__wrapper) {
  box-shadow: none !important;
}

.top_title {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 0px;
}

:deep(.el-tabs__nav) {
  padding: 0 45px;
}

:deep(.el-loading-mask) {
  background: #fafafa;
}

</style>
