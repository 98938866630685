<template>
  <div>
    <Forms :isCreate="true">
      <template #createMessage>
<!--        <div class="inner_outbox">-->
          <el-row :gutter="24">
            <el-col :span="6">
              <el-form-item :label="$t('common.region.name')" prop="zoneAddress">
                <el-select v-model="ruleForm.zoneAddress" :placeholder="$t('common.please.select')" filterable
                           value-key="country"
                           @change="selectMore($event)"
                >
                  <el-option
                      v-for="item in areaList"
                      :label="item.country"
                      :value="item"
                      :key="item.country"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                            prop="customerCode">
                <template #label>
                  <span><span style="color: #e47470;margin-right: 4px">*</span>客户编号</span>
                </template>
                <el-input v-model="ruleForm.customerCode" :placeholder="ruleForm.customerCodeDefault"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item :label="$t('common_customer_name')"
                            prop="customerName">
                <el-input v-model="ruleForm.customerName" :placeholder="$t('common.please.input')"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                  :label="$t('common.customer.source')"
                  prop="customerOrigin"
              >
                <el-input
                    v-model="ruleForm.customerOrigin"
                    :placeholder="$t('common.please.input')"
                />
              </el-form-item>
            </el-col>
          </el-row>
<!--        </div>-->
      </template>
    </Forms>
  </div>
</template>

<script setup>
import {reactive, computed, ref, onMounted} from "vue";
import {useRoute, useRouter} from "vue-router";
import Forms from "./CreateCheck/Forms.vue";

import {useStore} from "vuex";
import sessionStorageUtil from "@/utils/sessionStorageUtil";

const store = useStore();
const route = useRoute();
const ruleForm = computed(() => store.state.customer.ruleForm);
const form = computed(() => store.state.customer.form);
// 日期的处理
const teamId = computed(() => store.state.email.teamID)

// 区域列表
const areaList = computed(() => store.state.customer.areaList);
const randomCustomerCode = computed(() => store.state.customer.randomCustomerCode)
const disabledDate = (time) => {
  return time.getTime() > Date.now();
};

let otherDesCompanyVisible = ref(false);
const otherDesSalVisible = ref(false);
const showTextarea = () => {
  // 获取客户信息
  otherDesCompanyVisible.value = true;
};
const isSelected = computed(() => store.state.customer.isSelected)

async function selectMore(event) {
  ruleForm.value.zoneAddress = event.country;
  ruleForm.value.zoneCode = event.zoneCode;
  ruleForm.value.customerIds = event.zoneCode

//   获取 随机生成的 id
  const payload = {teamId: teamId.value, zoneCode: event.zoneCode}
  await store.dispatch("customer/getCustomerCodeApi", payload)
  ruleForm.value.customerCodeDefault = randomCustomerCode.value;
  store.commit("customer/setIsSelected", true)
}


onMounted(async () => {
  await store.dispatch('customer/getAreaNames')
});
</script>

<style lang="less" scoped>
.inner_outbox {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  height: 86px !important;
}


:deep(.el-date-editor.el-input) {
  width: 100% !important;
}

:deep(.el-select) {
  width: 100% !important;
}

:deep(.el-form-item__content) {
  justify-content: center !important;
}

</style>
