<template>
  <div class="inners">
    <div class="right">
      <div class="titles">申请开票</div>
      <div class="top_btn">
        <div>
          已选择金额：<span class="totals"
        >¥ {{ computedPayNumbers.toFixed(2) }}</span
        >
        </div>
        <el-button type="primary" @click="requestOpen">开票</el-button>
      </div>
      <div>
        <el-table
            :data="tableData"
            style="width: 100%"
            :row-style="{ height: '70px' }"
            v-loading="loading"
            @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"/>
          <el-table-column prop="orderCode" label="订单编号" width="180"/>
          <el-table-column prop="productName" label="产品和服务" width="180"/>
          <el-table-column prop="payTime" label="付款时间"/>
          <el-table-column prop="payMoney" label="实付金额">
            <template #default="{ row, column, $index }">
              <span style="font-size: 22px">￥</span>
              <span style="font-size: 22px">
             {{ row.payMoney !== null && Object.keys(row).length > 0 ? row.payMoney.toFixed(2) : row.payMoney }}
            </span>
            </template>
          </el-table-column>
        </el-table>
        <div class="pages">
          <el-pagination
              v-model:current-page="current"
              v-model:page-size="size"
              v-if="total"
              layout="total, prev, pager, next"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>

      <Dialog
          v-model="dialogVisible"
          :title="'申请开票'"
          :dialogVisible="dialogVisible"
          :isHeadLine="true"
          :width="400"
          @update:dialogVisible="(val) => (dialogVisible = val)"
      >
        <template #content>
          <el-form
              ref="ruleFormRef"
              :model="ruleForm"
              :rules="rules"
              class="demo-ruleForm"
              :size="formSize"
          >
            <el-form-item prop="title">
              <el-input
                  v-model="ruleForm.title"
                  placeholder="请输入发票的抬头"
              />
            </el-form-item>
            <el-form-item prop="text">
              <el-input
                  v-model="ruleForm.text"
                  type="textarea"
                  placeholder="请输入开票的信息"
              />
            </el-form-item>
            <el-form-item prop="email">
              <el-input v-model="ruleForm.email" placeholder="请输入邮箱地址"/>
            </el-form-item>
          </el-form>
        </template>
        <template #footer>
          <div class="footer_btn">
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="sendInvoice">申请开票</el-button>
          </div>
        </template>
      </Dialog>
    </div>
  </div>
</template>
<script setup>
import {onMounted, ref} from "vue";
import {useRouter} from "vue-router";

import Dialog from "@/components/Dialog.vue";
import {getQueryApplyInvoiceList, mailInvoiceAdd} from "@/api/usercenter";
import {ElMessage} from "element-plus";

const router = useRouter();
const tableData = ref([]);
const loading = ref(false)
const current = ref(1);
const size = ref(10);
const total = ref(100);
const handleCurrentChange = (page) => {
  current.value = page
};

// 选择
const computedPayNumbers = ref(0);
const computedPayNumberInfo = ref([])
const handleSelectionChange = (val) => {
  console.log(val, "res");
  const resSums = val.reduce((accumulator, currentValue) => {
    console.log(accumulator, currentValue, "ff");
    //字符串转换成数字
    return accumulator - 0 + currentValue.payMoney * 1;
  }, 0);
  computedPayNumbers.value = resSums - 0;
  computedPayNumberInfo.value = val
};

// 申请开票
const dialogVisible = ref(false);

const ruleForm = ref([{title: "", name: "", email: ""}]);
const requestOpen = () => {
  if (computedPayNumberInfo.value.length >= 1) {
    dialogVisible.value = true;
  } else {
    ElMessage.warning('请您选择开票的订单')
  }
};

const getQueryApplyInvoiceListApis = async () => {
  loading.value = true;
  const res = await getQueryApplyInvoiceList({
    pageNo: current.value,
    pageSize: size.value
  })
  if (res.code !== 200) {
    ElMessage.error(res.message);
    return
  }
  loading.value = false;
  const {records} = res.result;
  tableData.value = records;
  current.value = res.result.current;
  size.value = res.result.size;
  total.value = res.result.total;
}

const sendInvoice = async () => {
//   开票
  console.log(computedPayNumberInfo.value, "computedPayNumberInfo")
  const {title, text, email} = ruleForm.value
  const targetObj = {
    invoiceContent: title,
    invoiceInfo: text,
    invoiceEmail: email,
    mailPayOrderList: computedPayNumberInfo.value
  };
  console.log(targetObj, 'obj')
  const res = await mailInvoiceAdd(targetObj);
  if (res.code !== 200) {
    ElMessage.error(res.message);
    return
  }
  ElMessage.success(res.message);
  dialogVisible.value = false
  await getQueryApplyInvoiceListApis()
}


onMounted(async () => {
  await getQueryApplyInvoiceListApis()
})
</script>

<style lang="less" scoped>
@import "./less/Tables.less";
@import "../../less/page.less";

.inners {
  width: 840px !important;
  min-width: 840px !important;

  .right {
    height: max-content !important;
    //overflow: auto !important;
    padding-bottom: 40px !important;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 235px;
  margin-top: 40px;
}

:deep(.el-form) {
  width: 100% !important;
}

:deep(.el-form-item__content),
:deep(.el-select) {
  width: 100% !important;
}

:deep(.el-form-item__content) {
  display: flex;
  justify-content: center;
}

#buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

#avatar {
  display: flex;
  justify-content: center;
}

#sp_input {
  border-right: none !important;
}

:deep(.el-input__wrapper) {
  padding: 1px 0px 1px 11px !important;
}

.top_btn {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.totals {
  color: #7ca5f9;
}

.footer_btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>
