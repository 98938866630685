<template>
  <div
      v-if="item.productName=='工位特惠购'"
      class="item"
      :style="{
      background: item.isSelect
        ? `url(${item.selectTarget}) -1px -1px`
        // : `url(${item.preBackGround}) -1px -1px`,
        : '#fff',
      border: item.isSelect
        ? '1px solid #d93737 !important'
        : '1px solid transparent !important',
      transition: 'border 0.2s',
    }"
      @click="selectItem(item)"
  >
    <div class="title">{{ `${item.productName}${item.productUnit}` }}</div>
    <div class="nowpricebox">
      <div class="nowprice">
        <span class="unit">￥</span>
        <span class="intnum">{{ `${item.productPrice}`.split(".")[0] }}.</span
        ><span class="floatnum">{{ `${item.productPrice}`.split(".")[1] }}</span>
      </div>
      <div class="isvip">
        <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAOCAYAAABO3B6yAAAAAXNSR0IArs4c6QAAAxdJREFUSEu9kl1MU2cYx3/vKaXSFCi1BSmQicKicTEz0ZQoITqJEzWNGJeARgh4gZtupomaXazFqHMxSqLbomHGGCeKSrb4gZqxDzEmONBlflwsgOIGTopSaqvlu+cYjvEoNnIHz937f9/3//yeD9GclZUnFKVSgTQmMAR0KEKUiRaHo32ik7+qcwRCNDscypuFx3yQSdIXaxBCIA8M0t/yH0+OnkV+FiJ192ZCf/+Dv6ZO+2Ka/yHmFTl0fVfNUOcTTAvmYF6WTWfFj4R7Alg+WUJ8XjZStJ7eOy10fV+N3Nuv/Y8ASFj5EbaSlfhrr4IQmJfn8PR8Pd1VtWSe2Ufgj0a6DpzQDCwFS7EWLuPfTbsZ7PAyuTCPyQV53C9xY5z9PsmudQTrbzD02AeSDt/JixCW3w1gW59PgnMRbaUewoHnZJzeqxr0/PQr6Yfc+Grq8FXVagaJnxVg/ng+9wq3qZUlbSwkbrGD1tUurEVOLPmL6Tl3hZ6aOrWLb0dEB5K3lhCbPQf/hXoM09OImZHOw/KDoCik7fqcrsoaApeuaT52dxnGWRncK9iqaimeDUS/Z+fBeg/6ZBspnjKi7YnIg0P4qi/j//m3UQwRAGl7XBimpdLf2s6w7ymBugb67rYSu3Auya4i/v/6MKGmu5pJyo6NGFKn0FbqVrX0H8oZ6PDyaGelehb6KGIXzsNW5EQyxXB/zZfIfWPsQPrh7YQDz2jfUjGKNGFVLrZiJ90nL6qzDof66bvdTJJrHXE5c/EeqEKfaMG6djneg6cI/tJAXG4WOpORgfZObKX56OJNtBV/BfIYOzB1pIIHD+n85sgoAGuxE8uqXE0LXvsL775jRE+1k1r+KVGWePUueOUG3m+rkAwG7OUbMM6cpurD/iDe/cfpvdU89ghe9k2oMx8VOgnJOEmT5L4BGA6/POsk9FOshIOhiEWLspoRkwwMebtfv39t/DxiByLWdBwFCSreBhgp+xxCnFLkNwY1DhA6IToyGxv/1AAkuBxWFM+Mpqab45DvnZYjAL/rJMmdcf16w0QmfpXrBfuIN9OhNbKJAAAAAElFTkSuQmCC"
            alt=""
        />
      </div>
    </div>
    <div class="beforeprice">原价￥{{ item.productPrice }}</div>
  </div>
</template>

<script setup>
import {defineProps, computed, defineEmits} from "vue";
import {useStore} from "vuex";

const store = useStore();
const props = defineProps({
  item: {
    type: Object,
  },
});
const emit = defineEmits(["selectItems"]);

const checkIndex = computed(() => store.state.buystorage.checkIndex);

const selectItem = (value) => {
  emit("selectItems", value);
};
</script>

<style lang="less" scoped>
.item {
  width: 220px;
  height: 113px;
  margin: 0px 20px 25px 0px;
  background-repeat: no-repeat;
  overflow: hidden;
  padding-left: 10px;
  border-radius: 4px;
  cursor: pointer;

  .title {
    height: 14px;
    font-size: 14px;
    font-weight: 600;
    vertical-align: middle;
    color: rgba(0, 0, 0, 0.85);
    line-height: 14px;
    margin-top: 10px;
    margin-bottom: 6px;
  }

  .nowpricebox {
    position: relative;
    display: inline-block;
  }

  .beforeprice {
    font-size: 12px;
    color: #aaa;
    text-decoration: line-through;
    margin-top: 2px;
  }

  .nowprice {
    font-size: 18px;
    font-weight: 600;
    color: #d93737;

    .unit {
      font-size: 14px;
    }

    .intnum {
      font-size: 24px;
      letter-spacing: 0;
    }

    .floatnum {
      font-size: 16px;
    }
  }
}
</style>
