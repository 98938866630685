import _store from "store2";
import {} from "@/api/customer.js";
import { ElMessage } from "element-plus";
const customer = {
  namespaced: true,
  state: {
    ruleForm: {
      branchName: "",
      customerName: "",
      customerLevel: "",
      zoneAddress: "",
    },
  },
  mutations: {
    // 设置搜索列表
    setRuleForm(state, payload) {
      state.ruleForm = payload;
    },
    //获取数据
  },
  actions: {
    // 根据表单数据进行查询
    async searchRequest(context, payload) {
      //拷贝取消响应式
      const ruleForm = JSON.parse(JSON.stringify(context.state.ruleForm));
      // console.log(ruleForm, "按照这个条件进行查找");
    },
  },
};

export default customer;
