<template>
  <div class="inners">
    <div class="right">
      <div class="titles">意见反馈</div>
      <div>
        <div>
          <el-button type="primary" @click="handleClick">我要反馈</el-button>
        </div>
        <div style="margin-top: 20px">
          <el-table
              :data="tableData"
              style="width: 100%"
              :row-style="{ height: '70px' }"
              v-loading="loading"
              :header-cell-style="{'text-align': 'left', fontSize:'14px',color:'color: rgba(0,0,0,.85);', fontWeight: '500',background: '#f9f9f9', color: 'black', padding: '16px 0px'}"
          >
            <el-table-column prop="id" label="工单编号" width="180"/>
            <el-table-column prop="title" label="标题" width="180"/>
            <el-table-column prop="status" label="状态更新">
              <template #default="{row}">
                <el-button link type="success" v-if="row.status===2">已解决</el-button>
                <el-button link type="danger" v-else>待解决</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="updateTime" label="更新时间" width="180"/>
            <el-table-column label="操作">
              <template #default="{ row, column, $index }">
                <el-button link type="primary" @click="handleClickInfo(row)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="pages">
            <el-pagination
                v-model:current-page="current"
                v-model:page-size="size"
                v-if="total"
                layout="total, prev, pager, next"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted} from "vue";
import {useRouter} from "vue-router";

import BasicCard from "./Basic/BasicCard.vue";
import {getMailOpinionList} from "@/api/usercenter";
import {ElMessage} from "element-plus";

const router = useRouter();
const tableData = ref([
  {id: 1, titles: "标题1", status: "状态1", updateTimes: "2022-02-02"},
  {id: 2, titles: "标题2", status: "状态2", updateTimes: "2022-02-02"},
]);
const loading = ref(false)

const current = ref(1);
const size = ref(10);
const total = ref(0);
const handleCurrentChange = (page) => {
  current.value = page;
  getMailOpinionListApis();
};

const getMailOpinionListApis = async () => {
  loading.value = true;
  const res = await getMailOpinionList({
    pageNo: current.value,
    pageSize: size.value,
  })
  if (res.code !== 200) {
    ElMessage.error(res.message);
    return
  }
  const {records} = res.result;
  tableData.value = records;
  current.value = res.result.current;
  size.value = res.result.size;
  total.value = res.result.total;
  loading.value = false

}
const handleClick = () => {
  router.push("/personal/createdRepairOrder");
};

const handleClickInfo = (row) => {
  router.push({path: '/personal/repairOrderDetails', query: {id: row.id}})
}

onMounted(async () => {
  await getMailOpinionListApis()
})
</script>

<style lang="less" scoped>
@import "./less/Tables.less";
@import "../../less/page.less";

.inners {
  width: 840px !important;
  min-width: 840px !important;

  .right {
    height: max-content !important;
    padding-bottom: 20px !important;
  }
}

</style>
